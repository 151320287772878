import React from 'react';
import styles from './employee_detail_view.module.css';
import { ContactIcon, ArrowLeftIcon, FacebookIcon, LinkdinIcon, MoreIcon, TrashIcon, TwitterIcon } from '../../../../components/protected/Icons/IconsLib';
import { ActionButton, Divider, Flex, Heading, Item, Link, ListView, TextField, View } from '@adobe/react-spectrum';
import moment from 'moment';
import { usePreloadAssets } from '../../../../hooks/UsePreloadAssets';
import ChevronRight from '@spectrum-icons/workflow/ChevronRight';

const EmployeeDetailView = ({contact,handleEmployeeCloseClick,handleEmployerClick}:any) => {
const{contactSettings} = usePreloadAssets();
// Required fields to always display
const requiredFields = ["Mobil", "E-mail", "Telefon"];
const requiredIcons = ["Facebook", "Twitter", "Linkedin"];

// Function to generate contact details UI
const getContactDetailsUI = (contact: any): JSX.Element => {
  const contactDetails: Record<string, { value: string | undefined; className: string }> = {};
  
  // Extract contacts from response
  const contactsArray: any[] = contact?.CONTACTS?.CONTACT
    ? Array.isArray(contact?.CONTACTS.CONTACT)
      ? contact?.CONTACTS.CONTACT
      : [contact?.CONTACTS.CONTACT]
    : [];

  // Organize contacts based on type
  contactsArray.forEach((c) => {
    const typeInfo = contactSettings[c.type];
    if (typeInfo) {
      contactDetails[typeInfo.name] = { value: c.content, className: typeInfo.className };
    }
  });

  // Prioritize direct fields (fallback to array values if unavailable)
  if(!contactDetails){
    <View UNSAFE_className={styles['frame-group']}>
      
    </View>
  }
  contactDetails["Mobil"] = { value: contact.mobile || contactDetails["Mobil"]?.value, className: "mobile-parent" };
  contactDetails["E-mail"] = { value: contact.email || contactDetails["E-mail"]?.value, className: "e-mail-parent" };
  contactDetails["Telefon"] = { value: contact.phone || contactDetails["Telefon"]?.value, className: "phone-parent" };
  
  // Ensure required fields always appear
  requiredFields.forEach((field) => {
    if (!(field in contactDetails)) {
      contactDetails[field] = { value: "N/A", className: `${field.toLowerCase()}-parent` };
    }
  });

  const labelMap: Record<string, string> = {
    "Mobil": "Mobile",
    "Telefon": "Phone"
  };
  return (
    <View UNSAFE_className={styles['frame-group']}>
      {Object.entries(contactDetails).map(([label, { value, className }]) => {
        const displayLabel = labelMap[label] || label;
        return (<View key={label} UNSAFE_className={styles[className]}>
          <View UNSAFE_className={styles.mobile}>{displayLabel}</View>
          <View UNSAFE_className={styles.div}>{value}</View>
        </View>)
      })}
    </View>
  );
};

const getIconsDetailsUI = (contact: any): JSX.Element => {
  const contactDetails: Record<string, { value: string | undefined; className: string, icon?: JSX.Element }> = {};
  
  // Extract contacts from response
  const contactsArray: any[] = [];
  
  if (contact.CONTACTS) {
    if (Array.isArray(contact.CONTACTS)) {
      // Handle case where CONTACTS is an array of objects with CONTACT property
      contactsArray.push(...contact.CONTACTS.map((item:any) => item.CONTACT));
    } else if (contact.CONTACTS.CONTACT) {
      // Handle case where CONTACTS.CONTACT is an array 
      if (Array.isArray(contact.CONTACTS.CONTACT)) {
        contactsArray.push(...contact.CONTACTS.CONTACT);
      } else {
        // Handle case where CONTACTS.CONTACT is a single object
        contactsArray.push(contact.CONTACTS.CONTACT);
      }
    }
  }

  // Organize contacts based on type
  contactsArray.forEach((c) => {
    const typeInfo = contactSettings[c.type];
    if (typeInfo) {
      contactDetails[typeInfo.name] = { value: c.content, className: typeInfo.className };
    }
  });

  // Set social media icons with correct values and icons
  contactDetails["Facebook"] = { 
    value: contactDetails["Facebook"]?.value, 
    className: "icon_facebook", // Fixed className (was "icon_twitter")
    icon: <FacebookIcon size={24} />  // Fixed icon (was TwitterIcon)
  };
  
  contactDetails["Twitter"] = { 
    value: contactDetails["Twitter"]?.value, 
    className: "icon_twitter", // Fixed className (was "icon_facebook")
    icon: <TwitterIcon size={24} /> 
  };
  
  contactDetails["LinkedIn"] = { 
    value: contactDetails["LinkedIn"]?.value, 
    className: "icon_linkedin",
    icon: <LinkdinIcon size={24} /> 
  };

  // Ensure required fields always appear
  requiredIcons.forEach((field) => {
    if (!(field in contactDetails)) {
      contactDetails[field] = { 
        value: undefined, // Changed from "N/A" to undefined for better link handling
        className: `icon_${field.toLowerCase()}`
      };
    }
  });

  // Generate UI elements with proper link handling
  return (
    // <View UNSAFE_className={styles['socials-icons2']}>
    //   {Object.entries(contactDetails).map(([label, { value, className, icon }]) => (
    //     <View key={label} UNSAFE_className={className}>
    //       {value ? (
    //         <Link target='_blank' UNSAFE_className={styles[`${className}_active`]} href={value}>{icon}</Link>
    //       ) : (
    //         <span>{icon}</span> // When no link is available, just show the icon
    //       )}
    //     </View>
    //   ))}
    // </View>
    <View UNSAFE_className={styles.iconsstates}>
      {Object.entries(contactDetails)
        .filter(([label]) => ["Facebook", "Twitter", "LinkedIn"].includes(label))
        .map(([label, { value, className, icon }]) => (
          <View key={label} UNSAFE_className={className}>
            {value ? (
              <Link target='_blank' UNSAFE_className={styles[`${className}_active`]} href={value}>
                {icon}
              </Link>
            ) : (
              <span>{icon}</span>
            )}
          </View>
        ))}
    </View>
  );
};

const handleEmployerListClick = (key:any)=>{
  const filteredEmployer = contact.EMPLOYMENTS[key]
  if(filteredEmployer){
    handleEmployerClick(filteredEmployer.COMPANY.id);
  }
}

  return (
    <Flex UNSAFE_className={styles.detail}>
      <Flex UNSAFE_className={styles['header-wrapper']}>
        <Flex UNSAFE_className={styles.header}>
          <View UNSAFE_className={styles['header-child']} />
          <Flex UNSAFE_className={styles['header-parent']}>
            <Flex UNSAFE_className={styles.header1}>
              <Flex UNSAFE_className={styles['frame-parent']}>
                <View UNSAFE_className={styles['icon-contacts-parent_title']}>
                <ActionButton  isQuiet onPress={handleEmployeeCloseClick}>
                  <ArrowLeftIcon size={20} color={'#ff9800'} />
                </ActionButton>
                  <b className={styles['adam-novotn']}><span className={
                   +contact?.folderCount > 0 ?styles.contact_icon_orange:styles.contact_icon_grey
                  }>
                    </span> {contact?.name1} {contact?.name3}</b>
                </View>
                {getContactDetailsUI(contact)}
              </Flex>
            </Flex>
            {/* <Flex UNSAFE_className={styles['frame-container']}>
              <View UNSAFE_className={styles['mobile-parent']}>
                <View UNSAFE_className={styles.mobile}>Last modification</View>
                <View UNSAFE_className={styles['alena-janik']}>{moment(contact.modified).format('MM/DD/YYYY')}</View>
              </View>
              <View UNSAFE_className={styles['phone-parent']}>
                <View UNSAFE_className={styles.mobile}>Modified by</View>
                <View UNSAFE_className={styles['alena-janik']}>{contact.modifiedByName}</View>
              </View>
              <View UNSAFE_className={styles['created-parent']}>
                <View UNSAFE_className={styles.mobile}>Created</View>
                <View UNSAFE_className={styles.div3}>{moment(contact.created).format('MM/DD/YYYY')}</View>
              </View>
            </Flex> */}
          </Flex>
          {getIconsDetailsUI(contact)}
        </Flex>
      </Flex>

      {/* <Flex UNSAFE_className={styles['frame-div']}>
        {contact.street && 
            <View UNSAFE_className={styles['address-parent']}>
              <b className={styles.address}>Address</b>
              <View UNSAFE_className={styles['value-wrapper']}>
                  <View UNSAFE_className={styles.value}>
                    <p className={styles.bristol}>{contact?.street}</p>
                    <p className={styles.bristol}>{contact?.city}</p>
                    <p className={styles.bristol}>{contact?.zip}</p>
                  </View>
              </View>
            </View>
          }
      </Flex> */}
{contact.EMPLOYMENTS.length > 0 &&
      <Flex UNSAFE_className={styles['frame-parent1']}>
        <View UNSAFE_className={styles["employee-container"]} width="100%">
      <Flex justifyContent="space-between" alignItems="center">
        <Heading level={5}>Employer</Heading>
      </Flex>
      <ListView 
        UNSAFE_className={styles["employee-list"]}
        selectionMode='none'
        onAction={handleEmployerListClick}
      >
        {contact.EMPLOYMENTS.map((employee:any,index:number) => (
          <Item key={index} textValue={employee.COMPANY.name}>
            <Flex direction={'column'}>
              <View UNSAFE_className={styles["employee-name"]}>{employee.COMPANY.name}</View>
              <View   UNSAFE_className={styles["employee-details"]}>
                {employee.COMPANY.positionInfo} {employee.COMPANY.departmentName && <ChevronRight size='S' UNSAFE_style={{color:'#909090'}}/>}
                {employee.COMPANY.positionName} 
                {employee.COMPANY.departmentName && <ChevronRight size='S' UNSAFE_style={{color:'#909090'}}/>}
                {employee.COMPANY.departmentName}
              </View>
            </Flex>
          </Item>
        ))}
      </ListView>
    </View>
      </Flex> 
}
    </Flex>
  );
};

export default EmployeeDetailView;