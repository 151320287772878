import { Accordion, ComboBox, Disclosure, DisclosurePanel, DisclosureTitle, Divider, Flex, Heading, Item, TagGroup, Text, TextField, View } from "@adobe/react-spectrum";
import PersonDropdown from "../formComponents/PersonDropDown";
import { useEffect, useState } from "react";
import { ContactFormData } from "../AddNewPerson";
import { EditIcon } from "../../../../../components/protected/Icons/IconsLib";
import { truncateText } from "../../../../../utils/helperUtil";
import { useDependency } from "../../../../../contexts/DependencyProvider";
import { ListContactListRequest } from "../../../../../services/soap/main/requests/ListContactListRequest";
interface IPersonsSectionProps{
      data: ContactFormData['relations'];
      onChange: (data: ContactFormData['relations']) => void;
      onAdd: (relation:any,relationType:string,relationId:string) => void;
      contactType:string //p for Person and c for Company
}
const PersonsSection: React.FC<IPersonsSectionProps> = ({ data, onChange,onAdd,contactType }) => {
    const{mainService,store} = useDependency();
    const[selectedPersonType,setSelectedPersonType] = useState<any>("");
    const [expandedSections, setExpandedSections] = useState<Set<number>>(new Set());
    const [personRelations,setPersonRelations] = useState<any>([]);


    const getPersonRelation = async () => {
      const response = await mainService.listContactList(
        new ListContactListRequest(store.Server, store.SessionId, {
          list: `relationtype_${contactType}`,
        })
      );
      if (response.result === "OK" && response.count > 0) {
        const resultList = [...response.ITEM];
    
        const differentRecords = response.ITEM
          .filter((item: any) => item.name !== item.name2)
          .flatMap((item: any) => {
            const reversedItem = { ...item, name: item.name2, name2: item.name };
            const exists = resultList.some(
              (existing) =>
                existing.name === reversedItem.name
            );
    
            return exists ? [item] : [item, reversedItem];
          });
    
        resultList.push(...differentRecords);
        setPersonRelations(response.ITEM);
      }
    };
    

    useEffect(()=>{
      getPersonRelation();
    },[])

    const handlePersonSelectionChange = (person:any)=>{
      const relation = personRelations.find((x:any)=>x.id ===selectedPersonType)
      onAdd(person,relation.name,selectedPersonType)
    }

    const onRemove = (index:number) => {
      const updatedContacts = data.filter((_, i) => i !== index);
      setExpandedSections((prev) => {
        const newSet = new Set(prev);
        newSet.delete(index);
        return newSet;
      }); 
      onChange(updatedContacts); 
    };
  
    const onEdit = (data:any,index:number)=>{
      setExpandedSections((prev) => {
        const newSet = new Set(prev);
        if (newSet.has(index)) {
          newSet.delete(index);
        } else {
          newSet.add(index);
        }
        return newSet;
      });
    }

    return (
    <View padding="size-200" paddingBottom='size-0' paddingTop='size-300'>
      <Heading level={5}>{contactType==='p' ? 'Persons' : 'Companies'}</Heading>
      <Divider />
      <Flex direction="column" gap="size-100" width='100%' marginTop={10}>
      {data.map((tag, index) => ( 
              <View key={index}>
              <Flex key={index} gap="size-100" direction='row' width='100%'>
                <TagGroup
                  items={data}
                  onRemove={()=>onRemove(index)}
                  aria-label="Removable TagGroup example"
                  width="100%"
                  >
                  <Item>{tag.contact2Name1 && tag.contact2Name3  ? tag.contact2Name1+' '+ tag.contact2Name3 + '      Is ' +tag.typeName1 :
                   tag.contact2Name1 ? tag.contact2Name1 + '      Is ' +tag.typeName1:''}</Item>
                </TagGroup>
                  <a onClick={()=>onEdit(tag,index)}>
                      <EditIcon size={20}/> 
                  </a>
              </Flex>
              {(expandedSections.has(index) || !!data[index].description) &&(
                  <Flex key={index+1} direction='column'>
                  <Flex direction="row" gap="size-100">
                  <TextField 
                    value={tag.description} 
                    onChange={(description) => { 
                      const newData = [...data]; 
                      newData[index] = { ...tag, description }; 
                      onChange(newData); 
                    }} 
                    width="100%" 
                  />
                  </Flex>
                  </Flex>
              )
              }
              </View>
            ))}
            {data.length > 0 &&
              <Divider /> 
            }
        <Flex gap="size-100" direction='row' width='100%'>
        <ComboBox
          label="Type"
          selectedKey={selectedPersonType}
           onSelectionChange={(person) => {
              setSelectedPersonType(person?.toString())
            }
          }
          width='30%'
        >
          {personRelations.map((relation:any) => (
              <Item key={relation.id}>{relation.name}</Item>
          ))}
        </ComboBox>
        <PersonDropdown
        contacType={contactType}
        selectedPerson=""
        onChange={(person)=>{handlePersonSelectionChange(person)}} 
        isDisabled={selectedPersonType ===""} 
        width="70%"/>
        </Flex>
      </Flex>
    </View>
  );
}
export default PersonsSection