import { useEffect, useState } from 'react';
import { LayoutComponent } from '../LayoutComponent';
import { Cell, Column, Row, TableView, TableBody, TableHeader, Flex, Heading, Content, View, Link, ProgressCircle, DialogContainer, Dialog } from '@adobe/react-spectrum';
import moment from 'moment';
import { formatDecimalToString } from '../../../../utils/helperUtil';
import ProjectProductAddEdit from './ProjectProductAddEdit';
import { useDependency } from '../../../../contexts/DependencyProvider';
import { ListProjectItemRequest } from '../../../../services/soap/item/requests/ListProjectItemRequest';
import { ListProjectItemRequestOptions_ProjectItem } from '../../../../services/soap/item/requests/ListProjectItemRequestOptions';
import { useViewInfo } from '../../../../hooks/UseViewInfo';
import { useTranslation } from 'react-i18next';
import { FPAData, FPADataTypes } from '../../../../infra/protected/FPA/FPAData';
import { UpdateProjectItemRequest } from '../../../../services/soap/item/requests/UpdateProjectItemRequest';
import { generateProjectProductItem } from '../../../../services/soap/item/requests/CreateProjectItemOptions';
import { ToastQueue } from '@react-spectrum/toast';
import { GetItemRequest } from '../../../../services/soap/item/requests/GetItemRequest';
import { CreateProjectItemRequest } from '../../../../services/soap/item/requests/CreateProjectItemRequest';
import { DeleteProjectItemRequest } from '../../../../services/soap/item/requests/DeleteProjectItemRequest';
import useComonentReload from '../../../../hooks/UseComponentReload';
import styles from './project_product_component.module.css';

interface ProjectProductProps {
  attrib0ColumnName?: string;
  attrib1ColumnName?: string;
  attrib2ColumnName?: string;
  attrib0ColumnVisible?: boolean;
  attrib1ColumnVisible?: boolean;
  attrib2ColumnVisible?: boolean;
  billingDateColumnName?: string;
  billingDateColumnVisible?: boolean;
  componentTitle?: string;
  minHeight?: number;
  percentWidth?: number;
  productType?: number;
  quantityColumnName?: string;
  quantityColumnVisible?: boolean;
  serialNumberColumnName?: string;
  serialNumberColumnVisible?: boolean;
  serialNumberComponentIdent?: string;
  unitPriceColumnName?: string;
  unitPriceColumnVisible?: boolean;
  supplierColumnVisible?: boolean;
  selectedItem?: FPAData;
  isNew?: boolean;
  setData?: (dataItems: any[]) => void;
  isRequired?: boolean; // need for Create New Context
}

export interface RowData {
  id: string;
  quantity: number;
  partID: string;
  _refId?: string;
  supplier?: string;
  supplierName?: string;
  partNumber: string;
  productName: string;
  currency: string;
  unitPrice: number;
  amountWithVat: number;
  vatValue: number;
  totalPrice: string;
  totalPriceWithVat: string;
  attrib0: string;
  attrib1: string;
  attrib2: string;
  refId: string;
  totalQuantity: number;
  amount: number;
  billingDate: string;
}

function ProjectProduct({
  attrib0ColumnName = 'Col.0',
  attrib1ColumnName = 'Col.1',
  attrib2ColumnName = 'Col.2',
  attrib0ColumnVisible = false,
  attrib1ColumnVisible = false,
  attrib2ColumnVisible = false,
  billingDateColumnName = 'Billing Date',
  billingDateColumnVisible = false,
  componentTitle = 'Project products',
  minHeight = 64,
  percentWidth = 100,
  productType = 2,
  quantityColumnName = 'Quantity',
  quantityColumnVisible = false,
  serialNumberColumnName = 'Serial Number',
  serialNumberColumnVisible = false,
  serialNumberComponentIdent = '',
  unitPriceColumnName = 'Unit Price',
  unitPriceColumnVisible = false,
  supplierColumnVisible = false,
  selectedItem,
  isNew,
  setData,
  isRequired,
}: ProjectProductProps) {
  const { t } = useTranslation();

  let defaultRowData: RowData = {
    id: '0',
    quantity: 1,
    partID: '',
    _refId: '',
    partNumber: '',
    productName: '',
    currency: '',
    unitPrice: 0,
    amountWithVat: 0,
    vatValue: 0,
    totalPrice: '0',
    totalPriceWithVat: '0',
    attrib0: '',
    attrib1: '',
    attrib2: '',
    refId: '',
    totalQuantity: 0,
    amount: 0,
    supplier: '',
    supplierName: '',
    billingDate: moment().toISOString(),
  };

  const [reloadComponent] = useComonentReload();
  const { itemService, store } = useDependency();
  const { isMobile } = useViewInfo();
  const [projectProductData, setProjectProductData] = useState<any>([]);
  const [rowsData, setRowsData] = useState<any>([]);
  const [selectedRow, setSelectedRow] = useState<RowData>(defaultRowData);
  const [showLoader, setShowLoader] = useState<boolean>(true);
  const [showAddEditRow, setShowAddEditRow] = useState<boolean>(false);
  const [selectedKeys, setSelectedKeys] = useState<Set<any>>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [columnsData, setColumnsData] = useState<any[]>([
    { name: 'Ref Id', key: 'partNumber', width: 120 },
    { name: 'Product', key: 'productName' },
    { name: 'Supplier', key: 'supplierName' },
    { name: attrib0ColumnName, key: 'attrib0' },
    { name: attrib1ColumnName, key: 'attrib1' },
    { name: attrib2ColumnName, key: 'attrib2' },
    { name: serialNumberColumnName, key: 'refId' },
    { name: quantityColumnName, key: 'quantity', width: 100 },
    { name: unitPriceColumnName, key: 'unitPriceCurr', width: 100 },
    { name: billingDateColumnName, key: 'billingDateFormatted' },
  ]);

  const [columnsDataMobile, setColumnsDataMobile] = useState<any[]>([
    { name: 'Ref Id', key: 'partNumber', width: 120 },
    { name: 'Product', key: 'productName', width: 250 },
    { name: 'Supplier', key: 'supplierName', width: 250 },
    { name: attrib0ColumnName, key: 'attrib0', width: 120 },
    { name: attrib1ColumnName, key: 'attrib1', width: 120 },
    { name: attrib2ColumnName, key: 'attrib2', width: 120 },
    { name: serialNumberColumnName, key: 'refId', width: 120 },
    { name: quantityColumnName, key: 'quantity', width: 120 },
    { name: unitPriceColumnName, key: 'unitPriceCurr', width: 120 },
    { name: billingDateColumnName, key: 'billingDateFormatted', width: 120 },
  ]);

  useEffect(() => {
    // Define the columns to hide based on their visibility state
    const columnsToHide = [
      { key: 'supplierName', visible: supplierColumnVisible },
      { key: 'attrib0', visible: attrib0ColumnVisible },
      { key: 'attrib1', visible: attrib1ColumnVisible },
      { key: 'attrib2', visible: attrib2ColumnVisible },
      { key: 'refId', visible: serialNumberColumnVisible },
      { key: 'quantity', visible: quantityColumnVisible },
      { key: 'unitPriceCurr', visible: unitPriceColumnVisible },
      { key: 'billingDateFormatted', visible: billingDateColumnVisible },
    ];

    // Filter the columnsData array to exclude columns that are not visible
    const filteredColumnsData = columnsData.filter(column => columnsToHide.every(col => col.visible || col.key !== column.key));

    // Filter the columnsDataMobile array to exclude columns that are not visible
    const filteredColumnsDataMobile = columnsDataMobile.filter(column => columnsToHide.every(col => col.visible || col.key !== column.key));

    // Update the state with the filtered columns data
    setColumnsData(filteredColumnsData);
    setColumnsDataMobile(filteredColumnsDataMobile);
  }, [
    // Dependencies: the effect will run whenever any of these visibility states change
    attrib0ColumnVisible,
    attrib1ColumnVisible,
    attrib2ColumnVisible,
    serialNumberColumnVisible,
    quantityColumnVisible,
    unitPriceColumnVisible,
    billingDateColumnVisible,
    supplierColumnVisible,
  ]);

  useEffect(() => {
    setShowAddEditRow(false);
    (async () => {
      setShowLoader(true);
      !isNew && (await loadProjectProductData());
      setShowLoader(false);
    })();
  }, [selectedItem, productType]);

  const updateProjectProductData = async (projectProductData: any[]) => {
    if (!projectProductData) return [];
    return Promise.all(
      projectProductData.map(async item => {
        const partNumber = await getPartNumber(item);
        return { ...item, _refId: `${partNumber}_${item.productName}_ref`, partNumber };
      })
    );
  };

  const setRecordIds = (record: any, selectedItem: any) => {
    switch (selectedItem?.type) {
      case FPADataTypes.ACTIVITY:
        record.folderId = selectedItem?.parent?.parent_id;
        record.projectId = String(selectedItem?.parent_id);
        record.activityId = String(selectedItem?.id);
        break;
      case FPADataTypes.PROJECT:
        record.folderId = '';
        record.projectId = String(selectedItem?.id);
        record.activityId = '';
        break;
      case FPADataTypes.FOLDER:
        record.folderId = String(selectedItem?.id);
        record.projectId = '';
        record.activityId = '';
        break;
    }
  };

  // Function to load project product data
  const loadProjectProductData = async () => {
    // Retrieve selected item
    //console.log('selectedItem', selectedItem);
    let record: any = {};
    setRecordIds(record, selectedItem);

    // Call API to fetch project product data
    let projecrProdRes: any = await itemService.listProjectItem(
      new ListProjectItemRequest(store.Server, store.SessionId, 50, 0, new ListProjectItemRequestOptions_ProjectItem(record.folderId, record.projectId, record.activityId, String(productType)))
    );
    //  console.log('projecrProdRes', projecrProdRes);

    // Set project product data state
    setProjectProductData(projecrProdRes);

    // If API call is successful
    if (projecrProdRes?.result === 'OK') {
      // Map the project product data to desired format
      let projectProductData = projecrProdRes?.ROWS?.map((item: any) => {
        return {
          id: item.ITEM.id,
          quantity: formatDecimalToString(Number.parseFloat(item.ITEM.quantity).toFixed(2)),
          partID: item.ITEM.item,
          _refId: '',
          partNumber: 'Part Number',
          productName: item.ITEM.name,
          supplier: item.ITEM.supplier,
          supplierName: item.ITEM.supplierName,
          currency: item.ITEM.currency,
          vatValue: item.ITEM.vatValue,
          amountWithVat: item.ITEM.amountWithVat,
          unitPrice: formatDecimalToString(item.ITEM.amount),
          unitPriceCurr: formatDecimalToString(item.ITEM.amount) + ' ' + item.ITEM.currency,
          totalPrice: formatDecimalToString(Number.parseFloat(item.ITEM.totalAmount).toFixed(2)),
          totalPriceCurr: formatDecimalToString(Number.parseFloat(item.ITEM.totalAmount).toFixed(2)) + ' ' + item.ITEM.currency,
          purchasePrice: item.ITEM.purchasePrice,
          totalAmountWithVat: item.ITEM.totalAmountWithVat,
          attrib0: item.ITEM.attrib0,
          attrib1: item.ITEM.attrib1,
          attrib2: item.ITEM.attrib2,
          refId: item.ITEM.refId,
          billingDate: item.ITEM.billingDate,
          billingDateFormatted: moment(item.ITEM.billingDate).format('MM/DD/YYYY'),
        };
      });

      // Update project product data with additional information
      updateProjectProductData(projectProductData).then((updatedData: any) => {
        //console.log('updatedData', updatedData);
        setRowsData(updatedData);
        //console.log('rowsData', rowsData);
      });
    }
  };

  const getPartNumber = async (item: any) => {
    //console.log('item', item);
    let itemResp = await itemService.getItem(new GetItemRequest(store.Server, store.SessionId, item.partID));
    //console.log('itemResp', itemResp);
    return itemResp.ITEM.refId;
  };

  const handleRowClick = (rowId: any): void => {
    //console.log('rowId', rowId);
    const rowData: any = rowsData.find((row: any) => row?.id === rowId.currentKey);
    //console.log('rowData', rowData);
    setSelectedRow(rowData);
    setShowAddEditRow(true);
    isMobile && setIsOpen(true);
    setSelectedKeys(rowId);
  };

  const handleRowClose = (): void => {
    setSelectedRow(defaultRowData);
    setShowAddEditRow(false);
    isMobile && setIsOpen(false);
    handleUnselect();
  };

  const handleUnselect = () => {
    setSelectedKeys(new Set());
  };

  const addRecord = async (record: any) => {
    try {
      if (isNew && setData) {
        const updatedRowsData = {
          ...record,
          id: Math.random().toString(36).substring(7),
          unitPriceCurr: formatDecimalToString(record.unitPrice) + ' ' + record.currency,
          billingDateFormatted: moment(record.billingDate).format('MM/DD/YYYY'),
        };

        // Add the new record to the updated rows data
        const newRowsData = [...rowsData, updatedRowsData];
        // console.log('newRowsData', newRowsData);
        // Update the state with the new rows data
        setRowsData(newRowsData);
        setData(newRowsData);
        handleRowClose();
        return;
      }
      setShowLoader(true);
      //console.log('record', record);
      setRecordIds(record, selectedItem);

      let result = await itemService.createProjectItem(
        new CreateProjectItemRequest(
          store.Server,
          store.SessionId,
          generateProjectProductItem(
            record.folderId,
            record.projectId,
            record.activityId,
            record.partID,
            record.productName,
            record.quantity,
            record.unitPrice,
            record.totalPrice,
            record.totalAmountWithVat || 0,
            record.currency,
            record.vatValue || 0,
            record.purchasePrice,
            record.attrib0,
            record.attrib1,
            record.attrib2,
            record.supplier,
            record.refId,
            record.billingDate,
            { type: String(productType) }
          )
        )
      );

      await handleResult(result, t('record_saved_successfully', { ns: 'layout_components' }));
    } catch (error: any) {
      ToastQueue.negative(error.message, { timeout: 3000 });
    } finally {
      setShowLoader(false);
    }
  };

  const updateRecord = async (record: any) => {
    try {
      if (isNew && setData) {
        record.unitPriceCurr = formatDecimalToString(record.unitPrice) + ' ' + record.currency;
        record.billingDateFormatted = moment(record.billingDate).format('MM/DD/YYYY');
        const updatedRowsData = rowsData.map((row: any) => (row.id === record.id ? record : row));
        //console.log('updatedRowsData', updatedRowsData);
        setRowsData(updatedRowsData);
        setData(updatedRowsData);
        handleRowClose();
        return;
      }
      setShowLoader(true);
      //console.log('update record', record);
      let itemRow = projectProductData.ROWS.find((row: any) => row.ITEM.id == record.id);
      //console.log('itemRow', itemRow);
      setRecordIds(record, selectedItem);
      let result = await itemService.updateProjectItem(
        new UpdateProjectItemRequest(
          store.Server,
          store.SessionId,
          generateProjectProductItem(
            record.folderId,
            record.projectId,
            record.activityId,
            record.partID,
            record.productName,
            record.quantity,
            record.unitPrice,
            record.totalPrice,
            record.totalAmountWithVat || 0,
            record.currency,
            record.vatValue || 0,
            record.purchasePrice,
            record.attrib0,
            record.attrib1,
            record.attrib2,
            record.supplier,
            record.refId,
            record.billingDate,
            itemRow.ITEM
          )
        )
      );

      await handleResult(result, t('record_updated_successfully', { ns: 'layout_components' }));
    } catch (error) {
    } finally {
      setShowLoader(false);
    }
  };

  const deleteRecord = async (record: any) => {
    try {
      if (isNew && setData) {
        //console.log('record', record);
        const updatedRowsData = rowsData.filter((row: any) => row.id !== record.id);
        //console.log('updatedRowsData', updatedRowsData);
        setRowsData(updatedRowsData);
        setData(updatedRowsData);
        handleRowClose();
        return;
      }
      setShowLoader(true);
      //console.log('record', record);

      let result = await itemService.deleteProjectItem(new DeleteProjectItemRequest(store.Server, store.SessionId, record.id));
      await handleResult(result, t('record_deleted_successfully', { ns: 'layout_components' }));
    } catch (error) {
      console.log('deleteRecord Error', error);
    } finally {
      setShowLoader(false);
    }
  };

  const handleResult = async (result: any, successMessage: string) => {
    if (result.result === 'OK') {
      await loadProjectProductData();
      handleRowClose();
      ToastQueue.positive(successMessage, { timeout: 3000 });
    } else {
      ToastQueue.negative(result.EXCEPTION.reason, { timeout: 3000 });
    }
  };

  const closeDialog = () => {
    setIsOpen(false);
  };

  if (showLoader) {
    return (
      <Flex width="100%" justifyContent={'center'} marginTop={10}>
        <ProgressCircle aria-label="Loading…" isIndeterminate />
      </Flex>
    );
  } else {
    return (
      <Flex direction={'column'} gap={'size-150'} position={'relative'} width={'100%'}>
        <Flex direction={'row'} alignItems={'start'} justifyContent={'start'}>
          <Content
            position={'relative'}
            UNSAFE_className={styles.heading_text}
            UNSAFE_style={{ border: isRequired && rowsData.length === 0 ? '1px solid red' : undefined, borderRadius: '5px', padding: '10px', width: 'auto' }}
          >
            {componentTitle}
          </Content>
          {isRequired && rowsData.length === 0 && (
            <Flex direction={'row'} UNSAFE_style={{ color: 'red', fontSize: '10px', padding: '15px' }}>
              {componentTitle.toLocaleLowerCase()} required
            </Flex>
          )}
          <Flex direction={'row'} alignItems={'center'} justifyContent={'center'} gap={'size-100'} UNSAFE_className={styles.icon_add_parent}>
            <Content>
              <Link
                isQuiet
                onPress={e => {
                  setSelectedRow(defaultRowData);
                  handleUnselect();
                  isMobile && setIsOpen(true);
                  setShowAddEditRow(true);
                }}
              >
                <i className="bi bi-plus fs-5">
                  <View UNSAFE_className={styles.icon_add_text}>{t('add', { ns: 'layout_components' })}</View>
                </i>
              </Link>
            </Content>
          </Flex>
        </Flex>
        <Flex direction={'column'}>
          <Flex maxHeight={{ base: '1000px', L: '450px', M: '450px' }} width="100%" direction="column" UNSAFE_style={{ overflowX: 'auto' }}>
            <TableView
              UNSAFE_className="tblLayoutComponent"
              aria-label="Project Product"
              onSelectionChange={handleRowClick}
              selectionMode="single"
              selectedKeys={selectedKeys}
              selectionStyle="highlight"
              width={{ base: '100%', L: '100%', M: '100%' }}
              minHeight={'80px'}
              maxHeight={{ base: '1000px', L: '450px' }}
              marginBottom={'size-250'}
            >
              <TableHeader columns={isMobile ? columnsDataMobile : columnsData}>
                {column => (
                  <Column showDivider key={column.key} width={column?.width}>
                    {column.name}
                  </Column>
                )}
              </TableHeader>
              <TableBody items={rowsData}>{item => <Row>{columnKey => <Cell>{(item as any)[String(columnKey)]}</Cell>}</Row>}</TableBody>
            </TableView>
          </Flex>

          {isMobile ? (
            <DialogContainer
              isDismissable
              onDismiss={() => {
                setIsOpen(false);
              }}
            >
              {isOpen && (
                <Dialog>
                  <Heading> {selectedRow.id === '0' ? t('add', { ns: 'layout_components' }) : t('edit', { ns: 'layout_components' })}</Heading>
                  <Content>
                    <ProjectProductAddEdit
                      id={String(selectedItem?.id)}
                      selectedItemType={selectedItem?.type}
                      defaultRowData={defaultRowData}
                      selectedRowData={selectedRow}
                      handleRowClose={handleRowClose}
                      addRecord={addRecord}
                      updateRecord={updateRecord}
                      deleteRecord={deleteRecord}
                      attrib0ColumnName={attrib0ColumnName}
                      attrib1ColumnName={attrib1ColumnName}
                      attrib2ColumnName={attrib2ColumnName}
                      attrib0ColumnVisible={attrib0ColumnVisible}
                      attrib1ColumnVisible={attrib1ColumnVisible}
                      attrib2ColumnVisible={attrib2ColumnVisible}
                      billingDateColumnName={billingDateColumnName}
                      billingDateColumnVisible={billingDateColumnVisible}
                      componentTitle={componentTitle}
                      quantityColumnName={quantityColumnName}
                      quantityColumnVisible={quantityColumnVisible}
                      serialNumberColumnName={serialNumberColumnName}
                      serialNumberColumnVisible={serialNumberColumnVisible}
                      unitPriceColumnName={unitPriceColumnName}
                      unitPriceColumnVisible={unitPriceColumnVisible}
                      supplierColumnVisible={supplierColumnVisible}
                      closeDialog={closeDialog}
                      styles={styles}
                    />
                  </Content>
                </Dialog>
              )}
            </DialogContainer>
          ) : (
            showAddEditRow && (
              <ProjectProductAddEdit
                id={String(selectedItem?.id)}
                selectedItemType={selectedItem?.type}
                defaultRowData={defaultRowData}
                selectedRowData={selectedRow}
                handleRowClose={handleRowClose}
                addRecord={addRecord}
                updateRecord={updateRecord}
                deleteRecord={deleteRecord}
                attrib0ColumnName={attrib0ColumnName}
                attrib1ColumnName={attrib1ColumnName}
                attrib2ColumnName={attrib2ColumnName}
                attrib0ColumnVisible={attrib0ColumnVisible}
                attrib1ColumnVisible={attrib1ColumnVisible}
                attrib2ColumnVisible={attrib2ColumnVisible}
                billingDateColumnName={billingDateColumnName}
                billingDateColumnVisible={billingDateColumnVisible}
                componentTitle={componentTitle}
                quantityColumnName={quantityColumnName}
                quantityColumnVisible={quantityColumnVisible}
                serialNumberColumnName={serialNumberColumnName}
                serialNumberColumnVisible={serialNumberColumnVisible}
                unitPriceColumnName={unitPriceColumnName}
                unitPriceColumnVisible={unitPriceColumnVisible}
                supplierColumnVisible={supplierColumnVisible}
                closeDialog={closeDialog}
                styles={styles}
              />
            )
          )}
        </Flex>
      </Flex>
    );
  }
}

export const ProjectProductComponent = LayoutComponent(ProjectProduct);
