import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Flex, Item, ProgressCircle, ComboBox, TagGroup, ToastQueue } from '@adobe/react-spectrum';
import { useDebounce } from '@uidotdev/usehooks';
import { ListContactsContactRequest } from '../../../../../services/soap/main/requests/ListContactsContactRequest';
import { useDependency } from '../../../../../contexts/DependencyProvider';
import { useTranslation } from 'react-i18next';
import styles from './contacts_contact_filter_component.module.css';

interface ContactsContactFilterComponentProps {
  createParams: (filterType: any, filterValue: any) => void;
  fieldName: string;
}

const ContactsContactFilterComponent: React.FC<ContactsContactFilterComponentProps> = ({ createParams, fieldName }) => {
  const { t } = useTranslation();
  const { store, mainService } = useDependency();
  const [selectedContacts, setSelectedContacts] = useState<any>([]);
  const [availableContacts, setAvailableContacts] = useState<any>([]);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const refSelectedContacts = useRef<any>([]);
  const [filterTerm, setFilterTerm] = useState<string>('');
  const debouncedFilterTerm = useDebounce(filterTerm, 500);

  useEffect(() => {
    setSelectedContacts([]);
    setFilterTerm('');
    //getContactsContactData('');
  }, []);

  useEffect(() => {
    (async () => {
      if (debouncedFilterTerm !== '') {
        getContactsContactData(debouncedFilterTerm);
      }
    })();
  }, [debouncedFilterTerm]);

  const processManualEmails = (content: string): void => {
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;

    // Process all emails at once to avoid multiple renders
    const newContacts = content
      .trim()
      .split(',')
      .map(email => email.trim())
      .filter(email => !!email && emailRegex.test(email))
      .filter(email => !refSelectedContacts.current.some((contact: { name: string }) => contact.name.toLowerCase() === email.toLowerCase()))
      .map(email => ({
        id: crypto.randomUUID ? crypto.randomUUID() : Math.random().toString(36).substring(2, 11),
        name: email,
        contactName: email,
      }));

    if (newContacts.length === 0) return;

    // Update ref and state once with all new contacts
    const updatedContacts = [...refSelectedContacts.current, ...newContacts];
    refSelectedContacts.current = updatedContacts;

    // Batch state update
    setSelectedContacts((prevContacts: any) => [...prevContacts, ...newContacts]);

    // Create a Set from the map to ensure uniqueness

    const updateValue = refSelectedContacts.current.length > 0 ? new Set(refSelectedContacts.current.map((item: any) => ({ CONTENT: item.name, contact: item.contactName, id: item.id }))) : '';
    createParams(fieldName, updateValue);

    // Update parent component
    createParams(fieldName, updateValue);
  };

  const getContactsContactData = useCallback(
    async (content: string) => {
      setShowLoader(true);
      try {
        let contactsResp = await mainService.listContactsContact(new ListContactsContactRequest(store.Server, store.SessionId, { limit: 50, offset: 0, specificType: 'email', content: content }));
        if (contactsResp.result === 'OK' && contactsResp?.ROW) {
          //console.log('contactsResp.USERS', contactsResp);
          const formattedArray = contactsResp.ROW.map((row: any) => ({
            id: String(row.id),
            name: `${row.content}`,
            contactName: `${row.contactName}`,
          }));
          //console.log('formattedArray', formattedArray);
          setAvailableContacts(formattedArray);
        } else {
          processManualEmails(content);
        }
      } catch (error) {
        console.log('error', error);
        ToastQueue.negative(`Error fetching contacts: ${error}`, { timeout: 100 });
      } finally {
        setShowLoader(false);
      }
    },
    [filterTerm]
  );

  const handleSelectionChange = (selectedKey: any) => {
    const selectedContact = availableContacts.find((user: any) => user.id === selectedKey);
    //console.log('selectedContact', selectedContact);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(selectedContact.name)) {
      ToastQueue.negative('Please select a valid email address', { timeout: 100 });
      return;
    }

    if (selectedContact) {
      //console.log('selectedContact', selectedContact);
      refSelectedContacts.current = [...refSelectedContacts.current, selectedContact];
      setSelectedContacts((preValue: any) => [...preValue, selectedContact]);
      setAvailableContacts(availableContacts.filter((user: any) => user.id !== selectedKey));
      const updateValue = refSelectedContacts.current.length > 0 ? new Set(refSelectedContacts.current.map((item: any) => ({ CONTENT: item.name, contact: item.contactName, id: item.id }))) : '';
      createParams(fieldName, updateValue);
    }
  };

  const handleRemoveContact = (value: any) => {
    const arrValue = Array.from(value);
    //console.log('arrValue', arrValue[0]);
    let userId = arrValue[0];
    const removedUser = selectedContacts.find((user: any) => user.id === userId);
    if (removedUser) {
      refSelectedContacts.current = refSelectedContacts.current.filter((user: any) => user.id !== userId);
      setSelectedContacts(selectedContacts.filter((user: any) => user.id !== userId));
      setAvailableContacts([...availableContacts, removedUser]);
      const updateValue = refSelectedContacts.current.length > 0 ? new Set(refSelectedContacts.current.map((item: any) => item.name)) : '';
      createParams(fieldName, updateValue);
    }
  };

  const searchContacts = (e: any) => {
    setFilterTerm(e);
  };

  if (showLoader) {
    return (
      <Flex width="100%" height="100%" UNSAFE_style={{ backgroundColor: 'white' }} justifyContent={'center'} alignContent={'center'} alignItems={'center'}>
        <ProgressCircle aria-label="Loading…" isIndeterminate />
      </Flex>
    );
  } else {
    return (
      <Flex direction={'column'} minWidth={'200px'} alignSelf={'stretch'} alignItems={'start'} justifyContent={'start'}>
        <Flex direction={'column'} gap={'4px'} alignSelf={'stretch'} alignItems={'start'} justifyContent={'start'} UNSAFE_className={styles.contacts_container}>
          <Flex direction={'row'} alignSelf={'stretch'} alignItems={'start'} justifyContent={'start'} height={'40px'} UNSAFE_className={styles.contacts_component}>
            <ComboBox
              minWidth={'150px'}
              width={'300px'}
              selectedKey={null}
              defaultItems={availableContacts}
              onSelectionChange={handleSelectionChange}
              aria-label="none"
              onKeyDown={e => e.key === 'Enter' && e.preventDefault()}
              onInputChange={searchContacts}
            >
              {(item: any) => <Item key={item.id}>{item.name}</Item>}
            </ComboBox>
            {selectedContacts.length > 0 && (
              <>
                <TagGroup id="tagGroupContacts" aria-label="Selected Contacts" items={selectedContacts} onRemove={key => handleRemoveContact(key)} marginStart={'10px'}>
                  {(item: any) => (
                    <Item key={item.id}>
                      <img src="../../../../../../assets/images/avatar--desktop--light@2x.png" alt="" style={{ width: '20px', marginRight: '5px' }} />
                      {item.contactName}
                    </Item>
                  )}
                </TagGroup>
              </>
            )}
          </Flex>
        </Flex>
      </Flex>
    );
  }
};

export default ContactsContactFilterComponent;
