import { View, Accordion, Disclosure, DisclosureTitle, DisclosurePanel, Flex, DateField, TextArea, ComboBox, Checkbox, DatePicker, Heading, Divider } from '@adobe/react-spectrum';
import { Item } from '@react-stately/collections';
import React from 'react';
import { ContactFormData } from '../AddNewPerson';
import { CalendarDate,parseDate } from '@internationalized/date';

export const ConsentSection: React.FC<{
  data: ContactFormData['consent'];
  onChange: (data: ContactFormData['consent']) => void;
}> = ({ data, onChange }) => (
  <View padding="size-200" paddingBottom="size-0" paddingTop='size-300'>
    <Heading level={5}>Personal Data Protection</Heading>
    <Divider />
          <Flex direction="column" gap="size-100" width='100%' marginTop={10}>
            <DatePicker
              label="Consent provided"
              value={data.provided ? parseDate(data.provided) : undefined}
              onChange={(date) => onChange({ ...data, provided: date?.toString() || '' })}
              width='30%' />
            <DatePicker
              label="Consent expiration"
              value={data.expiration ? parseDate(data.expiration) : undefined}
              onChange={(date) => onChange({ ...data, expiration: date?.toString() || '' })}
              width='30%' />
            <TextArea
              label="Consent note"
              value={data.note}
              onChange={(note) => onChange({ ...data, note })}
              width='100%' />
            <ComboBox
              label="Consent status"
              selectedKey={data.status}
              onSelectionChange={(status) => onChange({ ...data, status: status ? status.toString() : "" })}
              width='30%'
            >
              <Item key="1">Unknown</Item>
              <Item key="2">Selected for update</Item>
              <Item key="3">Update pending</Item>
              <Item key="4">Valid</Item>
              <Item key="5">Expired</Item>
              <Item key="6">Erasure requested</Item>
              <Item key="7">Forgotten requested</Item>
            </ComboBox>
            <Checkbox
              isSelected={data.isAnonymized}
              onChange={(isAnonymized) => onChange({ ...data, isAnonymized })}
            >
              Anonymized
            </Checkbox>
          </Flex>
  </View>
);
