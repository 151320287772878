import React, { useEffect, useState } from 'react';
import { Flex, Content, Text, TableView, TableHeader, Column, TableBody, Row, Cell, ActionButton, Dialog, DialogTrigger, DialogContainer, View, Link, ProgressCircle } from '@adobe/react-spectrum';
import { FileUploader } from 'react-drag-drop-files';
import { ToastQueue } from '@react-spectrum/toast';
import { useTranslation } from 'react-i18next';
import { TrashIcon, AlertIconFill } from '../../Icons/IconsLib';
import { useReefConfig } from '../../../../hooks/UseReefConfig';
import { useDependency } from '../../../../contexts/DependencyProvider';
import { useViewInfo } from '../../../../hooks/UseViewInfo';
import { ListDocumentCategoryRequest } from '../../../../services/soap/document_library/requests/ListDocumentCategoryRequest';
import { CreateDocumentInStorageRequest } from '../../../../services/soap/document_library/requests/CreateDocumentInStorageRequest';
import DocumentAddEdit from './DocumentAddEdit';
import styles from './DocumentComponent.module.css';
import { getDownloadUrl } from '../../../../utils/ImagePathUtils';

const DocumentTable = ({
  documentsData = [],
  deleteRecord = (param: any) => {},
  getDocumentsData = () => {},
  selectedItem,
}: {
  documentsData?: any[];
  deleteRecord?: (param: any) => void;
  getDocumentsData?: () => void;
  selectedItem: any;
}) => {
  const { baseUrl } = useReefConfig();
  const { store, documentLibraryService } = useDependency();
  const { isMobile } = useViewInfo();
  let [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [documentCategoryTree, setDocumentCategoryTree] = useState<any>([]);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      //console.log('documentsData', documentsData);
      await loadCategoryDropDown();
    })();
  }, []);
  let columnsData = [
    { name: t('name', { ns: 'layout_components' }), key: 'name' },
    { name: t('valid_until', { ns: 'layout_components' }), key: 'validTo', width: 200 },
    { name: t('size', { ns: 'layout_components' }), key: 'fileSize', width: 100 },
    { name: t('last_modified_by', { ns: 'layout_components' }), key: 'createdByName', width: 200 },
    { name: t('action', { ns: 'layout_components' }), key: 'id', width: 125 },
  ];

  let columnsDataMobile = [
    { name: t('name', { ns: 'layout_components' }), key: 'name', width: 170 },
    { name: t('valid_until', { ns: 'layout_components' }), key: 'validTo', width: 130 },
    { name: t('size', { ns: 'layout_components' }), key: 'fileSize', width: 100 },
    { name: t('last_modified_by', { ns: 'layout_components' }), key: 'createdByName', width: 200 },
    { name: t('action', { ns: 'layout_components' }), key: 'id', width: 100 },
  ];
  const closePopUp = () => {
    setIsOpen(false);
  };

  const loadCategoryDropDown = async () => {
    const [{ item: documentCategories }] = await Promise.all([documentLibraryService.listDocumentCategory(new ListDocumentCategoryRequest(store.Server, store.SessionId))]);
    //console.log('documentCategories', documentCategories);
    const treeData = categoryTreeData(documentCategories);
    //console.log('treeData', treeData);
    setDocumentCategoryTree(treeData);
  };

  const categoryTreeData = (data: any) => {
    const map: any = {}; // Map to hold items by their ID
    const roots: any = []; // Array to hold root elements

    data.forEach((item: any) => {
      // Initialize each item in the map with an empty subChild array
      map[item.id] = { ...item, subChild: [] };

      // If the item has a parent, push it to the parent's subChild array,
      // otherwise, add it to the roots array
      item.parent ? map[item.parent].subChild.push(map[item.id]) : roots.push(map[item.id]);
    });

    return roots; // Return the array of root elements
  };

  const addSelected = (data: any, targetId: any) => {
    data.forEach((record: any) => {
      const isRootSelected = record.id === targetId;
      record.selected = isRootSelected;
      record.expanded = isRootSelected;

      record.subChild.forEach((child: any) => {
        const isChildSelected = child.id === targetId;
        child.selected = isChildSelected;
        child.expanded = isChildSelected;
        if (isChildSelected) record.expanded = true; // Expand parent if child is selected
      });
    });

    // console.log('Selected data', data);
    setDocumentCategoryTree(data);
  };

  const getBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = () => reject(new Error('Failed to read file'));
    });
  };

  const fileTypes = ['JPG', 'JPEG', 'PNG', 'GIF', 'PDF', 'XLSX', 'XLS', 'DOC', 'DOCX', 'PPT', 'PPTX', 'TXT', 'ODT', 'ODS', 'ODP', 'RTF', 'CSV', 'ZIP', 'RAR'];

  const handleUploadFile = async (files: File[], doc: any) => {
    try {
      setShowLoader(true);
      //console.log('doc', doc);
      const arrFile = Array.from(files);

      const uploadPromises = arrFile.map(async (item: any, index: any) => {
        let base64Files: any = await getBase64(item);
        //console.log('base64Files', base64Files);
        const data = base64Files.split(',')[1];
        //console.log('item', item);
        const request = new CreateDocumentInStorageRequest(
          store.Server,
          store.SessionId,
          {
            id: '',
            acl: '',
            parent: '',
            name: item.name,
            description: '',
            type: '', //doc?.type === 'No Category' ? '' : doc?.type,
            location: '',
            category: doc?.category,
            validByDate: '0',
            createdBy: '',
            fileHandle: '',
            treeHandle: String(selectedItem?.id),
            fileSize: item.size,
            internalNumber: index,
            fileName: item.name,
            deleted: '0',
            validFrom: new Date(),
            validTo: new Date(),
            storageId: '',
            isInStorage: '0',
            contextId: '',
          },
          data
        );

        const result = await documentLibraryService.createDocumentInStorage(request);
        if (result.result === 'OK') {
          ToastQueue.positive(t('record_saved_successfully', { ns: 'layout_components' }), { timeout: 3000 });
          getDocumentsData();
        } else {
          ToastQueue.negative(result.EXCEPTION.reason, { timeout: 3000 });
          return setShowLoader(false);
        }
      });

      const results = await Promise.all(uploadPromises);
      if (results.every(result => result)) {
        getDocumentsData();
      }
    } catch (exp: any) {
      ToastQueue.negative(exp.message, { timeout: 3000 });
    } finally {
      setShowLoader(false);
    }
  };

  const handleDownloadFile = (id: string) => {
    const strURL = getDownloadUrl(id, baseUrl, store.Server, store.SessionId);
    const windowReference = window.open('', '_blank', 'height=800,width=1200,scrollbars=yes,status=yes,menubar=no,toolbar=no');
    windowReference?.location.replace(strURL);
  };
  if (showLoader) {
    return (
      <Flex width="100%" justifyContent={'center'} marginTop={10}>
        <ProgressCircle aria-label="Loading…" isIndeterminate />
      </Flex>
    );
  } else {
    return (
      <>
        {documentsData.length === 0 && (
          <div
            className="divDragDrop"
            style={{ textAlign: 'end', width: '100%', height: '200px' }}
            onDragEnter={() => {
              setIsDragging(true);
              setTimeout(() => {
                setIsDragging(false);
              }, 2000);
            }}
          >
            <Link
              isQuiet
              onPress={e => {
                setIsOpen(true);
              }}
            >
              <i className="bi bi-upload fs-8">
                <View UNSAFE_className={styles.icon_upload_text}>{t('upload', { ns: 'layout_components' })}</View>
              </i>
            </Link>
            <View width={'100%'} position={'relative'} UNSAFE_className={styles.sub_heading_text}>
              {isDragging ? (
                <FileUploader
                  multiple={true}
                  label={t('drop_file_here', { ns: 'layout_components' })}
                  handleChange={(e: any) => handleUploadFile(e, {})}
                  name="file"
                  types={fileTypes}
                  classes={styles.noRecordUploader}
                />
              ) : (
                ''
              )}
            </View>
          </div>
        )}

        {documentsData.map((doc: any, index: number) => (
          <div
            className="divDragDrop"
            onDragEnter={() => {
              setIsDragging(true);
              setTimeout(() => {
                setIsDragging(false);
              }, 2000);
            }}
          >
            <Flex direction={'row'} alignItems={'start'} justifyContent={'start'}>
              <View width={'18%'} position={'relative'} UNSAFE_className={styles.sub_heading_text}>
                {doc.type} <Text UNSAFE_style={{ color: '#8f9fa6' }}>({doc.count})</Text>
              </View>

              <View width={'450px'} position={'relative'} UNSAFE_className={styles.sub_heading_text}>
                {isDragging ? (
                  <FileUploader
                    multiple={true}
                    label={t('drop_file_here', { ns: 'layout_components' })}
                    handleChange={(e: any) => handleUploadFile(e, doc)}
                    name="file"
                    types={fileTypes}
                    classes={styles.mainUploader}
                  />
                ) : (
                  ''
                )}
              </View>

              <Flex direction={'row'} alignItems={'center'} justifyContent={'center'} gap={'size-100'} UNSAFE_className={styles.icon_upload_parent}>
                <Content>
                  <Link
                    isQuiet
                    onPress={e => {
                      setIsOpen(true);
                      setSelectedCategory(doc.category ?? '');
                      addSelected(documentCategoryTree, doc.category ?? '');
                    }}
                  >
                    <i className="bi bi-upload fs-8">
                      <View UNSAFE_className={styles.icon_upload_text}>{t('upload', { ns: 'layout_components' })}</View>
                    </i>
                  </Link>
                </Content>
              </Flex>
            </Flex>
            <Flex direction={'column'}>
              <Flex width="100%" direction="column" UNSAFE_style={{ overflowX: 'auto' }}>
                <TableView
                  UNSAFE_className="tblLayoutComponent"
                  aria-label="document component"
                  width={{ base: '100%', L: '100%', M: '100%' }}
                  minHeight={'80px'}
                  maxHeight={{ base: '1000px', L: '450px' }}
                  marginBottom={'size-250'}
                  key={doc.id}
                >
                  <TableHeader columns={isMobile ? columnsDataMobile : columnsData}>
                    {(column: any) => (
                      <Column showDivider key={column.key} width={column?.width}>
                        {column.name}
                      </Column>
                    )}
                  </TableHeader>
                  <TableBody items={doc.DOCUMENT}>
                    {(item: any) => (
                      <Row>
                        {columnKey => {
                          if (columnKey === 'id') {
                            return (
                              <Cell>
                                <Flex direction={'row'} alignItems={'end'} justifyContent={'start'} gap={'24px'}>
                                  <ActionButton UNSAFE_style={{ border: 'none' }}>
                                    <i
                                      role="button"
                                      className="bi bi-download fs-8 action_icon"
                                      onClick={() => handleDownloadFile(item.id)}
                                    ></i>
                                  </ActionButton>
                                  <DialogTrigger isDismissable type="modal">
                                    <ActionButton UNSAFE_style={{ border: 'none' }}>
                                      <i className="bi bi-trash fs-8 action_icon"></i>
                                    </ActionButton>
                                    {close => (
                                      <Dialog size="S">
                                        <Flex UNSAFE_className={styles.delete_pop_up_parent}>
                                          <Content UNSAFE_className={styles.delete_pop_up_header}>{t('remove_file', { ns: 'layout_components' })}</Content>
                                          <View UNSAFE_className={styles.delete_pop_up_line}></View>
                                          <View UNSAFE_className={styles.delete_pop_up_warning_text}>{t('are_you_sure_you_want_to_remove_this_file', { ns: 'layout_components' })}</View>
                                          <View UNSAFE_className={styles.buttons_parent}>
                                            <View UNSAFE_className={styles.button_cancel}>
                                              <Link UNSAFE_className={styles.delete_pop_up_cancel_text} isQuiet onPress={close}>
                                                {t('no_keep_it', { ns: 'layout_components' })}
                                              </Link>
                                            </View>
                                            <View UNSAFE_className={styles.button_confirm}>
                                              <div className={styles.trash_icon}>
                                                <TrashIcon size={32} />
                                              </div>
                                              <Link UNSAFE_className={styles.delete_pop_up_confirm_text} isQuiet onPress={() => deleteRecord(item.id)}>
                                                {t('yes_remove', { ns: 'layout_components' })}
                                              </Link>
                                            </View>
                                          </View>
                                          <View UNSAFE_className={styles.ellipse_div}>
                                            <View UNSAFE_className={styles.delete_alert_icon}>
                                              <AlertIconFill size={72} />
                                            </View>
                                          </View>
                                        </Flex>
                                      </Dialog>
                                    )}
                                  </DialogTrigger>
                                </Flex>
                              </Cell>
                            );
                          } else if (columnKey === 'validTo') {
                            return (
                              <Cell>
                                <span>{item[columnKey].split('_')[0]} </span>
                                <span style={{ color: '#cfd8dc' }}>|</span>
                                <span> {item[columnKey].split('_')[1]}</span>
                              </Cell>
                            );
                          } else if (columnKey === 'createdByName') {
                            return (
                              <Cell>
                                <Flex flex={1} direction={'column'} alignItems={'start'} justifyContent={'center'}>
                                  <View alignSelf={'stretch'} position={'relative'} UNSAFE_style={{ lineHeight: '18px' }}>
                                    {item[columnKey].split('_')[0]}
                                  </View>
                                  <View alignSelf={'stretch'} position={'relative'} UNSAFE_style={{ fontSize: '12px', lineHeight: '16px', color: '#728289' }}>
                                    {item[columnKey].split('_')[1]}
                                  </View>
                                </Flex>
                              </Cell>
                            );
                          } else {
                            return <Cell>{item[columnKey]}</Cell>;
                          }
                        }}
                      </Row>
                    )}
                  </TableBody>
                </TableView>
              </Flex>
            </Flex>
          </div>
        ))}

        <DialogContainer
          onDismiss={() => {
            //console.log('setIsOpen');
            setIsOpen(false);
          }}
        >
          {isOpen && (
            <View>
              <DocumentAddEdit categoryId={selectedCategory} documentCategoryData={documentCategoryTree} getDocumentsData={getDocumentsData} close={closePopUp} selectedItem={selectedItem} />
            </View>
          )}
        </DialogContainer>
      </>
    );
  }
};

export default DocumentTable;
