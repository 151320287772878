import { BaseRequest } from "../../../soap/BaseRequest";

interface SignatureItem{
    name: string;
    picture: string;
}
export class PrintContextRequest extends BaseRequest {
    public print_type: string;
    public object_id: string;
    public output_format: string;
    public signatures: any;
    public template_id?: string;
    public signatureIdent: string;
    constructor(
        server: string, session: string, 
        print_type: string, object_id: string, output_format: string, 
        signatures: any, signatureIdent: string, template_id?: string) {
        super(server, session);

        this.print_type = print_type;
        this.object_id = object_id;
        this.output_format = output_format;
        this.signatures = signatures;
        this.signatureIdent = signatureIdent;
        this.template_id = template_id;
    }

    public toQueryString(): string {
        let queryString = [];
        queryString.push(`server=${this.server}`);
        queryString.push(`session=${this.session}`);
        queryString.push(`print_type=${this.print_type}`);  
        queryString.push(`object_id=${this.object_id}`);
        queryString.push(`output_format=${this.output_format}`);
        if (this.template_id && this.template_id !== 'undefined') {
            queryString.push(`template_id=${this.template_id}`);
        }
        return queryString.join('&');
    }
}
