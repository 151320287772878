import React from 'react';
import { ActionButton, Content, Dialog, DialogTrigger, Item, Menu, MenuTrigger, View } from '@adobe/react-spectrum';
import { Key } from '@react-types/shared';
import PrintDialog from './component/PrintDialog/PrintDialog';
import { FPAData } from '../../../../infra/protected/FPA/FPAData';

interface IButtonsComponentProps {
  styles: any;
  AtollonIcon: any;
  PrintIcon: any;
  EditIcon: any;
  ToolIcon: any;
  TrashIcon: any;
  onPrint?: (item: FPAData) => void;
  onEdit?: (item: FPAData) => void;
  onDelete?: (item: FPAData) => void;
  onTool?: (item: FPAData) => void;
  onAI?: (item: FPAData) => void;
  onCopy?: (item: FPAData) => void;
  onMove?: (item: FPAData) => void;
  selectedItem?: any;
}

const ButtonsComponent: React.FC<IButtonsComponentProps> = props => {
  const { styles, AtollonIcon, PrintIcon, EditIcon, ToolIcon, TrashIcon, onPrint, onEdit, onDelete, onTool, onAI, onCopy, onMove, selectedItem } = props;
  let menuItems = [
    { key: 'copy', name: 'Copy' },
    { key: 'move', name: 'Move' },
  ];

  const handlePrintClicked = () => {
    console.log('handlePrintClicked');
    if (onPrint) {
      onPrint(selectedItem);
    }
  };

  const handleEditClicked = () => {
    console.log('handleEditClicked');
    if (onEdit) {
      onEdit(selectedItem);
    }
  };

  const handleDeleteClicked = () => {
    if (onDelete) {
      onDelete(selectedItem);
    }
  };

  const handleToolClicked = () => {
    console.log('handleToolClicked');
    if (onTool) {
      onTool(selectedItem);
    }
  };

  const handleAIClicked = () => {
    console.log('handleAIClicked');
    if (onAI) {
      onAI(selectedItem);
    }
  };

  const handleMenuClicked = (key: Key) => {
    if (key === 'copy') {
      if (onCopy) {
        onCopy(selectedItem);
      }
    } else if (key === 'move') {
      if (onMove) {
        onMove(selectedItem);
      }
    }
  };

  return (
    <View UNSAFE_className={styles.icon_list_child}>
      <div className={styles.atollon_icon} onClick={handleAIClicked}>
        <AtollonIcon size={32} />
      </div>
      <div className={styles.print_icon} onClick={handlePrintClicked}>
        <DialogTrigger type="popover">
          <ActionButton UNSAFE_style={{ border: 'none' }}>
            <PrintIcon size={32} />
          </ActionButton>
          <Dialog>
            <Content>
              <PrintDialog selectedItem={selectedItem} print_type="activity" />
            </Content>
          </Dialog>
        </DialogTrigger>
      </div>
      <div className={styles.edit_icon} onClick={handleEditClicked}>
        <EditIcon size={32} />
      </div>
      <div className={styles.tool_icon} onClick={handleToolClicked}>
        <ToolIcon size={32} />
      </div>
      <div className={styles.delete_icon} onClick={handleDeleteClicked}>
        <TrashIcon size={32} />
      </div>
      {onCopy && onMove && (
        <MenuTrigger>
          <ActionButton UNSAFE_style={{ border: 'none' }}>...</ActionButton>
          <Menu items={menuItems} onAction={handleMenuClicked}>
            {item => <Item key={item.key}>{item.name}</Item>}
          </Menu>
        </MenuTrigger>
      )}
    </View>
  );
};

export default ButtonsComponent;
