import React, { useEffect } from "react";
import styles from "./employee_detail_view_mobile.module.css";
import {
  ArrowLeftIcon,
  FacebookIcon,
  LinkdinIcon,
  TwitterIcon,
} from "../../../../components/protected/Icons/IconsLib";
import {
  ActionButton,
  Divider,
  Flex,
  Heading,
  Item,
  Link,
  ListView,
  TextField,
  View,
} from "@adobe/react-spectrum";
import moment from "moment";
import { usePreloadAssets } from "../../../../hooks/UsePreloadAssets";
import ChevronRight from "@spectrum-icons/workflow/ChevronRight";

const EmployeeDetailViewMobile = ({
  contact,
  handleEmployeeCloseClick,
  handleEmployerClick,
}: any) => {
  const { contactSettings } = usePreloadAssets();
  // Required fields to always display
  const requiredFields = ["Mobil", "E-mail", "Telefon"];
  const requiredIcons = ["Facebook", "Twitter", "Linkedin"];

  const getContactDetailsUI = (contact: any): JSX.Element => {
    const contactDetails: Record<
      string,
      { value: string | undefined; className: string }
    > = {};

    const contactsArray: any[] = contact.CONTACTS?.CONTACT
      ? Array.isArray(contact.CONTACTS.CONTACT)
        ? contact.CONTACTS.CONTACT
        : [contact.CONTACTS.CONTACT]
      : [];

    contactsArray.forEach((c) => {
      const typeInfo = contactSettings[c.type];
      if (typeInfo) {
        contactDetails[typeInfo.name] = {
          value: c.content,
          className: typeInfo.className,
        };
      }
    });

    contactDetails["Mobil"] = {
      value: contact.mobile || contactDetails["Mobil"]?.value,
      className: "mobile-parent",
    };
    contactDetails["E-mail"] = {
      value: contact.email || contactDetails["E-mail"]?.value,
      className: "e-mail-parent",
    };
    contactDetails["Telefon"] = {
      value: contact.phone || contactDetails["Telefon"]?.value,
      className: "phone-parent",
    };

    requiredFields.forEach((field) => {
      if (!(field in contactDetails)) {
        contactDetails[field] = {
          value: "N/A",
          className: `${field.toLowerCase()}-parent`,
        };
      }
    });
    const labelMap: Record<string, string> = {
      "Mobil": "Mobile",
      "Telefon": "Phone"
    };
    return (
      <Flex
        direction="row"
        gap="size-100"
        justifyContent="space-evenly"
        width="100%"
      >
        {Object.entries(contactDetails).map(([label, { value, className }]) => {
          const displayLabel = labelMap[label] || label;
          return (<View
            key={label}
            flex="1"
            UNSAFE_className={styles[className]}
            padding={5}
          >
            {value ? (
              <Flex direction="column" gap="size-100">
                <View UNSAFE_className={styles.mobile}>{displayLabel}</View>
                <View UNSAFE_className={styles.div}>{value}</View>
              </Flex>
            ) : (
              <View
                UNSAFE_className={styles.mobile}
                UNSAFE_style={{ width: "100%", minWidth: "120px" }}
              >
                {displayLabel}
              </View>
            )}
          </View>)
      })}
      </Flex>
    );
  };

  const getIconsDetailsUI = (contact: any): JSX.Element => {
    const contactDetails: Record<
      string,
      { value: string | undefined; className: string; icon?: JSX.Element }
    > = {};

    // Extract contacts from response
    const contactsArray: any[] = [];

    if (contact.CONTACTS) {
      if (Array.isArray(contact.CONTACTS)) {
        contactsArray.push(
          ...contact.CONTACTS.map((item: any) => item.CONTACT)
        );
      } else if (contact.CONTACTS.CONTACT) {
        if (Array.isArray(contact.CONTACTS.CONTACT)) {
          contactsArray.push(...contact.CONTACTS.CONTACT);
        } else {
          contactsArray.push(contact.CONTACTS.CONTACT);
        }
      }
    }

    contactsArray.forEach((c) => {
      const typeInfo = contactSettings[c.type];
      if (typeInfo) {
        contactDetails[typeInfo.name] = {
          value: c.content,
          className: typeInfo.className,
        };
      }
    });
    contactDetails["Facebook"] = {
      value: contactDetails["Facebook"]?.value,
      className: "icon_facebook", // Fixed className (was "icon_twitter")
      icon: <FacebookIcon size={20} />, // Fixed icon (was TwitterIcon)
    };

    contactDetails["Twitter"] = {
      value: contactDetails["Twitter"]?.value,
      className: "icon_twitter", // Fixed className (was "icon_facebook")
      icon: <TwitterIcon size={20} />,
    };

    contactDetails["LinkedIn"] = {
      value: contactDetails["LinkedIn"]?.value,
      className: "icon_linkedin",
      icon: <LinkdinIcon size={20} />,
    };

    // Ensure required fields always appear
    requiredIcons.forEach((field) => {
      if (!(field in contactDetails)) {
        contactDetails[field] = {
          value: undefined, // Changed from "N/A" to undefined for better link handling
          className: `icon_${field.toLowerCase()}`,
        };
      }
    });

    // Generate UI elements with proper link handling
    return (
      <View UNSAFE_className={styles.iconsstates1}>
        {Object.entries(contactDetails)
          .filter(([label]) =>
            ["Facebook", "Twitter", "LinkedIn"].includes(label)
          )
          .map(([label, { value, className, icon }]) => (
            <View key={label} UNSAFE_className={className}>
              {value ? (
                <Link
                  target="_blank"
                  UNSAFE_className={styles[`${className}_active`]}
                  href={value}
                >
                  {icon}
                </Link>
              ) : (
                <span>{icon}</span>
              )}
            </View>
          ))}
      </View>
    );
  };
  
  useEffect(()=>{
    document.getElementById("top")?.scrollIntoView({ behavior: "smooth" });
  },[])

  const handleEmployerListClick = (key:any)=>{
    const filteredEmployer = contact.EMPLOYMENTS[key]
    if(filteredEmployer){
      handleEmployerClick(filteredEmployer.COMPANY.id);
    }
  }

  return (
    <Flex direction={"column"} UNSAFE_className={styles.detail}>
      <Flex justifyContent={"space-between"}>
        <View UNSAFE_className={styles["icon-contacts-parent"]}>
        <Flex direction={'row'} gap={'size-100'}>
        <ActionButton  isQuiet onPress={handleEmployeeCloseClick}>
            <ArrowLeftIcon size={20} color={'#ff9800'} />
        </ActionButton>
          <b className={styles["adam-novotn"]}>
             {contact?.name1} {contact?.name3}
          </b>
        </Flex>
        </View>
        {getIconsDetailsUI(contact)}
      </Flex>
      <View paddingTop={10} UNSAFE_className={styles["frame-group"]}>
        {getContactDetailsUI(contact)}
      </View>
      <View paddingTop={10}>
        {contact.EMPLOYMENTS.length > 0 && (
          <Flex UNSAFE_className={styles["frame-parent1"]}>
            <View UNSAFE_className={styles["employee-container"]} width="100%">
              <Flex justifyContent="space-between" alignItems="center">
                <Heading level={5}>Employer</Heading>
              </Flex>
              <ListView
                UNSAFE_className={styles["employee-list"]}
                selectionMode="none"
                onAction={handleEmployerListClick}
              >
                {contact.EMPLOYMENTS.map((employee: any,index:number) => (
                  <Item
                    key={index}
                    textValue={employee.COMPANY.name}
                  >
                    <Flex direction={'column'}>
              <View UNSAFE_className={styles["employee-name"]}>{employee.COMPANY.name}</View>
              <View   UNSAFE_className={styles["employee-details"]}>
                {employee.COMPANY.positionInfo} {employee.COMPANY.departmentName && <ChevronRight size='S' UNSAFE_style={{color:'#909090'}}/>}
                {employee.COMPANY.positionName} 
                {employee.COMPANY.departmentName && <ChevronRight size='S' UNSAFE_style={{color:'#909090'}}/>}
                {employee.COMPANY.departmentName}
              </View>
            </Flex>
                  </Item>
                ))}
              </ListView>
            </View>
          </Flex>
        )}
      </View>
    </Flex>
  );
};

export default EmployeeDetailViewMobile;
