import { View, Accordion, Disclosure, DisclosureTitle, DisclosurePanel, Flex, ComboBox, TagGroup, TextField, Divider, Link, DatePicker, Checkbox } from '@adobe/react-spectrum';
import { Item } from '@react-stately/collections';
import React, { useState } from 'react';
import { ContactFormData } from '../AddNewPerson';
import { useDependency } from '../../../../../contexts/DependencyProvider';
import CompanyDropDown from '../formComponents/CompaniesDropDown';
import { truncateText } from '../../../../../utils/helperUtil';
import { EditIcon } from '../../../../../components/protected/Icons/IconsLib';
import PositionDropDown from '../formComponents/PositionDropDown';
import DepartmentDropDown from '../formComponents/DepartmentDropDown';
import { CalendarDate,parseDate } from '@internationalized/date';
import PersonDropdown from '../formComponents/PersonDropDown';
import moment from 'moment';
import { DeleteConfirmationDialog } from '../../../finder/components/DeleteConfirmationDialog';

interface IEmployeeSectionProps{
  data: ContactFormData['employments'];
  onChange: (data: ContactFormData['employments']) => void;
  onAdd: (employeement:any) => void;
}
export const EmployeeSection: React.FC<IEmployeeSectionProps> = ({ data, onChange,onAdd }) => {
  const {store,mainService} = useDependency();
  const [expandedSections, setExpandedSections] = useState<Set<number>>(new Set());
  const [selectedEmployee,setSelectedEmployee] = useState<any>();
  const [isOpen,setIsOpen] = useState(false);
  const [selectedIndex,setSelectedIndex] = useState<any>();

  const handleSelectionChange = (company:any)=>{
    onAdd(company)
  }

  const onRemove = (employee:any,index:number) => {
    setIsOpen(true)
    setSelectedEmployee(employee)
    setSelectedIndex(index)
  };

  const onEdit = (data:any,index:number)=>{
    setExpandedSections((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(index)) {
        newSet.delete(index);
      } else {
        newSet.add(index);
      }
      return newSet;
    });
  }

  const handleDeleteCompanyRelation = async ()=>{
    const updatedContacts = data.filter((_, i) => i !== selectedIndex);
    setExpandedSections((prev) => {
      const newSet = new Set(prev);
      newSet.delete(selectedIndex);
      return newSet;
    }); 
    onChange(updatedContacts); 
  }

  const updatePrimary = (value: boolean, index: number) => {
    const primaryCount = data.filter(item => item.isPrimary).length;
    if (!value && primaryCount === 1) {
      return;
    }
    const newData = data.map((item, i) => ({
      ...item,
      isPrimary: i === index ? value : false,
    }));
    onChange(newData);
  };

  return(
  <View padding="size-200" paddingBottom='size-0' paddingTop='size-300'>
    <Accordion defaultExpandedKeys={["massMailSubscription"]} isQuiet>
      <Disclosure id="massMailSubscription">
        <DisclosureTitle>Employees</DisclosureTitle>
        <DisclosurePanel>
          <Flex direction="column" gap="size-100" width='100%'>
            {data.map((tag, index) => ( 
              <View>
              <Flex key={index} gap="size-50" direction='row' width='100%'>
                <TagGroup
                  items={data}
                  onRemove={()=>onRemove(tag,index)}
                  aria-label="Removable TagGroup example"
                  width="30%"
                  >
                  <Item>{truncateText(tag.companyInfo ? tag.companyInfo : '',20)}</Item>
                </TagGroup>
                  <TextField 
                    value={tag.positionInfo} 
                    onChange={(description) => { 
                      const newData = [...data]; 
                      newData[index] = { ...tag, positionInfo:description }; 
                      onChange(newData); 
                    }} 
                    width="70%" 
                  />
                  <a onClick={()=>onEdit(tag,index)}>
                      <EditIcon size={20}/> 
                  </a>
                  {/* <Checkbox isSelected={data[index].isPrimary}
                   onChange={(value)=>updatePrimary(value,index)}></Checkbox> */}
              </Flex>
              {(expandedSections.has(index) || !!data[index].department) &&(
                  <Flex key={index+1} direction='column'>
                  <Flex direction="row" gap="size-100">
                    <DepartmentDropDown 
                    selectedDepartment={data[index].department ? data[index].department : ''}
                    onChange={(dept)=>{ 
                        const newData = [...data]; 
                        newData[index] = { ...tag, department:dept.id,departmentInfo:dept.name }; 
                        onChange(newData); 
                      }
                    } />
                    <PositionDropDown
                     selectedPosition={data[index].position ? data[index].position : ''}
                     onChange={(position)=>{ 
                        const newData = [...data]; 
                        newData[index] = { ...tag, position:position.id}; 
                        onChange(newData); 
                      }
                    } />
                  </Flex>
                  <Flex direction="row" gap="size-100">
                    <DatePicker 
                    value={data[index].validFrom ? parseDate(moment(data[index].validFrom).toISOString().split("T")[0]) : undefined}
                    onChange={(value)=>{ 
                        if(!value) return;
                        const jsDate = new Date(value.year, value.month - 1, value.day);
                        const newData = [...data];
                        newData[index] = { ...tag, validFrom: moment(jsDate).format('YYYY/MM/DD') };
                        onChange(newData);
                      }
                    } label="Valid From" width="50%" />
                    <DatePicker label="Valid To" width="50%" 
                    value={data[index].validTo ? parseDate(moment(data[index].validTo).toISOString().split("T")[0]) : undefined}
                    onChange={(value)=>{ 
                      if(!value) return;
                      const jsDate = new Date(value.year, value.month - 1, value.day);
                      const newData = [...data];
                      newData[index] = { ...tag, validTo: moment(jsDate).format('YYYY/MM/DD') };
                      onChange(newData);
                    }}
                    />
                  </Flex>
                  </Flex>
              )
              }
              </View>
            ))}
            <Divider /> 
              <PersonDropdown
              contacType='p'
               onChange={handleSelectionChange}
               selectedPerson=''
               isDisabled = {false}
               width='100%' />
            </Flex>
        </DisclosurePanel>
      </Disclosure>
    </Accordion>
    <DeleteConfirmationDialog
                  isOpen={isOpen}
                  onClose={() => setIsOpen(false)}
                  onConfirm={()=>handleDeleteCompanyRelation()}
                  itemName={selectedEmployee?.companyInfo}
                />
  </View>
);
}
