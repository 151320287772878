// ContactForm.tsx
import React, { useEffect, useState } from 'react';
import {
  Form,
  Button,
  Flex,
  View,
  Grid,
  ButtonGroup,
  Dialog,
  Heading,
  Content,
  DialogContainer,
  Link,
  ProgressCircle,
} from '@adobe/react-spectrum';
import { CalendarDate,parseDate } from '@internationalized/date';
import DistributedGroupsSection from './person/DistributedGroups';
import PersonsSection from './person/PersonsSection';
import { CommentSection } from './person/CommentSection';
import { MailSubscriptionSection } from './person/MailSubscriptionSection';
import { ConsentSection } from './person/ConsentSection';
import { EmploymentSection } from './person/EmploymentSection';
import { BaseInfoSection } from './person/BaseInfoSection';
import { ContactSection } from './person/ContactSection';
import { AddressSection } from './person/AddressSection';
import { ContactFolderSection } from './person/ContactFolderSection';
import { NameSection } from './person/NameSection';
import BankSection from './person/BankSection';
import ImageUploadSection from './person/ImageUploadSection';
import { useDependency } from '../../../../contexts/DependencyProvider';
import { CreateContactRequest } from '../../../../services/soap/main/requests/CreateContactRequest';
import { Person } from '../../../../services/soap/dataObjects/Person';
import { ToastQueue } from '@react-spectrum/toast';
import moment from 'moment';
import { usePreloadAssets } from '../../../../hooks/UsePreloadAssets';
import { UpdateContactRequest } from '../../../../services/soap/main/requests/UpdateContactRequest';
import { CompanyBaseInfo } from './company/CompanyBaseInfo';
import { EmployeeSection } from './company/EmployeesSection';
import IndustrySection from './company/IndustrySection';
import { UpdateCompanyPersonRelationRequest } from '../../../../services/soap/main/requests/UpdateCompanyPersonRelationRequest';
import { CreateCompanyPersonRelationRequest } from '../../../../services/soap/main/requests/CreateCompanyPersonRelationRequest';

export interface ContactFormData {
  prefix: string;
  name1: string;
  name2: string;
  name3: string;
  suffix: string;
  regNumber:string;
  VATNumber:string;
  legalForm:string;
  legalFormName:string;
  id: string
  treeHandle: string
  aclh: string
  account: string
  accresponsiblePerson: string
  accresponsibleUserId: string
  bankCode: string
  bankName: string
  category: string
  sysUser: string
  sysUserName: string
  description: string
  adsAllowed: string
  validFrom: string
  validTo: string
  modified: string
  oldId: string
  position: string
  positionInfo: string
  reference: string
  swiftCode: string
  sourceDb: string
  trackingCode: string
  city:string;
  country: string;
  fax: '',
  icq: '',
  mobile: string;
  phone: string;
  email: string;
  other: string
  twitter: string
  facebook: string
  pager: string
  region: string
  street: string
  zip: string
  dueDays: number,
  bankIBAN: string,
  bankNoteForeign: string,
  consentStatus: string
  consentNote: string
  anonymized: string
  employer: string
  massmailEnabledFrom: string
  massmailEnabledTo: string
  massmailEnabled: string,
  FOLDERS:Array<{
    id:string;
    name:string;
  }>;
  addresses: Array<{
    city:       string;
    cityName:   string;
    country:    string;
    isPrimary:  string;
    region:     string;
    regionName: string;
    street:     string;
    type:       string;
    zip:        string;
    zipName:    string;
    name:string;
  }>;
  contacts: Array<{
    type: string;
    description: string;
    isPrimary: boolean;
    isMain: boolean;
    content?:     string;
    typeName?:    string;
    typeType?:    string;
    category?:    string;
  }>;
  baseInfo: {
    salutation: string;
    birthDate: string;
    maritalStatus: string;
    gender: string;
    language: string;
    regNo: string;
    vatNo: string;
    category: string;
    marketingChannel: string;
  };
  consent: {
    provided: string;
    expiration: string;
    note: string;
    status: string;
    isAnonymized: boolean;
  };
  massMailSubscription: {
    type: 'Allowed' | 'Forbidden' | 'Unknown';
    enabledFrom: string;
    enabledTo: string;
  };
  comment: string;
  keywords: string;
  employments:Array<{
    id?:             string;
    contact?:        string;
    company?:        string;
    companyInfo:     string;
    department?:     string;
    departmentInfo?: string;
    position?:       string;
    positionInfo?:   string;
    isPrimary?:      boolean;
    validFrom?:      string;
    validTo?:        string;
  }>
  DGroups:Array<{
    id?:      string;
    name?:   string;
    comment?: string;
  }>
  relations:Array<{
    contact1Id:string,
    contact2Id:string,
    contact1Name1:string,
    contact1Name2:string,
    contact1Name3:string,
    contact2Name1:string,
    contact2Name2:string,
    contact2Name3:string,
    contact1Phone:string,
    contact2Phone:string,
    contact1Email:string,
    contact2Email:string,
    description:string,
    relation:string,
    typeName1:string,
    typeName2:string
  }>
  banks:Array<{
    name?:            string;
    bankName?:        string;
    account?:         string;
    bankCode?:        string;
    swiftCode?:       string;
    variableSymbol?:  string;
    constantSymbol?:  string;
    specificSymbol?:  string;
    comment?:         string;
    isPrimary?:       string;
    bankIBAN?:        string;
    bankNoteForeign?: string;
  }>
  DOCUMENTS:Array<{
    documentId?:number,
    name?:string,
    description?:string,
    isPrimary?:boolean
  }>
  Industries:Array<{
    description?: string;
    industryName?: string;
    industry?: string;
    isPrimary?: string;
  }>
}
const steps = [
  "Name",
  "Contact Folder",
  "Address",
  "Contact",
];
interface IAddNewCompanyProps{
  onCloseModal:() => void
  company : any,
  isEdit : boolean,
  employments:any,
  handleEditClick:(data:boolean)=>void
  refreshDetailView:(data:any)=>void
}
const AddNewCompany: React.FC<IAddNewCompanyProps> = ({onCloseModal,company,isEdit,employments,handleEditClick,refreshDetailView}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [showLoader,setShowLoader] = useState<boolean>(false);
  const {store,mainService} = useDependency();
  const { addressTypes } = usePreloadAssets();
  const [formData, setFormData] = useState<ContactFormData>({
    prefix: '',
    name1: '',
    name2: '',
    name3: '',
    suffix: '',
    regNumber:'',
    VATNumber:'',
    legalForm:'',
    legalFormName:'',
    id: '',
    treeHandle: '',
    aclh: '',
    account: '',
    accresponsiblePerson: '',
    accresponsibleUserId: '',
    bankCode: '',
    bankName: '',
    category: '',
    sysUser: '',
    sysUserName: '',
    description: '',
    adsAllowed: '',
    validFrom: '',
    validTo: '',
    modified: '',
    oldId: '',
    position: '',
    positionInfo: '',
    reference: '',
    swiftCode: '',
    sourceDb: '',
    trackingCode: '',
    city: '',
    country:'',
    fax: '',
    icq: '',
    mobile: '',
    phone: '',
    email: '',
    other: '',
    twitter: '',
    facebook: '',
    pager: '',
    region: '',
    street: '',
    zip: '',
    dueDays: 0,
    bankIBAN: '',
    bankNoteForeign: '',
    consentStatus: '',
    consentNote: '',
    anonymized: '',
    employer: '',
    massmailEnabledFrom: '',
    massmailEnabledTo: '',
    massmailEnabled: '',
    FOLDERS:[],
    addresses: [],
    contacts: [{
      type:'183519101',
      typeType: '1',
      typeName:'Fakturační e-mail',
      content:'',
      description: '',
      isPrimary: false,
      isMain: false,

    },{
      type:'132026101',
      typeType: '1',
      typeName:'E-mail',
      content:'',
      description: '',
      isPrimary: false,
      isMain: false,
    },{
      type:'132028101',
      typeType: '3',
      content:'',
      typeName:'Mobil',
      description: '',
      isPrimary: false,
      isMain: false,
    }],
    baseInfo: {
      salutation: '',
      birthDate: '',
      maritalStatus: '0',
      gender: '0',
      language: '',
      regNo: '',
      vatNo: '',
      category: '',
      marketingChannel: '',
    },
    consent: {
      provided: '',
      expiration: '',
      note: '',
      status: '0',
      isAnonymized: false,
    },
    massMailSubscription: {
      type: 'Unknown',
      enabledFrom: '',
      enabledTo: '',
    },
    comment: '',
    keywords: '',
    employments:[],
    DGroups:[],
    relations:[],
    banks:[],
    DOCUMENTS:[],
    Industries:[]
  });

  const mapContacts = (data:any)=>{
    const validContacts = data?.contacts?.filter((contact: any) => contact.content !== '') || [];
    
    // If there's only one valid contact, make it both primary and main
    if (validContacts.length === 1) {
      return [{
        type: validContacts[0].type,
        typeType: validContacts[0].typeType,
        typeName: validContacts[0].typeName,
        content: validContacts[0].content,
        description: validContacts[0].description,
        isPrimary: 1,
        isMain: 1,
        category: ''
      }];
    }
    
    // If multiple valid contacts, ensure rules are followed
    const contactsByType:any = {};
    let hasMain = false;
    
    // First pass: check if any contact is marked as main
    validContacts.forEach((contact:any) => {
      if (contact.isMain) hasMain = true;
      
      // Group by typeType
      if (!contactsByType[contact.typeType]) {
        contactsByType[contact.typeType] = [];
      }
      contactsByType[contact.typeType].push(contact);
    });
    
    // If no main contact is found, mark the first one as main
    if (!hasMain && validContacts.length > 0) {
      validContacts[0].isMain = true;
    }
    
    // For each type, ensure one contact is primary
    Object.keys(contactsByType).forEach(typeType => {
      const typeContacts = contactsByType[typeType];
      const hasPrimary = typeContacts.some((c:any) => c.isPrimary);
      
      if (!hasPrimary && typeContacts.length > 0) {
        typeContacts[0].isPrimary = true;
      }
    });
    
    // Map to the final format
    return validContacts.map((contact: any) => ({
      type: contact.type,
      typeType: contact.typeType,
      typeName: contact.typeName,
      content: contact.content,
      description: contact.description,
      isPrimary: contact.isPrimary ? 1 : 0,
      isMain: contact.isMain ? 1 : 0,
      category: ''
    })) || [];
  }
  const mapPersonData = (data:any) =>{
    const personData:Person = {
      id: data?.id || '',
      treeHandle: data?.treeHandle || '',
      aclh: data?.aclh || '',
      account: data?.account || '',
      accresponsiblePerson: data?.accresponsiblePerson || '',
      accresponsibleUserId: data?.accresponsibleUserId || '',
      bankCode: data?.bankCode || '',
      bankName: data?.bankName || '',
      category: data?.category || '',
      sysUser: data?.sysUser || '',
      sysUserName: data?.sysUserName || '',
      description: data?.comment || '',
      keyId:data.keywords,
      adsAllowed: data?.massMailSubscription.type
      === 'Unknown' ? '':
      data?.massMailSubscription.type==='Allowed'?'Y':'N',
      validFrom: data?.consent.provided || '',
      validTo: data?.consent.expiration || '',
      modified: data?.modified || '',
      oldId: data?.oldId || '',
      position: data?.position || '',
      positionInfo: data?.positionInfo || '',
      reference: data?.reference || '',
      swiftCode: data?.swiftCode || '',
      sourceDb: data?.sourceDb || '',
      trackingCode: data?.trackingCode || '',
      name1: data?.name1 || '',
      name2: data?.name2 || '',
      name3: data?.name3 || '',
      city: data?.city || '',
      country: data?.country || '',
      fax: data?.fax || '',
      icq: data?.icq || '',
      mobile: data?.mobile || '',
      phone: data?.phone,
      email: data?.email || '',
      other: data?.other || '',
      twitter: data.twitter,
      facebook: data.facebook,
      pager: data?.pager || '',
      region: data?.region || '',
      street: data?.street || '',
      zip: data?.zip || '',
      dueDays: data?.dueDays || '0',
      bankIBAN: data?.bankIBAN || '',
      bankNoteForeign: data?.bankNoteForeign || '',
      consentStatus: data.consent.status || '',
      consentNote: data.consent.note || '',
      anonymized: data.consent.isAnonymized ? '1' : '0',
      employer: data?.employer || '',
      massmailEnabledFrom: data?.massMailSubscription.enabledFrom || '',
      massmailEnabledTo: data?.massMailSubscription.enabledTo || '',
      massmailEnabled: data.massmailEnabled,
      ADDRESSES:{ADDRESS:data?.addresses
        ?.filter((address: any) => address.street !== '')
        .map((address: any) => ({
          city:address.city,
          cityName:address.cityName,
          country:address.country,
          isPrimary:address.isPrimary,
          region:address.region,
          regionName:address.regionName,
          street:address.street,
          type:address.type,
          zip:address.zip,
          zipName:address.zipName
        })) || []},
      CONTACTS: {
        CONTACT: mapContacts(data)
        // data?.contacts
        //   ?.filter((contact: any) => contact.content !== '')
        //   .map((contact: any) => ({
        //     type: contact.type,
        //     typeType:contact.typeType,
        //     typeName:contact.typeName,
        //     content:contact.content,
        //     description: contact.description,
        //     isPrimary: contact.isPrimary ? 1 : 0,
        //     isMain: contact.isMain ? 1 : 0,
        //     category:''
        //   })) || []
      },
      DGROUPS:{DGROUP:data.DGroups || []},
      PERSONINFO:data.baseInfo.salutation !=='' ? 
      {
        prefixTitle:data.prefix || '',
        postfixTitle:data.suffix || '',
        sex:data.baseInfo.gender || '0',
        maritalStatus:data.baseInfo.maritalStatus || '0',
        born:data.baseInfo.birthDate || '',
        salutation:data.baseInfo.salutation || '',
        preferedLang:data.baseInfo.language || ''
      } : {},
      RELATIONS:{RELATION:
        data?.relations
          .map((relation: any) => ({
            contact:relation.contact,
            rSwap:'0',
            contact1Id:'',
            contact2Id:relation.contact2Id,
            contact1Name1:data.name1,
            contact1Name2:data.name2,
            contact1Name3:data.name3,
            contact2Name1:relation?.contact2Name1,
            contact2Name2:relation?.contact2Name2 || '',
            contact2Name3:relation?.contact2Name3,
            contact1Phone:'',
            contact2Phone:relation?.contact2Phone,
            contact1Email:'',
            contact2Email:relation?.contact2Email||'',
            description:relation?.description || '',
            relation:relation?.relation,
            typeName1:relation?.typeName1,
            typeName2:relation?.typeName2
          })) || []
      },
      BANKS:{BANK:data.banks||[]},
      // EMPLOYMENTS:{COMPANY:data.employments},
      COMPANYINFO:{
        regNumber:+data.regNumber || 0,
        VATNumber:data.VATNumber || '',
        legalForm:data.legalForm || '',
        legalFormName:data.legalFormName || ''
      },
      INDUSTRIES:{INDUSTRY:data.Industries.map((industry:any)=>({
        description:industry.description,
        industry:industry.industry,
        isPrimary:industry.isPrimary
      }))},
      FOLDERS:{FOLDER:data?.FOLDERS?.map((folder:any)=>({
          type: folder.id,
          id: "",
          aclh: "",
          comment: "",
          folderType: folder.id,
          folderTypeName: "",
          typeFormId: 0,
          formId: "",
          referenceId: "",
          name: [data.name3, data.name2, data.name1].filter(Boolean).join(" "),
          name1: "",
          name2: "",
          name3: "",
          formValues: "",
          messageGroup: "",
          messageDefault: "",
          customState: "",
          customStateName: "",
          created: "",
          createdByName: "",
          lastContactDate: "",
          modified: "",
          modifiedByName: "",
          itemId: "",
          pricing: "",
          parent: "",
          dimension1: "",
          dimension2: "",
          dimension3: "",
          messageDefaultPath: "",
          contact: "",
          itemPricing: "",
          primaryPicture: "",
          LOCATION: "",
          EXTERNAL_CONTACTS: ""
      }))}
    }
    return personData;
  }

  const handleSavePersonContact = async ()=>{
    const errors:any = {};
    if (!formData.name1 || formData.name1.trim() === '') {
      errors.name1 = 'Company name is required';
    }
    
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }
    setValidationErrors({});
        const person = mapPersonData(formData);
        console.log('person',person)
        console.log('formDAta',formData)
      if(isEdit){
        setShowLoader(true);
        const updateContact = await mainService.updateContact(new UpdateContactRequest(
          store.Server,
          store.SessionId,
          undefined,
          person,
          person.id))
        if(updateContact.result == "OK"){
          if(formData.employments.length > 0){
              console.log('creating company relation',person.id)
              createCompanyRelation(person.id)
          }
          ToastQueue.positive("Contact updated successfully...",{timeout:3000})
          refreshDetailView(person.id);
          setShowLoader(false)
          onCloseModal();
        }else{
          setShowLoader(false)
          ToastQueue.negative(updateContact.EXCEPTION.message,{timeout:5000})
        }
      }else{
        setShowLoader(true)
        const saveContact = await mainService.createContact(new CreateContactRequest(store.Server,store.SessionId,undefined,person))
          if(saveContact.result == "OK"){
            if(formData.employments.length > 0){
                createCompanyRelation(saveContact.newId);
            }
            ToastQueue.positive("Contact saved successfully...",{timeout:3000})
            refreshDetailView(saveContact.newId);
            setShowLoader(false)
            onCloseModal();
          }else{
            ToastQueue.negative(saveContact.EXCEPTION.message,{timeout:5000})
            setShowLoader(false)
          }
        }
    }

  const createCompanyRelation = async (contactId: any) => {
      for (const company of formData.employments) {
        const employee: any = {
          id:company.id,
          contact: company.contact,
          company: contactId,
          department: company.department,
          position: company.position,
          positionInfo: company.positionInfo,
          isPrimary: company?.isPrimary === true ? "1" : "0",
          validFrom: company.validFrom,
          validTo: company.validTo
        };
        if(employments && employments.length > 0){
          const isAlreadyExist = employments.find((x: any) => x.company === company.company);
          if (isAlreadyExist) {
            // Compare existing values with new values
            const hasChanged = Object.keys(employee).some(
              (key) => employee[key] !== isAlreadyExist[key]
            );
      
            if (hasChanged) {
              const saveCompanyRelation = await mainService.updateCompanyPersonRelation(
                new UpdateCompanyPersonRelationRequest(store.Server, store.SessionId, employee)
              );
      
              if (saveCompanyRelation.result !== "OK") {
                ToastQueue.negative(saveCompanyRelation.EXCEPTION.message, { timeout: 5000 });
              }
            }
          } else {
            // Create a new relation if not exists
            const saveCompanyRelation = await mainService.createCompanyPersonRelation(
              new CreateCompanyPersonRelationRequest(store.Server, store.SessionId, employee)
            );
      
            if (saveCompanyRelation.result !== "OK") {
              ToastQueue.negative(saveCompanyRelation.EXCEPTION.message, { timeout: 5000 });
            }
          }
        }else {
          // Create a new relation if not exists
          const saveCompanyRelation = await mainService.createCompanyPersonRelation(
            new CreateCompanyPersonRelationRequest(store.Server, store.SessionId, employee)
          );
    
          if (saveCompanyRelation.result !== "OK") {
            ToastQueue.negative(saveCompanyRelation.EXCEPTION.message, { timeout: 5000 });
          }
        }
      }
    };

  const addressOptions = Object.values(addressTypes) as {
    id: string;
    name: string;
    personAddress: string;
  }[];
  
  const filteredAddressOptions = addressOptions.filter((a) => a.personAddress === '0').reverse();
  useEffect(() => {
    if (formData.addresses.length === 0 && filteredAddressOptions.length > 0) {
      const newAddresses = filteredAddressOptions.map((option,index) => {
        if(isEdit){
          const result = {
            ...company,
            ADDRESSES: Array.isArray(company?.ADDRESSES)
            ? company.ADDRESSES.map((address: any) => address.ADDRESS)
            : company?.ADDRESSES?.ADDRESS
            ? [company.ADDRESSES.ADDRESS]
            : []
          };
          const filteredAddress = result.ADDRESSES.find((x:any)=>x.type === option.id);
          if(filteredAddress){
            return({
              type: filteredAddress.type,
              name: option.name,
              street: filteredAddress?.street || '',
              zip: filteredAddress?.zip || '',
              city: filteredAddress?.city || '',
              region: filteredAddress?.region || '',
              country: filteredAddress?.country || '',
              cityName:filteredAddress?.cityName || '',
              isPrimary:filteredAddress.isPrimary || '',
              regionName:filteredAddress?.regionName || '',
              zipName:filteredAddress?.zipName || ''
            })
          }else{
            return({
              type: option.id,
              name: option.name,
              street: '',
              zip: '',
              city: '',
              region: '',
              country: '',
              cityName:'',
              isPrimary:index === 0 ? '1':'0',
              regionName:'',
              zipName:''
            })
          }
        }else{
          return({
            type: option.id,
            name: option.name,
            street: '',
            zip: '',
            city: '',
            region: '',
            country: '',
            cityName:'',
            isPrimary:index === 0 ? '1':'0',
            regionName:'',
            zipName:''
          })   
        }
        }
    );
      setFormData(prev => ({ ...prev, addresses:newAddresses}))
    }
  }, [filteredAddressOptions]);

  useEffect(()=>{
    if(isEdit){
      const result = {
        ...company,
        ADDRESSES: Array.isArray(company?.ADDRESSES)
        ? company.ADDRESSES.map((address: any) => address.ADDRESS)
        : company?.ADDRESSES?.ADDRESS
        ? [company.ADDRESSES.ADDRESS]
        : [],
        CONTACTS: Array.isArray(company?.CONTACTS)
        ? company.CONTACTS.map((contact: any) => contact.CONTACT)
        : company?.CONTACTS?.CONTACT
        ? [company.CONTACTS.CONTACT]
        : [],
        DGROUPS: Array.isArray(company?.DGROUPS)
        ? company.DGROUPS.map((contact: any) => contact.DGROUP)
        : company?.DGROUPS?.DGROUP
        ? [company.DGROUPS.DGROUP]
        : [],
        RELATIONS: Array.isArray(company?.RELATIONS)
        ? company.RELATIONS.map((contact: any) => contact.RELATION)
        : company?.RELATIONS?.RELATION
        ? [company.RELATIONS.RELATION]
        : [],
        FOLDERS: Array.isArray(company?.FOLDERS)
        ? company.FOLDERS.map((address: any) => address.FOLDER)
        : company?.FOLDERS?.FOLDER
        ? [company.FOLDERS.FOLDER]
        : [],
        EMPLOYMENTS:Array.isArray(company?.EMPLOYMENTS)
        ? company.EMPLOYMENTS.map((employment: any) => employment.COMPANY)
        : company?.EMPLOYMENTS?.COMPANY
        ? [company.EMPLOYMENTS.COMPANY]
        : [],
        INDUSTRIES:Array.isArray(company?.INDUSTRIES)
        ? company.INDUSTRIES.map((Industry: any) => Industry.INDUSTRY)
        : company?.INDUSTRIES?.INDUSTRY
        ? [company.INDUSTRIES.INDUSTRY]
        : [],
      };
        console.log('result',result);
        console.log('Employees',employments);
        setFormData(prev => (({...prev,
        id:result?.id,
        prefix:result?.PERSONINFO?.prefixTitle,
        name1:result?.name1 || '',
        name2:result?.name2 || '',
        name3:result?.name3 || '',
        comment:result?.description,
        keywords:result.keyId,
        legalForm:result.COMPANYINFO?.legalForm || '',
        legalFormName:result.COMPANYINFO?.legalFormName || '',
        VATNumber:result.COMPANYINFO?.VATNumber || 0,
        regNumber:result.COMPANYINFO?.regNumber || '',
        trackingCode:result?.trackingCode || '',
        dueDays : +result?.dueDays|| 0,
        baseInfo:
        {
          salutation: result?.PERSONINFO?.salutation,
          birthDate: moment(result?.PERSONINFO?.born).format("YYYY-MM-DD"),
          maritalStatus: result.PERSONINFO?.maritalStatus,
          gender: result.PERSONINFO?.sex,
          language: result.PERSONINFO?.preferedLang,
          regNo: result.COMPANYINFO?.regNumber,
          vatNo: result.COMPANYINFO?.VATNumber,
          category: '',
          marketingChannel:result.trackingCode,
        },
        consent:{
          provided: moment(result?.validFrom).format("YYYY-MM-DD"),
          expiration: moment(result?.validTo).format("YYYY-MM-DD"),
          note: result?.consentNote,
          status: result?.consentStatus,
          isAnonymized: result?.anonymized,
        },
        massMailSubscription: {
          type: result?.adsAllowed === "Y" 
          ? "Allowed" 
          : result?.adsAllowed === "N" 
          ? "Forbidden" 
          : "Unknown",
          enabledFrom: moment(result?.massmailEnabledFrom).format("YYYY-MM-DD"),
          enabledTo: moment(result?.massmailEnabledTo).format("YYYY-MM-DD"),
        },
        suffix:result?.PERSONINFO?.postfixTitle || '',
        DGroups:result?.DGROUPS.map((dgroup:any)=>({
          id:dgroup?.id,
          name:dgroup?.name,
          comment:dgroup?.comment && dgroup?.comment !== '(null)' ? dgroup?.comment : ''
        })),
        relations: result.RELATIONS.flat()
        .filter((relation: any) => relation?.contact2Id !== undefined)
        .map((relation: any) => ({
          contact: relation?.contact2Id,
          rSwap: relation?.rSwap,
          contact1Id: result.id,
          contact2Id: relation?.contact2Id,
          contact1Name1: relation?.contact1Name1 || '',
          contact1Name2: relation?.contact1Name2 || '',
          contact1Name3: relation?.contact1Name3 || '',
          contact2Name1: relation?.contact2Name1 || '',
          contact2Name2: relation?.contact2Name2 || '',
          contact2Name3: relation?.contact2Name3 || '',
          contact1Phone: relation?.contact1Phone || '',
          contact2Phone: relation?.contact2Phone || '',
          contact1Email: relation?.contact1Email || '',
          contact2Email: relation?.contact2Email || '',
          description: relation?.description || '',
          relation: relation?.relation || '',
          typeName1: relation?.typeName1 || '',
          typeName2: relation?.typeName2 || ''
        })),
        contacts: prev.contacts.map((existingContact: any) => {
          const newContact = result.CONTACTS.find((contact: any) => contact.type === existingContact.type);
          if (newContact) {
            return {
              ...existingContact,
              ...newContact,
              isMain: newContact.isMain === "1",
              isPrimary: newContact.isPrimary === "1"
            };
          }
          return {...existingContact,isMain : false,isPrimary:false};
        }).concat(
          result.CONTACTS
            .filter((contact: any) => !prev.contacts.some((existingContact: any) => existingContact.type === contact.type))
            .map((contact: any) => ({
              ...contact,
              isMain: contact.isMain === "1",
              isPrimary: contact.isPrimary === "1"
            }))
        ),
        employments:employments ? employments.map((employment:any)=>(
          {
            id:employment?.id,
            contact:employment?.contact || '',
            company:employment?.company || '',
            companyInfo:employment?.name1 || '',
            department:employment?.department || '',
            departmentInfo:employment?.departmentName || '',
            position:employment?.position || '',
            positionInfo:employment?.positionInfo || '',
            isPrimary:employment.isPrimary === '1' ? true:false,
            validFrom:moment(employment.validFrom).format("YYYY/MM/DD"),
            validTo:moment(employment.validTo).format("YYYY/MM/DD")
          }
        )) : [],
        Industries:result.INDUSTRIES.map((industry:any)=>(
          {
            industry:industry.industry,
            description:industry.description,
            isPrimary:industry.isPrimary,
            industryName:industry.industryName
          }
        ))
      })))
      console.log(formData);
    } 
  },[company])

  const handleNext = () => {
    if (currentStep === 0) {
      const errors:any = {};
      
      if (!formData.name1 || formData.name1.trim() === '') {
        errors.name1 = 'Last name is required';
      }
      
      if (!formData.name3 || formData.name3.trim() === '') {
        errors.name3 = 'First name is required';
      }
      
      if (Object.keys(errors).length > 0) {
        setValidationErrors(errors);
        return;
      }
    }
    setValidationErrors({});
    setCurrentStep((prev) => Math.min(prev + 1, steps.length - 1));
  };

  const handleBack = () => {
    if (currentStep > 0) setCurrentStep((prev) => prev - 1);
  };

  const handleSkip = () => handleNext();
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    console.log('Form Data:', formData);
  };

  const addBank = () => {
    setFormData(prev => ({
      ...prev,
      banks: [...prev.banks, {
          name:'',
          bankName:'',
          account:'',
          bankCode:'',
          swiftCode:'',
          variableSymbol:'',
          constantSymbol:'',
          specificSymbol:'',
          comment:'',
          isPrimary:formData.banks.length === 0 ? '1' : '0',
          bankIBAN:'',
          bankNoteForeign:''
      }],
    }));
  };

  const addContact = () => {
    setFormData(prev => ({
      ...prev,
      contacts: [...prev.contacts, {
        type:'132026101',
        typeType: '1',
        typeName:'E-mail',
        content:'',
        description: '',
        isPrimary: false,
        isMain: false,
      }],
    }));
  };

  const addEmployment = (employment:any) => {
    setFormData(prev => ({
      ...prev,
      employments: [...prev.employments, {
          contact:employment.id,
          company:'',
          companyInfo:employment.name1,
          department:'',
          departmentInfo:'',
          position:'',
          positionInfo:'',
          isPrimary:formData.employments.length === 0 ? true : false,
      }],
    }));
  };

  const addRelation = (relation:any,relationType:string,relationId:string) => {
    console.log('relation',relation)
    setFormData(prev => ({
      ...prev,
      relations: [...prev.relations, {
        contact:relation.id,
        contact1Id:'',
        contact2Id:relation.id,
        contact1Name1:'',
        contact1Name2:'',
        contact1Name3:'',
        contact2Name1:relation?.name1,
        contact2Name2:relation?.name2 || '',
        contact2Name3:relation?.name3,
        contact1Phone:'',
        contact2Phone:relation?.phone,
        contact1Email:'',
        contact2Email:relation?.email,
        description:'',
        relation:relationId,
        typeName1:relationType,
        typeName2:relationType
      }],
    }));
  };

  // State to track validation errors
const [validationErrors, setValidationErrors] = useState({});

  return (
    <Dialog size='L' aria-label='Add New Company'>
      <Content>
        <Form aria-label='Add New Company Form'
        validationBehavior="native"
         onInvalid={(e)=>{e.preventDefault();}}>
              <Flex direction="column">
                {currentStep === 0 && (
                  <CompanyBaseInfo
                  data={{
                    name1: formData.name1 || '',
                    regNumber: formData.regNumber || '',
                    VATNumber: formData.VATNumber || '',
                    legalForm: formData.legalForm || '',
                    legalFormName: formData.legalFormName,
                    reference:formData.reference,
                    trackingCode:formData.trackingCode,
                    category:formData.category,
                    dueDays:formData.dueDays
                  }}
                  onChange={(name) =>
                    setFormData((prev) => ({
                      ...prev,
                      name1: name.name1,
                      regNumber: name.regNumber,
                      VATNumber: name.VATNumber,
                      legalForm:name.legalForm,
                      legalFormName:name.legalFormName,
                      reference:name.reference,
                      trackingCode:name.trackingCode,
                      category:name.category,
                      dueDays:name.dueDays
                    }))
                  }
                  validationErrors={validationErrors}
                />
                )}
                {currentStep === 0 && (
                  <ContactFolderSection
                    data={formData.FOLDERS}
                    onChange={(FOLDERS) => setFormData((prev) => ({ ...prev, FOLDERS }))}
                    contactType='2'
                  />
                )}
                {currentStep === 0 && (
                  <AddressSection
                              addresses={formData.addresses}
                              onChange={(addresses) => {
                                setFormData(prev => ({ ...prev, addresses,
                                  region:addresses[0]?.region,
                                  street:addresses[0]?.street,
                                  zip:addresses[0]?.zip }))}
                              }
                            />
                )}
                {currentStep === 0 && (
                  <ContactSection
                              contacts={formData.contacts}
                              onChange={(contacts:any) => {
                                setFormData(prev => ({ ...prev, 
                                  contacts,
                                  mobile:contacts.find((c:any) => c.type === '3')?.description || '',
                                  email:contacts.find((c:any) => c.type === '1')?.description || '',
                                  phone:contacts.find((c:any) => c.type === '2')?.description || '',
                                  twitter: contacts.find((x:any)=>x.type === '10')?.description || '',
                                  facebook: contacts.find((x:any)=>x.type === '11')?.description || '',
                                }))
                              }
                            }
                              onAdd={addContact}
                            />
                )}
                {currentStep === 0 && (
                  <BankSection
                    data={formData.banks}
                    onChange={(banks) => setFormData((prev) => ({ ...prev, banks }))}
                    onAdd={addBank}
                  />
                )}
                {currentStep === 0 && (
                  <EmployeeSection 
                            data={formData.employments} 
                            onAdd={(employeement:any)=>addEmployment(employeement)}
                            onChange={(employments:any) => setFormData(prev => ({ ...prev, employments }))}
                            />
                )}
                {currentStep === 0 && (
                  <ImageUploadSection
                  data={formData.DOCUMENTS}
                  onChange={(documents:any)=>setFormData(prev=>({...prev,DOCUMENTS:documents}))}
                  treeHandle='' />
                )}
                {currentStep === 0 && (
                  <PersonsSection
                    contactType='c'
                    data={formData.relations}
                    onChange={(relations)=>setFormData(prev=>({...prev,relations}))}
                    onAdd={(
                    relation:any,
                    relationtype:string,
                    relationId:string)=>addRelation(relation,relationtype,relationId)}
                  />
                )}
                {currentStep === 0 && (
                  <IndustrySection
                  data={formData.Industries}
                  onChange={(Industries)=>setFormData(prev=>({...prev,Industries}))}
                  />
                )}
                {/* {currentStep === 0 && (
                  <BaseInfoSection
                    data={formData.baseInfo}
                    onChange={(baseInfo) => setFormData((prev) => ({ ...prev, baseInfo }))}
                  />
                )} */}
                {/* {currentStep === 0 && (
                  <ConsentSection
                    data={formData.consent}
                    onChange={(consent) => setFormData((prev) => ({ ...prev, consent,
                      consentStatus:consent.status,
                      consentNote : consent.note,
                      anonymized : consent.isAnonymized.toString() || ''
                     }))}
                  />
                )} */}
                {currentStep === 0 && (
                  <MailSubscriptionSection
                    data={formData.massMailSubscription}
                    onChange={(massMailSubscription) =>
                      setFormData((prev) => ({ ...prev, massMailSubscription,
                        massmailEnabled:massMailSubscription.type
                        === 'Unknown' ? '':
                        massMailSubscription.type==='Allowed'?'Y':'N',
                        massmailEnabledFrom:massMailSubscription.enabledFrom,
                        massmailEnabledTo:massMailSubscription.enabledTo, }))
                    }
                  />
                )}
                {currentStep === 0 && (
                  <DistributedGroupsSection
                  data={formData.DGroups}
                  onChange={(DGroups)=>setFormData(prev=>({...prev,DGroups}))}
                  />
                )}
                {currentStep === 0 && (
                  <CommentSection
                    comment={formData.comment}
                    keywords={formData.keywords}
                    onChange={(data) => setFormData((prev) => ({ ...prev, ...data }))}
                  />
                )}
              </Flex>
        </Form>
    </Content>
            <ButtonGroup>
            {/* {currentStep > 0 && (
                <Button variant="secondary" onPress={handleBack}>
                  Back
                </Button>
              )} */}
              <ProgressCircle aria-label='....Loading' isHidden={!showLoader} isIndeterminate UNSAFE_style={{marginRight:20}} />
              <Button variant="secondary" onPress={onCloseModal}>
                Cancel
              </Button>
              <Button onPress={()=>handleSavePersonContact()} variant="cta">
                  Save
                </Button>
              {/* {currentStep < steps.length - 1 ? (

                <Button variant="accent" onPress={handleNext}>
                  Next
                </Button>
              ) : (
                <Button onPress={()=>handleSavePersonContact()} variant="cta">
                  Save
                </Button>
              )} */}
            </ButtonGroup>
    </Dialog>
  );
};

export default AddNewCompany;