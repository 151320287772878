import React, { useCallback, useEffect, useState } from 'react';
import { Floppy, Printer, Send } from 'react-bootstrap-icons';
import { Flex, ProgressCircle, Radio, RadioGroup, View, Button, Text } from '@adobe/react-spectrum';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState } from '../../../../../../state/store';
import { useDependency } from '../../../../../../contexts/DependencyProvider';
import { ListFPATypeDocumentParsRequest } from '../../../../../../services/soap/project/requests/ListFPATypeDocumentParsRequest';
import { ListLanguagesRequest } from '../../../../../../services/soap/translate/reqeusts/ListLanguagesRequest';
import { PrintContextRequest } from '../../../../../../services/rest/print/requests/PrintContextRequest';
import { Col, FilterCol, Order } from '../../../../../../services/soap/project/requests/ListFPARequest';
import { useReefConfig } from '../../../../../../hooks/UseReefConfig';
//import './print_diallog.css';
import styles from './print_dialog.module.css';
import { PrintDataRequest } from '../../../../../../services/rest/print/requests/PrintDataRequest';
import { getDownloadUrl } from '../../../../../../utils/ImagePathUtils';
import { openPdfContentInNewWindow, openUrlInNewWindow } from '../../../../../../utils/NavigationUtils';

interface PrintDialogProps {
  open?: boolean;
  onClose?: () => void;
  onSend?: () => void;
  onPrint?: () => void;
  onSave?: () => void;
  selectedItem?: any;
  print_type: string;
}

const PrintDialog: React.FC<PrintDialogProps> = ({ open, onClose, onSend, onPrint, onSave, selectedItem, print_type }) => {
  const formSignatures = useSelector((state: RootState) => state.userWebLayout.formSignatures);
  const { store, projectService, translateService, printRestService } = useDependency();
  const { baseUrl, print_service } = useReefConfig();
  const [docTemplates, setDocTemplates] = useState<any>([]);
  const [langList, setLangList] = useState<any>([]);
  const [langName, setLangName] = useState<string>('');
  const [docType, setDocType] = useState<string>('pdf');
  const [selectDocumentID, setSelectDocumentID] = useState<any>('');
  const [showLoader, setShowLoader] = useState<boolean>(true);
  const [isPrinting, setIsPrinting] = useState<boolean>(false);
  const [isSending, setIsSending] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [showPopupBlockerMessage, setShowPopupBlockerMessage] = useState(false);
  const [browserHelpLink, setBrowserHelpLink] = useState<string>('');
  const { t } = useTranslation();

  const _t = (key: string) => t(`printDialog.${key}`, { ns: 'finder' });
  useEffect(() => {
    (async () => {
      await loadDocumentParams();
      await loadLanguage();
      setShowLoader(false);
      // Check for popup blocker on initial load
      checkPopupBlocker();
    })();
  }, []);

  const loadDocumentParams = useCallback(async () => {
    let resDocPars = await projectService.listFPATypeDocumentPars(
      new ListFPATypeDocumentParsRequest(store.Server, store.SessionId, {
        limit: 50,
        offset: 0,
        FILTER: [new FilterCol('type', 'equals', selectedItem?.item_type_id)],
        ORDER: new Order([new Col('priority'), new Col('description')]),
      })
    );
    //console.info('defaultDocument', resDocPars);
    if (resDocPars.result === 'OK' && resDocPars?.FPA_TYPE_DOCUMENT_PAR?.length > 0) {
      let docList: any = resDocPars.FPA_TYPE_DOCUMENT_PAR;
      //console.log('docList', docList);
      docList.unshift({
        id: 0,
        description: _t('system_default'),
      });
      const defaultDocument = resDocPars.FPA_TYPE_DOCUMENT_PAR.find((doc: any) => doc.isDefault === 'true');

      setDocTemplates(resDocPars.FPA_TYPE_DOCUMENT_PAR);
      setSelectDocumentID(String(defaultDocument?.document));
    }
  }, [selectedItem]);

  const loadLanguage = useCallback(async () => {
    let langResp = await translateService.listLanguages(new ListLanguagesRequest(store.Server, store.SessionId));
    if (langResp.result === 'OK' && langResp?.LANGUAGE?.length > 0) {
      const filterList: any = langResp.LANGUAGE.filter((lang: any) => lang.useForTranslate === '1');
      filterList.unshift({
        languageShort: '',
        languageLong: _t('default'),
      });
      //console.log('langList', filterList);
      setLangList(filterList);
    }
  }, [selectedItem]);

  const handleSendClick = async () => {
    if (isSending || isPrinting || isSaving) return;
    setIsSending(true);
    try {
      await onSend?.();
    } catch (error) {
      console.log('error', error);
    } finally {
      setIsSending(false);
    }
  };

  const onPrintClick = () => {
    if (isSending || isPrinting || isSaving) return;
    setIsPrinting(true);

    if (docType === 'template') {
      let printDataRequest = new PrintDataRequest(store.Server, store.SessionId, {
        print_type: selectedItem.type,
        object_id: selectedItem.id,
        template_id: selectDocumentID,
        language: langName,
      });

      let printData = printRestService.printDataTemplate(printDataRequest);
      let windowReference: any = window.open('', '_blank', 'scrollbars=yes,status=yes,menubar=no,toolbar=no');
      windowReference?.location.replace(`${print_service.uri}${printData}`);
      setIsPrinting(false);
      return;
    }

    if(docType === 'json') {
      let printDataBody = {
        print_type: selectedItem.type,
        object_id: selectedItem.id,
        language: langName,
        signatures: formSignatures,
      }

      printRestService.printData(new PrintDataRequest(store.Server, store.SessionId, printDataBody), { signatures: formSignatures })
      .then(async (response: any) => {
        var data_response = response.data;
        var signatures_data = data_response.signatures;
        var jsonString = JSON.stringify(data_response, null, 2);
        if(signatures_data) {
          jsonString = jsonString.split('"signatures": {}').join(`"signatures": ${JSON.stringify(signatures_data, null, 2)}`);
        }
        let windowReference: any = window.open('', '_blank', 'scrollbars=yes,status=yes,menubar=no,toolbar=no');
        windowReference?.document.write(`
          <!DOCTYPE html>
        <html>
        <head>
            <title>JSON Data</title>
            <style>
                body { font-family: monospace; }
            </style>
        </head>
        <body>
            <pre>${jsonString}</pre>
        </body>
        </html>
        `);
      })
      .catch(error => {
        console.log('error', error);
      })
      .finally(() => {
        setIsPrinting(false);
      });
    } else {
      printRestService
        .printContext(new PrintContextRequest(store.Server, store.SessionId, print_type, String(selectedItem?.id), docType, { signatures: formSignatures }, ``, selectDocumentID))
        .then(async (response: any) => {
          openPdfContentInNewWindow(response);
        })
        .catch(error => {
          console.log('error', error);
        })
        .finally(() => {
          setIsPrinting(false);
          setShowLoader(false);
        });
    }
  };

  const onSaveClick = () => {
    if (isSending || isPrinting || isSaving) return;
    if (docType === 'template') {
      return;
    }
    setIsSaving(true);
    printRestService
        .printDocument(new PrintContextRequest(store.Server, store.SessionId, print_type, String(selectedItem?.id), docType, { signatures: formSignatures }, ``, selectDocumentID))
        .then(async (response: any) => {
          let strURL = getDownloadUrl(response.documentId, baseUrl, store.Server, store.SessionId);
          openUrlInNewWindow(strURL);
        })
        .catch(error => {
          console.log('error', error);
        })
        .finally(() => {
          setIsSaving(false);
          setShowLoader(false);
        });
  };

  const getBrowserHelpLink = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.includes('chrome')) {
      return 'https://support.google.com/chrome/answer/95472';
    } else if (userAgent.includes('firefox')) {
      return 'https://support.mozilla.org/en-US/kb/pop-blocker-settings-exceptions-troubleshooting';
    } else if (userAgent.includes('safari')) {
      return 'https://support.apple.com/guide/safari/block-pop-ups-sfri40696/mac';
    } else if (userAgent.includes('edge')) {
      return 'https://support.microsoft.com/en-us/microsoft-edge/block-pop-ups-in-microsoft-edge-1d8ba4f8-f385-9a0b-e944-aa47339b6bb5';
    }
    return '';
  };

  const checkPopupBlocker = (): boolean => {
    const popup = window.open('', '_blank');
    if (!popup || popup.closed || typeof popup.closed === 'undefined') {
      setBrowserHelpLink(getBrowserHelpLink());
      setShowPopupBlockerMessage(true);
      return true;
    }
    popup.close();
    return false;
  };

  if (showLoader) {
    return (
      <Flex width="100%" justifyContent={'center'} marginTop={10}>
        <ProgressCircle aria-label="Loading…" isIndeterminate />
      </Flex>
    );
  } else {
    return (
      <View width={'100%'} position={'relative'} height={'auto'} UNSAFE_className={styles.print_dialog_parent}>
        {showPopupBlockerMessage && (
          <Flex direction="column" gap="size-100" UNSAFE_style={{ padding: '16px', backgroundColor: '#FEF3C7', borderRadius: '8px', marginBottom: '16px' }}>
            <Text>{_t('popup_blocker_detected')}</Text>
            <Text>{_t('popup_blocker_detected_message')}</Text>
            <Text>{_t('popup_blocker_detected_message_2')}</Text>
            <Text>{_t('popup_blocker_detected_message_3')}</Text>
            <Text>{_t('popup_blocker_detected_message_4')}</Text>
            {browserHelpLink && (
              <Text>
                {_t('popup_blocker_detected_message_7')} <a href={browserHelpLink} target="_blank" rel="noopener noreferrer">{_t('popup_blocker_detected_message_8')}</a>
              </Text>
            )}
            <Button variant="primary" onPress={() => setShowPopupBlockerMessage(false)}>
              {_t('popup_blocker_detected_message_6')}
            </Button>
          </Flex>
        )}
        {docTemplates.length > 0 && (
          <>
            <Flex position={'relative'} height={'30px'} UNSAFE_className={styles.doc_template_heading} alignContent={'center'} alignItems={'center'} justifyContent={'start'}>
              {_t('document_template')}
            </Flex>
            <Flex direction={'row'} position={'relative'} alignItems={'center'} justifyContent={'start'} gap={'32px'}>
              <RadioGroup label="" defaultValue={selectDocumentID} onChange={(value: any) => setSelectDocumentID(value)} orientation="vertical">
                {docTemplates.map((docTemplate: any) => (
                  <Radio key={docTemplate.id} value={String(docTemplate.document)}>
                    {docTemplate.description}
                  </Radio>
                ))}
              </RadioGroup>
            </Flex>
          </>
        )}

        <Flex position={'relative'} height={'30px'} UNSAFE_className={styles.doc_template_heading} alignContent={'center'} alignItems={'center'} justifyContent={'start'}>
          {_t('document_type')}
        </Flex>
        <Flex direction={'row'} position={'relative'} alignItems={'center'} justifyContent={'start'} gap={'32px'}>
          <RadioGroup label="" orientation="horizontal" defaultValue={'pdf'} onChange={value => setDocType(value)}>
            <Radio value="pdf">{_t('pdf')}</Radio>
            <Radio value="docx">{_t('word_doc')}</Radio>
            <Radio value="xlsx">{_t('excel_doc')}</Radio>
            <Radio value="json">{_t('json')}</Radio>
            <Radio value="template">{_t('template')}</Radio>
          </RadioGroup>
        </Flex>

        <Flex position={'relative'} height={'30px'} UNSAFE_className={styles.doc_template_heading} alignContent={'center'} alignItems={'center'} justifyContent={'start'}>
          {_t('document_language')}
        </Flex>
        <Flex direction={'row'} position={'relative'} alignItems={'center'} justifyContent={'start'} gap={'32px'}>
          <RadioGroup label="" orientation="horizontal" onChange={value => setLangName(value)} defaultValue={''}>
            {langList.map((lang: any) => (
              <Radio key={lang.languageShort} value={lang.languageShort}>
                {lang.languageLong}
              </Radio>
            ))}
          </RadioGroup>
        </Flex>

        <View position={'relative'} bottom={'0px'} left={'0px'} width={'100%'} height={'55px'} UNSAFE_className={styles.footer}>
          <Flex direction={'row'} position={'absolute'} bottom={'0px'} left={'calc(50% - 150px)'} alignItems={'start'} justifyContent={'start'} gap={'10px'}>
            <Flex direction={'row'} width={'90px'} height={'40px'} flexShrink={0} alignItems={'center'} justifyContent={'center'} gap={'8px'} 
              UNSAFE_className={`${styles.footer_btn} ${(isSending || isPrinting || isSaving) ? styles.disabled : ''}`}>
              <div style={{ display: 'contents' }} onClick={handleSendClick}>
                {isSending ? (
                  <ProgressCircle size="S" aria-label="Sending..." isIndeterminate />
                ) : (
                  <Send size={32} />
                )}
                <View UNSAFE_className={styles.btn_label}>{_t('send')}</View>
              </div>
            </Flex>
            <Flex direction={'row'} width={'90px'} height={'40px'} flexShrink={0} alignItems={'center'} justifyContent={'center'} gap={'8px'} 
              UNSAFE_className={`${styles.footer_btn} ${(isSending || isPrinting || isSaving) ? styles.disabled : ''}`}>
              <div style={{ display: 'contents' }} onClick={onPrintClick}>
                {isPrinting ? (
                  <ProgressCircle size="S" aria-label="Printing..." isIndeterminate />
                ) : (
                  <Printer size={16} />
                )}
                <View UNSAFE_className={styles.btn_label}>{_t('print')}</View>
              </div>
            </Flex>
            <Flex direction={'row'} width={'90px'} height={'40px'} flexShrink={0} alignItems={'center'} justifyContent={'center'} gap={'8px'} 
              UNSAFE_className={`${styles.footer_btn} ${(isSending || isPrinting || isSaving) ? styles.disabled : ''}`}>
              <div style={{ display: 'contents' }} onClick={onSaveClick}>
                {isSaving ? (
                  <ProgressCircle size="S" aria-label="Saving..." isIndeterminate />
                ) : (
                  <Floppy size={32} />
                )}
                <View UNSAFE_className={styles.btn_label}>{_t('save')}</View>
              </div>
            </Flex>
          </Flex>
        </View>
      </View>
    );
  }
};

export default PrintDialog;
