import { BaseRequest, IBaseRequest } from '../../BaseRequest';

export interface IDeleteActivityRequest extends IBaseRequest {
    id: number;
}

export class DeleteActivityRequest extends BaseRequest implements IDeleteActivityRequest {
    public id: number;

    constructor(server: string, session: string, id: number) {
        super(server, session);
        this.id = id;
    }

  public toXml(): string {
    var parameters: string = super.toXml();

    parameters += `<id>${this.id}</id>`;
    return super.wrapperXml('DeleteActivity', parameters);
  }
}
