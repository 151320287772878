class AtollonEvent extends Event {
    public static readonly FORM_COMPONENT_LOADED: string = 'formComponentLoaded';
    public static readonly FORM_VALUE_CHANGED: string = 'formValueChanged';
    public static readonly FORM_VALUE_UPDATED: string = 'formValueUpdated';
    public static readonly REQUEST_DATA:string = 'requestData';
    public static readonly DATA_RECEIVED:string = 'dataReceived';
    public static readonly DATA_ERROR:string = 'dataError';

    public static readonly ALLOWED_REQUEST_DATA_COMPONENTS: string[] = [
        'currency',
        'currencyRate',
        'currencyRateList',
    ];

    public componentId?: string;
    public Value?: any;
    public Error?: string;

    constructor(eventName: string, otherOptions: any) {
        super(eventName);
        this.componentId = otherOptions.componentId;
        this.Value = otherOptions.value;
        this.Error = otherOptions.error;
    }
}

export { AtollonEvent };
