import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDependency } from '../../../contexts/DependencyProvider';
import { useViewInfo } from '../../../hooks/UseViewInfo';
import { statusColor } from '../../../utils/helperUtil';
import useBrowserName from '../../../hooks/UseBrowserName';
import useScreenSize from '../../../hooks/UseScreenSize';
import moment from 'moment';
import { ToastQueue } from '@react-spectrum/toast';
import { useAsyncList } from 'react-stately';
import { 
  Flex, 
  View, 
  ProgressCircle, 
  Grid, 
  TableView, 
  TableBody, 
  TableHeader, 
  Row, 
  Cell, 
  Column, 
  Heading, 
  Content, 
  ListBox, 
  Item, 
  IllustratedMessage, 
  Link, 
  DialogContainer, 
  MenuTrigger,
  Menu} from '@adobe/react-spectrum';
import { CircleFill } from 'react-bootstrap-icons';
import NotFound from '@spectrum-icons/illustrations/NotFound';
import { FinderIcon } from '../../../components/protected/Icons/IconsLib';
import FilterComponent from './component/FilterComponent';
import HeaderComponent from './component/HeaderComponent';
import { invokeFinderApp } from '../../../utils/NavigationUtils';
import { ListActivityAdvancedRequest } from '../../../services/soap/project/requests/ListActivityAdvancedRequest';
import { ListStatusRequest, ListStatusType } from '../../../services/soap/project/requests/ListStatusRequest';
import { ListFormSettingsRequest } from '../../../services/soap/features/requests/ListFormSettingsRequest';
import { LayoutDefinition } from '../../../infra/protected/Layout/LayoutDefinitions';
import { GetFormPanelRequest } from '../../../services/soap/features/requests/GetFormPanelRequest';
import { SoapUtils } from '../../../utils/SoapUtils';
import { GetFormSettingsRequest } from '../../../services/soap/features/requests/GetFormSettingsRequest';
import { ListRegisterRequest } from '../../../services/soap/features/requests/ListRegisterRequest';
import CardComponent from './component/CardComponent';
import { ComponentFactory } from '../../../components/protected/layout-components/ComponentFactory';
import FilterSearchTextBox from './component/FilterSearchTextBox';
import { FPAData, FPADataFactory } from '../../../infra/protected/FPA/FPAData';
import { usePreloadAssets } from '../../../hooks/UsePreloadAssets';
import { RootState } from '../../../state/store';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { updateAppInfo } from '../../../state/userWebLayout/userWebLayoutSlice';
import styles from './Contact.module.css';
import { ContextNewDialog } from '../../../components/protected/Context/ContextNewDialog';
import { DataProvider } from '../../../components/protected/data-components/Contexts/DataContext';
import { GetContactListRequest } from '../../../services/soap/main/requests/GetContactListRequest';
import { GetContactRequest } from '../../../services/soap/main/requests/GetContactRequest';
import ContactDetailView from './component/ContactDetailView';
import { ListFolderOrganizationRequest } from '../../../services/soap/project/requests/ListFolderOrganizationRequest';
import { ListCompanyPersonRelationsRequest } from '../../../services/soap/main/requests/ListCompanyPersonRelationsRequest';
import AddNewPerson from './component/AddNewPerson';
import EmployeeDetailView from './component/EmployeeDetailView';
import ContactDetailViewMobile from './component/ContactDetailViewMobile';
import EmployeeDetailViewMobile from './component/EmployeeDetailViewMobile';
import AddNewCompany from './component/AddNewCompany';
import { DeleteContactRequest } from '../../../services/soap/main/requests/DeleteContactRequest';

interface Character {
  id: string;
  name: string;
}

export function Contact() {
  const browserName = useBrowserName();
  const screenSize = useScreenSize();
  const { t } = useTranslation();
  const { isMobile } = useViewInfo();
  const { store, projectService, featureService,mainService } = useDependency();
  const dataStore = useSelector((state: any) => state.userWebLayout.appInfos);
  const selectedApp = useSelector((state: RootState) => state.userWebLayout.selectedAppId);
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [showLoader, setShowLoader] = useState(true);
  const [showLoaderDetail, setShowLoaderDetail] = useState(true);
  const [rowsData, setRowsData] = useState<any>([]);
  const [columnsData, setColumnsData] = useState<any>([]);
  const [pageView, setPageView] = useState<string>('Full');
  const [selectedKeys, setSelectedKeys] = useState<Set<any>>();
  const [activityStatusList, setActivityStatusList] = useState<any>([]);
  const [contactStatusList, setContactStatusList] = useState<any>([]);
  const [definition, setDefinition] = useState<LayoutDefinition | null>(null);
  const [showMobileDetailView, setShowMobileDetailView] = useState<boolean>(false);
  const [stopLoadData, setStopLoadData] = useState<boolean>(false);
  const [searchedValue, setSearchedValue] = useState<string>('');
  const [selectedItem, setSelectedItem] = useState<any | null>(null);
  const [selectedDataRow, setSelectedDataRow] = useState<any>({});
  const [isPerson,setIsPerson] = useState<boolean>(false);
  const [listOrganizationFolder,setListOrganizationFolder] = useState<any>([]);
  const [employeesList,setEmployeesList] = useState<any>([]);
  const serviceActivityValue = useRef<string>('');
  const queryParams = useRef<any>({ FILTER: { COL: [] } });
  const totalContactListRec = useRef<number>(0);
  const offSetLimit = useRef<number>(0);
  const dataRef = useRef<any>({});
  const [showAdd, setShowAdd] = useState(false);
  const [addFormType,setAddFormType] = useState<string>('');
  const [isEdit,setIsEdit] = useState(false);
  const [showEmployeeDetail,setShowEmployeeDetail] = useState<boolean>(false);
  const [employeeDetail,setEmployeeDetail] = useState<any>(null);
  const PAGE_SIZE = 50;
  useEffect(() => {
    if (state.appKey && dataStore[state.appKey]) {
      if (dataStore[state.appKey].pageView) {
        dataRef.current.pageView = dataStore[state.appKey].pageView;
        setPageView(dataStore[state.appKey].pageView);
        return;
      }
    }
  }, [dataStore.appInfos, state.appKey]);

  useEffect(() => {
    (async () => {
      setShowLoader(true);
      setShowLoaderDetail(true);
      queryParams.current = { FILTER: { COL: [] } };
      serviceActivityValue.current = '';
      setPageView('Full');
      setSearchedValue('');
      Promise.all([getFolderStatus()]);
    })();
  }, [selectedApp]);

  useEffect(() => {
    if (selectedApp !== '') {
      listContactList.reload();
    }
  }, [selectedApp]);

  useEffect(() => {
    return () => {
      dispatch(
        updateAppInfo({
          key: state.appKey,
          data: {
            pageView: dataRef.current.pageView,
          },
        })
      );
    };
  }, []);

  const getFolderStatus = async()=>{
    const res = await projectService.listFolderStatus2(new ListStatusRequest(store.Server,store.SessionId,ListStatusType.Folder))
    const statusArray: any = [];
    if (res.result === 'OK' && res.STATUS.length > 0) {
          res?.STATUS.forEach((status: any) => {
            const { id, name, color } = status;
            let colorString = statusColor(color);
            statusArray.push({ id, name, color: colorString });
          });
          setContactStatusList(statusArray);
    }
  }
  const getFormSettings = useCallback(
    async (settingId: string) => {
      setColumnsData([
        {key:"name1",name:"Name"},
        {key:"employer",name:"Employer"},
        {key:"phone",name:"Phone"},
        {key:"mobile",name:"Mobile"},
        {key:"email",name:"E-mail"},
        {key:"modified",name:"Last modification"},
        {key:"modifiedByName",name:"Modified by"},
        {key:"created",name:"Created"},
      ]);
    },
    [selectedApp]
  );

  // const getAcitivityListKey = useCallback(async () => {
  //   let resListRegister = await featureService.listRegister(new ListRegisterRequest(store.Server, store.SessionId, undefined, undefined, undefined, undefined, +selectedApp));
  //   //console.log('resListRegister', resListRegister);

  //   serviceActivityValue.current = resListRegister?.ROWS?.find((row: any) => row.ROW.key === 'FPATypeCategoryForListActivity')?.ROW.value ?? '';

  //   let settingId = resListRegister?.ROWS?.find((row: any) => row.ROW.key === 'formSetting')?.ROW.value ?? '';

  //   await getFormSettings(settingId);

  //   return resListRegister?.ROWS?.find((row: any) => row.ROW.key === 'FPATypeCategoryForListActivity')?.ROW.value ?? '';
  // }, [selectedApp]);

  const getContactListCount = async () => {
    await getFormSettings('');
    const requestParams = mapFiltersToRequest(queryParams.current.FILTER);
    requestParams.countOnly = '1';
    const response = await mainService.getContactList(new GetContactListRequest(
      store.Server,
      store.SessionId,
      requestParams));
    if (response.result === "OK") {
      totalContactListRec.current = response.count !=0 ? response.count : 0;
      return response.count !=0?response.count:0
    } else {
      return 0;
    }
  };

  const mapFiltersToRequest = (filters: { COL: any[] }) => {
    const requestParams: { 
      limit?:number,
      offset?:number,
      countOnly?:string,
      type?: string,
      onlyUser?: number,
      contains?: string,
      industry?: string,
      FOLDERTYPE?: { value: string }[],
      DISTRGROUPS?: { ROW: { id: string }[] }
    } = {
      limit: PAGE_SIZE,
      offset: offSetLimit.current ?? 0,
      countOnly:'0'
    };
    filters.COL.forEach((filter) => {
      if (filter.name === "contactType" && filter.value !== "3") {
        requestParams.type = filter.value;
      }
      if (filter.name === "contactType" && filter.value === "3") {
        requestParams.onlyUser = 1;
      }
      if (filter.name === "industry") {
        requestParams.industry = filter.value;
      }
      if (filter.name === "contains") {
        requestParams.contains = `*${filter.value}`;
      }
      if (filter.name === "folderType") {
        if (!requestParams.FOLDERTYPE) {
          requestParams.FOLDERTYPE = [];
        }
        requestParams.FOLDERTYPE.push({ value: filter.value });
      }
      if (filter.name === "groups") {
        if (!requestParams.DISTRGROUPS) {
          requestParams.DISTRGROUPS = { ROW: [] };
        }
        const ids = filter.value.includes(",") ? filter.value.split(",") : [filter.value];
        ids.forEach((id:any) => {
          requestParams?.DISTRGROUPS?.ROW.push({ id: id.trim() });
        });
      }
    });
    return requestParams;
  };

  const loadcontactList = async () => {
    try {
      if (stopLoadData) {
        return rowsData;
      }
      const requestParams = mapFiltersToRequest(queryParams.current.FILTER);
      // const params = {
      //   limit: PAGE_SIZE,
      //   offset: offSetLimit.current ?? 0,
      //   ORDER: {
      //     COL: [
      //       {
      //         name: "created",
      //         desc: "true",
      //       },
      //     ],
      //   },
      //   FIELDS: {
      //     field: columnsData.map((column: any) => column.key),
      //   },
      // };
      setSearchedValue('');
      console.log(requestParams)
      const response = await mainService.getContactList(new GetContactListRequest(store.Server, store.SessionId,requestParams));
      if (response.result === "OK" && response?.count > 0) {   
            const contactList = response.CONTACT;
            setRowsData((prev: any) => [...prev, ...contactList]);
            setSelectedKeys(new Set([contactList[0].id]));
            setSelectedDataRow(contactList[0]);
            getLayout(contactList[0].id)
            return contactList;
          } else {
            ToastQueue.info(t('no_results', { ns: 'layout_components' }), { timeout: 50 });
            setRowsData([]);
            return [];
          }
    } finally {
      setShowLoader(false);
    }
  };

  let listContactList = useAsyncList<Character>({
    async load({ cursor }) {
      //console.log('cursor', cursor);
      // if (selectedApp === '') {
      //   setShowLoader(false);
      //   return { items: rowsData, cursor: undefined };
      // }
      if (cursor && Number(cursor) * PAGE_SIZE >= totalContactListRec.current) {
        setShowLoader(false);
        return { items: rowsData, cursor: undefined };
      }
      let totalRecords = await getContactListCount();
      totalRecords = totalRecords ? Number(totalRecords) : 0;
      let currentPage = cursor ? parseInt(cursor) : 0;
      let offSet = PAGE_SIZE * currentPage;
      offSetLimit.current = offSet;
      let itemList = await loadcontactList();
      return {
        items: itemList,
        cursor: String(offSet >= totalRecords ? undefined : currentPage + 1),
      };
    },
  });

  const reloadContacts = useCallback((filterParams: any) => {
    setStopLoadData(false);
    queryParams.current = filterParams;
    totalContactListRec.current = 0;
    offSetLimit.current = 0;
    listContactList.reload();
  }, []);

  const addStatusColor = (statusName: any) => {
    const statusObj = activityStatusList.find((obj: any) => obj.name === statusName);
    return statusObj?.color;
  };

  const handleTableRowClick = (rowId: any): void => {
     if (selectedKeys?.has(rowId.currentKey) || rowId === null) {
      return
    } else {
      setShowLoaderDetail(true);
      setShowEmployeeDetail(false);
      setSelectedKeys(new Set([rowId]));
      setPageView('Full');
      dataRef.current.pageView = 'Full';
      let selectedRow = rowsData.find((item: any) => item.id === rowId);
      if (!selectedRow) {
        setSelectedDataRow({});
        setSelectedKeys(undefined);
        setDefinition(null);
        return;
      }
      setSelectedDataRow(selectedRow);
      getLayout(rowId);
    }    
  };

  const handleListRowClick = (rowId: any): void => {
    if (selectedKeys?.has(rowId.currentKey) || rowId === null) {
      return
    } else {
      setShowLoaderDetail(true);
      setShowEmployeeDetail(false);
      setSelectedKeys(rowId);
      setPageView('Full');
      dataRef.current.pageView = 'Full';
      let selectedRow = rowsData.find((item: any) => item.id === rowId.currentKey);
      if (!selectedRow) {
        setSelectedDataRow({});
        setSelectedKeys(undefined);
        setDefinition(null);
        return;
      }
      // invokeFinderApp(rowId.currentKey)
      setSelectedDataRow(selectedRow);
      getLayout(rowId.currentKey);
    }
  };

  const handleRowClickMobile = (rowId: any): void => {
    setShowEmployeeDetail(false);
    const nameSearchExists = queryParams.current.FILTER.COL.find((col: any) => col.name === 'nameSearch');
    if (nameSearchExists) {
      setSearchedValue(nameSearchExists.value);
    }
    setSelectedKeys(undefined);
    let selectedRow = rowsData.find((item: any) => item.id === rowId.currentKey);
    if (!selectedRow) {
      setSelectedDataRow({});
      setSelectedKeys(undefined);
      setDefinition(null);
      return;
    }
    setSelectedDataRow(selectedRow);
    getLayout(rowId.currentKey);
    setShowMobileDetailView(true);
  };

  const handleViewChange = (view: string) => {
    setPageView(view);
    dataRef.current.pageView = view;
  };

  const getStatusColor = () => {
    return (
      +selectedDataRow?.folderCount > 0 ? '#ff9800' : '#B0BEC5'
    );
  };

  function extractSingleObject(data: any): any {
    if (typeof data !== "object" || data === null) {
        return null; // Invalid input
    }
    const keys = Object.keys(data);
    // If it contains numbered keys, return the first object's value
    if (keys.some(key => !isNaN(Number(key)))) {
        for (const key of keys) {
            if (!isNaN(Number(key))) {
                return data[key]; // Return the first numeric key's value
            }
        }
    }
    return data;
}

  const getLayout = async (selectedId: any) => {
    try {
      const contactResult = await mainService.getContact(new GetContactRequest(
        store.Server,
        store.SessionId,
        undefined,
        undefined,
        selectedId))
      if (contactResult.result === "OK") {
        let result:any = null;
        let relationFilters = {};
        if (contactResult.PERSON) {
            setIsPerson(true);
            result = extractSingleObject(contactResult.PERSON);
            setSelectedItem(result);
            relationFilters = 
          {COL:[
            {name:'contact',type:'equals',value:selectedId}
          ]}
        }
         else if (contactResult.COMPANY) {
            setIsPerson(false);
            result = extractSingleObject(contactResult.COMPANY);
            setSelectedItem(result);
            relationFilters = 
          {COL:[
            {name:'company',type:'equals',value:selectedId}
          ]}
          }
         else {
          setIsPerson(false);
          setSelectedItem(null);
        }

        
        var employeesResult = await mainService.listCompanyPersonRelations(new ListCompanyPersonRelationsRequest(
          store.Server,
          store.SessionId,{
          FILTER:relationFilters
        }))
        if(employeesResult.result == "OK"){
          setEmployeesList(employeesResult.EMPLOYEE)
        }

        const folders = await projectService.listFolderOrganization(new ListFolderOrganizationRequest(
          store.Server,
          store.SessionId,
          {contactId:selectedId}))
          if(result.FOLDERS){
            let folderIds;
            if(!Array.isArray(result.FOLDERS)){
                folderIds = [result.FOLDERS.FOLDER.id]
            }else{
              folderIds = result?.FOLDERS?.reduce((uniqueIds: Set<string>, folder: any) => {
                const folderId = folder.FOLDER.id;
                uniqueIds.add(folderId);
                return uniqueIds;
              }, new Set<string>());
            }
              
            if(folderIds){
                const uniqueFolderIds = Array.from(folderIds);

              const uniqueItems = folders?.ITEM?.reduce((uniqueMap: Map<string, any>, item: any) => {
                if (!uniqueMap.has(item.folderId)) {
                  uniqueMap.set(item.folderId, item); // Store unique items by folderId
                }
                return uniqueMap;
              }, new Map<string, any>());
  
              // Filter unique items based on unique folder IDs
              const filteredItems = Array.from(uniqueItems.values()).filter((item: any) =>
                uniqueFolderIds.includes(item.folderId)
              );

              setListOrganizationFolder(filteredItems);
            }else{
              setListOrganizationFolder([]);
            }
          }else{
            setListOrganizationFolder([]);
          }
        setDefinition(null);
        console.log('contactResult',result)
      }

      // let resSelectedItem = FPADataFactory.createFromActivityAdvanced(selectedRow, activityStatusMapping);
      // setSelectedItem(resSelectedItem);
      // let typeId = selectedRow.type;
      // const layout = await featureService.getFormPanel(new GetFormPanelRequest(store.Server, store.SessionId, '' + typeId));

      // if (layout.count > 0) {
      //   let scheme = SoapUtils.parseXmlString2(layout.ROW.SCHEME);
      //   let layoutObject = JSON.parse(scheme.ActivityPanelSettings.layoutObject);
      //   setDefinition(layoutObject);
      // } else {
      //   setDefinition(null);
      // }
    } catch (err) {
      console.error('getlayout error: ', err);
    } finally {
      setShowLoaderDetail(false);
    }
  };

  const handleEmployeeClick = async (employee:any)=>{
    setShowLoaderDetail(true);
    const contactResult = await mainService.getContact(new GetContactRequest(
      store.Server,
      store.SessionId,
      undefined,
      undefined,
      employee))
    if (contactResult.result === "OK") {
        setEmployeeDetail(contactResult.PERSON);
        setShowEmployeeDetail(true);
        setShowLoaderDetail(false);
    }
  }

  const handleEmployerClick = async (employer:any)=>{
    setShowLoaderDetail(true);
    const contactResult = await mainService.getContact(new GetContactRequest(
      store.Server,
      store.SessionId,
      undefined,
      undefined,
      employer))
    if (contactResult.result === "OK") {
        const contact = contactResult.COMPANY;
        // setRowsData(contact);
        // setSelectedKeys(new Set(contact.id));
        // setSelectedDataRow(contact);
        getLayout(contact.id)
        setShowEmployeeDetail(false);
        setShowLoaderDetail(false);
    }
  }

  const Loader = () => (
    <Flex width="100%" justifyContent="center" marginTop={10}>
      <ProgressCircle aria-label="Loading…" isIndeterminate />
    </Flex>
  );

  const NoResultsMessage = () => (
    <Flex direction="row" width="100%" alignContent="center" justifyContent="center">
      <IllustratedMessage>
        <NotFound />
        <Heading>{t('no_results', { ns: 'layout_components' })}</Heading>
        <Content>{t('try_another_search', { ns: 'layout_components' })}</Content>
      </IllustratedMessage>
    </Flex>
  );

  const getHeight = (baseHeight: number) => {
    if (browserName === 'Chrome') {
      return `${baseHeight - 120}px`;
    } else if (browserName === 'Safari') {
      return `${baseHeight - 120}px`;
    } else {
      return `${baseHeight - 120}px`;
    }
  };

  const getWidth = () => {
    // if (browserName === 'Chrome') {
    //   return `95%`;
    // } else if (browserName === 'Safari') {
    //   return `95%`;
    // } else {
    //   return `95%`;
    // }
    return `95%`;
  };

  const detailStyle = {
    height: getHeight(screenSize.height - 20),
  };

  const cardStyle = {
    height: getHeight(screenSize.height),
    width: getWidth(),
    top:0
  };

  const filterStyle = {
    height: getHeight(screenSize.height + 60),
    overflow: 'hidden auto',
  };

  const handleAddNew = (key:any) => {
    if(key === 'person'){
      setAddFormType(key);
    }else{
      setAddFormType(key);
    } 
    setShowAdd(true);
  }

  const handleAddNewClose = () => {
    setShowAdd(false);
    setIsEdit(false);
    setAddFormType('');
    //listContactList.reload();
    setIsPerson(false);
  }

  const handleEditClick = (data:boolean)=>{
    if(data){
      setIsEdit(data);
      if(isPerson){
        setAddFormType('person');
        setShowAdd(true);
      }else{
        setAddFormType('company');
        setShowAdd(true);
      }
    }  
  }

  const refreshDetailView = (contactId:any)=>{
    setShowLoaderDetail(true);
    setSelectedKeys(new Set([contactId]));
    getLayout(contactId)
  }

  const handleContactDelete = async (data:any)=>{
    const deleteContact = await mainService.deleteContact(new DeleteContactRequest(store.Server,store.SessionId,data.id))
    if(deleteContact.result === "OK"){
      ToastQueue.positive("Contact deleted successfully...",{timeout:3000})
      listContactList.reload()
    }else{
      ToastQueue.negative(deleteContact.EXCEPTION.message,{timeout:3000})
    }
  }

  return (
    <>
      {isMobile ? (
        <>
          {!showMobileDetailView && (
            <>
              <FilterComponent onFilterChange={reloadContacts} filterParams={queryParams.current} />
              <View position={'relative'} width={'100%'} height={'100%'} UNSAFE_className={styles.card_list_parent}>
                <Flex direction={'column'} position={'absolute'} width={'100%'} top={'72px'} left={'0%'} alignItems={'start'} justifyContent={'start'} gap={'12px'}>
                  {showLoader ? (
                    <Loader />
                  ) : (
                    <ListBox
                      id="contactCardsMobile"
                      selectionMode="single"
                      onSelectionChange={handleRowClickMobile}
                      onLoadMore={listContactList.items.length > 40 ? listContactList.loadMore : undefined}
                      marginStart={'18px'}
                      maxHeight={'100vh'}
                      minHeight={'130px'}
                      defaultSelectedKeys={selectedKeys}
                      aria-label="Service Activities Cards Mobile"
                      items={listContactList.items}
                      isLoading={listContactList.isLoading}
                      width={'90%'}
                    >
                      {item => (
                        <Item key={item.id} textValue={item.name}>
                          <CardComponent item={item} activityStatusList={activityStatusList} />
                        </Item>
                      )}
                    </ListBox>
                  )}
                </Flex>
                <Flex
                  direction={'column'}
                  position={'absolute'}
                  width={'calc(100% - 32px)'}
                  top={'16px'}
                  right={'16px'}
                  left={'16px'}
                  alignItems={'start'}
                  justifyContent={'start'}
                  UNSAFE_className={styles.search_parent}
                >
                  <Flex direction={'row'} alignSelf={'stretch'} height={'40px'} flexShrink={0} alignItems={'center'} justifyContent={'end'} gap={'8px'} UNSAFE_className={styles.search_field}>
                    <FilterSearchTextBox 
                    onSearchChange={reloadContacts} 
                    filterParams={queryParams.current} 
                    serachedText={searchedValue} />
                    <i className={`${styles.icon_search} bi bi-search`}></i>
                  </Flex>
                </Flex>
                <Flex
                  direction={'row'}
                  position={'fixed'}
                  right={'16px'}
                  bottom={'95px'}
                  height={'48px'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  gap={'8px'}
                  UNSAFE_className={styles.add_new_parent}
                >
                  <MenuTrigger>
                  <Link
                    isQuiet
                    aria-label='Actions'
                    UNSAFE_className={styles.add_new_btn}
                  >
                    <i className="bi bi-plus fs-5">
                      <View UNSAFE_className={styles.add_new_btn_text}>{t('add_new', { ns: 'layout_components' })}</View>
                    </i>
                  </Link>
                  <Menu onAction={handleAddNew}>
                    <Item key="person">Person</Item>
                    <Item key="company">Company</Item>
                  </Menu>
                  </MenuTrigger>
                </Flex>
              </View>
            </>
          )}

          {showMobileDetailView && (
            <>
              <View id='top' position={'relative'} width={'100%'} height={'64px'} overflow={'hidden'} UNSAFE_className={styles.layout_header_parent}>
                <View position={'absolute'} top={'calc(50% - 14px)'} left={'15%'} UNSAFE_className={styles.layout_header_text}>
                  {selectedItem?.item_type_name}
                </View>
                <View position={'absolute'} top={'calc(50% - 15px)'} left={'25px'} width={'24px'} height={'30px'} overflow={'hidden'} UNSAFE_className={styles.layout_header_btn_back}>
                  <Link
                    onPress={() => {
                      setStopLoadData(true);
                      setShowMobileDetailView(false);
                    }}
                  >
                    <i className="bi bi-chevron-left fs-5" />
                  </Link>
                </View>
                <a
                  onClick={() => {
                    setStopLoadData(true);
                    setShowMobileDetailView(false);
                  }}
                  className={styles.layout_header_btn_folder}
                >
                  <FinderIcon size={24} />
                </a>
              </View>
              <Flex direction="column" alignItems={'start'} justifyContent={'center'} UNSAFE_className={styles.detailsHolder} UNSAFE_style={{ padding: '0px', paddingTop: '0px' }}>
                {selectedItem && (
                  <>
                    {showLoader ? (
                      <Loader />
                    ) : (
                      <>
                        <View
                          UNSAFE_className={styles.service_status_bar}
                          marginBottom={'5px'}
                          marginTop={'0px'}
                          UNSAFE_style={{ backgroundColor: addStatusColor(selectedDataRow.customStateName) }}
                        ></View>
                        {/* <ComponentFactory
                          selectedItem={selectedItem}
                          definition={definition}
                          systemAttributes={{
                            onPrint: item => console.log('onPrint', item),
                            onEdit: item => console.log('onEdit', item),
                            onDelete: item => console.log('onDelete', item),
                            onChat: item => console.log('onChat', item),
                            onConfig: (item, type) => console.log('onConfig', item, type),
                          }}
                        /> */}
                        {showEmployeeDetail ?(
                                  <EmployeeDetailViewMobile 
                                  contact={employeeDetail}
                                  handleEmployeeCloseClick={()=>setShowEmployeeDetail(false)}
                                  handleEmployerClick={handleEmployerClick}/> 
                                  )
                                  :
                                   ( <ContactDetailViewMobile 
                                    contact={selectedItem}
                                    contactDataRow={selectedDataRow} 
                                    listOrganizationFolder={listOrganizationFolder}
                                    employees = {employeesList}
                                    contactStatusList = {contactStatusList}
                                    handleEmployeeClick={handleEmployeeClick}
                                    handleEditClick = {handleEditClick}
                                    handleContactDelete={handleContactDelete}
                                    />
                                   )
                          }
                      </>
                    )}
                  </>
                )}
              </Flex>
            </>
          )}
        </>
      ) : (
        <Grid
          areas={pageView == 'Full' ? ['filter header header', 'filter card detail'] : ['filter header header', 'filter detail detail']}
          columns={{ base: ['245px', '28%', '55%'], L: ['245px', '28%', '55%'] }}
          rows={['auto', '1fr']}
          height="100%"
          width="100%"
          UNSAFE_style={{ backgroundColor: '#f5f6f9', padding: '0px', margin: '0px', overflow: 'hidden' }}
        >
          <View gridArea="filter">
            <FilterComponent onFilterChange={reloadContacts} filterParams={queryParams.current} filterStyle={filterStyle} />
          </View>
          <View width={'100%'} gridArea="header" UNSAFE_style={pageView == 'Full' ? {} : { height: '100%' }}>
            <HeaderComponent
              onPageViewChange={handleViewChange}
              autoChanged={pageView}
              filterParams={queryParams.current}
              onSearchChange={reloadContacts}
              fpaTypeCategory={serviceActivityValue.current}
              onAddNew={handleAddNew}
            />
          </View>
          {pageView == 'Full' ? (
            <>
              {showLoader ? (
                <Loader />
              ) : (
                <>
                  <View gridArea="card" UNSAFE_style={{ backgroundColor: '#f5f6f9',overflow:'auto' }}>
                    {rowsData?.length > 0 ? (
                          <ListBox
                        autoFocus
                        id="contactCards"
                        selectionMode="single"
                        onSelectionChange={handleListRowClick}
                        onLoadMore={listContactList.items.length > 40 ? listContactList.loadMore : undefined}
                        marginStart={'15px'}
                        minHeight={'130px'}
                        defaultSelectedKeys={selectedKeys}
                        selectedKeys={selectedKeys}
                        aria-label="Contact Cards"
                        items={listContactList.items}
                        isLoading={listContactList.isLoading}
                        UNSAFE_style={{...cardStyle,marginTop:13}}
                      >
                        {item => (
                          <Item
                           key={item.id} textValue={item.name}>
                            <CardComponent item={item} activityStatusList={activityStatusList} />
                          </Item>
                        )}
                      </ListBox>
                    ) : (
                      <View marginTop={'13px'}>
                        <NoResultsMessage />
                      </View>
                    )}
                  </View>
                  <View
                    gridArea="detail"
                    backgroundColor={'static-white'}
                    borderRadius={'large'}
                    padding={'size-100'}
                    paddingTop={'0'}
                    marginTop={'17px'}
                    UNSAFE_style={detailStyle}
                    width={'98%'}
                    UNSAFE_className={styles.detailView}
                  >
                    {(showLoaderDetail ? (
                        <Loader />
                      ) : (
                        <View height="100%" UNSAFE_className={styles.detailsHolder} UNSAFE_style={{ overflow: 'hidden auto' }} marginStart={'10px'}>
                          {selectedItem && (
                            <>
                              {rowsData?.length > 0 ? (
                                <>
                                  <View UNSAFE_className={styles.service_status_bar} UNSAFE_style={{ backgroundColor: getStatusColor() }}></View>
                                  <View height={'2px'}>&nbsp;</View>
                                  {showEmployeeDetail ?(
                                  <EmployeeDetailView 
                                  contact={employeeDetail}
                                  handleEmployeeCloseClick={()=>setShowEmployeeDetail(false)}
                                  handleEmployerClick={handleEmployerClick}/> 
                                  )
                                  :
                                   ( <ContactDetailView 
                                    contact={selectedItem}
                                    contactDataRow={selectedDataRow} 
                                    listOrganizationFolder={listOrganizationFolder}
                                    employees = {employeesList}
                                    contactStatusList = {contactStatusList}
                                    handleEmployeeClick={handleEmployeeClick}
                                    handleEditClick = {handleEditClick}
                                    isPerson = {isPerson}
                                    handleContactDelete = {handleContactDelete}
                                    />
                                   )
                                  }
                                </>
                              ) : (
                                ''
                              )}
                            </>
                          )}
                        </View>
                      ))}
                  </View>
                </>
              )}
            </>
          ) : (
            <View gridArea="detail">
              {showLoader ? (
                <Loader />
              ) : (
                <View position={'relative'} overflow={'auto'} height={'100%'} width={'100%'} UNSAFE_className={styles.service_activities}>
                  {columnsData?.length > 0 && rowsData?.length > 0 ? (
                    <TableView
                      UNSAFE_className="tblLayoutComponent"
                      aria-label="Contacts"
                      selectionMode="single"
                      defaultSelectedKeys={selectedKeys}
                      selectionStyle="highlight"
                      width={{ base: '100%', L: '100%', M: '1100px' }}
                      minHeight={'100px'}
                      maxHeight={{ base: '100vh', L: '625px' }}
                      onAction={handleTableRowClick}
                    >
                      <TableHeader columns={columnsData}>
                        {(column: any) => (
                          <Column showDivider key={column.key}>
                            {column.name}
                          </Column>
                        )}
                      </TableHeader>
                      <TableBody 
                      onLoadMore={listContactList.items.length > 40 ? listContactList.loadMore : undefined}
                      items={listContactList.items} 
                      loadingState={listContactList.loadingState}>
                        {(item: any) => (
                          <Row>
                            {columnKey => (
                              <Cell>
                                {(() => {
                                  if (moment(item[columnKey], moment.ISO_8601, true).isValid()) {
                                    return moment(item[columnKey]).format('MM/DD/YYYY');
                                  } else if (columnKey === 'customStateName') {
                                    return getStatusColor();
                                  } else {
                                    return item[columnKey];
                                  }
                                })()}
                              </Cell>
                            )}
                          </Row>
                        )}
                      </TableBody>
                    </TableView>
                  ) : (
                    <NoResultsMessage />
                  )}
                </View>
              )}
            </View>
          )}
        </Grid>
      )}
        <DialogContainer onDismiss={handleAddNewClose}>
          {showAdd && addFormType === 'person' ? (
            <AddNewPerson 
            onCloseModal={handleAddNewClose} 
            person={selectedItem} 
            isEdit={isEdit} 
            employments={employeesList}
            handleEditClick={handleEditClick}
            refreshDetailView = {refreshDetailView} />
          ): showAdd && addFormType === 'company' &&(<AddNewCompany
            refreshDetailView={refreshDetailView}
          onCloseModal={handleAddNewClose} 
          company={selectedItem} 
          isEdit={isEdit} 
          employments={employeesList}
          handleEditClick={handleEditClick} />)}
        </DialogContainer>
    </>
  );
}
