import { BaseRequest, IBaseRequest } from '../../BaseRequest';

export interface IListMessageRequest extends IBaseRequest {
    parent?:            string;
    offset?:            string;
    limit?:             string;
    dateTo?:            string;
    dateFrom?:          string;
    contains?:          string;
    senderPlain?:       string;
    sender?:            string;
    status?:            string;
    printed?:           string;
    newOnly?:           string;
    order?:             string;
    orderBy?:           string;
    fromNode?:          string;
    treeHandle?:        string;
    showInbox?:         string;
    tree?:              string;
    showDeleted?:       string;
    showOutgoing?:      string;
    showSent?:          string;
    showDrafts?:        string;
    showAll?:           string;
    showAllMy?:         string;
    starred?:           string;
    subjectContains?:   string;
    countOnly?:         string;
    filterLastMonths?:  string;
    CONTACT_RELATIONS?: ContactRelations;
    threadId?:          string;
}

export interface ContactRelations {
    CONTACT_RELATION: ContactRelation[];
}

export function toContactRelationsXml(cr: ContactRelations): string {
    var xml = `<CONTACT_RELATIONS>`;
    if(cr.CONTACT_RELATION) {
        cr.CONTACT_RELATION.forEach(function (contactRelation) {
            xml += toContactRelationXml(contactRelation);
        });
    }
    xml += `</CONTACT_RELATIONS>`;
    return xml;
}

export interface ContactRelation {
    type?:       string;
    identifier?: string;
    contact?:    string;
}

export function toContactRelationXml(cr: ContactRelation): string {
    var xml = `<CONTACT_RELATION>`;
    if(cr.type) xml += `<type>${cr.type}</type>`;
    if(cr.identifier) xml += `<identifier>${cr.identifier}</identifier>`;
    if(cr.contact) xml += `<contact>${cr.contact}</contact>`;
    xml += `</CONTACT_RELATION>`;
    return xml;
}

export class ListMessageRequest extends BaseRequest implements IListMessageRequest {

    public parent?: string;
    public offset?: string;
    public limit?: string;
    public dateTo?: string;
    public dateFrom?: string;
    public contains?: string;
    public senderPlain?: string;
    public sender?: string;
    public status?: string;
    public printed?: string;
    public newOnly?: string;
    public order?: string;
    public orderBy?: string;
    public fromNode?: string;
    public treeHandle?: string;
    public showInbox?: string;
    public tree?: string;
    public showDeleted?: string;
    public showOutgoing?: string;
    public showSent?: string;
    public showDrafts?: string;
    public showAll?: string;
    public showAllMy?: string;
    public starred?: string;
    public subjectContains?: string;
    public countOnly?: string;
    public filterLastMonths?: string;
    public CONTACT_RELATIONS?: ContactRelations;
    public threadId?: string;

    constructor(server: string, session: string, otherOptions: any) {
        super(server, session);
        this.parent = otherOptions.parent;
        this.offset = otherOptions.offset;
        this.limit = otherOptions.limit;
        this.dateTo = otherOptions.dateTo;
        this.dateFrom = otherOptions.dateFrom;
        this.contains = otherOptions.contains;
        this.senderPlain = otherOptions.senderPlain;
        this.sender = otherOptions.sender;
        this.status = otherOptions.status;
        this.printed = otherOptions.printed;
        this.newOnly = otherOptions.newOnly;
        this.order = otherOptions.order;
        this.orderBy = otherOptions.orderBy;
        this.fromNode = otherOptions.fromNode;
        this.treeHandle = otherOptions.treeHandle;
        this.showInbox = otherOptions.showInbox;
        this.tree = otherOptions.tree;
        this.showDeleted = otherOptions.showDeleted;
        this.showOutgoing = otherOptions.showOutgoing;
        this.showSent = otherOptions.showSent;
        this.showDrafts = otherOptions.showDrafts;
        this.showAll = otherOptions.showAll;
        this.showAllMy = otherOptions.showAllMy;
        this.starred = otherOptions.starred;
        this.subjectContains = otherOptions.subjectContains;
        this.countOnly = otherOptions.countOnly;
        this.filterLastMonths = otherOptions.filterLastMonths;
        this.threadId = otherOptions.threadId;
        this.CONTACT_RELATIONS = otherOptions.CONTACT_RELATIONS;
    }

    public toXml(): string {
        var parameters: string = super.toXml();

        if(this.parent) parameters += `<parent>${this.parent}</parent>`;
        if(this.offset) parameters += `<offset>${this.offset}</offset>`;
        if(this.limit) parameters += `<limit>${this.limit}</limit>`;
        if(this.dateTo) parameters += `<dateTo>${this.dateTo}</dateTo>`;
        if(this.dateFrom) parameters += `<dateFrom>${this.dateFrom}</dateFrom>`;
        if(this.contains) parameters += `<contains>${this.contains}</contains>`;
        if(this.senderPlain) parameters += `<senderPlain>${this.senderPlain}</senderPlain>`;
        if(this.sender) parameters += `<sender>${this.sender}</sender>`;
        if(this.status) parameters += `<status>${this.status}</status>`;
        if(this.printed) parameters += `<printed>${this.printed}</printed>`;
        if(this.newOnly) parameters += `<newOnly>${this.newOnly}</newOnly>`;
        if(this.order) parameters += `<order>${this.order}</order>`;
        if(this.orderBy) parameters += `<orderBy>${this.orderBy}</orderBy>`;
        if(this.fromNode) parameters += `<fromNode>${this.fromNode}</fromNode>`;
        if(this.treeHandle) parameters += `<treeHandle>${this.treeHandle}</treeHandle>`;
        if(this.showInbox) parameters += `<showInbox>${this.showInbox}</showInbox>`;
        if(this.tree) parameters += `<tree>${this.tree}</tree>`;
        if(this.showDeleted) parameters += `<showDeleted>${this.showDeleted}</showDeleted>`;
        if(this.showOutgoing) parameters += `<showOutgoing>${this.showOutgoing}</showOutgoing>`;
        if(this.showSent) parameters += `<showSent>${this.showSent}</showSent>`;
        if(this.showDrafts) parameters += `<showDrafts>${this.showDrafts}</showDrafts>`;
        if(this.showAll) parameters += `<showAll>${this.showAll}</showAll>`;
        if(this.showAllMy) parameters += `<showAllMy>${this.showAllMy}</showAllMy>`;
        if(this.starred) parameters += `<starred>${this.starred}</starred>`;
        if(this.subjectContains) parameters += `<subjectContains>${this.subjectContains}</subjectContains>`;
        if(this.countOnly) parameters += `<countOnly>${this.countOnly}</countOnly>`;
        if(this.filterLastMonths) parameters += `<filterLastMonths>${this.filterLastMonths}</filterLastMonths>`;
        if(this.threadId) parameters += `<threadId>${this.threadId}</threadId>`;
        if(this.CONTACT_RELATIONS) parameters += toContactRelationsXml(this.CONTACT_RELATIONS);
        
        return super.wrapperXml('ListMessage', parameters);
    }
}
