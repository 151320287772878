import { Flex, View } from '@adobe/react-spectrum';
import styles from './Dashboard.module.css';
import jsonPackage from '../../../package.json';
import { useDependency } from '../../contexts/DependencyProvider';
import { useTranslation } from 'react-i18next';

import { ListContactListRequest } from '../../services/soap/main/requests/ListContactListRequest';
import { GetFromContactListRequest } from '../../services/soap/main/requests/GetFromContactListRequest';
import { ListCountryRequest } from '../../services/soap/translate/reqeusts/ListCountryRequest';
import { ListActivityUserRequest } from '../../services/soap/team/requests/ListActivityUserRequest';
import { NewActivityUserRequest } from '../../services/soap/team/requests/NewActivityUserRequest';
import { GetContactListRequest } from '../../services/soap/main/requests/GetContactListRequest';
import { ListProjectUserRequest } from '../../services/soap/team/requests/ListProjectUserRequest';
import { ListFolderUserRequest } from '../../services/soap/team/requests/ListFolderUserRequest';
import { UpdateActivityUserRequest } from '../../services/soap/team/requests/UpdateActivityUserRequest';
import { DeleteActivityUserRequest } from '../../services/soap/team/requests/DeleteActivityUserRequest';
import { UpdateFolderUserRequest } from '../../services/soap/team/requests/UpdateFolderUserRequest';
import { ListTimelineRequest } from '../../services/soap/features/requests/ListTimelineRequest';
import { ListActivityTypeRequest } from '../../services/soap/project/requests/ListActivityTypeRequest';
import { NewMessageRequest } from '../../services/soap/message/requests/NewMessageRequest';
import { ListFolderTemplateRequest } from '../../services/soap/project/requests/ListFolderTemplateRequest';
import { ListFormSettingsRequest } from '../../services/soap/features/requests/ListFormSettingsRequest';
import { GetFormPanelRequest } from '../../services/soap/features/requests/GetFormPanelRequest';
import { usePreloadAssets } from '../../hooks/UsePreloadAssets';
import { GetFormSettingsRequest } from '../../services/soap/features/requests/GetFormSettingsRequest';
import { GetFolderTemplateRequest } from '../../services/soap/project/requests/GetFolderTemplateRequest';
import { GetFormRequest } from '../../services/soap/form/requests/GetFormRequest';
import { CheckWorkFlowQueueRequest } from '../../services/soap/features/requests/CheckWorkFlowQueueRequest';
import { ListActivityAdvancedRequest } from '../../services/soap/project/requests/ListActivityAdvancedRequest';
import { ListActivityTemplateRequest } from '../../services/soap/project/requests/ListActivityTemplateRequest';
import { ListDimensionNodeRequest } from '../../services/soap/features/requests/ListDimensionNodeRequest';
import { ListProjectAdvancedRequest } from '../../services/soap/project/requests/ListProjectAdvancedRequest';
import { ListProjectTemplateRequest } from '../../services/soap/project/requests/ListProjectTemplateRequest';
import { useSelector } from 'react-redux';
import { RootState } from '../../state/store';
import { FPADataFactory } from '../../infra/protected/FPA/FPAData';
import { ListRegisterRequest } from '../../services/soap/features/requests/ListRegisterRequest';
import { ListFPARequest2 } from '../../services/soap/project/requests/ListFPARequest2';
import { IdentifyContactRequest } from '../../services/soap/main/requests/IdentifyContactRequest';
import { ListEventRequest } from '../../services/soap/scheduler/requests/ListEventRequest';
import { ListFPATypeDocumentParsRequest } from '../../services/soap/project/requests/ListFPATypeDocumentParsRequest';
import { Col, FilterCol, Order } from '../../services/soap/project/requests/ListFPARequest';
import { ListDistributionGroupRequest } from '../../services/soap/main/requests/ListDistributionGroupRequest';
import { GetContactRequest } from '../../services/soap/main/requests/GetContactRequest';
import { DeleteMessageRequest } from '../../services/soap/message/requests/DeleteMessageRequest';
import { ListUserRequest } from '../../services/soap/access/requests/ListUserRequest';
import { ListPositionRequest } from '../../services/soap/main/requests/ListPositionRequest';
import { ListDepartmentRequest } from '../../services/soap/main/requests/ListDepartmentRequest';
import { ListReferenceRequest } from '../../services/soap/main/requests/ListReferenceRequest';
import { ListCurrencyRequest } from '../../services/soap/global/requests/ListCurrencyRequest';
import { ListCurrencyRateRequest } from '../../services/soap/finance/requests/ListCurrencyRateRequest';
import { GetMailboxRequest } from '../../services/soap/mail/requests/GetMailboxRequest';
import { NewMessageTemplateRequest } from '../../services/soap/message/requests/NewMessageTemplateRequest';
//import { ListMailboxRequest } from '../../services/soap/mail/requests/ListMailboxRequest';

const Dashboard = () => {
  const { activityStatusMapping, contactSettings } = usePreloadAssets();
  const {
    mainService,
    featureService,
    translateService,
    projectService,
    formService,
    itemService,
    financeService,
    globalService,
    schedulerService,
    accessService,
    printRestService,
    documentLibraryService,
    teamService,
    messageService,
    mailService,
    store,
  } = useDependency();
  const { formSettings, industries } = usePreloadAssets();
  const { t } = useTranslation();

  const _t = (key: string) => t(`dashboard.${key}`, { ns: 'common' });
  const today = new Date();

  const folderId = 524524101;
  const projectId = 524587101;

  const selectedApp = useSelector((state: RootState) => state.userWebLayout.selectedAppId);

  const handleClick = async () => {
    try {
      var res1 = await messageService.newMessageTemplate(new NewMessageTemplateRequest(store.Server, store.SessionId, 
        {
          messageTemplate: {
            id: "",
            aclh: "",
            treeHandle: 131749101,
            templateName: "[ Autosave: Draft with Notify ]",
            description: "",
            created: "2025-03-14T12:38:11",
            subject: "Draft with Notify",
            isDraft: 1,
            html: 0,
            type: 7,
            language: "",
            isDefault: 0,
            folderId: "",
            projectId: "",
            activityId: "",
            RECIPIENT: {
              TO: [
                {
                  CONTENT: "mailto:test@firstclient.atollon.com",
                  contact: "First Client Co. Ltd.",
                  id: 132799101
                },
                {
                  CONTENT: "mailto:testovaci@testmail.eu",
                  contact: "ELBA s.r.o.",
                  id: 132783101
                }
              ],
              CC: [
                {
                  CONTENT: "mailto:z@z.com",
                  contact: "Zubair Shah",
                  id: 530549101
                },
                {
                  CONTENT: "mailto:z_102@zubair.com",
                  contact: "102 201",
                  id: 537656101
                }
              ],
              USER: [
                {
                  CONTENT: "admin@pioneer",
                  contact: "admin@pioneer",
                  id: 131750101
                },
                {
                  CONTENT: "finance@pioneer",
                  contact: "finance@pioneer",
                  id: 134830101
                }
              ]
            },
            BODY: {
              string: "Draft with Notify\n\n\nAdmin Top\nPrague\nCZ"
            },
            DOCUMENTS: "",
            TRANSLATIONS: ""
          
          }
        }
      ));
      console.log('res1', res1);
    } catch (error) {
      console.log('error', error);
    }
  };
  return (
    <>
      <div className={styles.centerText} onClick={handleClick}>
        {_t('welcome')}
      </div>
      <Flex direction="column" alignItems="center">
        <View>
          <p style={{ paddingTop: '10px' }}>
            v{jsonPackage.version} {_t('copyright')} {today.getFullYear()}
          </p>
        </View>
      </Flex>
    </>
  );
};

export default Dashboard;
