import { SettingItem } from '../../../services/soap/features/responses/GetFormSettingsResponse';
import { Divider, Flex } from '@adobe/react-spectrum';
import {
  CreateActivityFields,
  CreateActivityNameField,
  CreateBillingItemsFields,
  CreateContextInfoFields,
  CreateCustomFormFieldForContextType,
  CreateCustomFormFields,
  CreateMasterActivityFields,
  CreatePersonFields,
  CreateResponsibleUserFields,
  CreateServiceItemsFields,
  CreateTrackingCodeFields,
} from './ContextFields';
import { FPATemplateItem } from '../../../infra/protected/FPA/FPATemplateItem';
import { FPAData } from '../../../infra/protected/FPA/FPAData';
import { ContextNewDialogMode } from './ContextNewDialog';
import { useState } from 'react';

export interface IActivityContextFieldsProps {
  settings: SettingItem[];
  parentItem: FPAData | null;
  selectedItem?: FPATemplateItem;
  setFormData?: (key: string, value: any, isNew: boolean) => void;
  contextTypeFormId?: string;
  activityType?: number;
  haveMasterActivity?: boolean;
  mode?: ContextNewDialogMode;
  getFormData?: (key: string) => any;
}

export function ActivityContextFields({ settings, parentItem, selectedItem, setFormData, contextTypeFormId, activityType, haveMasterActivity, mode, getFormData }: IActivityContextFieldsProps) {
  const [projectId, setProjectId] = useState<number>(0);
  return (
    <Flex direction={'column'} gap={'size-100'}>
      <CreateActivityNameField settings={settings} parentItem={parentItem} selectedItem={selectedItem} setFormData={setFormData} mode={mode} getFormData={getFormData} />
      <CreateActivityFields settings={settings} parentItem={parentItem} selectedItem={selectedItem} setFormData={setFormData} mode={mode} getFormData={getFormData} />

      {haveMasterActivity && (
        <>
          <Divider size="M" />
          <CreateMasterActivityFields
            settings={settings}
            parentItem={parentItem}
            selectedItem={selectedItem}
            setFormData={setFormData}
            activityType={activityType}
            mode={mode}
            getFormData={getFormData}
          />
        </>
      )}
      <Divider size="M" />
      <CreateContextInfoFields
        settings={settings}
        parentItem={parentItem}
        selectedItem={selectedItem}
        setFormData={setFormData}
        getFormData={getFormData}
        showFolders={true}
        showProjects={true}
        showActivities={false}
        activityType={activityType}
        mode={mode}
        onChangeProject={setProjectId}
      />
      <Divider size="M" />
      <CreateTrackingCodeFields settings={settings} parentItem={parentItem} selectedItem={selectedItem} setFormData={setFormData} mode={mode} getFormData={getFormData} />
      <Divider size="M" />
      <CreatePersonFields settings={settings} parentItem={parentItem} selectedItem={selectedItem} setFormData={setFormData} mode={mode} getFormData={getFormData} />
      <Divider size="M" />
      <CreateServiceItemsFields settings={settings} parentItem={parentItem} selectedItem={selectedItem} setFormData={setFormData} mode={mode} getFormData={getFormData} projectId={projectId} />
      <Divider size="M" />
      <CreateResponsibleUserFields settings={settings} parentItem={parentItem} selectedItem={selectedItem} setFormData={setFormData} mode={mode} getFormData={getFormData} />
      <Divider size="M" />
      <CreateBillingItemsFields settings={settings} parentItem={parentItem} selectedItem={selectedItem} setFormData={setFormData} mode={mode} getFormData={getFormData} />
      <Divider size="M" />
      <CreateCustomFormFields settings={settings} parentItem={parentItem} selectedItem={selectedItem} setFormData={setFormData} mode={mode} getFormData={getFormData} />

      <CreateCustomFormFieldForContextType formId={contextTypeFormId} setFormData={setFormData} mode={mode} getFormData={getFormData} />
    </Flex>
  );
}
