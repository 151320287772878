import { BaseRequest, IBaseRequest } from '../../BaseRequest';

export interface IDeleteContactRequest extends IBaseRequest {
    contactId: number;
}

export class DeleteContactRequest extends BaseRequest implements IDeleteContactRequest {
    public contactId: number;

    constructor(server: string, session: string, contactId: number) {
        super(server, session);
        this.contactId = contactId;
    }

  public toXml(): string {
    var parameters: string = super.toXml();

    if(this.contactId) parameters += '<contactId>' + this.contactId + '</contactId>';

    return super.wrapperXml('DeleteContact', parameters);
  }
}
