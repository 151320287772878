import * as React from 'react';
import { LayoutComponent } from '../LayoutComponent';
import { Flex } from '@adobe/react-spectrum';
import { LayoutDefinition } from '../../../../infra/protected/Layout/LayoutDefinitions';
import { ComponentFactory } from '../ComponentFactory';
import { FPAData } from '../../../../infra/protected/FPA/FPAData';
import { SystemAttributes } from '../../../../infra/protected/Layout/SystemAttributes';

export interface IVerticalLayoutProps {
    children?: LayoutDefinition[];
    selectedItem?: FPAData;
    systemAttributes: SystemAttributes<FPAData>;
}

function VerticalLayout ({ children, selectedItem, systemAttributes }: IVerticalLayoutProps) {
    if(!children) return (<>No children</>);
    return (
        <Flex direction="column" gap="size-100">
            {children.map((child, index) => (<ComponentFactory key={index} definition={child} selectedItem={selectedItem!} systemAttributes={systemAttributes} />))}
        </Flex>
    );
}

export const VerticalLayoutComponent = LayoutComponent(VerticalLayout);
