import { View, Accordion, Disclosure, DisclosureTitle, DisclosurePanel, Flex, TextField, Heading, Divider } from '@adobe/react-spectrum';
import React from 'react';
import { ContactFormData } from '../AddNewPerson';

// Separate component sections
export const NameSection: React.FC<{
  data: {
    prefix: string;
    name1: string;
    name2: string;
    name3: string;
    suffix: string;
  };
  onChange: (data: {
    prefix: string;
    name1: string;
    name2: string;
    name3: string;
    suffix: string;
  }) => void;
  validationErrors: any
}> = ({ data, onChange,validationErrors }) => {
  
  return (
  <View padding="size-200" paddingBottom="size-0" paddingTop="size-0">
    <Heading level={5}>Name</Heading>
    <Divider aria-label='' />
    <Flex direction="column" gap="size-100" width="100%">
            <TextField
              label="Prefix"
              aria-label='Prefix'
              value={data.prefix}
              onChange={(prefix) => onChange({ ...data, prefix })}
              width="30%"
               />
            <TextField
              label="First Name"
              aria-label='First Name'
              isRequired
              value={data.name3}
              onChange={(name3) => onChange({ ...data, name3 })}
              validationState={validationErrors.name3 ? 'invalid' : 'valid'}
              errorMessage={validationErrors.name3}
              width="100%" />
            <TextField
              label="Last Name"
              aria-label='Last Name'
              isRequired
              value={data.name1}
              onChange={(name1) => onChange({ ...data, name1 })}
              validationState={validationErrors.name1 ? 'invalid' : 'valid'}
              errorMessage={validationErrors.name1}
              width="100%" />
            <TextField
              label="Middle Name"
              aria-label='Middle Name'
              value={data.name2}
              onChange={(name2) => onChange({ ...data, name2 })}
              width="100%" />
            <TextField
              label="Suffix"
              aria-label='Suffix'
              value={data.suffix}
              onChange={(suffix) => onChange({ ...data, suffix })}
              width="30%" />
          </Flex>
  </View>
);
}
