import { TRANSLATE } from "../../../utils/ServiceUtils";
import { ServiceBase } from "../ServiceBase";
import { IListCityRequest } from "./reqeusts/ListCityRequest";
import { IListCountryRequest } from "./reqeusts/ListCountryRequest";
import { IListLanguagesRequest } from "./reqeusts/ListLanguagesRequest";
import { IListRegionRequest } from "./reqeusts/ListRegionRequest";
import { IListZipRequest } from "./reqeusts/ListZipRequest";
import { ListCityResponse } from "./responses/ListCityResponse";
import { ListCountryResponse } from "./responses/ListCountryResponse";
import { ListLanguagesResponse } from "./responses/ListLanguagesResponse";
import { ListRegionResponse } from "./responses/ListRegionResponse";
import { ListZipResponse } from "./responses/ListZipResponse";

export interface ITranslateService {
    listLanguages(request: IListLanguagesRequest): Promise<ListLanguagesResponse>;
    listCountry(request: IListCountryRequest): Promise<ListCountryResponse>;
    listZip(request: IListZipRequest): Promise<ListZipResponse>;
    listCity(request: IListCityRequest): Promise<ListCityResponse>;
    listRegion(request: IListRegionRequest): Promise<ListRegionResponse>;
}


export class TranslateService extends ServiceBase implements ITranslateService{
    private _addRootElementToLanguagesList(xml: string): string {
        xml = xml.replaceAll('</count><LANGUAGE>', '</count><LIST><LANGUAGE>');
        xml = xml.replaceAll('</LANGUAGE></ns1:ListLanguagesResponse>', '</LANGUAGE></LIST></ns1:ListLanguagesResponse>');
        return xml;
    }
    public async listLanguages(request: IListLanguagesRequest): Promise<ListLanguagesResponse> {
        let response: any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), TRANSLATE.listLanguages, request.cancelSource));
            // response = this._addRootElementToLanguagesList(tmp_response.data);
            response = tmp_response.data;
            await this.updateCache(TRANSLATE.listLanguages, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(TRANSLATE.listLanguages, request.toXml());
        }

        var parsedResponse = super.sendResponse2(response, ['LANGUAGE']).ListLanguagesResponse as ListLanguagesResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;        
    }

    public async listCountry(request: IListCountryRequest): Promise<ListCountryResponse> {
        let response: any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), TRANSLATE.listCountry, request.cancelSource));
            response = tmp_response.data;
            await this.updateCache(TRANSLATE.listCountry, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(TRANSLATE.listCountry, request.toXml());
        }

        var parsedResponse = super.sendResponse2(response, ['ROW']).ListCountryResponse as ListCountryResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;        
    }

    public async listZip(request: IListZipRequest): Promise<ListZipResponse> {
        let response: any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), TRANSLATE.listZip, request.cancelSource));
            response = tmp_response.data;
            await this.updateCache(TRANSLATE.listZip, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(TRANSLATE.listZip, request.toXml());
        }

        var parsedResponse = super.sendResponse2(response, ['ROW']).ListZipResponse as ListZipResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;        
    }

    public async listCity(request: IListCityRequest): Promise<ListCityResponse> {
        let response: any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), TRANSLATE.listCity, request.cancelSource));
            response = tmp_response.data;
            await this.updateCache(TRANSLATE.listCity, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(TRANSLATE.listCity, request.toXml());
        }    

        var parsedResponse = super.sendResponse2(response, ['ROW']).ListCityResponse as ListCityResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;        
    }

    public async listRegion(request: IListRegionRequest): Promise<ListRegionResponse> {
        let response: any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), TRANSLATE.listRegion, request.cancelSource));
            response = tmp_response.data;
            await this.updateCache(TRANSLATE.listRegion, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(TRANSLATE.listRegion, request.toXml());
        }

        var parsedResponse = super.sendResponse2(response, ['ROW']).ListRegionResponse as ListRegionResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;        
    }
}