import {
  View,
  Accordion,
  Disclosure,
  DisclosureTitle,
  DisclosurePanel,
  Flex,
  TextField,
  Heading,
  Divider,
  NumberField,
  ComboBox,
  Item,
} from "@adobe/react-spectrum";
import React, { useEffect, useState } from "react";
import { ContactFormData } from "../AddNewPerson";
import ReferenceMarketingChanelDropDown from "../formComponents/ReferenceMarketingChanelDropDown";
import CompanyNameDropDown from "../formComponents/CompanyNameDropdown";
import { useDependency } from "../../../../../contexts/DependencyProvider";
import { ListContactListRequest } from "../../../../../services/soap/main/requests/ListContactListRequest";

// Separate component sections
export const CompanyBaseInfo: React.FC<{
  data: {
    name1: string;
    regNumber: string;
    VATNumber: string;
    legalForm: string;
    legalFormName: string;
    reference: string;
    trackingCode: string;
    category: string;
    dueDays: number;
  };
  onChange: (data: {
    name1: string;
    regNumber: string;
    VATNumber: string;
    legalForm: string;
    legalFormName: string;
    reference: string;
    trackingCode: string;
    category: string;
    dueDays: number;
  }) => void;
  validationErrors: any;
}> = ({ data, onChange, validationErrors }) => {
  const { store, mainService } = useDependency();
  const [legalForms, setLegalForms] = useState<any>([]);
  const [selectedKey, setSelectedKey] = useState<string | null>(null);
  const getLegalForms = async () => {
    const legalForms = await mainService.listContactList(
      new ListContactListRequest(store.Server, store.SessionId, {
        list: "companylegalform",
      })
    );
    setLegalForms(legalForms.ITEM);
  };

  useEffect(() => {
    getLegalForms();
  }, []);

  const handleSelectionChange = (key: any) => {
    setSelectedKey(key);
    const filteredLefalForm = legalForms.find((x: any) => x.id === key);
    onChange({
      ...data,
      legalForm: key,
      legalFormName: filteredLefalForm.name || "",
    });
  };

  return (
    <View padding="size-200" paddingBottom="size-0" paddingTop="size-0">
      <Heading level={5}>Base Info </Heading>
      <Divider />
      <Flex direction="column" gap="size-100" width="100%">
        <CompanyNameDropDown
          validationErrors={validationErrors}
          textValue={data.name1}
          onChange={(company) => {
            onChange({
              ...data,
              name1: company.nazev,
              regNumber: company.ic,
              VATNumber: company.dic,
            });
          }}
        />
        <ComboBox
          label="Legal Form"
          items={legalForms}
          onSelectionChange={handleSelectionChange}
          width="100%"
          selectedKey={data.legalForm}
        >
          {(item: any) => <Item key={item.id}>{item.name}</Item>}
        </ComboBox>
        <TextField
          label="Reg No."
          value={data.regNumber}
          onChange={(regNumber) => onChange({ ...data, regNumber })}
          width="100%"
        />
        <TextField
          label="VAT No."
          value={data.VATNumber}
          onChange={(VATNumber) => onChange({ ...data, VATNumber })}
          width="100%"
        />
        <ComboBox
          label="Category"
          onSelectionChange={(cat: any) => onChange({ ...data, category: cat })}
          width="100%"
        >
          <Item>''</Item>
        </ComboBox>
        <ReferenceMarketingChanelDropDown
          selectedChanel={data.trackingCode}
          onChange={(channel) => {
            const { refId, refName } = channel;
            onChange({ ...data, trackingCode: refName });
          }}
        />
        <NumberField
          label="Due Days"
          value={data.dueDays}
          onChange={(dueDays) => onChange({ ...data, dueDays })}
          width="40%"
        />
      </Flex>
    </View>
  );
};
