import { Accordion, Disclosure, DisclosurePanel, DisclosureTitle, Divider, Flex, Heading, TextArea, View } from "@adobe/react-spectrum";
interface ICommentSectionProps{
    comment:string;
    keywords:string;
    onChange:(data:{comment:string,keywords:string})=> void;
}
export const CommentSection: React.FC<ICommentSectionProps> = ({ comment, keywords, onChange }) => (
  <View padding="size-200" paddingBottom='size-0' paddingTop='size-300'>
    <Heading level={5}>Comment</Heading>
    <Divider />
    <Flex direction="column" gap="size-100" width="100%" marginTop={10}>
      <TextArea
        label="Comment"
        value={comment}
        onChange={(newComment) => onChange({ comment: newComment, keywords })}
        width='100%'
      />
      <TextArea
        label="Keywords"
        value={keywords}
        onChange={(newKeywords) => onChange({ comment, keywords: newKeywords })}
        width='100%'
      />
    </Flex>
  </View>
);