import React, { useEffect, useState } from "react";
import { Item, ListBox, Text, ComboBox, Grid, useAsyncList } from "@adobe/react-spectrum";
import { useDependency } from "../../../../../contexts/DependencyProvider";
import { GetContactListRequest } from "../../../../../services/soap/main/requests/GetContactListRequest";
import { ListDepartmentRequest } from "../../../../../services/soap/main/requests/ListDepartmentRequest";


export interface Character {
  id:string;
  name:string;
  description:string;
}

interface IDepartmentDropDownProps{
   selectedDepartment:string;
   onChange:(data:Character)=> void;
}

const DepartmentDropDown:React.FC<IDepartmentDropDownProps> = ({onChange,selectedDepartment}) => {
  const { store, mainService } = useDependency();
  const [selectedKey, setSelectedKey] = useState<string | null>(null);

  useEffect(()=>{
     list.reload();
  },[])

  const list = useAsyncList<Character>({
    async load({ cursor }) {
      const offset = cursor ? Number(cursor) : 0;
      const limit = 50;
      try {
        const request = new ListDepartmentRequest(store.Server, store.SessionId);
        const response = await mainService.listDepartment(request);

        const companies = response?.DEPARTMENT || [];
        if (!Array.isArray(companies)) {
          console.error("Invalid API response: Expected an array", response);
          return { items: [] };
        }

        return {
          items: companies,
          cursor: companies.length === limit ? String(offset + limit) : undefined,
        };
      } catch (error) {
        console.error("Error fetching companies:", error);
        return { items: [] };
      }
    },
  });

  useEffect(() => {
        if (selectedDepartment) {
          setSelectedKey(selectedDepartment);
          list.setFilterText(selectedDepartment)
          list.reload();
        } else {
          setSelectedKey(null);
        }
      }, [selectedDepartment]);
  
  const handleSelectionChange = (key:any) => {
    setSelectedKey(key);
    if (key) {
      const selectedDepartment = list.items.find((x) => x.id === key);
      if (selectedDepartment) {
        onChange(selectedDepartment);
      }
    }
  };

  return (
    <ComboBox
      label="Department"
      items={list.items}
      loadingState={list.loadingState}
      selectedKey={selectedKey}
      onSelectionChange={handleSelectionChange}
      width="50%"
    > 
  {(item) => (
    <Item key={item.id} textValue={item.name}>
        {item.name}
    </Item>
  )}
</ComboBox>
  );
};

export default DepartmentDropDown;
