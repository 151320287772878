import { Button, Checkbox, Divider, Flex, Heading, Item, TagGroup, Text, TextField, View } from "@adobe/react-spectrum";
import PersonDropdown from "../formComponents/PersonDropDown";
import { useState } from "react";
import { ContactFormData } from "../AddNewPerson";
import { EditIcon } from "../../../../../components/protected/Icons/IconsLib";
import { truncateText } from "../../../../../utils/helperUtil";
import { Plus } from "react-bootstrap-icons";
interface IBankSectionProps{
      data: ContactFormData['banks'];
      onChange: (data: ContactFormData['banks']) => void;
      onAdd: () => void;
}
const BankSection: React.FC<IBankSectionProps> = ({ data, onChange,onAdd }) => {
    const [expandedSections, setExpandedSections] = useState<Set<number>>(new Set());
    
    const handleAddBank = ()=>{
        const index = data.length;
        onAdd();
        setExpandedSections((prev) => {
            const newSet = new Set(prev);
            if (newSet.has(index)) {
              newSet.delete(index);
            } else {
              newSet.add(index);
            }
            return newSet;
          });
    }

    const onRemove = (index:number) => {
      const updatedContacts = data.filter((_, i) => i !== index);
      setExpandedSections((prev) => {
        const newSet = new Set(prev);
        newSet.delete(index);
        return newSet;
      }); 
      onChange(updatedContacts); 
    };
  
    const onEdit = (index:number)=>{
      setExpandedSections((prev) => {
        const newSet = new Set(prev);
        if (newSet.has(index)) {
          newSet.delete(index);
        } else {
          newSet.add(index);
        }
        return newSet;
      });
    }

    const updatePrimary = (value: boolean, index: number) => {
      const primaryCount = data.filter(item => item.isPrimary === '1').length;
      if (!value && primaryCount === 1) {
        return;
      }
      const newData = data.map((item, i) => ({
        ...item,
        isPrimary: i === index ? '1' : '0',
      }));
      onChange(newData);
    };

    return (
    <View padding="size-200" paddingBottom='size-0' paddingTop='size-300'>
      <Heading level={5}>Banks</Heading>
      <Divider aria-label="" />
      <Flex direction="column" gap="size-100" width='100%' marginTop={10}>
      {data.map((tag, index) => ( 
              <View>
                {!expandedSections.has(index) &&
                <Flex key={index} gap="size-100" direction='row' width='100%'>
                    <TagGroup
                    items={data}
                    onRemove={()=>onRemove(index)}
                    aria-label="Removable TagGroup example"
                    width="100%"
                    >
                    <Item aria-label={tag.name}>{tag.name ? truncateText(tag.name,50): ''}</Item>
                    </TagGroup>
                    <a onClick={()=>onEdit(index)}>
                        <EditIcon size={20}/> 
                    </a>
                    <Checkbox
                          aria-label="Primary"
                          isSelected={tag.isPrimary == '1' ? true: false}
                          onChange={(value)=>updatePrimary(value,index)}
                      >
                    </Checkbox>
                </Flex>
                }
              {expandedSections.has(index) &&(
                  <Flex key={index+1} direction='column'>
                  <Flex direction="column" gap="size-100">
                  <TextField 
                    label="Name"
                    aria-label="Name"
                    value={tag.name} 
                    onChange={(name) => { 
                      const newData = [...data]; 
                      newData[index] = { ...tag, name }; 
                      onChange(newData); 
                    }} 
                    width="100%" 
                  />
                  <TextField 
                    label="Domestic Bank"
                    aria-label="Domestic Bank"
                    value={tag.bankName} 
                    onChange={(bankName) => { 
                      const newData = [...data]; 
                      newData[index] = { ...tag, bankName }; 
                      onChange(newData); 
                    }} 
                    width="100%" 
                  />
                  <TextField 
                    label="Bank Account"
                    aria-label="Bank Account"
                    value={tag.account} 
                    onChange={(account) => { 
                      const newData = [...data]; 
                      newData[index] = { ...tag, account }; 
                      onChange(newData); 
                    }} 
                    width="30%" 
                  />
                  <TextField 
                    label="Bank Code"
                    aria-label="Bank Code"
                    value={tag.bankCode} 
                    onChange={(bankCode) => { 
                      const newData = [...data]; 
                      newData[index] = { ...tag, bankCode }; 
                      onChange(newData); 
                    }} 
                    width="30%" 
                  />
                  <TextField 
                    label="Foreign Bank"
                    aria-label="Foreign Bank"
                    value={tag.bankNoteForeign} 
                    onChange={(bankNoteForeign) => { 
                      const newData = [...data]; 
                      newData[index] = { ...tag, bankNoteForeign }; 
                      onChange(newData); 
                    }} 
                    width="30%" 
                  />
                  <TextField 
                    label="IBAN Code"
                    aria-label="IBAN Code"
                    value={tag.bankIBAN} 
                    onChange={(bankIBAN) => { 
                      const newData = [...data]; 
                      newData[index] = { ...tag, bankIBAN }; 
                      onChange(newData); 
                    }} 
                    width="30%" 
                  />
                  <TextField 
                    label="BIC/SWIFT"
                    aria-label="BIC/SWIFT"
                    value={tag.swiftCode} 
                    onChange={(swiftCode) => { 
                      const newData = [...data]; 
                      newData[index] = { ...tag, swiftCode }; 
                      onChange(newData); 
                    }} 
                    width="30%" 
                  />
                  <TextField 
                    label="Variable Symbol"
                    aria-label="Variable Symbol"
                    value={tag.variableSymbol} 
                    onChange={(variableSymbol) => { 
                      const newData = [...data]; 
                      newData[index] = { ...tag, variableSymbol }; 
                      onChange(newData); 
                    }} 
                    width="30%" 
                  />
                  <TextField 
                    label="Constant Symbol"
                    aria-label="Constant Symbol"
                    value={tag.constantSymbol} 
                    onChange={(constantSymbol) => { 
                      const newData = [...data]; 
                      newData[index] = { ...tag, constantSymbol }; 
                      onChange(newData); 
                    }} 
                    width="30%" 
                  />
                  <TextField 
                    label="Specific Symbol"
                    aria-label="Specific Symbol"
                    value={tag.specificSymbol} 
                    onChange={(specificSymbol) => { 
                      const newData = [...data]; 
                      newData[index] = { ...tag, specificSymbol }; 
                      onChange(newData); 
                    }} 
                    width="30%" 
                  />
                  <TextField 
                    label="Comment"
                    aria-label="Comment"
                    value={tag.comment} 
                    onChange={(comment) => { 
                      const newData = [...data]; 
                      newData[index] = { ...tag, comment }; 
                      onChange(newData); 
                    }} 
                    width="100%" 
                  />
                  </Flex>
                  <Flex direction="row" gap="size-100" marginTop={10} justifyContent='end'>
                  <Button variant="cta" onPress={()=>onEdit(index)}>
                        Save
                    </Button>
                    <Button variant="secondary" onPress={()=>onEdit(index)}>
                        Cancel
                    </Button>
                  </Flex>
                  </Flex>
              )
              }
              </View>
            ))}
            {data.length > 0 &&
              <Divider aria-label="" /> 
            }
        <Flex gap="size-100" direction='row' width='100%'>
            <Button variant="cta" onPress={handleAddBank}><Plus /> Add</Button>
        </Flex>
      </Flex>
    </View>
  );
}
export default BankSection