import { Flex, Link, ProgressCircle, TextField, View } from '@adobe/react-spectrum';
import { useEffect, useRef, useState } from 'react';
import { useDependency } from '../../../../../contexts/DependencyProvider';
import { TrashIcon } from '../../../Icons/IconsLib';
import { ToastQueue } from '@react-spectrum/toast';
import SignatureCanvas from 'react-signature-canvas';
import { useTranslation } from 'react-i18next';
import { FPAData } from '../../../../../infra/protected/FPA/FPAData';
import { useReefConfig } from '../../../../../hooks/UseReefConfig';
import { useViewInfo } from '../../../../../hooks/UseViewInfo';
import { PrintContextRequest } from '../../../../../services/rest/print/requests/PrintContextRequest';
import useComponentReload from '../../../../../hooks/UseComponentReload';
import { UpdateActivityRequest } from '../../../../../services/soap/project/requests/UpdateActivityRequest';
import { GetActivityRequest } from '../../../../../services/soap/project/requests/GetActivityRequest';
import { CheckWorkFlowQueueRequest } from '../../../../../services/soap/features/requests/CheckWorkFlowQueueRequest';
import styles from '../SignComponent.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { addFormSignature, removeFormSignature } from '../../../../../state/userWebLayout/userWebLayoutSlice';
import { getDownloadUrl } from '../../../../../utils/ImagePathUtils';
import { RootState } from '../../../../../state/store';

interface ISignatureControlsProps {
  readonly signatureColor?: number;
  readonly signatureSize?: number;
  readonly signedStatusId?: string;
  readonly selectedItem?: FPAData;
  readonly numSignatures?: number;
  readonly signatureIdent?: string;

  readonly signatureTitle?: string;

  readonly signature2Title?: string;
}

const SignatureControls = ({ signatureColor, signatureSize, signedStatusId, selectedItem, numSignatures, signatureIdent, signatureTitle, signature2Title }: ISignatureControlsProps) => {
  const { baseUrl } = useReefConfig();
  const formSignatures = useSelector((state: RootState) => state.userWebLayout.formSignatures);
  const { isMobile } = useViewInfo();
  const { t } = useTranslation();
  const [reloadComponent] = useComponentReload();
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [signBase64, setSignBase64] = useState<Record<string, string>>({
    signCanvas0: '',
    signCanvas1: '',
    signCanvas2: '',
    signCanvas3: '',
    signCanvas4: '',
    signCanvas5: '',
    signCanvas6: '',
    signCanvas7: '',
    signCanvas8: '',
    signCanvas9: '',
  });

  const [fullName, setFullName] = useState<any>(['', '', '', '', '', '', '', '', '', '']);
  const [signatureKey, setSignatureKey] = useState(Date.now());
  const { featureService, printRestService, projectService, store } = useDependency();

  const [signCanvasRefs, setSignCanvasRefs] = useState<SignatureCanvas[]>([]);
  const signCanvas0 = useRef<SignatureCanvas>(null);
  const signCanvas1 = useRef<SignatureCanvas>(null);
  const signCanvas2 = useRef<SignatureCanvas>(null);
  const signCanvas3 = useRef<SignatureCanvas>(null);
  const signCanvas4 = useRef<SignatureCanvas>(null);
  const signCanvas5 = useRef<SignatureCanvas>(null);
  const signCanvas6 = useRef<SignatureCanvas>(null);
  const signCanvas7 = useRef<SignatureCanvas>(null);
  const signCanvas8 = useRef<SignatureCanvas>(null);
  const signCanvas9 = useRef<SignatureCanvas>(null);

  const dispatch = useDispatch();

  useEffect(() => {
    //console.log('numSignatures', numSignatures);
    let tabSessionId = sessionStorage.getItem('tabSessionId');
    for (let i = 0; i < sessionStorage.length; i++) {
      let key = sessionStorage.key(i);
      if (key?.startsWith('print_signature_') || key?.startsWith('print_name_') || key?.includes(tabSessionId ?? '')) {
        sessionStorage.removeItem(key);
      }
    }

    sessionStorage.setItem('tabSessionId', generateUUID());

    setSignCanvasRefs([signCanvas0, signCanvas1, signCanvas2, signCanvas3, signCanvas4, signCanvas5, signCanvas6, signCanvas7, signCanvas8, signCanvas9]);
    //reloadSignatureComponent();
  }, [selectedItem /*, reloadComponent*/]);

  useEffect(() => {
    let tabSessionId = sessionStorage.getItem('tabSessionId');
    for (let i = 0; i < sessionStorage.length; i++) {
      let key = sessionStorage.key(i);
      if (key?.startsWith('print_signature_') || key?.startsWith('print_name_') || key?.includes(tabSessionId ?? '')) {
        sessionStorage.removeItem(key);
      }
    }
    sessionStorage.setItem('tabSessionId', generateUUID());
  }, [signatureKey]);

  const generateUUID = () =>
    'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
      const r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });

  function statusColor(color: string) {
    if (!color) return '#000000';
    return '#' + ('00000' + parseInt(color).toString(16)).slice(-6);
  }

  const reloadSignatureComponent = () => {
    //console.log('reloadSignatureComponent');
    setSignatureKey(Date.now());
  };

  const onChange = (signCanvasName: string, index: number) => {
    let signatureData = signCanvasRefs[index]?.current.getTrimmedCanvas().toDataURL('image/png');
    signatureData = signatureData?.replace(/^data:image\/png;base64,/, '');
    const tabSessionId = sessionStorage.getItem('tabSessionId');

    // Update session storage
    setSignBase64(prevState => {
      const updatedState = { ...prevState, [signCanvasName]: signatureData };
      sessionStorage.setItem(`print_signature_${tabSessionId}_${signatureKey}`, JSON.stringify(updatedState));
      return updatedState;
    });

    // Update Redux store
    dispatch(
      addFormSignature({
        ident: `${signatureIdent}${index + 1}`,
        name: fullName[index] || '',
        picture: signatureData,
      })
    );
  };

  const clearSignature = (signCanvasName: string, index: number) => {
    let canvas = signCanvasRefs[index]?.current;
    canvas?.clear();
    const tabSessionId = sessionStorage.getItem('tabSessionId');

    // Update session storage
    setSignBase64(prevState => {
      const updatedState = { ...prevState, [signCanvasName]: '' };
      sessionStorage.setItem(`print_signature_${tabSessionId}_${signatureKey}`, JSON.stringify(updatedState));
      return updatedState;
    });

    setFullName((prevState: any) => {
      const newState = [...prevState];
      newState[index] = '';
      sessionStorage.setItem(`print_name_${tabSessionId}_${signatureKey}`, JSON.stringify(newState));
      return newState;
    });

    // Remove from Redux store
    dispatch(removeFormSignature(`${signatureIdent}${index + 1}`));
  };

  const onTextChange = (name: string, index: number) => {
    const tabSessionId = sessionStorage.getItem('tabSessionId');

    // Update session storage
    setFullName((prevState: any) => {
      const newState = [...prevState];
      newState[index] = name;
      sessionStorage.setItem(`print_name_${tabSessionId}_${signatureKey}`, JSON.stringify(newState));
      return newState;
    });

    // Update Redux store with current signature data and new name
    const currentSignature = signBase64[`signCanvas${index}`] || '';
    dispatch(
      addFormSignature({
        ident: `${signatureIdent}${index + 1}`,
        name: name,
        picture: currentSignature,
      })
    );
  };

  const handleConfirm = () => {
    setShowLoader(true);
    const signatureItem: any = {};
    Array.from({ length: numSignatures! }).forEach((_, i) => {
      signatureItem[`${signatureIdent}${i + 1}`] = { name: fullName[i], picture: signBase64[`signCanvas${i}`] };
    });

    //console.log('signatureItem', signatureItem);
    console.log('formSignatures', formSignatures);
    let windowReference: any = window.open('', '_blank', 'scrollbars=yes,status=yes,menubar=no,toolbar=no');
    printRestService
      .printDocument(new PrintContextRequest(store.Server, store.SessionId, 'activity', String(selectedItem?.id), 'pdf', { signatures: formSignatures }, signatureIdent ?? ``))
      .then(async (response: any) => {
        console.log('response', response.documentId);

        let strURL = getDownloadUrl(response.documentId, baseUrl, store.Server, store.SessionId);

        windowReference?.location.replace(strURL);

        const { ACTIVITY } = await projectService.getActivity(new GetActivityRequest(store.Server, store.SessionId, selectedItem!.id));
        if (ACTIVITY.customState !== signedStatusId) {
          const { result } = await projectService.updateActivity(new UpdateActivityRequest(store.Server, store.SessionId, { ...ACTIVITY, customState: signedStatusId }));
          if (result === 'OK') {
            await featureService.checkWorkFlowQueue(new CheckWorkFlowQueueRequest(store.Server, store.SessionId));
          } else ToastQueue.negative('Error updating activity status');
        }

        // setFullName((prevState: any) => {
        //   const newState = [...prevState];
        //   newState[0] = '';
        //   newState[1] = '';
        //   newState[2] = '';
        //   newState[3] = '';
        //   newState[4] = '';
        //   newState[5] = '';
        //   newState[6] = '';
        //   newState[7] = '';
        //   newState[8] = '';
        //   newState[9] = '';
        //   return newState;
        // });

        // setSignBase64({
        //   signCanvas0: '',
        //   signCanvas1: '',
        //   signCanvas2: '',
        //   signCanvas3: '',
        //   signCanvas4: '',
        //   signCanvas5: '',
        //   signCanvas6: '',
        //   signCanvas7: '',
        //   signCanvas8: '',
        //   signCanvas9: '',
        // });
        // //clear all signatures
        // Array.from({ length: numSignatures! }).forEach((_, i) => {
        //   clearSignature(`signCanvas${i}`, i);
        // });

        //reloadSignatureComponent();
        setShowLoader(false);
      })
      .catch(error => {
        console.log('error', error);
        setShowLoader(false);
        windowReference?.close();
      });
  };

  const multiSignatureSaveBtn = () => {
    const allSignaturesValid = Array.from({ length: numSignatures ?? 0 }).every((_, i) => fullName[i] && signBase64[`signCanvas${i}`] !== '');

    if (allSignaturesValid) {
      return (
        <Flex width="100%" justifyContent="end" alignContent="end" marginTop={'20px'} marginBottom={{ base: '40px', md: '20px', lg: '20px' }}>
          <Link UNSAFE_className={styles.btn_confirm} isQuiet onPress={handleConfirm}>
            {t('confirm_and_open', { ns: 'layout_components' })}
          </Link>
        </Flex>
      );
    }

    return <></>;
  };

  // if (showLoader) {
  //   return (
  //     <Flex width="100%" minHeight={'50px'} justifyContent={'center'} marginTop={10}>
  //       <ProgressCircle aria-label="Loading…" isIndeterminate />
  //     </Flex>
  //   );
  // } else
  if (numSignatures == 1) {
    return (
      <Flex alignSelf={'stretch'} position={'relative'} UNSAFE_className={isMobile ? styles.signature_pad_parent_mobile : styles.signature_pad_parent}>
        <Flex direction={'column'} position={'absolute'} top={'18px'} left={'16px'} width={'97%'}>
          <Flex direction={'column'} alignItems={'start'} justifyContent={'start'} position={'relative'} gap={'16px'}>
            <Flex direction={'column'} alignItems={'start'} justifyContent={'start'} zIndex={0}>
              <Flex direction={'row'} alignItems={'center'} justifyContent={'start'} height={'24px'}>
                <Flex direction={'row'} alignItems={'center'} justifyContent={'start'} height={'24px'}>
                  <View position={'relative'} UNSAFE_className={styles.input_label_text}>
                    {t('full_name', { ns: 'layout_components' })}
                  </View>
                </Flex>
              </Flex>
              <View>
                <TextField aria-label="none" value={fullName[0]} width={'256px'} height={'32px'} onChange={val => onTextChange(val, 0)}></TextField>
              </View>
            </Flex>
            <View UNSAFE_className={isMobile ? styles.rectangle_parent_mobile : styles.rectangle_parent}>
              <Flex UNSAFE_className={styles.signature_control}>
                <SignatureCanvas
                  id={signatureKey + '_1'}
                  penColor={statusColor(signatureColor?.toString() ?? '#000')}
                  canvasProps={{
                    className: isMobile ? styles.signature_pad_mobile : styles.signature_pad,
                  }}
                  dotSize={signatureSize ?? 3}
                  minWidth={1}
                  maxWidth={signatureSize ?? 3}
                  ref={signCanvasRefs[0]}
                  onEnd={() => onChange('signCanvas0', 0)}
                />
              </Flex>
              <Flex alignItems={'center'} justifyContent={'center'}>
                <View position={'relative'} width={'50%'} UNSAFE_className={styles.line_div}></View>
              </Flex>
              <Flex UNSAFE_className={isMobile ? styles.label_fullname_text_mobile : styles.label_fullname_text} direction={'row'} justifyContent={'center'} alignContent={'center'}>
                {fullName[0]}
              </Flex>
            </View>
            <Flex width={'100%'} justifyContent={'end'} alignContent={'end'}>
              {fullName[0] != '' && signBase64['signCanvas0'] != '' && (
                <Link UNSAFE_className={styles.btn_confirm} isQuiet onPress={handleConfirm}>
                  {t('confirm_and_open', { ns: 'layout_components' })}
                </Link>
              )}
            </Flex>

            <Flex direction={'row'} position={'absolute'} alignItems={'center'} justifyContent={'center'} zIndex={2} UNSAFE_className={styles.delete_button}>
              <div className={styles.icon_trash} onClick={() => clearSignature('signCanvas1', 0)}>
                <TrashIcon size={32} />
              </div>
              {!isMobile && (
                <Flex UNSAFE_className={styles.lable_delete} alignSelf={'stretch'} alignItems={'center'} justifyContent={'center'}>
                  <div onClick={() => clearSignature('signCanvas1', 0)}>{t('delete', { ns: 'layout_components' })}</div>
                </Flex>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    );
  } else if (numSignatures == 2) {
    return (
      <Flex
        direction={isMobile ? 'column' : 'row'}
        position={'relative'}
        width={'100%'}
        UNSAFE_className={isMobile ? styles.signature_pad_parent_mobile : styles.signature_pad_parent}
        justifyContent={'space-between'}
        UNSAFE_style={{ overflowY: 'auto' }}
      >
        <View position={'relative'} top={'18px'} margin={'10px'} width={isMobile ? '100%' : '50%'}>
          {/* <Flex direction={'row'} alignItems={'center'} justifyContent={'start'} height={'24px'}>
            <Flex direction={'row'} alignItems={'center'} justifyContent={'start'} height={'24px'}>
              <View position={'relative'} UNSAFE_className={styles.signature_title}>
                {signatureTitle}
              </View>
            </Flex>
          </Flex> */}
          <Flex direction={'column'} alignItems={'start'} justifyContent={'start'} position={'relative'} gap={'16px'}>
            <Flex direction={'column'} alignItems={'start'} justifyContent={'start'} zIndex={0}>
              <Flex direction={'row'} alignItems={'center'} justifyContent={'start'} height={'24px'}>
                <Flex direction={'row'} alignItems={'center'} justifyContent={'start'} height={'24px'}>
                  <View position={'relative'} UNSAFE_className={styles.input_label_text}>
                    {signatureTitle}
                  </View>
                </Flex>
              </Flex>
              <View>
                <TextField aria-label="none" value={fullName[0]} width={'256px'} height={'32px'} onChange={val => onTextChange(val, 0)}></TextField>
              </View>
            </Flex>
            <View UNSAFE_className={isMobile ? styles.rectangle_parent_mobile : styles.rectangle_parent}>
              <Flex UNSAFE_className={styles.signature_control}>
                <SignatureCanvas
                  id={`signatureKey_${signatureKey}_1`}
                  key={`signatureKey_${signatureKey}_1`}
                  penColor={statusColor(signatureColor?.toString() ?? '#000')}
                  canvasProps={{
                    className: isMobile ? styles.signature_pad_mobile : styles.signature_pad_double,
                  }}
                  dotSize={signatureSize ?? 3}
                  minWidth={1}
                  maxWidth={signatureSize ?? 3}
                  ref={signCanvasRefs[0]}
                  onEnd={() => onChange('signCanvas0', 0)}
                />
              </Flex>
              <Flex alignItems={'center'} justifyContent={'center'}>
                <View position={'relative'} width={'50%'} UNSAFE_className={styles.line_div}></View>
              </Flex>
              <Flex UNSAFE_className={isMobile ? styles.label_fullname_text_mobile : styles.label_fullname_text} direction={'row'} justifyContent={'center'} alignContent={'center'}>
                {fullName[0]}
              </Flex>
            </View>
            {/* <Flex width={'100%'} justifyContent={'end'} alignContent={'end'}>
              {fullName[0] && signBase64['signCanvas1'] && (
                <Link UNSAFE_className={styles.btn_confirm} isQuiet onPress={handleConfirm}>
                  {t('confirm_and_open', { ns: 'layout_components' })}
                </Link>
              )}
            </Flex> */}

            <Flex direction={'row'} position={'absolute'} alignItems={'center'} justifyContent={'center'} zIndex={2} UNSAFE_className={styles.delete_button}>
              <div className={styles.icon_trash} onClick={() => clearSignature('signCanvas0', 0)}>
                <TrashIcon size={32} />
              </div>
              {!isMobile && (
                <Flex UNSAFE_className={styles.lable_delete} alignSelf={'stretch'} alignItems={'center'} justifyContent={'center'}>
                  <div onClick={() => clearSignature('signCanvas0', 0)}>{t('delete', { ns: 'layout_components' })}</div>
                </Flex>
              )}
            </Flex>
          </Flex>
        </View>
        <View position={'relative'} top={'18px'} margin={'10px'} width={isMobile ? '100%' : '50%'}>
          {/* <Flex direction={'row'} alignItems={'center'} justifyContent={'start'} height={'24px'}>
            <Flex direction={'row'} alignItems={'center'} justifyContent={'start'} height={'24px'}>
              <View position={'relative'} UNSAFE_className={styles.signature_title}>
                {signature2Title}
              </View>
            </Flex>
          </Flex> */}
          <Flex direction={'column'} alignItems={'start'} justifyContent={'start'} position={'relative'} gap={'16px'}>
            <Flex direction={'column'} alignItems={'start'} justifyContent={'start'} zIndex={0}>
              <Flex direction={'row'} alignItems={'center'} justifyContent={'start'} height={'24px'}>
                <Flex direction={'row'} alignItems={'center'} justifyContent={'start'} height={'24px'}>
                  <View position={'relative'} UNSAFE_className={styles.input_label_text}>
                    {signature2Title}
                  </View>
                </Flex>
              </Flex>
              <View>
                <TextField aria-label="none" width={'256px'} value={fullName[1]} height={'32px'} onChange={val => onTextChange(val, 1)}></TextField>
              </View>
            </Flex>
            <View UNSAFE_className={isMobile ? styles.rectangle_parent_mobile : styles.rectangle_parent}>
              <Flex UNSAFE_className={styles.signature_control}>
                <SignatureCanvas
                  key={`signatureKey_${signatureKey}_2`}
                  id={`signatureKey_${signatureKey}_2`}
                  penColor={statusColor(signatureColor?.toString() ?? '#000')}
                  canvasProps={{
                    className: isMobile ? styles.signature_pad_mobile : styles.signature_pad_double,
                  }}
                  dotSize={signatureSize ?? 3}
                  minWidth={1}
                  maxWidth={signatureSize ?? 3}
                  ref={signCanvasRefs[1]}
                  onEnd={() => onChange('signCanvas1', 1)}
                />
              </Flex>
              <Flex alignItems={'center'} justifyContent={'center'}>
                <View position={'relative'} width={'50%'} UNSAFE_className={styles.line_div}></View>
              </Flex>
              <Flex UNSAFE_className={isMobile ? styles.label_fullname_text_mobile : styles.label_fullname_text} direction={'row'} justifyContent={'center'} alignContent={'center'}>
                {fullName[1]}
              </Flex>
            </View>
            <Flex width={'100%'} justifyContent={'end'} alignContent={'end'} marginBottom={{ base: '40px', md: '0' }}>
              {fullName[0] != '' && fullName[1] != '' && signBase64['signCanvas0'] != '' && signBase64['signCanvas1'] != '' && (
                <Link UNSAFE_className={styles.btn_confirm} isQuiet onPress={handleConfirm}>
                  {t('confirm_and_open', { ns: 'layout_components' })}
                </Link>
              )}
            </Flex>

            <Flex direction={'row'} position={'absolute'} alignItems={'center'} justifyContent={'center'} zIndex={2} UNSAFE_className={styles.delete_button}>
              <div className={styles.icon_trash} onClick={() => clearSignature('signCanvas1', 1)}>
                <TrashIcon size={32} />
              </div>
              {!isMobile && (
                <Flex UNSAFE_className={styles.lable_delete} alignSelf={'stretch'} alignItems={'center'} justifyContent={'center'}>
                  <div onClick={() => clearSignature('signCanvas1', 1)}>{t('delete', { ns: 'layout_components' })}</div>
                </Flex>
              )}
            </Flex>
          </Flex>
        </View>
      </Flex>
    );
  } else {
    return (
      <Flex direction={'column'} width={'100%'} position={'relative'} alignItems={'center'} justifyContent={'start'} height={'500px'} UNSAFE_className={styles.multi_signature_container}>
        <View width={'100%'} position={'relative'} height={'223px'}>
          {Array.from({ length: numSignatures ?? 0 }).map((_, i) => (
            <Flex direction={'row'} position={'relative'} top={'0px'} left={'0px'} width={'100%'} height={'80px'} alignItems={'start'} justifyContent={'start'} gap={'50px'} key={`signatureKey_${i}`}>
              <Flex direction={'column'} width={'40%'} height={'80px'} alignItems={'start'} justifyContent={'start'}>
                <Flex direction={'row'} alignSelf={'stretch'} height={'24px'} alignItems={'center'} justifyContent={'start'}>
                  <Flex direction={'row'} position={'relative'} height={'24px'} alignItems={'center'} justifyContent={'start'} UNSAFE_className={styles.full_name_label}>
                    {t('full_name', { ns: 'layout_components' })}
                  </Flex>
                </Flex>
                <TextField aria-label="none" width={'100%'} value={fullName[i]} height={'32px'} onChange={val => onTextChange(val, i)}></TextField>
              </Flex>
              <View width={'60%'} position={'relative'} height={'69px'} UNSAFE_style={{ color: '#8f9fa6' }}>
                <View position={'absolute'} top={'12px'} left={'9px'} width={'100%'} UNSAFE_style={{ lineHeight: '16px' }}>
                  <View>
                    Signature
                    <span style={{ float: 'inline-end' }} className={styles.icon_trash} onClick={() => clearSignature(`signCanvas${i}`, i)}>
                      <TrashIcon size={32} />
                    </span>
                  </View>
                  <View UNSAFE_className={isMobile ? styles.multi_signature_pad_mobile : styles.multi_signature_pad}>
                    <SignatureCanvas
                      id={signatureKey + `_${i}`}
                      penColor={statusColor(signatureColor?.toString() ?? '#000')}
                      canvasProps={{
                        className: isMobile ? styles.signature_pad_mobile : styles.signature_pad,
                      }}
                      dotSize={signatureSize ?? 3}
                      minWidth={1}
                      maxWidth={signatureSize ?? 3}
                      ref={signCanvasRefs[i]}
                      onEnd={() => onChange(`signCanvas${i}`, i)}
                    />
                  </View>
                </View>
                <View position={'absolute'} top={'80px'} left={'8px'} width={'100%'} height={'1.5px'} UNSAFE_className={styles.multi_signature_pad_line}></View>
              </View>
            </Flex>
          ))}
          {multiSignatureSaveBtn()}
        </View>
      </Flex>
    );
  }
};

export default SignatureControls;
