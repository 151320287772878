import { BaseRequest, IBaseRequest } from '../../BaseRequest';

export interface IListMessageTypeRequest extends IBaseRequest {
    filterName?: string;
}

export class ListMessageTypeRequest extends BaseRequest implements IListMessageTypeRequest {
    filterName?: string;
  constructor(server: string, session: string, filterName?: string) {
    super(server, session);
    this.filterName = filterName;
  }


  public toXml(): string {
    var parameters: string = super.toXml();

    if (this.filterName) {
      parameters += `<filterName>${this.filterName}</filterName>`;
    }


    return super.wrapperXml('ListMessageType', parameters);
  }
}
