import { useCallback, useEffect, useState } from 'react';
import { useDependency } from '../../../../contexts/DependencyProvider';
import { ListStatusRequest, ListStatusType } from '../../../../services/soap/project/requests/ListStatusRequest';
import { Flex, View } from '@adobe/react-spectrum';
import { AtollonIcon, PrintIcon, EditIcon, ToolIcon, TrashIcon, ActivityIconBlue } from '../../Icons/IconsLib';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { GetContextPathRequest } from '../../../../services/soap/project/requests/GetContextPathRequest';
import ButtonsComponent from './ButtonsComponent';
import { GetActivityRequest } from '../../../../services/soap/project/requests/GetActivityRequest';
import { UpdateActivityRequest } from '../../../../services/soap/project/requests/UpdateActivityRequest';
import { CheckWorkFlowQueueRequest } from '../../../../services/soap/features/requests/CheckWorkFlowQueueRequest';
import { FPAData } from '../../../../infra/protected/FPA/FPAData';
import { ToastQueue } from '@react-spectrum/toast';
import { useTranslation } from 'react-i18next';
import styles from './HeaderToolbarComponent.module.css';

interface IActivityComponentProps {
  headerData: any;
  statusColor: (color: string) => string;
  onPrint?: (item:FPAData) => void;
  onEdit?: (item:FPAData) => void;
  onDelete?: (item:FPAData) => void;
  onTool?: (item:FPAData) => void;
  onAI?: (item:FPAData) => void;
  onCopy?: (item:FPAData) => void;
  onMove?: (item:FPAData) => void;
  selectedItem?: FPAData;
}


const ActivityComponent = ({ 
  headerData, 
  statusColor, 
  onPrint, 
  onEdit, 
  onDelete, 
  onTool, 
  onAI, 
  selectedItem,
  onCopy,
  onMove,
}: IActivityComponentProps) => {
  const { store, projectService, featureService } = useDependency();
  const [activityStatusList, setActivityStatusList] = useState<any>([]);

  let [activityStatus, _] = useState(headerData?.customState);
  const [fullPath, setFullPath] = useState<string>('');
  const [activityData, setActivityData] = useState<any>([]);

  const { t } = useTranslation();
  useEffect(() => {
    (async () => {
      headerData && (await getFullPath());
      await getActivityData();
      await getActivityStatusArray();
    })();
  }, []);

  const getActivityStatusArray = useCallback(async () => {
    const statusArray: any = [];
    let res: any = await projectService.listActivityStatus2(new ListStatusRequest(store.Server, store.SessionId, ListStatusType.Activity));

    if (res.result === 'OK' && res.STATUS.length > 0) {
      res?.STATUS.forEach((status: any) => {
        // Extract id and name from each STATUS object
        const { id, name, color, TYPE } = status;
        // Push an object containing id and name to the statusArray
        statusArray.push({ id, name, color, types: TYPE });
      });
      //console.log('statusArray', statusArray);
      var filtered_status = statusArray.filter((status: any) => status.types.find((type: any) => type.id === headerData?.activityType));
      setActivityStatusList(filtered_status);
    }
    return statusArray;
  }, []);

  const getFullPath = async () => {
    const response = await projectService.getContextPath(new GetContextPathRequest(store.Server, store.SessionId, headerData?.id || 0));
    const modifiedList = response.LIST.slice(1).reverse();
    setFullPath(modifiedList.map(item => item.CONTEXT.name).join(' > '));
  };

  function itemTemplate(data: any): JSX.Element {
    return (
      <Flex UNSAFE_className={styles.dot_text_container}>
        <View UNSAFE_className={styles.dot} UNSAFE_style={{ backgroundColor: `${statusColor(data.color)}` }}></View>
        <View marginTop={5}>{data.name}</View>
      </Flex>
    );
  }

  function valueTemplate(data: any): JSX.Element {
    return (
      <Flex UNSAFE_className={styles.dot_text_container}>
        <View UNSAFE_className={styles.dot} UNSAFE_style={{ backgroundColor: `${statusColor(data.color)}` }}></View>
        <View marginTop={5} UNSAFE_style={{ marginLeft: '10px' }}>
          <span title={data?.name}>{data?.name && data?.name.length > 16 ? data?.name.substring(0, 16) + '...' : data?.name}</span>
        </View>
      </Flex>
    );
  }

  async function getActivityData() {
    if (selectedItem) {
      let { ACTIVITY } = await projectService.getActivity(new GetActivityRequest(store.Server, store.SessionId, selectedItem.id));
      setActivityData(ACTIVITY);
    }
  }

  async function updateActivityStatus(e: any) {
    let resActivity = await projectService.updateActivity(new UpdateActivityRequest(store.Server, store.SessionId, { ...activityData, customState: e.value }));
    if (resActivity.result == 'OK') {
      await featureService.checkWorkFlowQueue(new CheckWorkFlowQueueRequest(store.Server, store.SessionId));
      ToastQueue.positive(t('status_updated_successfully', { ns: 'layout_components' }), { timeout: 1000 });
    } else {
      ToastQueue.negative(resActivity.EXCEPTION?.message, { timeout: 1000 });
    }
  }

  return (
    <Flex width="100%">
      <View UNSAFE_className={styles.header_main}>
        <View UNSAFE_className={styles.header_child}>
          <View UNSAFE_className={styles.frame_parent}>
            <View UNSAFE_className={styles.icon_activity_parent}>
              <ActivityIconBlue size={25} />
              <b title={headerData?.name} className={styles.activity_name}>
                {headerData?.name.length > 55 ? headerData?.name.substring(0, 55) + '...' : headerData?.name}
              </b>
            </View>

            <View UNSAFE_className={styles.folder_name_parent}>
              <View UNSAFE_className={styles.folder_name}>{fullPath}</View>
            </View>
          </View>
          <View UNSAFE_className={styles.tag_parent} marginTop={20}>
            <View UNSAFE_className={styles.tag}>
              <View UNSAFE_className={styles.activity_type}>
                <View UNSAFE_className={styles.activity_type_text}>{headerData?.activityTypeName}</View>

                {headerData?.refId ? (
                  <>
                    <View UNSAFE_className={styles.divider}></View> <View UNSAFE_className={styles.ref_id}>{headerData?.refId}</View>
                  </>
                ) : (
                  <View UNSAFE_className={styles.divider_transparent}></View>
                )}
              </View>
            </View>
          </View>
        </View>
        <View UNSAFE_className={styles.icon_list_parent}>
          <DropDownListComponent
            width={'200px'}
            name="vacancyTypeReef"
            dataSource={activityStatusList}
            fields={{ text: 'name', value: 'id' }}
            showClearButton={true}
            itemTemplate={itemTemplate}
            valueTemplate={valueTemplate}
            value={activityStatus}
            onChange={(e: any) => {
              updateActivityStatus(e);
            }}
          />

          <ButtonsComponent
            styles={styles}
            AtollonIcon={AtollonIcon}
            PrintIcon={PrintIcon}
            EditIcon={EditIcon}
            ToolIcon={ToolIcon}
            TrashIcon={TrashIcon}
            onPrint={onPrint}
            onEdit={onEdit}
            onDelete={onDelete}
            onTool={onTool}
            onAI={onAI}
            selectedItem={selectedItem}
            onCopy={onCopy}
            onMove={onMove}
          />
        </View>
      </View>

    </Flex>
  );
};

export default ActivityComponent;
