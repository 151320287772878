import { useState, useEffect } from 'react';
import { ToastQueue } from '@react-spectrum/toast';
import { Flex, View, Link, ToggleButton, ActionBar, ActionButton } from '@adobe/react-spectrum';
import { Grid, Fullscreen } from 'react-bootstrap-icons';
import FilterSearchTextBox from './FilterSearchTextBox';
import { useTranslation } from 'react-i18next';
import styles from './header_component.module.css';
import { CopyIcon } from '../../../../components/protected/Icons/IconsLib';
import { EditIcon, TrashIcon } from '../../../../components/protected/Icons/IconsLib';
import { FPAData } from '../../../../infra/protected/FPA/FPAData';

interface IHeaderComponentProps {
  onPageViewChange: (view: string) => void;
  autoChanged: string;
  filterParams: any;
  onSearchChange: (params: {}) => void;
  fpaTypeCategory: string;
  onAddNew: () => void;
  onCopy?: () => void;
  onDelete: () => void;
}

const HeaderComponent: React.FC<IHeaderComponentProps> = ({ onPageViewChange, autoChanged, filterParams, onSearchChange, fpaTypeCategory, onAddNew, onCopy, onDelete }) => {
  const { t } = useTranslation();
  const [selectedButton, setSelectedButton] = useState<string>('Grid');

  useEffect(() => {
    setSelectedButton(prev => (prev === autoChanged ? prev : autoChanged));
  }, [autoChanged]);

  const handleToggle = (view: string) => {
    setSelectedButton(prev => (prev === view ? prev : view));
    onPageViewChange(view);
  };

  const handleCopy = () => {
    if (onCopy) {
      onCopy();
    }
  }

  return (
    <Flex direction={'row'} justifyContent={'space-between'} UNSAFE_className={styles.header_parent}>
      <Flex direction={'row'} gap={'size-200'}>

        <Link
          isQuiet

          onPress={e => {
            onAddNew();
          }}
          UNSAFE_className={styles.add_new_btn}
        >
          <i className="bi bi-plus fs-5">
            <View UNSAFE_className={styles.add_new_btn_text}>{t('add_new', { ns: 'layout_components' })}</View>
          </i>
        </Link>

        <Flex direction={'column'}>
          <Flex direction={'row'} UNSAFE_className={styles.activity_search}>
            <FilterSearchTextBox onSearchChange={onSearchChange} filterParams={filterParams} fpaTypeCategory={fpaTypeCategory} />
            <i className={`${styles.icon_activity_search} bi bi-search`}></i>
          </Flex>

        </Flex>
        <View UNSAFE_className={styles.frame_divider}></View>
      </Flex>

      <Flex direction={'row'} gap={'size-100'}>
        <Flex direction={'row'} UNSAFE_className={styles.action_btn_container}>
          <div className={styles.action_btn}>
            <EditIcon size={32} />
          </div>

          <div className={styles.action_btn} onClick={() => onDelete()}>
            <TrashIcon size={32} />
          </div>
          <div className={styles.action_btn} onClick={handleCopy}>
            <CopyIcon size={32} />
          </div>
        </Flex>
        <ToggleButton isSelected={selectedButton === 'Grid'} onChange={() => handleToggle('Grid')} isEmphasized>
          <Grid />
        </ToggleButton>

        <ToggleButton isSelected={selectedButton === 'Full'} onChange={() => handleToggle('Full')} isEmphasized>
          <Fullscreen />
        </ToggleButton>
      </Flex>
    </Flex>
  );
};


export default HeaderComponent;
