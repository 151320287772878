import { useCallback, useEffect, useRef, useState } from 'react';
import { ActionButton, ComboBox, FileTrigger, Flex, Item, ProgressCircle, Text, TextField, ToastQueue, View } from '@adobe/react-spectrum';
import { truncateText } from '../../../../../utils/helperUtil';
import { useTranslation } from 'react-i18next';
import { AttachmentIcon, CancelIcon } from '../../../Icons/IconsLib';
import { ListMailboxRequest } from '../../../../../services/soap/mail/requests/ListMailboxRequest';
import { ListMessageTypeRequest } from '../../../../../services/soap/message/requests/ListMessageTypeRequest';
import CustomDialogComponent from '../../../../ui/CustomDialogComponent';
import { useDependency } from '../../../../../contexts/DependencyProvider';
import ContactsContactFilterComponent from '../../FilterComponent/ContactsContactFilter/ContactsContactFilterComponent';
import ContactsFilterComponent from '../../FilterComponent/ContactsFilter/ContactsFilterComponent';
import { NewMailRequest } from '../../../../../services/soap/mail/requests/NewMailRequest';
import { GetMailboxRequest } from '../../../../../services/soap/mail/requests/GetMailboxRequest';
import { FileUploader } from 'react-drag-drop-files';
import { NewMessageTemplateRequest } from '../../../../../services/soap/message/requests/NewMessageTemplateRequest';
import { Editor } from '@tinymce/tinymce-react';
import './new_message_component.css';
import { CreateDocumentInStorageRequest } from '../../../../../services/soap/document_library/requests/CreateDocumentInStorageRequest';

interface INewMessageComponentProps {
  styles: any;
  dialogVisibility: boolean;
  updateNewMessageDialogState: (newState: boolean, reloadMessageList: boolean) => void;
  selectedItem: any;
}

const NewMessage: React.FC<INewMessageComponentProps> = ({ styles, dialogVisibility, updateNewMessageDialogState, selectedItem }) => {
  const { store, messageService, mailService, documentLibraryService } = useDependency();
  const { t } = useTranslation();
  const [fromEmailAddress, setFromEmailAddress] = useState<any>([]);
  const [messageType, setmessageType] = useState<any>([]);
  const [newEmailFields, setNewEmailFields] = useState<any>({ messageType: '', context: '', to: [], cc: [], notify: [], subject: '', from: [], mailBox: {} });
  const [visibility, setVisibility] = useState<boolean>(dialogVisibility);
  const editorRef = useRef<any>(null);
  const txtSubjectRef = useRef<any>(null);
  const [itemsRef, setItemRef] = useState<any>([]);
  const itemCounter = useRef<number>(1);
  const [showLoader, setShowLoader] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch from email addresses
        const emailResp = await mailService.listMailbox(
          new ListMailboxRequest(store.Server, store.SessionId, {
            usableByUser: store.UserId,
            offset: 0,
            limit: 100,
          })
        );

        // Fetch message types
        const messageTypeResp = await messageService.listMessageType(new ListMessageTypeRequest(store.Server, store.SessionId));

        // Process from email addresses
        if (emailResp.result === 'OK' && emailResp.MAILBOX) {
          //console.log('emailResp.MAILBOX', emailResp.MAILBOX);
          const emailData = emailResp.MAILBOX.map(item => ({
            id: item.id,
            name: `${item.name}@${item.domainName}`,
          }));
          setFromEmailAddress(emailData);

          // Process message types
          if (messageTypeResp.result === 'OK') {
            const filteredTypes = messageTypeResp.ITEM.filter(item => ['7', '1', '6', '12'].includes(item.type)).sort((a, b) => (Number(b.priority) || 0) - (Number(a.priority) || 0));

            setmessageType(filteredTypes);

            var mainBoxResp = await mailService.getMailbox(new GetMailboxRequest(store.Server, store.SessionId, Number(emailData[0]?.id)));

            //console.log('mainBoxResp?.MAILBOX', mainBoxResp?.MAILBOX);

            // Update form fields with default values
            setNewEmailFields((prev: any) => ({
              ...prev,
              from: emailData[0]?.name,
              messageType: filteredTypes[0]?.type,
              mailBox: mainBoxResp?.MAILBOX,
            }));
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [store.Server, store.SessionId, store.UserId]);

  const handleClose = async (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Escape') {
      updateNewMessageDialogState(false, false);
      setVisibility(false);
    }
  };

  const newMessageButton: any = [
    {
      buttonModel: {
        content: t('cancel', { ns: 'layout_components' }),
        cssClass: 'e-flat-cancel',
      },
      click: () => {
        updateNewMessageDialogState(false, false);
        setVisibility(false);
      },
    },
    {
      buttonModel: {
        content: showLoader ? 'Saving...' : t('Save draft and close', { ns: 'recruitment' }),
        cssClass: 'e-flat-apply',
        isPrimary: true,
        disabled: showLoader,
      },
      click: () => {
        saveDraft();
      },
    },
    {
      buttonModel: {
        content: showLoader ? 'Sending...' : t('Send', { ns: 'recruitment' }),
        cssClass: 'e-flat-apply',
        isPrimary: true,
        disabled: showLoader,
      },
      click: () => {
        //console.log('newEmailFields', newEmailFields);
        sendEmail();
      },
    },
  ];

  const createParams = useCallback(async (filterTypeName: any, filterValue: any) => {
    //console.log('object', filterTypeName, Array.from(filterValue));
    setNewEmailFields((prev: any) => ({ ...prev, [filterTypeName]: Array.from(filterValue) }));
  }, []);

  const closeDialog = () => {
    updateNewMessageDialogState(false, false);
    setVisibility(false);
  };

  function encodeHTML(str: string) {
    if (!str) {
      return '';
    }
    return str.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;').replace(/'/g, '&#39;');
  }

  const getCompleteHtml = () => {
    if (editorRef.current) {
      // Get the editor instance
      const editor = editorRef.current;

      // Get the HTML content
      const content = editor.getContent();

      // Create a complete HTML document
      const completeHtml = `<!DOCTYPE html>
      <html>
        <head>
          <meta charset="utf-8">
          <title>${newEmailFields.subject || 'Email'}</title>
          <style>
            body { margin: 0; padding: 16px; }
          </style>
        </head>
        <body>
          ${content}
        </body>
      </html>`;

      return completeHtml;
    }
    return '';
  };

  const sendEmail = async () => {
    try {
      setShowLoader(true);
      let documentId: any = [];
      if (newEmailFields.messageType == 7) {
        if (newEmailFields.to.length == 0) {
          ToastQueue.negative('Please enter the recipient email', { timeout: 100 });
          return;
        }
      }
      if (newEmailFields.messageType == 7 || newEmailFields.messageType == 1) {
        if (newEmailFields.subject === '') {
          ToastQueue.negative('Please enter the subject', { timeout: 100 });
          return;
        }
      }

      if (itemsRef.length > 0) {
        documentId = await uploadFiles();
      }

      //console.log('documentId', documentId);
      let toEmails = newEmailFields.to.map((item: any) => item.CONTENT);
      let ccEmails = newEmailFields.cc.map((item: any) => item.CONTENT);

      if (editorRef.current) {
        const emailContent = getCompleteHtml();
        let messageParams = {
          priority: '2',
          contextId: selectedItem?.id,
          html: '1',
          from: encodeHTML(newEmailFields.from),
          to: toEmails,
          cc: ccEmails,
          notify: newEmailFields.notify,
          recipient: newEmailFields.notify,
          subject: newEmailFields.subject,
          body: encodeHTML(emailContent),
          attachments: { attachment: documentId },
          messageType: newEmailFields.messageType,
        };
        newEmailFields.messageType == 7 && delete messageParams.recipient;
        newEmailFields.messageType == 1 && delete messageParams.notify;
        let mailResp: any = await mailService.newMail(new NewMailRequest(store.Server, store.SessionId, messageParams));

        if (mailResp.result === 'OK') {
          ToastQueue.positive('Email sent successfully', { timeout: 100 });
          updateNewMessageDialogState(false, true);
          setVisibility(false);
        } else {
          ToastQueue.negative(mailResp.EXCEPTION.message, { timeout: 100 });
        }
      }
    } catch (e) {
      console.log('error', e);
    } finally {
      setShowLoader(false);
    }
  };

  const saveDraft = async () => {
    try {
      setShowLoader(true);
      let documentId: any = [];
      if (newEmailFields.messageType == 7) {
        if (newEmailFields.to.length == 0) {
          ToastQueue.negative('Please enter the recipient email', { timeout: 100 });
          return;
        }

        if (newEmailFields.subject === '') {
          ToastQueue.negative('Please enter the email subject', { timeout: 100 });
          return;
        }
      }

      if (itemsRef.length > 0) {
        documentId = await uploadFiles();
      }
      //console.log('documentId', documentId);

      if (editorRef.current) {
        const emailContent = editorRef.current.getContent({ format: 'text' });

        let messageTemplate = {
          contextId: selectedItem?.id,
          templateName: newEmailFields.subject,
          description: '',
          created: new Date().toISOString(),
          subject: newEmailFields.subject,
          isDraft: 1,
          html: 0,
          type: newEmailFields.messageType,
          language: '',
          isDefault: 0,
          folderId: '',
          projectId: '',
          activityId: '',
          RECIPIENT: {
            TO: newEmailFields.to,
            CC: newEmailFields.cc,
            USER: newEmailFields.notify,
          },
          BODY: {
            string: emailContent,
          },
          DOCUMENTS: {
            DOCUMENT: documentId,
          },
          TRANSLATIONS: '',
        };

        //console.log('messageTemplate', messageTemplate);

        let draftResp: any = await messageService.newMessageTemplate(
          new NewMessageTemplateRequest(store.Server, store.SessionId, {
            messageTemplate: messageTemplate,
          })
        );
        if (draftResp.result === 'OK') {
          ToastQueue.positive('Draft saved successfully', { timeout: 100 });
          updateNewMessageDialogState(false, false);
          setVisibility(false);
        } else {
          ToastQueue.negative(draftResp.EXCEPTION.message, { timeout: 100 });
        }
      }
    } catch (error) {
      console.log('error', error);
    } finally {
      setShowLoader(false);
    }
  };

  const setMailBox = async (value: any) => {
    //console.log('value', value);
    let fromEmailId = fromEmailAddress.find((item: any) => item.name === value)?.id;
    //console.log('fromEmailId', fromEmailId);
    var mainBoxResp = await mailService.getMailbox(new GetMailboxRequest(store.Server, store.SessionId, Number(fromEmailId)));
    //console.log('mainBoxResp', mainBoxResp);
    // Update form fields with default values
    setNewEmailFields((prev: any) => ({
      ...prev,
      from: value,
      mailbox: mainBoxResp?.MAILBOX,
    }));
  };

  const getBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  };

  const fileTypes = ['JPG', 'JPEG', 'PNG', 'GIF', 'PDF', 'XLSX', 'XLS', 'DOC', 'DOCX', 'PPT', 'PPTX', 'TXT', 'ODT', 'ODS', 'ODP', 'RTF', 'CSV', 'ZIP', 'RAR'];

  const handleUploadFile = async (files: File[]) => {
    const arrFile = Array.from(files);
    arrFile.forEach(async (file: any) => {
      let fileName = file.name;
      let fileSize = file.size;
      let base64Files = await getBase64(file);
      addFile(fileName, fileSize, base64Files);
    });
  };

  const addFile = (fileName?: any, fileSize?: any, base64Files?: any) => {
    const newItemId = itemCounter.current;
    const newItem = {
      id: newItemId,
      fileName: fileName,
      fileSize: fileSize,
      base64Files,
    };
    setItemRef((prev: any) => [...prev, newItem]);
    itemCounter.current += 1;
  };

  const deleteFile = (id: any) => {
    setItemRef((prev: any) => prev.filter((item: any) => item.id !== id));
    //itemsRef.current = itemsRef.current.filter((item: any) => item.id !== id);
  };

  const uploadFiles = async () => {
    let documentId: any = [];
    const uploadPromises = itemsRef.map(async (item: any) => {
      const data = item.base64Files.split(',')[1];
      const request = new CreateDocumentInStorageRequest(
        store.Server,
        store.SessionId,
        {
          id: '',
          acl: '',
          parent: '',
          name: item.fileName,
          description: '',
          location: '',
          validByDate: '0',
          createdBy: '',
          fileHandle: '',
          treeHandle: String(selectedItem?.id),
          fileSize: item.fileSize,
          internalNumber: '',
          fileName: item.fileName,
          deleted: '0',
          validFrom: new Date(),
          validTo: new Date(),
          storageId: '',
          isInStorage: '0',
          contextId: selectedItem?.id,
        },
        data
      );

      const result = await documentLibraryService.createDocumentInStorage(request);
      if (result.result === 'OK') {
        //ToastQueue.positive(t('record_saved_successfully', { ns: 'layout_components' }), { timeout: 3000 });
        console.log('result', result);
        documentId.push({ id: String(result.newId), name: item.fileName, size: item.fileSize });
        return true;
      } else {
        ToastQueue.negative(result.EXCEPTION.reason, { timeout: 3000 });
        return false;
      }
    });

    return Promise.all(uploadPromises).then(results => {
      return documentId;
    });
  };

  function newMessageDalogheader(): JSX.Element {
    return (
      <>
        <View UNSAFE_className="message_heading">New message</View>
        <Flex
          direction={'row'}
          position={'absolute'}
          top={'8px'}
          right={'36px'}
          alignItems={'start'}
          justifyContent={'start'}
          gap={'12px'}
          UNSAFE_style={{ fontSize: '12px', color: '#455a64', float: 'inline-end' }}
        >
          <Flex direction={'row'} alignSelf={'stretch'} alignItems={'center'} justifyContent={'start'} UNSAFE_style={{ fontSize: '12px' }}>
            <View position={'relative'} UNSAFE_className="label_message_type">
              Message type
            </View>
          </Flex>
          <View>
            <ComboBox
              label=""
              width="size-2000"
              selectedKey={newEmailFields.messageType}
              onSelectionChange={value => setNewEmailFields({ ...newEmailFields, messageType: value })}
              UNSAFE_style={{ fontSize: '12px', color: '#455a64', textAlign: 'left' }}
            >
              {messageType.map((item: any) => (
                <Item key={item.type} textValue={item.name}>
                  {truncateText(item.name, 30)}
                </Item>
              ))}
            </ComboBox>
          </View>
        </Flex>
      </>
    );
  }

  function newMessageDalogContent(): any {
    if (showLoader) {
      return (
        <Flex width="100%" justifyContent={'center'} marginTop={10}>
          <ProgressCircle aria-label="Loading…" isIndeterminate />
        </Flex>
      );
    } else {
      return (
        <View height={'550px'} UNSAFE_className="message_modal">
          <Flex direction={'column'} width={'100%'} alignItems={'start'} justifyContent={'start'} UNSAFE_className="message_options">
            <Flex direction={'row'} top={'6px'} alignSelf={'stretch'} position={'relative'} flexShrink={0} UNSAFE_className="label_wrapper" gap={'size-200'}>
              <View position={'relative'} width={'5%'} left={'8px'}>
                Context:
              </View>
              <View width={'90%'}>
                <Text>{selectedItem.id}</Text>
                {/* <ComboBox label="" width="100%" UNSAFE_style={{ fontSize: '12px', color: '#455a64', textAlign: 'left' }}>
                <Item key="1" textValue="1">
                  1
                </Item>
                <Item key="2" textValue="2">
                  2
                </Item>
              </ComboBox> */}
              </View>
            </Flex>
            {newEmailFields.messageType == 7 && (
              <Flex direction={'row'} top={'14px'} alignSelf={'stretch'} position={'relative'} flexShrink={0} UNSAFE_className="label_wrapper" gap={'size-200'}>
                <View position={'relative'} width={'5%'} left={'8px'}>
                  To:
                </View>
                <View width={'90%'} UNSAFE_style={{ overflow: 'auto' }}>
                  <ContactsContactFilterComponent createParams={createParams} fieldName={'to'} />
                </View>
              </Flex>
            )}
            {newEmailFields.messageType == 7 && (
              <Flex direction={'row'} top={'22px'} alignSelf={'stretch'} position={'relative'} flexShrink={0} UNSAFE_className="label_wrapper" gap={'size-200'}>
                <View position={'relative'} width={'5%'} left={'8px'}>
                  Cc:
                </View>
                <View width={'90%'} UNSAFE_style={{ overflow: 'auto' }}>
                  <ContactsContactFilterComponent createParams={createParams} fieldName={'cc'} />
                </View>
              </Flex>
            )}
            <Flex direction={'row'} top={'30px'} alignSelf={'stretch'} position={'relative'} flexShrink={0} UNSAFE_className="label_wrapper" gap={'size-200'}>
              <View position={'relative'} width={'5%'} left={'8px'}>
                Notify:
              </View>
              <View width={'90%'} UNSAFE_style={{ overflow: 'auto' }}>
                <ContactsFilterComponent createParams={createParams} fieldName={'notify'} />
              </View>
            </Flex>
            <Flex direction={'row'} top={'38px'} alignSelf={'stretch'} position={'relative'} flexShrink={0} UNSAFE_className="label_wrapper" gap={'size-200'}>
              <View position={'relative'} width={'5%'} left={'8px'}>
                Subject:
              </View>
              <View width={'90%'}>
                <TextField ref={txtSubjectRef} width={'100%'} onChange={e => setNewEmailFields({ ...newEmailFields, subject: e })} isRequired />
              </View>
            </Flex>

            {fromEmailAddress.length > 2 && (
              <Flex direction={'row'} top={'44px'} alignSelf={'stretch'} position={'relative'} flexShrink={0} UNSAFE_className="label_wrapper" gap={'size-200'}>
                <View position={'relative'} width={'5%'} left={'8px'}>
                  From:
                </View>
                <View width={'90%'}>
                  <ComboBox
                    label=""
                    width="100%"
                    defaultSelectedKey={newEmailFields.from}
                    selectedKey={newEmailFields.from}
                    onSelectionChange={value => setMailBox(value)}
                    UNSAFE_style={{ fontSize: '12px', color: '#455a64', textAlign: 'left' }}
                  >
                    {fromEmailAddress.map((item: any) => (
                      <Item key={item.name} textValue={item.name}>
                        {truncateText(item.name, 30)}
                      </Item>
                    ))}
                  </ComboBox>
                </View>
              </Flex>
            )}
          </Flex>
          <Flex direction={'column'} position={'relative'} top={'50px'}>
            <Flex direction={'row'} position={'relative'} gap={'size-100'} left={'52px'} height={'32px'}>
              <View position={'relative'} width={'170px'} UNSAFE_className="attachment_button" UNSAFE_style={{ lineHeight: '32px' }}>
                {/* <Button
                variant="primary"
                UNSAFE_style={{ background: 'transparent', border: 'none', width: '100%', fontSize: '14px' }}
                onPress={() => {
                  console.log('attachment_button');
                }}
              >
                <>
                  <AttachmentIcon size={18} />
                  &nbsp;&nbsp;
                  <Text UNSAFE_className={styles.btn_new_email_text}>{t('Add attachment', { ns: 'layout_components' })}</Text>
                </>
              </Button> */}

                <FileTrigger
                  allowsMultiple
                  onSelect={async (e: any) => {
                    handleUploadFile(e);
                  }}
                >
                  <ActionButton UNSAFE_style={{ border: 'none', width: '100%', fontSize: '14px' }}>
                    <span style={{ marginLeft: '25px' }}>
                      <AttachmentIcon size={18} />
                    </span>
                    <Text UNSAFE_className={'btn_attachment'}>{t('Add attachment', { ns: 'layout_components' })}</Text>
                  </ActionButton>
                </FileTrigger>
              </View>
              <View position={'relative'} width={'485px'}>
                <Flex direction={'column'} width={'480px'}>
                  <FileUploader
                    multiple={true}
                    label={t('drop_file_here', { ns: 'layout_components' })}
                    handleChange={handleUploadFile}
                    name="file"
                    types={fileTypes}
                    classes={styles.noRecordUploader}
                  />
                </Flex>
              </View>
            </Flex>

            <Flex wrap direction="row" gap="size-100" alignItems="center" width="90%" marginTop="25px" marginStart="50px">
              {itemsRef.map((item: any) => (
                <View width={'200px'} position={'relative'} height={'40px'} UNSAFE_className="icon_cancel_parent" key={item.id}>
                  <View position={'relative'} top={'8px'} width={'24px'} height={'24px'} UNSAFE_className="icon_cancel">
                    <div onClick={() => deleteFile(item.id)}>
                      <CancelIcon size={18} />
                    </div>
                  </View>
                  <View position={'relative'} top={'10px'} left={'35px'} width={'70%'} UNSAFE_className="label_attachment">
                    {truncateText(item.fileName, 20)}
                  </View>
                  <View position={'absolute'} top={'6px'} left={'8px'} width={'28px'} height={'28px'} UNSAFE_className="attachment_icon">
                    <AttachmentIcon size={18} />
                  </View>
                </View>
              ))}
            </Flex>
          </Flex>

          <Flex direction={'column'} position={'relative'} top={'70px'} left={'52px'} width={'90%'} height={'300px'} alignItems={'start'} justifyContent={'start'}>
            <Editor
              apiKey="us96vzft4can83ypypjp9biqho9mmaqe2fum42k8f79ietm7"
              onInit={(_evt: any, editor: any) => (editorRef.current = editor)}
              initialValue={newEmailFields.mailBox?.signature?.replace(/\n/g, '<br/>')}
              init={{
                width: '100%',
                height: 900,
                menubar: false,
                plugins: [
                  'advlist',
                  'autolink',
                  'lists',
                  'link',
                  'image',
                  'charmap',
                  'preview',
                  'anchor',
                  'searchreplace',
                  'visualblocks',
                  'code',
                  'fullscreen',
                  'insertdatetime',
                  'media',
                  'table',
                  'help',
                  'wordcount',
                ],
                toolbar: [
                  'undo redo | bold italic underline strikethrough | blocks fontsize',
                  ' | forecolor backcolor | link unlink image media table emoticons | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help',
                ],
                content_style: 'body { font-family:"Adobe Clean", sans-serif; font-size:14px }',
              }}
            />
          </Flex>
        </View>
      );
    }
  }

  return (
    <div className={styles.overlay} onKeyUp={handleClose}>
      <CustomDialogComponent
        width="750px"
        isModal={false}
        buttons={newMessageButton}
        visible={visibility}
        header={newMessageDalogheader}
        dialogContent={newMessageDalogContent}
        closeDialog={closeDialog}
      ></CustomDialogComponent>
    </div>
  );
};

export default NewMessage;
