import React, { useState } from "react";
import {
  Button,
  Flex,
  View,
  Image,
  Text,
  Link,
  DialogContainer,
  Content,
  Heading,
  Divider,
} from "@adobe/react-spectrum";
import { t } from "i18next";
import ImageAddEdit from "../formComponents/ImageAddEdit";
import styles from './ImageUploadSection.module.css';
import { Star, Trash } from "react-bootstrap-icons";
import { ContactFormData } from "../AddNewPerson";
interface IImageUploadSection{
  data: ContactFormData['DOCUMENTS'];
  onChange: (data: ContactFormData['DOCUMENTS']) => void;
  treeHandle:string
}
const ImageUploadSection: React.FC<IImageUploadSection> = ({data,treeHandle,onChange}) => {
  const [images, setImages] = useState<ContactFormData['DOCUMENTS']>([]);
  const [isOpen, setIsOpen] = useState(false);

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const filesArray = Array.from(event.target.files);
      const newImages = filesArray.map((file) => URL.createObjectURL(file));
      //setImages((prev) => [...prev, ...newImages]);
    }
  };
  
  const handleDeleteImage = (id:number)=>{
    const newFileData = images.filter((x:any)=>x.id!==id)
    if (setImages) {
      setImages(newFileData);
    }
  }

  return (
    <View padding="size-200" paddingBottom="size-0" paddingTop="size-300">
      <Heading level={5}>Contact pictures</Heading>
      <Divider />
      <Flex 
      direction={'row'} 
      alignItems={'center'} 
      justifyContent={'start'} 
      gap={'size-100'} 
      UNSAFE_className={styles.icon_upload_parent}>
        <Content UNSAFE_style={{paddingTop:15}}>
          <Flex direction='row' wrap gap="size-100">
            {images.map(((image:any)=>
            <Flex key={image.id} direction='column'>
              <Image width='size-1250' height='size-1250' src={image.url} />
              <Flex justifyContent='center' direction='row' gap='size-100' UNSAFE_className={styles.delete_overlay}>
              <Link onPress={()=>handleDeleteImage(image.id)}><Trash /></Link>
              <Link><Star /></Link>
              </Flex>
            </Flex>
            ))}
          <View 
          UNSAFE_className={styles.image_upload_container}
          >
              <Link isQuiet onPress={(e) => {
              setIsOpen(true);
            }}
            UNSAFE_className={styles.icon_upload_link}
            >
              <i className="bi bi-upload fs8">
            <View UNSAFE_className={styles.icon_upload_text}>{t("upload", { ns: "layout_components" })}</View>
          </i></Link>
          </View>
          </Flex>
          <DialogContainer
            onDismiss={() => {
              setIsOpen(false);
            }}
          >
            {isOpen && (
              <View>
                <ImageAddEdit close={() => setIsOpen(false)} setImages={setImages} images={images} treeHandle={"123456"} />
              </View>
            )}
          </DialogContainer>
        </Content>
      </Flex>
    </View>
  );
};

export default ImageUploadSection;
