import { BaseRequest, IBaseRequest } from '../../BaseRequest';

export interface IListContactsContactRequest extends IBaseRequest {
    type?:         string;
    specificType?: string;
    content?:      string;
    isPrimary?:    string;
    isMain?:       string;
    noSystemUser?: string;
    limit?:        number;
    offset?:       number;
}

export class ListContactsContactRequest extends BaseRequest implements IListContactsContactRequest {

    public type?:         string;
    public specificType?: string;
    public content?:      string;
    public isPrimary?:    string;
    public isMain?:       string;
    public noSystemUser?: string;
    public limit?:        number;
    public offset?:       number;

    constructor(server: string, session: string, otherOptions: any) {
        super(server, session);
        this.type = otherOptions.type;
        this.specificType = otherOptions.specificType;
        this.content = otherOptions.content;
        this.isPrimary = otherOptions.isPrimary;
        this.isMain = otherOptions.isMain;
        this.noSystemUser = otherOptions.noSystemUser;
        this.limit = otherOptions.limit;
        this.offset = otherOptions.offset;
    }

    public toXml(): string {
        var parameters: string = super.toXml();

        if (this.type) parameters += `<type>${this.type}</type>`;
        if (this.specificType) parameters += `<specificType>${this.specificType}</specificType>`;
        if (this.content) parameters += `<content>${this.content}</content>`;
        if (this.isPrimary) parameters += `<isPrimary>${this.isPrimary}</isPrimary>`;
        if (this.isMain) parameters += `<isMain>${this.isMain}</isMain>`;
        if (this.noSystemUser) parameters += `<noSystemUser>${this.noSystemUser}</noSystemUser>`;
        if (this.limit) parameters += `<limit>${this.limit}</limit>`;
        if (this.offset) parameters += `<offset>${this.offset}</offset>`;
        
        return super.wrapperXml('ListContactsContact', parameters);
    }
}
