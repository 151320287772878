import React, { useEffect, useState } from "react";
import { Item, ListBox, Text, ComboBox, Grid, useAsyncList } from "@adobe/react-spectrum";
import { useDependency } from "../../../../../contexts/DependencyProvider";
import { GetContactListRequest } from "../../../../../services/soap/main/requests/GetContactListRequest";
import { ListPositionRequest } from "../../../../../services/soap/main/requests/ListPositionRequest";
import { ListReferenceRequest } from "../../../../../services/soap/main/requests/ListReferenceRequest";


export interface Character {
    refId:string;
    refName:string;
    refPriority: string;
    refStandardtype:string;
    refTreeHandle:string
}

interface IReferenceMarketingChanelDropDown{
   selectedChanel:string;
   onChange:(data:Character)=> void;
}

const ReferenceMarketingChanelDropDown:React.FC<IReferenceMarketingChanelDropDown> = ({onChange,selectedChanel}) => {
  const { store, mainService } = useDependency();
  const [selectedKey, setSelectedKey] = useState<string | null>(null);

  useEffect(()=>{
     list.reload();
  },[])

  const list = useAsyncList<Character>({
    async load({ cursor }) {
      const offset = cursor ? Number(cursor) : 0;
      const limit = 30;
      try {
        const request = new ListReferenceRequest(store.Server, store.SessionId);
        const response = await mainService.listReference(request);
        const characters = response.LIST.map(item => item.REFERENCE);

        if (!Array.isArray(characters)) {
          console.error("Invalid API response: Expected an array", response);
          return { items: [] };
        }

        return {
          items: characters,
          cursor: characters.length === limit ? String(offset + limit) : undefined,
        };
      } catch (error) {
        console.error("Error fetching characters:", error);
        return { items: [] };
      }
    },
  });

  useEffect(() => {
      if (selectedChanel) {
        setSelectedKey(selectedChanel);
        list.setFilterText(selectedChanel)
        list.reload();
      } else {
        setSelectedKey(null);
      }
    }, [selectedChanel]);
  
  const handleSelectionChange = (key:any) => {
    setSelectedKey(key);
    if (key) {
      const selectedCompany = list.items.find((x) => x.refName === key);
      if (selectedCompany) {
        onChange(selectedCompany);
      }
    }
  };

  return (
    <ComboBox
      label="Marketing Chanel"
      items={list.items}
      loadingState={list.loadingState}
      selectedKey={selectedKey}
      onSelectionChange={handleSelectionChange}
      width="40%"
    > 
  {(item) => (
    <Item key={item.refName} textValue={item.refName}>
        {item.refName}
    </Item>
  )}
</ComboBox>
  );
};

export default ReferenceMarketingChanelDropDown;
