//import jsonConfig from "../config/lagoon-config.json";
import { useCallback, useEffect, useRef, useState } from 'react';
import { OneTimeStorage } from '../services/LocalStorage/OneTimeStorage';
import { saveToCache } from './UsePreloadAssets';
import { joinPaths } from '../utils/helperUtil';
import ConstantUtils from '../utils/ConstantUtils';

const CACHE_KEYS = {
  reef_config: 'reef_config',
};

interface LoginInfo {
  showServer: boolean;
  serverName: string;
  instanceName: string;
}
interface SOAP {
  uri: string;
}
interface REST {
  uri: string;
}
interface ReefConfig {
  hasLoaded: boolean;
  baseUrl: string;
  loginInfo: LoginInfo;
  soap: SOAP;
  recruitment_dashboard: REST;
  print_service: REST;
  install_path: string;
}

export function useReefConfig(): ReefConfig {
  const [config, setConfig] = useState<ReefConfig>({
    hasLoaded: false,
    baseUrl: '',
    loginInfo: {
      showServer: true,
      serverName: '',
      instanceName: '',
    },
    soap: {
      uri: '',
    },
    recruitment_dashboard: {
      uri: '',
    },
    print_service: {
      uri: '',
    },
    install_path: '',
  });

  const loadCallback = useCallback(async () => {
    const reef_config = await OneTimeStorage.getInstance().get(CACHE_KEYS.reef_config);
    var jsonConfig = null;
    var has_loaded = false;

    if (ConstantUtils.FIRST_TIME_LOAD || reef_config === null) {
      try {
        ConstantUtils.FIRST_TIME_LOAD = false;
        console.log('fetching reef-config.json...');
        var response = await fetch('/reef-config.json');
        jsonConfig = await response.json();
        await saveToCache(CACHE_KEYS.reef_config, jsonConfig);
      } catch (error) {
        console.log('Error in fetching reef-config.json', error);
        if (reef_config) jsonConfig = JSON.parse(reef_config);
      }
    } else {
      jsonConfig = JSON.parse(reef_config);
    }

    if (jsonConfig) {
      has_loaded = true;
      jsonConfig = jsonConfig.configuration;
    }

    if (!has_loaded) {
      console.log('No reef-config.json found');
      setConfig({ ...config, hasLoaded: has_loaded });
      return;
    }
    var services = jsonConfig.services;

    var server: string = '';
    var instance: string = '';
    var showServer: boolean =
      jsonConfig.loginInfo === undefined || jsonConfig.loginInfo.serverName === undefined || jsonConfig.loginInfo.serverName === null || jsonConfig.loginInfo.serverName === '' ? true : false;

    if (!showServer) {
      server = jsonConfig.loginInfo.serverName;
      instance = jsonConfig.loginInfo.instanceName;
    }

    var base_url = process.env.NODE_ENV === 'development' ? jsonConfig.baseUrl : '';

    var recruitment_dashboard = joinPaths(base_url, services['recruitment-dashboard'].uri);
    var print_service = joinPaths(base_url, services['print-service'] ? services['print-service'].uri : '');

    if (!recruitment_dashboard.endsWith('/')) recruitment_dashboard += '/';

    if (!print_service.endsWith('/')) print_service += '/';

    var soap_path = joinPaths(base_url, jsonConfig.soap.uri);
    var landing_page = joinPaths(base_url, services['landing-page'].uri);

    console.log('recruitment_dashboard: ' + recruitment_dashboard);
    console.log('print_service: ' + print_service);
    console.log('soap-path: ' + soap_path);
    console.log('landing-page: ' + landing_page);

    setConfig({
      baseUrl: jsonConfig.baseUrl,
      loginInfo: { serverName: server, instanceName: instance, showServer: showServer },
      soap: { uri: soap_path },
      recruitment_dashboard: { uri: recruitment_dashboard },
      print_service: { uri: print_service },
      install_path: landing_page,
      hasLoaded: has_loaded,
    });
  }, []);

  useEffect(() => {
    loadCallback();
  }, [loadCallback]);

  return config;
}
