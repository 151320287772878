import { BaseRequest, IBaseRequest } from '../../BaseRequest';

export interface IGetActivityTemplateRequest extends IBaseRequest {
    id?: number;
    templateName?: string;
}


export class GetActivityTemplateRequest extends BaseRequest implements IGetActivityTemplateRequest {
    public id?: number;
    public templateName?: string;
    constructor(server: string, session: string, id?: number, templateName?: string) {
        super(server, session);
        this.id = id;
        this.templateName = templateName;
    }



    public toXml(): string {
        var parameters: string = super.toXml();
        if(this.id) {
            parameters += '<id>' + this.id + '</id>';
        }
        if(this.templateName) {
            parameters += '<templateName>' + this.templateName + '</templateName>';
        }
        return super.wrapperXml('GetActivityTemplate', parameters);
    }
}
