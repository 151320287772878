import { BaseRequest, IBaseRequest } from '../../BaseRequest';

export interface IListReferenceRequest extends IBaseRequest {
    refStandardtype?: string;
}

export class ListReferenceRequest extends BaseRequest implements IListReferenceRequest {

    public refStandardtype?: string;

  constructor(server: string, session: string, refStandardtype?: string) {
    super(server, session);
    this.refStandardtype = refStandardtype;
  }

  public toXml(): string {
    var parameters: string = super.toXml();

    if (this.refStandardtype) {
      parameters += `<refStandardtype>${this.refStandardtype}</refStandardtype>`;
    }
    return super.wrapperXml('ListReference', parameters);
  }
}
