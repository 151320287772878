import { BaseRequest, IBaseRequest } from '../../BaseRequest';

export interface IListContactListRequest extends IBaseRequest {
    systemId?:             string;
    filterName?:           string;
    offset?:               number;
    limit?:                number;
    treeHandle?:           string;
    useDefaultTreeHandle?: string;
    list?:                 string;
}

export class ListContactListRequest extends BaseRequest implements IListContactListRequest {
    public systemId?:             string;
    public filterName?:           string;
    public offset?:               number;
    public limit?:                number;
    public treeHandle?:           string;
    public useDefaultTreeHandle?: string;
    public list?:                 string;

    constructor(server: string, session: string, otherOptions: any) {
        super(server, session);
        this.systemId = otherOptions.systemId;
        this.filterName = otherOptions.filterName;
        this.offset = otherOptions.offset;
        this.limit = otherOptions.limit;
        this.treeHandle = otherOptions.treeHandle;
        this.useDefaultTreeHandle = otherOptions.useDefaultTreeHandle;
        this.list = otherOptions.list;
    }

    public toXml(): string {
        var parameters: string = super.toXml();

        if (this.systemId) parameters += `<systemId>${this.systemId}</systemId>`;
        if (this.filterName) parameters += `<filterName>${this.filterName}</filterName>`;
        if (this.offset) parameters += `<offset>${this.offset}</offset>`;
        if (this.limit) parameters += `<limit>${this.limit}</limit>`;
        if (this.treeHandle) parameters += `<treeHandle>${this.treeHandle}</treeHandle>`;
        if (this.useDefaultTreeHandle) parameters += `<useDefaultTreeHandle>${this.useDefaultTreeHandle}</useDefaultTreeHandle>`;
        if (this.list) parameters += `<list>${this.list}</list>`;
        
        return super.wrapperXml('ListContactList', parameters);
    }
}
