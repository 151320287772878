import React, { useCallback, useEffect, useState } from 'react';
import { Flex, ListBox, Item, View, ProgressCircle, Link } from '@adobe/react-spectrum';
import { CircleFill, ChevronDown, ChevronUp } from 'react-bootstrap-icons';
import { statusColor, truncateText } from '../../../../../utils/helperUtil';
import { useViewInfo } from '../../../../../hooks/UseViewInfo';
import { useDependency } from '../../../../../contexts/DependencyProvider';
import { ListStatusRequest, ListStatusType } from '../../../../../services/soap/project/requests/ListStatusRequest';
import { ListRegisterRequest } from '../../../../../services/soap/features/requests/ListRegisterRequest';
import { useTranslation } from 'react-i18next';
import { RootState } from '../../../../../state/store';
import { useSelector } from 'react-redux';
import styles from './distribution_group_filter_component.module.css';
import { usePreloadAssets } from '../../../../../hooks/UsePreloadAssets';
import { ListDistributionGroupRequest } from '../../../../../services/soap/main/requests/ListDistributionGroupRequest';

interface DistributionGroupFilterComponentProps {
  createParams: (filterType: any, filterValue: any) => void;
  filterParams?: any;
  clearFilters?:boolean
}

const DistributionGroupFilterComponent: React.FC<DistributionGroupFilterComponentProps> = ({ createParams, filterParams,clearFilters }) => {
  const selectedApp = useSelector((state: RootState) => state.userWebLayout.selectedAppId);
  const{store,mainService} = useDependency();
  const { isMobile } = useViewInfo();
  const { t } = useTranslation();
  const [toggleListVisibility, setToggleListVisibility] = useState<any>({ stateList: false });
  const [selectedGroupKey, setSelectedGroupKey] = useState<Set<string>>(new Set(['All groups']));
  const [distributionGroups,setDistributionGroups] = useState<any>([]);
  const [showLoader, setShowLoader] = useState<boolean>(true);
  const handleSelectionChange = (value: any) => {
    const arrValue = Array.from(value);
    const filteredValue = arrValue.filter(item => item !== 'All groups');
    const updateValue = filteredValue.length > 0 ? new Set(filteredValue) : new Set(['All groups']);
    setSelectedGroupKey(new Set(Array.from(updateValue).map(String)));
    createParams('groups', updateValue);
  };

  useEffect(() => {
    if(clearFilters){
      setSelectedGroupKey(new Set(['All groups']));
    }
  }, [clearFilters]);

   useEffect(() => {
      (async () => {
        setSelectedGroupKey(new Set(['All groups']));
        await getActivityStatusArray();
      })();
    }, [selectedApp]);

  const getActivityStatusArray = useCallback(async () => {
      try {
        let res = await mainService.listDistributionGroup(new ListDistributionGroupRequest(store.Server, store.SessionId));
        if (res.result === 'OK' && res.DGROUP.length > 0) {
          setDistributionGroups(res.DGROUP);
        }
      } finally {
        setShowLoader(false);
      }
    }, []);

  if (showLoader) {
    return (
      <Flex width="100%" height="100%" UNSAFE_style={{ backgroundColor: 'white' }} justifyContent={'center'} alignContent={'center'} alignItems={'center'}>
        <ProgressCircle aria-label="Loading…" isIndeterminate />
      </Flex>
    );
  } else {
    return (
      <Flex direction={'column'} minWidth={'200px'} alignSelf={'stretch'} alignItems={'start'} justifyContent={'start'}>
        <Flex direction={'column'} gap={'4px'} alignSelf={'stretch'} alignItems={'start'} justifyContent={'start'} UNSAFE_className={styles.group_container}>
          <Flex direction={'row'} height={'36px'} alignSelf={'stretch'} alignItems={'center'} justifyContent={'space-between'} UNSAFE_className={styles.group_heading}>
            <Flex direction={'row'} flex={1} alignItems={'center'} justifyContent={'start'}>
              <View position={'relative'} UNSAFE_className={styles.group_heading_text}>
                {/* {t('group', { ns: 'layout_components' })} */}
                Groups
              </View>
            </Flex>

            {toggleListVisibility.stateList ? (
              <ChevronDown color="#B0BEC5" onClick={() => setToggleListVisibility((preValue: any) => ({ ...preValue, stateList: false }))} style={{ cursor: 'pointer' }} />
            ) : (
              <ChevronUp color="#B0BEC5" onClick={() => setToggleListVisibility((preValue: any) => ({ ...preValue, stateList: true }))} style={{ cursor: 'pointer' }} />
            )}
          </Flex>
          <Flex
            direction={'column'}
            alignSelf={'stretch'}
            alignItems={'start'}
            justifyContent={'start'}
            UNSAFE_className={isMobile ? styles.group_component_mobile : styles.group_component}
            UNSAFE_style={{ display: toggleListVisibility.stateList ? '' : 'none' }}
          >
            <ListBox
              id="listActivityStatus"
              width="100%"
              selectionMode="multiple"
              defaultSelectedKeys={selectedGroupKey}
              selectedKeys={selectedGroupKey}
              items={distributionGroups}
              onSelectionChange={(value: any) => {
                handleSelectionChange(value);
              }}
              UNSAFE_style={{ color: '#455a64 !important' }}
              aria-label="none"
            >
              {(item: any) => (
                <Item key={item.id} textValue={truncateText(item.name, 18)}>
                  <Flex direction={'row'} height={'30px'} UNSAFE_style={{ paddingBottom: '30px',paddingLeft:'10px' }}>
                    <View UNSAFE_style={{ whiteSpace: 'nowrap', color: '#455a64 !important', lineHeight: '30px' }}>
                      <span title={item.name}>{truncateText(item.name, 18)}</span>
                    </View>
                  </Flex>
                </Item>
              )}
            </ListBox>
          </Flex>
        </Flex>
      </Flex>
    );
  }
};

export default DistributionGroupFilterComponent;
