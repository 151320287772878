export const ContextFieldsMapping = {
  COMPANY: {
    NAME: 'company.name1',
    VAT_NO: 'company.VATNumber',
    COUNTRY: 'company.country',
    INDUSTRY: 'company.industry',
    REF_NO: 'company.regNumber',
    TRACKING_CODE: 'company.trackingCode',
  },
  PERSON: {
    PREFIX: 'person.prefix',
    FIRST_NAME: 'person.person.name3',
    SURNAME: 'person.person.name1',
    SUFFIX: 'person.person.suffix',
    EMAIL: 'person.email',
    MOBILE: 'person.person.mobile',
    PHONE: 'person.phone',
    SALUTATION: 'person.salutation',
    COUNTRY: 'person.country',
    PREFERRED_LANGUAGE: 'person.preferedLanguageId',
  },
  EMPLOYEE: {
    // PANEL: 'relatedPerson',
    POSITION: 'relatedPerson.positionId',
    POSITION_NAME: 'relatedPerson.positionInfo',
    DEPARTMENT: 'relatedPerson.departmentId',
  },
  SIMPLE_FOLDER: {
    REF_ID: 'folder.refId',
    TRACKING_CATEGORY_ONE: 'folder.dim1',
    TRACKING_CATEGORY_TWO: 'folder.dim2',
    TRACKING_CATEGORY_THREE: 'folder.dim3',
    DESCRIPTION: 'folder.description',
  },
  PROJECT: {
    NAME: 'project.name1',
    DESCRIPTION: 'project.description',
    // FOLDER_TYPE_NAME: 'project.folderTypeName',
    SUB_FOLDER_TYPE_NAME: 'project.subFolderTypeName',
    TRACKING_CATEGORY_ONE: 'project.dim1',
    TRACKING_CATEGORY_TWO: 'project.dim2',
    TRACKING_CATEGORY_THREE: 'project.dim3',
    CONTACT: 'projectContact',
    REF_ID: 'project.refId',
    MASTER_PROJECT: 'project.masterProjectId',
  },
  ACTIVITY: {
    SUB_FOLDER_TYPE_NAME: 'activity.subFolderTypeName',
    PROJECT_TYPE_NAME: 'activity.projectTypeName',
    MASTER_ACTIVITY_TYPE_NAME: 'activity.masteractivityTypeName',
    TRACKING_CATEGORY_TWO: 'activity.dim2',
    REF_ID: 'activity.refId',
    DESCRIPTION: 'activity.description',
    CONTACT: 'activityContact',
    FOLDER_TYPE_NAME: 'activity.folderTypeName',
    TRACKING_CATEGORY_ONE: 'activity.dim1',
    TRACKING_CATEGORY_THREE: 'activity.dim3',
    NAME: 'activity.name1',
  },
  TRACKING_CODE: 'trackingCode',
  CUSTOM_FORM: 'form',
  RESPONSIBLE_USER: 'responsibleUser',
  SALES_ITEMS: 'salesItems',
  BILLING_ITEMS: 'billingItems',
  SERVICED_ITEMS: 'servicedItems',
  TECHNOLOGY_ITEMS: 'technologyItems',
  PROJECT_ITEMS_TYPE: {
    SALES_ITEMS: '0',
    BILLING_ITEMS: '1',
    TIME_SHEET_ITEMS: '2',
    TECHNOLOGY_ITEMS: '3',
    SERVICED_ITEMS: '4',
  },
};
