import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Flex, Item, ProgressCircle, ComboBox, TagGroup, ToastQueue } from '@adobe/react-spectrum';
import { useDebounce } from '@uidotdev/usehooks';
import { GetContactListRequest } from '../../../../../services/soap/main/requests/GetContactListRequest';
import { useDependency } from '../../../../../contexts/DependencyProvider';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../state/store';
import { useTranslation } from 'react-i18next';
import styles from './contacts_filter_component.module.css';

interface ContactsFilterComponentProps {
  createParams: (filterType: any, filterValue: any) => void;
  fieldName: string;
}

const ContactsFilterComponent: React.FC<ContactsFilterComponentProps> = ({ createParams, fieldName }) => {
  const { t } = useTranslation();
  const selectedApp = useSelector((state: RootState) => state.userWebLayout.selectedAppId);
  const { store, mainService } = useDependency();
  const [selectedContacts, setSelectedContacts] = useState<any>([]);
  const [availableContacts, setAvailableContacts] = useState<any>([]);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const refSelectedContacts = useRef<any>([]);
  const [filterTerm, setFilterTerm] = useState<string>('');
  const debouncedFilterTerm = useDebounce(filterTerm, 500);

  useEffect(() => {
    setSelectedContacts([]);
    setFilterTerm('');
    //getContactsData('');
  }, []);

  useEffect(() => {
    (async () => {
      if (debouncedFilterTerm !== '') {
        getContactsData(debouncedFilterTerm);
      }
    })();
  }, [debouncedFilterTerm]);

  const getContactsData = useCallback(
    async (content: string) => {
      setShowLoader(true);
      try {
        let contactsResp = await mainService.getContactList(
          new GetContactListRequest(store.Server, store.SessionId, {
            offset: 0,
            limit: 20,
            contains: '*' + content,
          })
        );
        if (contactsResp.result === 'OK') {
          //console.log('contactsResp.USERS', contactsResp);
          const formattedArray = contactsResp.CONTACT.map((row: any) => ({
            id: String(row.id),
            name: `${row?.name3} ${row?.name1}`,
          }));
          //console.log('formattedArray', formattedArray);
          setAvailableContacts(formattedArray);
        }
      } catch (error) {
        console.log('error', error);
        ToastQueue.negative(`Error fetching contacts: ${error}`, { timeout: 100 });
      } finally {
        setShowLoader(false);
      }
    },
    [filterTerm]
  );

  const handleSelectionChange = (selectedKey: any) => {
    const selectedContact = availableContacts.find((user: any) => user.id === selectedKey);
    //console.log('selectedContact', selectedContact);

    if (selectedContact) {
      refSelectedContacts.current = [...refSelectedContacts.current, selectedContact];
      setSelectedContacts((preValue: any) => [...preValue, selectedContact]);
      setAvailableContacts(availableContacts.filter((user: any) => user.id !== selectedKey));
      const updateValue = refSelectedContacts.current.length > 0 ? new Set(refSelectedContacts.current.map((item: any) => ({ CONTENT: item.name, contact: item.name, id: item.id }))) : '';
      createParams(fieldName, updateValue);
    }
  };

  const handleRemoveContact = (value: any) => {
    const arrValue = Array.from(value);
    //console.log('arrValue', arrValue[0]);
    let userId = arrValue[0];
    const removedUser = selectedContacts.find((user: any) => user.id === userId);
    if (removedUser) {
      refSelectedContacts.current = refSelectedContacts.current.filter((user: any) => user.id !== userId);
      setSelectedContacts(selectedContacts.filter((user: any) => user.id !== userId));
      setAvailableContacts([...availableContacts, removedUser]);
      const updateValue = refSelectedContacts.current.length > 0 ? new Set(refSelectedContacts.current.map((item: any) => ({ CONTENT: item.name, contact: item.name, id: item.id }))) : '';
      createParams(fieldName, updateValue);
    }
  };

  const searchContacts = (e: any) => {
    setFilterTerm(e);
  };

  if (showLoader) {
    return (
      <Flex width="100%" height="100%" UNSAFE_style={{ backgroundColor: 'white' }} justifyContent={'center'} alignContent={'center'} alignItems={'center'}>
        <ProgressCircle aria-label="Loading…" isIndeterminate />
      </Flex>
    );
  } else {
    return (
      <Flex direction={'column'} minWidth={'200px'} alignSelf={'stretch'} alignItems={'start'} justifyContent={'start'}>
        <Flex direction={'column'} gap={'4px'} alignSelf={'stretch'} alignItems={'start'} justifyContent={'start'} UNSAFE_className={styles.contacts_container}>
          <Flex direction={'row'} alignSelf={'stretch'} alignItems={'start'} justifyContent={'start'} height={'40px'} UNSAFE_className={styles.contacts_component}>
            <ComboBox
              minWidth={'150px'}
              width={'300px'}
              selectedKey={null}
              defaultItems={availableContacts}
              onSelectionChange={handleSelectionChange}
              aria-label="none"
              onKeyDown={e => e.key === 'Enter' && e.preventDefault()}
              onInputChange={searchContacts}
            >
              {(item: any) => <Item key={item.id}>{item.name}</Item>}
            </ComboBox>
            {selectedContacts.length > 0 && (
              <>
                <TagGroup id="tagGroupContacts" aria-label="Selected Contacts" items={selectedContacts} onRemove={key => handleRemoveContact(key)}>
                  {(item: any) => (
                    <Item key={item.id}>
                      <img src="../../../../../../assets/images/avatar--desktop--light@2x.png" alt="" style={{ width: '20px', marginRight: '5px' }} />
                      {item.name}
                    </Item>
                  )}
                </TagGroup>
              </>
            )}
          </Flex>
        </Flex>
      </Flex>
    );
  }
};

export default ContactsFilterComponent;
