export interface Employee {
    id?:               string;
    contact?:          string;
    company?:          string;
    department?:       string;
    departmentInfo?:   string;
    position?:         string;
    positionInfo?:     string;
    isPrimary?:        string;
    validFrom?:        string;
    validTo?:          string;
    type?:             string;
    originalName?:     string;
    custom1?:          string;
    custom2?:          string;
    custom3?:          string;
    positionName?:     string;
    departmentName?:   string;
    name1?:            string;
    name2?:            string;
    name3?:            string;
    phone?:            string;
    email?:            string;
    sourceDb?:         string;
    reference?:        string;
    personFolderType?: string;
}

export function toEmployeeXml(employee: Employee): string {
    var xml = '';
    if(employee.id)  xml += `<id>${employee.id}</id>`;
    if(employee.contact) xml += `<contact>${employee.contact}</contact>`;
    if(employee.company) xml += `<company>${employee.company}</company>`;
    if(employee.department) xml += `<department>${employee.department}</department>`;
    if(employee.departmentInfo) xml += `<departmentInfo>${employee.departmentInfo}</departmentInfo>`;
    if(employee.position) xml += `<position>${employee.position}</position>`;
    if(employee.positionInfo) xml += `<positionInfo>${employee.positionInfo}</positionInfo>`;
    if(employee.isPrimary) xml += `<isPrimary>${employee.isPrimary}</isPrimary>`;
    if(employee.validFrom) xml += `<validFrom>${employee.validFrom}</validFrom>`;
    if(employee.validTo) xml += `<validTo>${employee.validTo}</validTo>`;
    if(employee.type) xml += `<type>${employee.type}</type>`;
    if(employee.originalName) xml += `<originalName>${employee.originalName}</originalName>`;
    if(employee.custom1) xml += `<custom1>${employee.custom1}</custom1>`;
    if(employee.custom2) xml += `<custom2>${employee.custom2}</custom2>`;
    if(employee.custom3) xml += `<custom3>${employee.custom3}</custom3>`;   
    if(employee.positionName) xml += `<positionName>${employee.positionName}</positionName>`;
    if(employee.departmentName) xml += `<departmentName>${employee.departmentName}</departmentName>`;
    if(employee.name1) xml += `<name1>${employee.name1}</name1>`;
    if(employee.name2) xml += `<name2>${employee.name2}</name2>`;
    if(employee.name3) xml += `<name3>${employee.name3}</name3>`;
    if(employee.phone) xml += `<phone>${employee.phone}</phone>`;
    if(employee.email) xml += `<email>${employee.email}</email>`;
    if(employee.sourceDb) xml += `<sourceDb>${employee.sourceDb}</sourceDb>`;
    if(employee.reference) xml += `<reference>${employee.reference}</reference>`;
    if(employee.personFolderType) xml += `<personFolderType>${employee.personFolderType}</personFolderType>`;
    return `<EMPLOYEE>${xml}</EMPLOYEE>`;
}   

