import { RestServiceBase } from '../RestServiceBase';
import { PrintContextRequest } from './requests/PrintContextRequest';
import { PrintDataRequest } from './requests/PrintDataRequest';
import { PrintContextResponse } from './responses/PrintContextResponse';

export interface IPrintRestService {
  printContext(request: PrintContextRequest): Promise<any>;
  printDocument(request: PrintContextRequest): Promise<PrintContextResponse>;
  printData(request: PrintDataRequest, body: any): Promise<any>;
  printDataUrl(request: PrintDataRequest): string;
  printDataTemplate(request: PrintDataRequest): string;
  getPrintUrl(request: PrintContextRequest): string;
}

export class PrintRestService extends RestServiceBase implements IPrintRestService {
  public async printContext(request: PrintContextRequest): Promise<any> {
    const CALL_TAG = `file?${request.toQueryString()}`;
    let response: any = null;

    try {
      let tmp_response = await this.makeCall(request.signatures, CALL_TAG, request.cancelSource, 'arraybuffer');
      response = tmp_response.data;
      await this.updateCache(CALL_TAG, JSON.stringify(request.signatures), response);
    } catch (error: any) {
      response = await this.getFromCache(CALL_TAG, JSON.stringify(request.signatures));
    }
    return response;
  }
  public async printDocument(request: PrintContextRequest): Promise<PrintContextResponse> {
    const CALL_TAG = `document?${request.toQueryString()}`;
    let response: any = null;

    try {
      let tmp_response = await this.makeCall(request.signatures, CALL_TAG, request.cancelSource);
      response = tmp_response.data;
      await this.updateCache(CALL_TAG, JSON.stringify(request.signatures), response);
    } catch (error: any) {
      response = await this.getFromCache(CALL_TAG, JSON.stringify(request.signatures));
    }
    var parsedResponse = response as PrintContextResponse;
    return parsedResponse;
  }

  public printDataUrl(request: PrintDataRequest): string {
    const CALL_TAG = `printdata?${request.toQueryString()}`;
    return CALL_TAG;
  }

  public printDataTemplate(request: PrintDataRequest): string {
    const CALL_TAG = `tedy?${request.toQueryString()}`;
    return CALL_TAG;
  }

  public getPrintUrl(request: PrintContextRequest): string {
    const CALL_TAG = `${this.REST_URL}file?${request.toQueryString()}`;
    return CALL_TAG;
  }

  public async printData(request: PrintDataRequest, body: any): Promise<any> {
    const CALL_TAG = `printdata?${request.toQueryString()}`;
    return await this.makeCall(body, CALL_TAG, request.cancelSource);
  }
}
