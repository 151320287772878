import { useState, useEffect } from 'react';
import { ToastQueue } from '@react-spectrum/toast';
import { Flex, View, Link, ToggleButton, MenuTrigger, Menu, Item, ActionButton, Dialog, Content, StatusLight, Heading } from '@adobe/react-spectrum';
import { Grid, Fullscreen } from 'react-bootstrap-icons';
import FilterSearchTextBox from './FilterSearchTextBox';
import { useTranslation } from 'react-i18next';
import styles from './header_component.module.css';

interface IHeaderComponentProps {
  onPageViewChange: (view: string) => void;
  autoChanged: string;
  filterParams: any;
  onSearchChange: (params: {}) => void;
  fpaTypeCategory: string;
  onAddNew: (data:any) => void;
}

const HeaderComponent: React.FC<IHeaderComponentProps> = ({ 
  onPageViewChange, autoChanged, filterParams, onSearchChange, fpaTypeCategory, onAddNew }) => {
  const { t } = useTranslation();
  const [selectedButton, setSelectedButton] = useState<string>('Grid');

  useEffect(() => {
    setSelectedButton(prev => (prev === autoChanged ? prev : autoChanged));
  }, [autoChanged]);

  const handleToggle = (view: string) => {
    setSelectedButton(prev => (prev === view ? prev : view));
    onPageViewChange(view);
  };

  return (
    <View position={'relative'} width={'100%'} height={'56px'} overflow={'hidden'} UNSAFE_className={styles.header_parent}>
      <Flex direction={'row'} position={'absolute'} top={'0px'} left={'16px'} alignItems={'center'} justifyContent={'start'}>
        <MenuTrigger>
        <Link isQuiet aria-label="Actions" UNSAFE_className={styles.add_new_btn}>
        <i className="bi bi-plus fs-5">
            <View UNSAFE_className={styles.add_new_btn_text}>{t('add_new', { ns: 'layout_components' })}</View>
          </i>
        </Link>
        <Menu aria-label='Menu' onAction={onAddNew}>
          <Item aria-label='person' key="person">Person</Item>
          <Item aria-label='company' key="company">Company</Item>
        </Menu>
      </MenuTrigger>

        <Flex direction={'column'} width={'217px'} alignItems={'start'} justifyContent={'start'} UNSAFE_className={styles.activity_search_input} marginStart={'20px'} marginEnd={'20px'}>
          <Flex direction={'row'} height={'32px'} alignSelf={'stretch'} flexShrink={0} alignItems={'center'} justifyContent={'end'} UNSAFE_className={styles.activity_search}>
            {/* <TextField width={'100%'} onChange={handleFiltering} /> */}
            <FilterSearchTextBox onSearchChange={onSearchChange} filterParams={filterParams}/>
            <i className={`${styles.icon_activity_search} bi bi-search`}></i>
          </Flex>
          
        </Flex>
        <View position={'relative'} width={'1px'} height={'56px'} UNSAFE_className={styles.frame_divider}></View>
        <Flex alignItems="center">
          <StatusLight role='status' aria-label='Info' variant="yellow"></StatusLight>
          <Heading level={4}>This module is currently under development.</Heading>
        </Flex>
      </Flex>
      <Flex direction={'row'} gap={'size-100'} justifyContent={'end'} alignItems={'center'} top={10} right={10} position={'absolute'}>
        <ToggleButton isSelected={selectedButton === 'Grid'} onChange={() => handleToggle('Grid')} isEmphasized>
          <Grid aria-label='' />
        </ToggleButton>

        <ToggleButton isSelected={selectedButton === 'Full'} onChange={() => handleToggle('Full')} isEmphasized>
          <Fullscreen />
        </ToggleButton>
      </Flex>
    </View>
  );
};

export default HeaderComponent;
