import { View, Accordion, Disclosure, DisclosureTitle, DisclosurePanel, Flex, TextField, DateField, ComboBox, DatePicker, Heading, Divider } from '@adobe/react-spectrum';
import { Item } from '@react-stately/collections';
import React from 'react';
import { ContactFormData } from '../AddNewPerson';
import LanguageDropDown from '../../../../../components/protected/data-components/LanguageDropDown';
import { DataProvider } from '../../../../../components/protected/data-components/Contexts/DataContext';
import ReferenceMarketingChanelDropDown from '../formComponents/ReferenceMarketingChanelDropDown';
import { CalendarDate,parseDate } from '@internationalized/date';
interface IBaseInfoSectionProps{
  data: ContactFormData['baseInfo'];
  onChange: (data: ContactFormData['baseInfo']) => void;
}

export const BaseInfoSection: React.FC<IBaseInfoSectionProps> = ({ data, onChange }) => {

  return (
  <View padding="size-200" paddingBottom="size-0" paddingTop="size-300">
    <Heading level={5}>Base Info</Heading>
    <Divider />
          <Flex direction="column" gap="size-100" width='100%' marginTop={10}>
            <TextField
              label="Salutation"
              value={data.salutation}
              onChange={(salutation) => onChange({ ...data, salutation })}
              width='100%' />
            <DatePicker
              label="Birth Date"
              value={data.birthDate ? parseDate(data.birthDate) : undefined}
              onChange={(date) => onChange({ ...data, birthDate: date? date.toString() : '' })}
              width='30%' />
            <ComboBox
              label="Marital Status"
              selectedKey={data.maritalStatus}
              onSelectionChange={(maritalStatus) => onChange({ ...data, maritalStatus: maritalStatus ? maritalStatus.toString() : '' })}
              width='30%'
            >
              <Item key="0">Not specified</Item>
              <Item key="1">Single</Item>
              <Item key="2">Married</Item>
              <Item key="3">Divorced</Item>
              <Item key="4">Widow/er</Item>
            </ComboBox>
            <ComboBox
              label="Gender"
              selectedKey={data.gender}
              onSelectionChange={(gender) => onChange({ ...data, gender: gender ? gender.toString() : '' })}
              width='30%'
            >
              <Item key="0">Not specified</Item>
              <Item key="1">Male</Item>
              <Item key="2">Female</Item>
            </ComboBox>
            <DataProvider>
              <LanguageDropDown 
              onValueChange={(language) => 
                onChange({ ...data, language: language ? language.toString() : "" })} 
                compKey={'123'} 
                label={'Language'} 
                value={data.language}
                width='100%'/>
            </DataProvider>
            <TextField
              label="Reg. No."
              value={data.regNo}
              onChange={(regNo) => onChange({ ...data, regNo })}
              width='100%' />
            <TextField
              label="VAT No."
              value={data.vatNo}
              onChange={(vatNo) => onChange({ ...data, vatNo })}
              width='30%' />
            {/* <TextField
              label="Category"
              value={data.category}
              onChange={(category) => onChange({ ...data, category })}
              width='30%' /> */}
            <ReferenceMarketingChanelDropDown 
              selectedChanel={data.marketingChannel} 
              onChange={(channel)=>{ 
                const {refId,refName} = channel; 
                onChange({...data,marketingChannel:refName}); 
              }
            }
            />
          </Flex>
  </View>
);
}
