import { TreeView, TreeViewItem, TreeViewItemContent } from '@adobe/react-spectrum';
import { FPAItem } from '../../../../infra/protected/FPA/FPAItem';
import { Key, Selection, Text } from '@adobe/react-spectrum';

export interface IFinderContextTreeProps {
    items: FPAItem[];
    onSelection?: (key: string) => void;
    expandedKeys?: Set<Key>;
    setExpandedKeys?: (keys: Set<Key>) => void;
    selectedKeys?: Selection;
    setSelectedKeys?: (keys: Selection) => void;
}

const TreeItem = (item: FPAItem) => (
    <TreeViewItem key={item.id} textValue={item.title} hasChildItems={item.childItems?.length > 0}>
        <TreeViewItemContent>
            <Text>{item.title}</Text>
        </TreeViewItemContent>
        {item.childItems?.map(child => TreeItem(child))}
    </TreeViewItem>
);

export function FinderContextTree ({ 
    items, 
    onSelection,
    expandedKeys = new Set<Key>(),
    setExpandedKeys,
    selectedKeys = new Set<Key>(),
    setSelectedKeys,
}: IFinderContextTreeProps) {

    const handleSelectionChange = (key: any) => { 
        if (setSelectedKeys) setSelectedKeys(key);
        if (onSelection) onSelection(key.currentKey);
    };

    const handleExpandedChange = (keys: Set<Key>) => {
        if (setExpandedKeys) setExpandedKeys(keys);
    };

    return (
        <TreeView
            aria-label='Finder Context Tree'
            items={items}
            selectionMode="single"
            onSelectionChange={handleSelectionChange}
            onExpandedChange={handleExpandedChange}
            height={'size-6000'}
            selectionStyle='highlight'
            expandedKeys={expandedKeys}
            selectedKeys={selectedKeys}
        >
            {(item: FPAItem) => TreeItem(item)}
        </TreeView>
    );
}
