import React, { useEffect, useRef, useState } from 'react';
import { useViewInfo } from '../../../../hooks/UseViewInfo';
import { useTranslation } from 'react-i18next';
import { useDependency } from '../../../../contexts/DependencyProvider';
import { ActionButton, ComboBox, Dialog, FileTrigger, Flex, Item, Link, ProgressCircle, TextArea, TextField, useDialogContainer, View } from '@adobe/react-spectrum';
import { AccordionComponent, AccordionItemDirective, AccordionItemsDirective } from '@syncfusion/ej2-react-navigations';
import { DropDownTreeComponent } from '@syncfusion/ej2-react-dropdowns';
import { FileUploader } from 'react-drag-drop-files';
import { ToastQueue } from '@react-spectrum/toast';
import { FPAData } from '../../../../infra/protected/FPA/FPAData';
import { CreateDocumentInStorageRequest } from '../../../../services/soap/document_library/requests/CreateDocumentInStorageRequest';
import { ListDocumentTypeRequest } from '../../../../services/soap/document_library/requests/ListDocumentTypeRequest';
import styles from './DocumentComponent.module.css';

interface DocumentAddEditProps {
  categoryId: any;
  documentCategoryData: any;
  getDocumentsData: () => void;
  close: () => void;
  selectedItem?: FPAData;
}

const DocumentAddEdit: React.FC<DocumentAddEditProps> = ({ categoryId, documentCategoryData, getDocumentsData, close, selectedItem }) => {
  const { isMobile } = useViewInfo();
  const { documentLibraryService, store } = useDependency();
  let dialog = useDialogContainer();
  const { t } = useTranslation();

  const itemsRef = useRef<any>([]);
  const itemCounter = useRef<number>(1);
  const textboxValuesRef = useRef<any>({});
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [documentCategoryId, setDocumentCategoryId] = useState<any>(categoryId);
  const [documentType, setDocumentType] = useState<any>([]);
  const [documentTypeId, setDocumentTypeId] = useState<any>('');
  const [categoryItem, setCategoryItem] = useState<any>([categoryId]);
  const [refreshKey, setRefreshKey] = useState(0);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  let fields = { dataSource: documentCategoryData, value: 'id', text: 'name', child: 'subChild' };

  useEffect(() => {
    (async () => {
      await loadDocumentDropDown();
    })();
  }, []);

  const loadDocumentDropDown = async () => {
    const [{ item: documentTypes }] = await Promise.all([documentLibraryService.listDocumentType(new ListDocumentTypeRequest(store.Server, store.SessionId))]);
    setDocumentType(documentTypes);
  };

  const addFile = (fileName?: any, fileSize?: any, base64Files?: any) => {
    const newItemId = itemCounter.current;
    const newItem = {
      header: `<div class="${styles.table_cell_parent}" style='float:left'>
                    <div class="${styles.table_cell}" style='float:left'>
                        <div class="${styles.table_cell_text}">${newItemId}.</div>
                      </div>
              </div>
              <div class="${styles.table_cell_parent}" style='float:right'>
                      <div class="${styles.table_cell}" style='float:right'>
                        <div class="${styles.table_cell_sub_text_parent}">
                          <div>${fileName}</div>
                          <div class="${styles.table_cell_sub_text}">${formatFileSize(fileSize)}</div>
                        </div>
              </div>`,
      content: () => {
        return (
          <div>
            <Flex direction="column">
              <View>
                <TextField
                  width={'100%'}
                  label={t('document_name', { ns: 'layout_components' })}
                  defaultValue={textboxValuesRef.current[newItemId]?.[0] || fileName}
                  onChange={e => handleTextboxChange(newItemId, 0, e)}
                />
              </View>
              <View>
                <TextField
                  width={'100%'}
                  label={t('internal_number', { ns: 'layout_components' })}
                  defaultValue={textboxValuesRef.current[newItemId]?.[1] || ''}
                  onChange={e => {
                    handleTextboxChange(newItemId, 1, e);
                  }}
                />
              </View>
              <View>
                <TextArea
                  width={'100%'}
                  label={t('description', { ns: 'layout_components' })}
                  defaultValue={textboxValuesRef.current[newItemId]?.[2] || ''}
                  onChange={e => handleTextboxChange(newItemId, 2, e)}
                />
              </View>
              <View marginTop={'size-100'}>
                <ActionButton onPress={() => deleteFile(newItemId)} UNSAFE_style={{ color: 'rgb(188, 19, 6)', backgroundColor: '#fee8e6', border: 'none' }}>
                  <i className="bi bi-trash fs-8"></i>
                </ActionButton>
              </View>
            </Flex>
          </div>
        );
      },
      id: newItemId,
      fileName: fileName,
      fileSize: fileSize,
      base64Files,
    };

    // Initialize the textbox values for the new item
    textboxValuesRef.current[newItemId] = ['', '', ''];
    itemsRef.current = [...itemsRef.current, newItem];
    refreshAccordion();
    itemCounter.current += 1;
  };

  const deleteFile = (id: any) => {
    itemsRef.current = itemsRef.current.filter((item: any) => item.id !== id);
    refreshAccordion();
    delete textboxValuesRef.current[id];
  };

  const handleTextboxChange = (itemId: any, textboxIndex: any, value: any) => {
    if (!textboxValuesRef.current[itemId]) {
      textboxValuesRef.current[itemId] = ['', '', ''];
    }
    textboxValuesRef.current[itemId][textboxIndex] = value;
  };

  const getTextboxValues = () => {
    const values = Object.values(textboxValuesRef.current);
    console.log(values);
    return values;
  };

  const uploadFiles = async () => {
    setShowLoader(true);
    console.log('itemsRef.current', itemsRef.current);

    const uploadPromises = itemsRef.current.map(async (item: any) => {
      const data = item.base64Files.split(',')[1];
      const request = new CreateDocumentInStorageRequest(
        store.Server,
        store.SessionId,
        {
          id: '',
          acl: '',
          parent: '',
          name: textboxValuesRef.current[item.id][0] || item.fileName,
          description: textboxValuesRef.current[item.id][2],
          type: documentTypeId,
          location: '',
          category: documentCategoryId,
          validByDate: '0',
          createdBy: '',
          fileHandle: '',
          treeHandle: String(selectedItem?.id),
          fileSize: item.fileSize,
          internalNumber: textboxValuesRef.current[item.id][1],
          fileName: item.fileName,
          deleted: '0',
          validFrom: new Date(),
          validTo: new Date(),
          storageId: '',
          isInStorage: '0',
          contextId: '',
        },
        data
      );

      const result = await documentLibraryService.createDocumentInStorage(request);
      if (result.result === 'OK') {
        ToastQueue.positive(t('record_saved_successfully', { ns: 'layout_components' }), { timeout: 3000 });
        return true;
      } else {
        ToastQueue.negative(result.EXCEPTION.reason, { timeout: 3000 });
        return false;
      }
    });

    const results = await Promise.all(uploadPromises);
    setShowLoader(false);

    if (results.every(result => result)) {
      getDocumentsData();
      close();
    }
  };

  const getBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  };

  const formatFileSize = (bytes: any) => {
    if (bytes >= 1073741824) {
      return (bytes / 1073741824).toFixed(2) + ' GB';
    } else if (bytes >= 1048576) {
      return (bytes / 1048576).toFixed(2) + ' MB';
    } else if (bytes >= 1024) {
      return (bytes / 1024).toFixed(2) + ' KB';
    } else {
      return bytes + ' bytes';
    }
  };

  const onCategoryChange = (args: any): void => {
    //console.log('args', args.value[0]);
    setCategoryItem(args.value);
    setDocumentCategoryId(args.value[0]);
  };

  const refreshAccordion = () => {
    setRefreshKey(prevKey => prevKey + 1);
  };

  const fileTypes = ['JPG', 'JPEG', 'PNG', 'GIF', 'PDF', 'XLSX', 'XLS', 'DOC', 'DOCX', 'PPT', 'PPTX', 'TXT', 'ODT', 'ODS', 'ODP', 'RTF', 'CSV', 'ZIP', 'RAR'];

  const handleUploadFile = async (files: File[]) => {
    const arrFile = Array.from(files);
    arrFile.forEach(async (file: any) => {
      let fileName = file.name;
      let fileSize = file.size;
      let base64Files = await getBase64(file);
      addFile(fileName, fileSize, base64Files);
    });
  };

  if (showLoader) {
    return (
      <Flex width="100%" justifyContent={'center'} marginTop={10}>
        <ProgressCircle aria-label="Loading…" isIndeterminate />
      </Flex>
    );
  } else {
    return (
      <>
        <Dialog>
          {isMobile ? (
            <Flex direction="column" height={'600px'} marginTop={'size-200'} UNSAFE_style={{ paddingLeft: '25px' }}>
              <View>
                <ComboBox
                  width={'300px'}
                  label={t('type', { ns: 'layout_components' })}
                  defaultItems={documentType}
                  selectedKey={documentTypeId}
                  onSelectionChange={value => {
                    setDocumentTypeId(value);
                  }}
                >
                  {(item: any) => <Item key={item.id}>{item.name}</Item>}
                </ComboBox>
              </View>
              <View>
                <span className="A-HlBa_spectrum-FieldLabel">{t('category', { ns: 'layout_components' })}</span>
                <DropDownTreeComponent value={categoryItem} fields={fields} change={onCategoryChange.bind(this)} changeOnBlur={false} placeholder="" popupHeight="200px" width={'300px'} />
              </View>
              <View UNSAFE_style={{ height: '330px', top: '180px', overflow: 'auto' }} position={'absolute'}>
                <Flex direction={'row'} alignItems={'start'} justifyContent={'start'}>
                  <View UNSAFE_className={`${styles.table_column} ${styles.table_column_counter}`}></View>
                  <Flex direction={'row'} width={'268px'} height={'40px'} alignItems={'center'} justifyContent={'center'} UNSAFE_className={`${styles.table_column} ${styles.table_column_file}`}>
                    <Flex UNSAFE_className={styles.table_column_file_text}>{t('file', { ns: 'layout_components' })}</Flex>
                  </Flex>
                </Flex>
                <AccordionComponent width={'300px'} expandMode="Single" key={refreshKey}>
                  <AccordionItemsDirective>
                    {itemsRef.current.map((item: any) => (
                      <AccordionItemDirective expanded={item.id === 1 && true} key={item.id} header={item.header} content={item.content} />
                    ))}
                  </AccordionItemsDirective>
                </AccordionComponent>
              </View>
              <View>
                <View height={'72px'} width={'100%'} position={'absolute'} UNSAFE_className={styles.upload_child}>
                  <Flex position={'absolute'} top={'calc(50% - 20px)'} direction={'row'} alignItems={'start'} justifyContent={'start'} gap={'20px'}>
                    <Flex direction={'row'} height={'40px'} gap={'8px'} alignItems={'center'} justifyContent={'center'} UNSAFE_className={styles.file_upload_container} marginStart={'8px'}>
                      <FileTrigger
                        onSelect={async (e: any) => {
                          //console.log('e', e);
                          let files = Array.from(e);
                          let fileName = files.map((file: any) => file.name);
                          let fileSize = files.map((file: any) => file.size);
                          let base64Files = await Promise.all(files.map(file => getBase64(file)));
                          addFile(fileName, fileSize, base64Files);
                          //setFile(filenames);
                        }}
                      >
                        <ActionButton UNSAFE_style={{ border: 'none', color: '#1976d2', background: '#dfeffd', lineHeight: '22px' }}>
                          <i className="bi bi-plus fs-4"></i>
                        </ActionButton>
                      </FileTrigger>
                    </Flex>
                    <Flex direction={'row'} alignItems={'center'} justifyContent={'center'} height={'40px'} gap={'8px'} UNSAFE_className={styles.btn_cancel_container}>
                      <Link UNSAFE_style={{ color: '#bc1306', background: '#fee8e6', lineHeight: '22px', fontWeight: 'bold' }} isQuiet onPress={dialog.dismiss}>
                        {t('cancel', { ns: 'layout_components' })}
                      </Link>
                    </Flex>
                    {itemsRef.current.length > 0 && (
                      <Flex direction={'row'} alignItems={'center'} justifyContent={'center'} height={'40px'} width={'140px'} gap={'8px'} UNSAFE_className={styles.btn_upload_container}>
                        <ActionButton
                          isQuiet
                          UNSAFE_style={{ border: 'none', color: '#1976d2', background: '#dfeffd', lineHeight: '22px', fontWeight: 'bold' }}
                          onPress={() => {
                            uploadFiles();
                          }}
                        >
                          <i className="bi bi-upload fs-8"></i>
                          &nbsp;&nbsp; {t('upload', { ns: 'layout_components' })}
                        </ActionButton>
                      </Flex>
                    )}
                  </Flex>
                </View>
              </View>
            </Flex>
          ) : (
            <div
              className="divDragDrop"
              onDragEnter={() => {
                setIsDragging(true);
                setTimeout(() => {
                  setIsDragging(false);
                }, 2000);
              }}
            >
              <Flex UNSAFE_className={styles.upload_parent}>
                {isDragging ? (
                  <View id="divUploader" width={'635px'} height={'550px'} UNSAFE_className={styles.upload_inner}>
                    <FileUploader
                      style={{ width: '635px', height: '500px' }}
                      multiple={true}
                      label={t('drop_file_here', { ns: 'layout_components' })}
                      handleChange={handleUploadFile}
                      name="file"
                      types={fileTypes}
                      classes={styles.guZdik}
                    />
                  </View>
                ) : (
                  <>
                    <View UNSAFE_className={styles.upload_documents_text}>{t('upload_documents', { ns: 'layout_components' })} </View>
                    <View UNSAFE_className={styles.upload_desc_text}>{t('select_file_to_add_more_description', { ns: 'layout_components' })}</View>
                    <Flex direction={'column'} position={'absolute'} top={'100px'} left={'418px'} alignItems={'start'} justifyContent={'start'} gap={'12px'}>
                      <Flex direction={'column'} width={'215px'} alignItems={'start'} justifyContent={'start'}>
                        <Flex direction={'row'} alignSelf={'stretch'} alignItems={'center'} justifyContent={'start'}>
                          <View position={'relative'} UNSAFE_style={{ fontSize: '12px', lineHeight: '16px' }}>
                            {t('type', { ns: 'layout_components' })}
                          </View>
                        </Flex>
                        <View>
                          <ComboBox
                            width={'100%'}
                            label=""
                            defaultItems={documentType}
                            selectedKey={documentTypeId}
                            onSelectionChange={value => {
                              setDocumentTypeId(value);
                            }}
                          >
                            {(item: any) => <Item key={item.id}>{item.name}</Item>}
                          </ComboBox>
                        </View>
                      </Flex>
                    </Flex>
                    <Flex direction={'column'} position={'absolute'} top={'160px'} left={'418px'} alignItems={'start'} justifyContent={'start'} gap={'12px'}>
                      <Flex direction={'column'} width={'215px'} alignItems={'start'} justifyContent={'start'}>
                        <Flex direction={'row'} alignSelf={'stretch'} alignItems={'center'} justifyContent={'start'}>
                          <View position={'relative'} UNSAFE_style={{ fontSize: '12px', lineHeight: '16px' }}>
                            {t('category', { ns: 'layout_components' })}
                          </View>
                        </Flex>
                        <View>
                          <DropDownTreeComponent value={categoryItem} fields={fields} change={onCategoryChange.bind(this)} changeOnBlur={false} placeholder="" popupHeight="200px" width={'215px'} />
                        </View>
                      </Flex>
                      {/* <Flex direction={'column'} width={'215px'} alignItems={'start'} justifyContent={'start'}>
                  <FileUploader multiple={true} label={t('drop_file_here', { ns: 'layout_components' })} handleChange={handleUploadFile} name="file" types={fileTypes} classes={styles.guZdik} />
                </Flex> */}
                    </Flex>
                    <View position={'absolute'} width={'355px'} height={'400px'} overflow={'auto'} top={'100px'} left={'32px'} UNSAFE_className={styles.upload_inner}>
                      <Flex position={'absolute'} top={0} left={0} direction={'column'} alignItems={'start'} justifyContent={'start'}>
                        <Flex direction={'row'} alignItems={'start'} justifyContent={'start'}>
                          <View UNSAFE_className={`${styles.table_column} ${styles.table_column_counter}`}></View>
                          <Flex
                            direction={'row'}
                            width={'322px'}
                            height={'40px'}
                            alignItems={'center'}
                            justifyContent={'center'}
                            UNSAFE_className={`${styles.table_column} ${styles.table_column_file}`}
                          >
                            <Flex UNSAFE_className={styles.table_column_file_text}>{t('file', { ns: 'layout_components' })}</Flex>
                          </Flex>
                        </Flex>
                        <View>
                          <AccordionComponent width={'355px'} expandMode="Single" key={refreshKey}>
                            <AccordionItemsDirective>
                              {itemsRef.current.map((item: any) => (
                                <AccordionItemDirective expanded={item.id === 1 && true} key={item.id} header={item.header} content={item.content} />
                              ))}
                            </AccordionItemsDirective>
                          </AccordionComponent>
                        </View>
                      </Flex>
                    </View>
                    <View UNSAFE_className={styles.upload_child}>
                      <Flex direction={'row'} position={'absolute'} top={'calc(50% - 20px)'} right={'88%'} alignItems={'center'} justifyContent={'start'} gap={'32px'}>
                        <Flex direction={'row'} height={'40px'} gap={'8px'} alignItems={'center'} justifyContent={'center'} UNSAFE_className={styles.file_upload_container}>
                          <FileTrigger
                            allowsMultiple
                            onSelect={async (e: any) => {
                              let files = Array.from(e);
                              //add multiple files to addFiles
                              files.map(async (file: any) => {
                                let fileName = file.name;
                                let fileSize = file.size;
                                let base64Files = await getBase64(file);
                                addFile(fileName, fileSize, base64Files);
                              });
                            }}
                          >
                            <ActionButton UNSAFE_style={{ border: 'none', color: '#1976d2', background: '#dfeffd', lineHeight: '22px' }}>
                              <i className="bi bi-plus fs-4"></i>
                            </ActionButton>
                          </FileTrigger>
                        </Flex>
                      </Flex>
                      <Flex direction={'row'} position={'absolute'} top={'calc(50% - 20px)'} left={'calc(60% - 5px)'} alignItems={'center'} justifyContent={'start'} gap={'32px'}>
                        <Flex direction={'row'} height={'40px'} alignItems={'center'} justifyContent={'center'} UNSAFE_className={styles.btn_cancel_container}>
                          <Link UNSAFE_style={{ color: '#bc1306', fontWeight: 'bold', lineHeight: '22px' }} isQuiet onPress={dialog.dismiss}>
                            {t('cancel', { ns: 'layout_components' })}
                          </Link>
                        </Flex>
                        {itemsRef.current.length > 0 && (
                          <Flex direction={'row'} alignItems={'center'} justifyContent={'center'} height={'40px'} gap={'8px'} UNSAFE_className={styles.btn_upload_container}>
                            <ActionButton
                              isQuiet
                              UNSAFE_style={{ border: 'none', color: '#1976d2', background: '#dfeffd', lineHeight: '22px', fontWeight: 'bold' }}
                              onPress={() => {
                                uploadFiles();
                              }}
                            >
                              <i className="bi bi-upload fs-8"></i>
                              &nbsp;&nbsp; {t('upload', { ns: 'layout_components' })}
                            </ActionButton>
                          </Flex>
                        )}
                      </Flex>
                    </View>
                  </>
                )}
              </Flex>
            </div>
          )}
        </Dialog>
      </>
    );
  }
};

export default DocumentAddEdit;
