import React, { useEffect, useRef, useState } from 'react';
import { LayoutComponent } from '../LayoutComponent';
import { useReefConfig } from '../../../../hooks/UseReefConfig';
import { useViewInfo } from '../../../../hooks/UseViewInfo';
import { AlertIconFill, TrashIcon } from '../../Icons/IconsLib';
import { Flex, Content, ProgressCircle, View, Link, Text, DialogTrigger, Dialog, DialogContainer } from '@adobe/react-spectrum';
import { useTranslation } from 'react-i18next';
import { useDependency } from '../../../../contexts/DependencyProvider';
import { ToastQueue } from '@react-spectrum/toast';
import { GetContextPathRequest } from '../../../../services/soap/project/requests/GetContextPathRequest';
import useComonentReload from '../../../../hooks/UseComponentReload';
import { FPAData, FPADataTypes } from '../../../../infra/protected/FPA/FPAData';
import { ListDocumentRequest } from '../../../../services/soap/document_library/requests/ListDocumentRequest';
import { PermanentlyDeleteDocumentRequest } from '../../../../services/soap/document_library/requests/PermanentlyDeleteDocumentRequest';
import { ListDocumentCategoryRequest } from '../../../../services/soap/document_library/requests/ListDocumentCategoryRequest';
import { getDownloadUrl, getImagePath, ImageVariant } from '../../../../utils/ImagePathUtils';
import GalleryAddEdit from './GalleryAddEdit';
import styles from './GalleryComponent.module.css';
import { ListDocumentTypeRequest } from '../../../../services/soap/document_library/requests/ListDocumentTypeRequest';

interface GalleryProps {
  controlsEnabled?: boolean;
  dragDropEnabled?: boolean;
  galleryName?: string;
  isNameLabelVisible?: boolean;
  maxNumOfLines?: number;
  minHeight?: number;
  percentWidth?: number;
  selectedItem?: FPAData;
}

function Gallery({ controlsEnabled, dragDropEnabled, galleryName, isNameLabelVisible, maxNumOfLines, minHeight, percentWidth, selectedItem }: GalleryProps) {
  const PAGE_SIZE: number = 100;
  const { baseUrl, loginInfo } = useReefConfig();
  const { isMobile } = useViewInfo();
  const [reloadComponent] = useComonentReload();
  const { projectService, documentLibraryService, store } = useDependency();
  const { t } = useTranslation();
  const [documentsData, setDocumentsData] = useState<any>([]);
  const [showLoader, setShowLoader] = useState<boolean>(true);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [selectedImageURL, setSelectedImageURL] = useState<string>('');
  const [documentType, setDocumentType] = useState<string>('');
  const [loadingImages, setLoadingImages] = useState<{ [key: string]: boolean }>({});
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [documentCategoryTree, setDocumentCategoryTree] = useState<any>([]);
  const [galleryTypeId, setGalleryTypeId] = useState<string>('');
  const dataRef = useRef<any>({});

  useEffect(() => {
    (async () => {
      const { LIST } = await projectService.getContextPath(new GetContextPathRequest(store.Server, store.SessionId, Number(selectedItem?.id)));
      //console.log('contextRespone', contextRespone);
      let folderID;
      if (selectedItem) {
        const index = selectedItem.type === FPADataTypes.ACTIVITY ? 1 : 0;
        const context = LIST[index]?.CONTEXT;
        folderID = selectedItem.type === FPADataTypes.FOLDER ? context?.id : context?.parentId;
      } else {
        folderID = undefined;
      }
      //console.log('folderID', folderID);
      dataRef.current = { folderID };
      await loadCategoryDropDown();
      setShowLoader(false);
    })();
  }, [selectedItem]);

  useEffect(() => {
    (async () => {
      await getDocumentsData();
    })();
  }, [reloadComponent, selectedItem]);

  const loadCategoryDropDown = async () => {
    const [{ item: documentCategories }, { item: documentTypes }] = await Promise.all([
      documentLibraryService.listDocumentCategory(new ListDocumentCategoryRequest(store.Server, store.SessionId)),
      documentLibraryService.listDocumentType(new ListDocumentTypeRequest(store.Server, store.SessionId)),
    ]);
    const galleryItem = documentTypes.find(item => item.name === 'Gallery');
    const galleryId = galleryItem ? galleryItem.id : '';
    setGalleryTypeId(galleryId);
    const treeData = categoryTreeData(documentCategories);
    //console.log('treeData', treeData);
    setDocumentCategoryTree(treeData);
  };

  const categoryTreeData = (data: any) => {
    const map: any = {}; // Map to hold items by their ID
    const roots: any = []; // Array to hold root elements

    data.forEach((item: any) => {
      // Initialize each item in the map with an empty subChild array
      map[item.id] = { ...item, subChild: [] };

      // If the item has a parent, push it to the parent's subChild array,
      // otherwise, add it to the roots array
      item.parent ? map[item.parent].subChild.push(map[item.id]) : roots.push(map[item.id]);
    });

    return roots; // Return the array of root elements
  };

  const addSelected = (data: any, targetId: any) => {
    data.forEach((record: any) => {
      const isRootSelected = record.id === targetId;
      record.selected = isRootSelected;
      record.expanded = isRootSelected;

      record.subChild.forEach((child: any) => {
        const isChildSelected = child.id === targetId;
        child.selected = isChildSelected;
        child.expanded = isChildSelected;
        if (isChildSelected) record.expanded = true; // Expand parent if child is selected
      });
    });

    // console.log('Selected data', data);
    setDocumentCategoryTree(data);
  };

  let getDocumentsData = async () => {
    let { DOCUMENT: allDocuments } = await documentLibraryService.listDocument(
      new ListDocumentRequest(store.Server, store.SessionId, {
        limit: PAGE_SIZE,
        offset: 0,
        parent: selectedItem?.id,
        orderBy: 'doc_file_name',
        type: 'Gallery',
        PRIORITIES: ['aa.doc_name'],
      })
    );

    //console.log('allDocuments', allDocuments);
    //setDocumentsData(result.DOCUMENT);

    const groupedDocuments: any = {};

    // Group documents by categoryName
    allDocuments?.forEach((doc: any) => {
      const category = doc.categoryName || t('no_category', { ns: 'layout_components' });
      if (!groupedDocuments[category]) {
        groupedDocuments[category] = [];
      }
      groupedDocuments[category].push({
        id: doc.id,
        name: doc.name,
        category: doc.category,
      });
    });

    // Convert grouped object to array and sort by count
    const resultArray = Object.keys(groupedDocuments)
      .map(category => ({
        count: groupedDocuments[category].length.toString(),
        DOCUMENT: groupedDocuments[category],
        type: category,
        category: groupedDocuments[category][0].category,
      }))
      .sort((a: any, b: any) => b.count - a.count);
    //console.log('resultArray', resultArray);
    setDocumentsData(resultArray);
  };

  const deleteRecord = async (recordId: any) => {
    try {
      setShowLoader(true);
      //console.log('record', record);

      let result = await documentLibraryService.permanentlyDeleteDocument(new PermanentlyDeleteDocumentRequest(store.Server, store.SessionId, { id: recordId }));

      // console.log('result', result);
      if (result.result === 'OK') {
        setSelectedImage(null);
        setSelectedImageURL('');
        setDocumentType('');
        await getDocumentsData();
        ToastQueue.positive(t('record_deleted_successfully', { ns: 'layout_components' }), { timeout: 3000 });
      } else {
        ToastQueue.negative(result.EXCEPTION.reason, { timeout: 3000 });
      }
    } catch (error) {
    } finally {
      setShowLoader(false);
    }
  };

  const handleCopyLink = (imageUrl: string) => {
    navigator.clipboard
      .writeText(imageUrl)
      .then(() => {
        setSelectedImage(null);
        setSelectedImageURL('');
        setDocumentType('');
        ToastQueue.positive('Copied to clipboard!');
      })
      .catch(err => {
        console.error('Failed to copy: ', err);
      });
  };

  const handleImageLoad = (id: string) => {
    setLoadingImages(prev => ({ ...prev, [id]: false }));
  };

  const handleImageClick = (id: string, url: string, type: string) => {
    setSelectedImage(id);
    setSelectedImageURL(url);
    setDocumentType(type);
  };

  const closeDialog = () => {
    setIsOpen(false);
  };

  if (showLoader) {
    return (
      <Flex width="100%" justifyContent={'center'} marginTop={10}>
        <ProgressCircle aria-label="Loading…" isIndeterminate />
      </Flex>
    );
  } else {
    return (
      <>
        <Flex direction={'column'} gap={'size-150'} position={'relative'} width={'100%'}>
          <Flex direction={'row'} alignItems={'start'} justifyContent={'start'}>
            <Content position={'relative'} UNSAFE_className={styles.heading_text}>
              {galleryName}
            </Content>
            {documentsData.length === 0 && controlsEnabled && (
              <Flex direction={'row'} alignItems={'center'} justifyContent={'center'} gap={'size-100'} UNSAFE_className={styles.icon_add_parent}>
                <Content>
                  <Link
                    isQuiet
                    onPress={e => {
                      setIsOpen(true);
                      setSelectedImage(null);
                      setSelectedImageURL('');
                      setDocumentType('');
                    }}
                  >
                    <i className="bi bi-upload fs-8">
                      <View UNSAFE_className={styles.icon_add_text}>&nbsp;&nbsp;{t('upload', { ns: 'layout_components' })}</View>
                    </i>
                  </Link>
                </Content>
              </Flex>
            )}
          </Flex>
          {documentsData.map((documents: any, index: number) => (
            <React.Fragment key={documents.type + documents.id + index}>
              <Flex direction={'row'} alignItems={'start'} justifyContent={'start'}>
                <Content position={'relative'} UNSAFE_className={styles.sub_heading_text}>
                  {documents.type} <Text UNSAFE_style={{ color: '#8f9fa6' }}>({documents.count})</Text>
                </Content>
                {controlsEnabled && (
                  <Flex direction={'row'} alignItems={'center'} justifyContent={'center'} gap={'32px'} UNSAFE_className={styles.icon_parent}>
                    {/* <View>
                      <Link
                        isQuiet
                        onPress={(e: any) => {
                          setIsOpen(true);
                          setSelectedImage(null);
                          setSelectedImageURL('');
                          setDocumentType('');
                        }}
                      >
                        <i className="bi bi-upload fs-8">{!isMobile && <View UNSAFE_className={styles.icon_copy_text}> &nbsp;&nbsp;Upload</View>}</i>
                      </Link>
                    </View> */}
                    {selectedImage && documents.type === documentType ? (
                      <React.Fragment>
                        {/* <View>
                          <Link
                            isQuiet
                            onPress={(e: any) => {
                              handleCopyLink(selectedImageURL);
                            }}
                          >
                            <i className="bi bi-copy fs-8">{!isMobile && <View UNSAFE_className={styles.icon_copy_text}> &nbsp;&nbsp;Copy</View>}</i>
                          </Link>
                        </View> */}
                        <View>
                          <Link
                            isQuiet
                            onPress={(e: any) => {
                              const windowReference = window.open('', '_blank', 'height=800,width=1200,scrollbars=yes,status=yes,menubar=no,toolbar=no');
                              windowReference?.location.replace(selectedImageURL);
                              setSelectedImage(null);
                              setSelectedImageURL('');
                              setDocumentType('');
                            }}
                          >
                            <i className="bi bi-download fs-8">{!isMobile && <View UNSAFE_className={styles.icon_download_text}> &nbsp;&nbsp;{t('download', { ns: 'layout_components' })}</View>}</i>
                          </Link>
                        </View>
                        <View>
                          <DialogTrigger isDismissable type="modal">
                            <Link isQuiet UNSAFE_style={{ color: '#e31607' }}>
                              <i className="bi bi-trash fs-8">
                                {!isMobile && (
                                  <View UNSAFE_style={{ color: '#e31607' }} UNSAFE_className={styles.icon_delete_text}>
                                    &nbsp;&nbsp;{t('delete', { ns: 'layout_components' })}
                                  </View>
                                )}
                              </i>
                            </Link>
                            {close => (
                              <Dialog size="S">
                                <Flex UNSAFE_className={styles.delete_pop_up_parent}>
                                  <Content UNSAFE_className={styles.delete_pop_up_header}>{t('remove_file', { ns: 'layout_components' })}</Content>
                                  <View UNSAFE_className={styles.delete_pop_up_line}></View>
                                  <View UNSAFE_className={styles.delete_pop_up_warning_text}>{t('are_you_sure_you_want_to_remove_this_file', { ns: 'layout_components' })}</View>
                                  <View UNSAFE_className={styles.buttons_parent}>
                                    <View UNSAFE_className={styles.button_cancel}>
                                      <Link UNSAFE_className={styles.delete_pop_up_cancel_text} isQuiet onPress={close}>
                                        {t('no_keep_it', { ns: 'layout_components' })}
                                      </Link>
                                    </View>
                                    <View UNSAFE_className={styles.button_confirm}>
                                      <div className={styles.trash_icon}>
                                        <TrashIcon size={32} />
                                      </div>
                                      <Link UNSAFE_className={styles.delete_pop_up_confirm_text} isQuiet onPress={() => deleteRecord(selectedImage)}>
                                        {t('yes_remove', { ns: 'layout_components' })}
                                      </Link>
                                    </View>
                                  </View>
                                  <View UNSAFE_className={styles.ellipse_div}>
                                    <View UNSAFE_className={styles.delete_alert_icon}>
                                      <AlertIconFill size={72} />
                                    </View>
                                  </View>
                                </Flex>
                              </Dialog>
                            )}
                          </DialogTrigger>
                        </View>
                      </React.Fragment>
                    ) : (
                      <>
                        {/* <View>
                          <i className="bi bi-copy fs-8" style={{ color: '#B0BEC5' }}>
                            {!isMobile && (
                              <View UNSAFE_style={{ color: '#B0BEC5' }} UNSAFE_className={styles.icon_copy_text}>
                                &nbsp; &nbsp;Copy
                              </View>
                            )}
                          </i>
                        </View> */}
                        <View>
                          <i className="bi bi-download fs-8" style={{ color: '#B0BEC5' }}>
                            {!isMobile && (
                              <View UNSAFE_style={{ color: '#B0BEC5' }} UNSAFE_className={styles.icon_download_text}>
                                &nbsp;&nbsp;{t('download', { ns: 'layout_components' })}
                              </View>
                            )}
                          </i>
                        </View>
                        <View>
                          <i className="bi bi-trash fs-8" style={{ color: '#B0BEC5' }}>
                            {!isMobile && (
                              <View UNSAFE_style={{ color: '#B0BEC5' }} UNSAFE_className={styles.icon_delete_text}>
                                &nbsp;&nbsp;{t('delete', { ns: 'layout_components' })}
                              </View>
                            )}
                          </i>
                        </View>
                      </>
                    )}
                  </Flex>
                )}
              </Flex>
              <Flex direction="column">
                <Flex UNSAFE_className={styles.gallery_image_parent} direction="row">
                  <View>
                    <Flex direction="row" gap="size-150" wrap="wrap">
                      {documents.DOCUMENT.map((doc: any, index: number) => {
                        const imageUrl = getDownloadUrl(doc.id, baseUrl, store.Server, store.SessionId);

                        const imageUrlThumbnail = getImagePath(doc.id, ImageVariant.PICTURE_CONTACT_THUMBNAIL, baseUrl, store.Server, store.SessionId);

                        return (
                          <View key={doc.id} paddingTop="10px" paddingStart="5px" paddingEnd="5px" UNSAFE_style={{ position: 'relative' }}>
                            {loadingImages[doc.id] !== false && (
                              <Flex width={'140px'} height={'140px'} alignItems={'center'} justifyContent={'center'} UNSAFE_className={styles.loading_spinner}>
                                <ProgressCircle aria-label="Loading…" isIndeterminate />
                              </Flex>
                            )}
                            <img
                              className={`${styles.gallery_image_icon} ${selectedImage === doc.id ? styles.selected : ''}`}
                              src={imageUrlThumbnail}
                              onClick={() => handleImageClick(doc.id, imageUrl, documents.type)}
                              onLoad={() => handleImageLoad(doc.id)}
                              style={{ display: loadingImages[doc.id] === false ? 'block' : 'none' }}
                            />
                            {loadingImages[doc.id] !== true && isNameLabelVisible && (
                              <View UNSAFE_className={styles.image_name}>{doc.name.length > 18 ? `${doc.name.slice(0, 18)}...` : doc.name}</View>
                            )}
                          </View>
                        );
                      })}
                      <View height={'140px'} width={'140px'} paddingTop="10px" paddingStart="5px" paddingEnd="5px" UNSAFE_style={{ borderRadius: '8px' }}>
                        <div
                          onClick={() => {
                            setIsOpen(true);
                            setSelectedImage(null);
                            setSelectedImageURL('');
                            setDocumentType('');
                            setSelectedCategory(documents.category ?? '');
                            addSelected(documentCategoryTree, documents.category ?? '');
                          }}
                          className={styles.gallery_image_icon}
                          style={{ background: '#DEE4E7', display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#B0BEC5' }}
                        >
                          <i className="bi bi-plus-circle fs-1"></i>
                        </div>
                      </View>
                    </Flex>
                  </View>
                </Flex>
              </Flex>
            </React.Fragment>
          ))}
        </Flex>

        <DialogContainer
          onDismiss={() => {
            //console.log('setIsOpen');
            setIsOpen(false);
          }}
        >
          {isOpen && (
            <View>
              <GalleryAddEdit
                selectedItem={selectedItem}
                categoryId={selectedCategory}
                documentCategoryData={documentCategoryTree}
                getDocumentsData={getDocumentsData}
                closeDialog={closeDialog}
                dragDropEnabled={dragDropEnabled}
                galleryTypeId={galleryTypeId}
              />
            </View>
          )}
        </DialogContainer>
      </>
    );
  }
}

export const GalleryComponent = LayoutComponent(Gallery);
