import { BaseRequest, IBaseRequest } from '../../BaseRequest';
import { Employee, toEmployeeXml } from '../../dataObjects/Employee';

export interface IUpdateCompanyPersonRelationRequest extends IBaseRequest {
    EMPLOYEE: Employee;
}

export class UpdateCompanyPersonRelationRequest extends BaseRequest implements IUpdateCompanyPersonRelationRequest {
    public EMPLOYEE: Employee;

  constructor(server: string, session: string, employee: Employee) {
    super(server, session);
    this.EMPLOYEE = employee;
  }

  public toXml(): string {
    var parameters: string = super.toXml();
    parameters += toEmployeeXml(this.EMPLOYEE);
    return super.wrapperXml('UpdateCompanyPersonRelation', parameters);
  }
}
