import { ServiceBase } from '../ServiceBase';
import { MAIL } from '../../../utils/ServiceUtils';
import { INewMailRequest } from './requests/NewMailRequest';
import { NewMailResponse } from './responses/NewMailResponse';
import { IListMailboxRequest } from './requests/ListMailboxRequest';
import { ListMailboxResponse } from './responses/ListMailboxResponse';
import { IGetMailboxRequest } from './requests/GetMailboxRequest';
import { GetMailboxResponse } from './responses/GetMailboxResponse';

export interface IMailService {
  newMail(request: INewMailRequest): Promise<NewMailResponse>;
  listMailbox(request: IListMailboxRequest): Promise<ListMailboxResponse>;
  getMailbox(request: IGetMailboxRequest): Promise<GetMailboxResponse>;
}

export class MailService extends ServiceBase implements IMailService {
  public async newMail(request: INewMailRequest): Promise<NewMailResponse> {
    let response: any = null;
    try {
      response = (await this.makeCall(request.toXml(), MAIL.newMail, request.cancelSource)).data;
      await this.updateCache(MAIL.newMail, request.toXml(), response);
    } catch (error: any) {
      response = await this.getFromCache(MAIL.newMail, request.toXml());
    }

    var parsedResponse = super.sendResponse2(response).NewMailResponse as NewMailResponse;
    try {
      super._checkErrors(parsedResponse);
    } catch (e) {
      console.error(e);
    }
    return parsedResponse;
  }

  public async listMailbox(request: IListMailboxRequest): Promise<ListMailboxResponse> {
    let response: any = null;
    try {
      response = (await this.makeCall(request.toXml(), MAIL.listMailbox, request.cancelSource)).data;
      await this.updateCache(MAIL.listMailbox, request.toXml(), response);
    } catch (error: any) {
      response = await this.getFromCache(MAIL.listMailbox, request.toXml());
    }

    var parsedResponse = super.sendResponse2(response, ['MAILBOX']).ListMailboxResponse as ListMailboxResponse;
    try {
      super._checkErrors(parsedResponse);
    } catch (e) {
      console.error(e);
    }
    return parsedResponse;
  }

  public async getMailbox(request: IGetMailboxRequest): Promise<GetMailboxResponse> {
    let response: any = null;
    try {
      response = (await this.makeCall(request.toXml(), MAIL.getMailbox, request.cancelSource)).data;
      await this.updateCache(MAIL.getMailbox, request.toXml(), response);
    } catch (error: any) {
      response = await this.getFromCache(MAIL.getMailbox, request.toXml());
    }

    var parsedResponse = super.sendResponse2(response, ['USER']).GetMailboxResponse as GetMailboxResponse;
    try {
      super._checkErrors(parsedResponse);
    } catch (e) {
      console.error(e);
    }
    return parsedResponse;
  }
}
