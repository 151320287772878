import { Flex, View } from '@adobe/react-spectrum';
import { Paperclip } from 'react-bootstrap-icons';
import moment from 'moment';
import { truncateText } from '../../../../../utils/helperUtil';
import { DocumentIcon, InvoiceIcon, MailInCommingIcon, MailOutCommingIcon, MassmailGreen, NoticeIcon, PhoneInComingIcon, TaskIcon } from '../../../Icons/IconsLib';
import styles from './card_component_large.module.css';

interface ICardComponentLargeProps {
  item: any;
}

const CardComponentLarge: React.FC<ICardComponentLargeProps> = ({ item }) => {
  const formatRecipients = (recipients: any): string => {
    const formatContent = (label: string, data: any, prop: any): string => {
      if (Array.isArray(data)) {
        return `${label}: ${data.map(item => item[prop]).join(', ')}`;
      } else if (data && typeof data === 'object') {
        return `${label}: ${data[prop]}`;
      }
      return '';
    };

    const toContent = formatContent('To', recipients?.TO, 'CONTENT');
    const ccContent = formatContent('Cc', recipients?.CC, 'CONTENT');
    const bccContent = formatContent('Bcc', recipients?.BCC, 'CONTENT');
    const userContent = formatContent('Notify', recipients?.USER, 'contact');

    return [toContent, ccContent, bccContent, userContent].filter(Boolean).join(' | ');
  };

  const getSenderEmail = (email: string) => {
    const match = /<(.+?)>/.exec(email);
    return match ? match[1] : email;
  };

  const getSenderName = (sender: string) => {
    const match = /^([^<]+)/.exec(sender);
    return match ? match[1].trim() : sender;
  };

  const emailTypeImg = () => {
    switch (item.type) {
      case '1':
        return (
          <View UNSAFE_className={styles.email_type_img_mobile}>
            <NoticeIcon size={24} />
          </View>
        );
      case '2':
      case '3':
      case '7':
        if (item.senderContactId) {
          return (
            <View UNSAFE_className={styles.email_type_img_mobile}>
              <MailOutCommingIcon size={24} />
            </View>
          );
        } else {
          return (
            <View UNSAFE_className={styles.email_type_img_mobile}>
              <MailInCommingIcon size={24} />
            </View>
          );
        }
      case '9':
      case '12':
      case '14':
      case '15':
      case '11':
        return (
          <View UNSAFE_className={styles.email_type_img_mobile}>
            <MailOutCommingIcon size={24} />
          </View>
        );
      case '4':
      case '5':
        return (
          <View UNSAFE_className={styles.email_type_img_mobile}>
            <DocumentIcon size={24} />
          </View>
        );
      case '6':
        return (
          <View UNSAFE_className={styles.email_type_img_mobile}>
            <PhoneInComingIcon size={24} />
          </View>
        );
      case '8':
        return (
          <View UNSAFE_className={styles.email_type_img_mobile}>
            <MassmailGreen size={24} />
          </View>
        );
      case '10':
        return (
          <View UNSAFE_className={styles.email_type_img_mobile}>
            <TaskIcon size={24} />
          </View>
        );
      case '13':
        return (
          <View UNSAFE_className={styles.email_type_img_mobile}>
            <InvoiceIcon size={24} />
          </View>
        );
    }
  };

  return (
    <View width={'100%'} position={'absolute'} top={'0px'} height={'71px'}>
      <Flex
        direction={'row'}
        position={'absolute'}
        top={'15px'}
        left={'50px'}
        alignItems={'center'}
        justifyContent={'start'}
        gap={'8px'}
        width={'100%'}
        UNSAFE_className={styles.user_detail_parent_mobile}
      >
        <View position={'relative'} UNSAFE_className={item?.seen == '1' ? styles.user_name_read_mobile : styles.user_name_unread_mobile}>
          <span title={item?.sender}>{truncateText(getSenderName(item?.sender), 30)}</span>
        </View>
        {getSenderEmail(item?.mailFrom) && <View width={'1px'} position={'relative'} height={'12px'} UNSAFE_className={styles.user_detail_parent_line_mobile}></View>}
        <View position={'relative'} UNSAFE_className={styles.user_email_mobile}>
          <span title={getSenderEmail(item?.mailFrom)}>{truncateText(getSenderEmail(item?.mailFrom), 30)}</span>
        </View>
        <View position={'absolute'} right={'60px'} UNSAFE_className={styles.user_email_mobile}>
          <span title={formatRecipients(item?.RECIPIENT)}>
            {/* <div dangerouslySetInnerHTML={{ __html: formatRecipients(item?.RECIPIENT) }} /> */}
            {truncateText(formatRecipients(item?.RECIPIENT), 30)}
          </span>
        </View>
      </Flex>
      <View position={'absolute'} top={'35px'} left={'50px'} UNSAFE_className={item?.seen == '1' ? styles.email_subject_read_mobile : styles.email_subject_unread_mobile}>
        <span title={item?.subject}>{truncateText(item?.subject, 60)}</span>
      </View>
      <View position={'absolute'} top={'35px'} right={'0px'} width={'90px'} UNSAFE_className={styles.email_date_mobile}>
        {moment(item?.date).format('DD.MM.YYYY HH:mm')}
      </View>
      <View position={'absolute'} top={'50px'} right={'0px'} width={'20px'} UNSAFE_className={styles.email_date_mobile}>
        {item?.numberOfAttachments > 0 && <Paperclip size={12} />}
      </View>
      {emailTypeImg()}
    </View>
  );
};

export default CardComponentLarge;
