// DataComponent.tsx
import { useEffect, useState } from 'react';
import { useDataContext } from './Contexts/DataContext';
import { ComboBox, Item, Key } from '@adobe/react-spectrum';
import { DataComponentProps } from './DataComponentProps';
import { useTranslation } from 'react-i18next';
import { FPAData } from '../../../infra/protected/FPA/FPAData';

interface ProjectDropDownProps extends DataComponentProps {
    parent_folder_id?: number;
    activity_type_id?: number;
    fpa_type_category?: string;
    flatView?: boolean;
    debug?: boolean;
    showParent?: boolean;
}
const ProjectDropDown: React.FC<ProjectDropDownProps> = ({ 
  parent_folder_id,
  activity_type_id,
  fpa_type_category,
  flatView,
  debug,
  compKey, 
  label, 
  width, 
  isRequired, 
  onValueChange,
  value: dvalue,
  showParent
}) => {
  const { t } = useTranslation();
  const _t = (key: string) => t(`newContextDialog.dataComponents.${key}`, { ns: 'finder' });
  const message = _t('is_required').split('[content]').join(label);
  const [errorMessage, setErrorMessage] = useState(isRequired && !dvalue? message : '');  
  const [value, setValue] = useState<Key | null>('');
  const [input, setInput] = useState('');
  const { getProjectData } = useDataContext();
  const { data, error, loading } = getProjectData({ parent_folder_id, activity_type_id, fpa_type_category, flatView, debug });

  useEffect(() => {
    if(dvalue && dvalue != 'undefined') {
        setValue(dvalue);
    }
  },[dvalue]);
  
  const onSelectionChange = (value: Key | null) => {
    if(onValueChange)
        onValueChange(value ? value.toString() : '', input);
    setValue(value);
    if(isRequired && !value && !input) {
      setErrorMessage(message);
    } else 
      setErrorMessage('');
  };

  const onInputChange = (_value: string) => {
    setInput(_value);
    if(isRequired && !_value) {
      setErrorMessage(message);
    } else 
      setErrorMessage('');    
  }
  
  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  const itemTitle = (item: FPAData) => {
    return (<Item key={item.id} textValue={item.title}>
      {item.title}
    </Item>)
  }

  const itemTitleWithParent = (item: FPAData) => {
    return (<Item key={item.id} textValue={`${item.title} (${item.parent?.title})`}>
      {`${item.title} (${item.parent?.title})`}
    </Item>)
  }

  return (
    <ComboBox key={compKey} 
        label={label} 
        width={width} 
        isRequired={isRequired} 
        selectedKey={value}
        onSelectionChange={onSelectionChange} 
        onInputChange={onInputChange}
        validationState={errorMessage ? 'invalid' : undefined}
        errorMessage={errorMessage}
        >
      {data.map((item) => (
        showParent ? itemTitleWithParent(item) : itemTitle(item)
      ))}
    </ComboBox>
  );
};

export default ProjectDropDown;
