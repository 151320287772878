import {ServiceBase} from '../ServiceBase';
import { FEATURES } from '../../../utils/ServiceUtils';
import { IListApplicationsRequest } from './requests/ListApplicationsRequest';
import { ListApplicationsResponse } from './responses/ListApplicationsResponse';
import { UpdateRegisterResponse } from './responses/UpdateRegisterResponse';
import { IUpdateRegisterRequest } from './requests/UpdateRegisterRequest';
import { ListRegisterResponse } from './responses/ListRegisterResponse';
import { IListRegisterRequest } from './requests/ListRegisterRequest';
import { ICreateRegisterRequest } from './requests/CreateRegisterRequest';
import { CreateRegisterResponse } from './responses/CreateRegisterResponse';
import { IGetFormPanelRequest } from './requests/GetFormPanelRequest';
import { GetFormPanelResponse } from './responses/GetFormPanelResponse';
import { ListTimelineResponse } from './responses/ListTimelineResponse';
import { IListTimelineRequest } from './requests/ListTimelineRequest';
import { ListFormSettingsResponse } from './responses/ListFormSettingsResponse';
import { IListFormSettingsRequest } from './requests/ListFormSettingsRequest';
import { IGetFormSettingsRequest } from './requests/GetFormSettingsRequest';
import { GetFormSettingsResponse } from './responses/GetFormSettingsResponse';
import { ICheckWorkFlowQueueRequest } from './requests/CheckWorkFlowQueueRequest';
import { CheckWorkFlowQueueResponse } from './responses/CheckWorkFlowQueueResponse';
import { IGetDimensionSettingsRequest } from './requests/GetDimensionSettingsRequest';
import { GetDimensionSettingsResponse } from './responses/GetDimensionSettingsResponse';
import { IListDimensionNodeRequest } from './requests/ListDimensionNodeRequest';
import { ListDimensionNodeResponse } from './responses/ListDimensionNodeResponse';
import { IListUserPreferencesRequest } from './requests/ListUserPreferencesRequest';
import { ListUserPreferencesResponse } from './responses/ListUserPreferencesResponse';
import { GetSystemIDResponse } from './responses/GetSystemIDResponse';
import { IGetSystemIdRequest } from './requests/GetSystemIdRequest';
export interface IFeatureService {
    listApplications(request:IListApplicationsRequest):Promise<ListApplicationsResponse>;
    updateRegister(request:IUpdateRegisterRequest):Promise<UpdateRegisterResponse>;
    listRegister(request:IListRegisterRequest):Promise<ListRegisterResponse>;
    createRegister(request:ICreateRegisterRequest):Promise<CreateRegisterResponse>;
    getFormPanel(request: IGetFormPanelRequest): Promise<GetFormPanelResponse>;
    listTimeline(request: IListTimelineRequest): Promise<ListTimelineResponse>;
    listFormSettings(request: IListFormSettingsRequest): Promise<ListFormSettingsResponse>;
    getFormSettings(request: IGetFormSettingsRequest): Promise<GetFormSettingsResponse>;
    checkWorkFlowQueue(request: ICheckWorkFlowQueueRequest): Promise<CheckWorkFlowQueueResponse>;
    getDimensionSettings(request: IGetDimensionSettingsRequest): Promise<GetDimensionSettingsResponse>;
    listDimensionNode(request: IListDimensionNodeRequest): Promise<ListDimensionNodeResponse>;
    listUserPreferences(request: IListUserPreferencesRequest): Promise<ListUserPreferencesResponse>;
    getSystemId(request: IGetSystemIdRequest): Promise<GetSystemIDResponse>;
}

export class FeatureService extends ServiceBase implements IFeatureService { 
    private _addRootElementForApplicationList(xml:string):string{
        xml = xml.replace('</count><APPLICATION>', '</count><APPLICATIONS><APPLICATION>');
        xml = xml.replace('</APPLICATION></ns1:ListApplicationsResponse>', '</APPLICATION></APPLICATIONS></ns1:ListApplicationsResponse>');
        return xml;
    }
    
    private _addRootElementForRegisterList(xml:string):string{
        xml = xml.replace('</count><ROW>', '</count><ROWS><ROW>');
        xml = xml.replace('</ROW></ns1:ListRegisterResponse>', '</ROW></ROWS></ns1:ListRegisterResponse>');
        return xml;
    }

    public async listApplications(request: IListApplicationsRequest): Promise<ListApplicationsResponse>{
        let response:any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), FEATURES.listApplications, request.cancelSource));
            response = tmp_response.data;
            await this.updateCache(FEATURES.listApplications, request.toXml(), response);
        } catch (error:any) {
            response = await this.getFromCache(FEATURES.listApplications, request.toXml());
        }

        var parsedResponse = super.sendResponse2(response, ['APPLICATION','path']).ListApplicationsResponse as ListApplicationsResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;
    }
 
    public async updateRegister(request: IUpdateRegisterRequest): Promise<UpdateRegisterResponse>{
        let response:any = null;
        try {
            response = (await this.makeCall(request.toXml(), FEATURES.updateRegister, request.cancelSource)).data ;
            await this.updateCache(FEATURES.updateRegister, request.toXml(), response);
        } catch (error:any) {
            response = await this.getFromCache(FEATURES.updateRegister, request.toXml());
        }

        var parsedResponse = super.sendResponse(response).UpdateRegisterResponse as UpdateRegisterResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;
    }

    public async listRegister(request: IListRegisterRequest): Promise<ListRegisterResponse>{
        let response:any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), FEATURES.listRegister, request.cancelSource)).data;
            response = this._addRootElementForRegisterList(tmp_response);
            await this.updateCache(FEATURES.listRegister, request.toXml(), response);
        } catch (error:any) {
            response = await this.getFromCache(FEATURES.listRegister, request.toXml());
        }

        var parsedResponse = super.sendResponse(response).ListRegisterResponse as ListRegisterResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;
    }

    public async createRegister(request: ICreateRegisterRequest): Promise<CreateRegisterResponse>{
        let response:any = null;
        try {
            response = (await this.makeCall(request.toXml(), FEATURES.createRegister, request.cancelSource)).data ;
            await this.updateCache(FEATURES.createRegister, request.toXml(), response);
        } catch (error:any) {
            response = await this.getFromCache(FEATURES.createRegister, request.toXml());
        }

        var parsedResponse = super.sendResponse(response).CreateRegisterResponse as CreateRegisterResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;
    }

    public async getFormPanel(request: IGetFormPanelRequest): Promise<GetFormPanelResponse>{
        let response:any = null;
        try {
            response = (await this.makeCall(request.toXml(), FEATURES.getFormPanel, request.cancelSource)).data ;
            await this.updateCache(FEATURES.getFormPanel, request.toXml(), response);
        } catch (error:any) {
            response = await this.getFromCache(FEATURES.getFormPanel, request.toXml());
        }

        var parsedResponse = super.sendResponse(response).GetFormPanelResponse as GetFormPanelResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;
    }

    public async listTimeline(request: IListTimelineRequest): Promise<ListTimelineResponse>{
        let response:any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), FEATURES.listTimeline, request.cancelSource)).data;
            response = this._addRootElementForRegisterList(tmp_response);
            await this.updateCache(FEATURES.listTimeline, request.toXml(), response);
        } catch (error:any) {
            response = await this.getFromCache(FEATURES.listTimeline, request.toXml());
        }

        var parsedResponse = super.sendResponse2(response,['CHANGE', 'CONTEXT', 'ITEM']).ListTimelineResponse as ListTimelineResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;
    }

    public async listFormSettings(request: IListFormSettingsRequest): Promise<ListFormSettingsResponse>{
        let response:any = null;
        try {
            response = (await this.makeCall(request.toXml(), FEATURES.listFormSettings, request.cancelSource)).data ;
            await this.updateCache(FEATURES.listFormSettings, request.toXml(), response);
        } catch (error:any) {
            response = await this.getFromCache(FEATURES.listFormSettings, request.toXml());
        }

        var parsedResponse = super.sendResponse2(response,['SETTING', 'TYPE']).ListFormSettingsResponse as ListFormSettingsResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;
    }

    public async getFormSettings(request: IGetFormSettingsRequest): Promise<GetFormSettingsResponse>{
        let response:any = null;
        try {
            response = (await this.makeCall(request.toXml(), FEATURES.getFormSettings, request.cancelSource)).data ;
            await this.updateCache(FEATURES.getFormSettings, request.toXml(), response);
        } catch (error:any) {
            response = await this.getFromCache(FEATURES.getFormSettings, request.toXml());
        }

        var parsedResponse = super.sendResponse2(response,['SETTING', 'ITEM']).GetFormSettingsResponse as GetFormSettingsResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;
    }

    public async checkWorkFlowQueue(request: ICheckWorkFlowQueueRequest): Promise<CheckWorkFlowQueueResponse>{
        let response:any = null;
        try {
            response = (await this.makeCall(request.toXml(), FEATURES.checkWorkFlowQueue, request.cancelSource)).data ;
            await this.updateCache(FEATURES.checkWorkFlowQueue, request.toXml(), response);
        } catch (error:any) {
            response = await this.getFromCache(FEATURES.checkWorkFlowQueue, request.toXml());
        }

        var parsedResponse = super.sendResponse(response).CheckWorkFlowQueueResponse as CheckWorkFlowQueueResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;
    }

    public async getDimensionSettings(request: IGetDimensionSettingsRequest): Promise<GetDimensionSettingsResponse>{
        let response:any = null;
        try {
            response = (await this.makeCall(request.toXml(), FEATURES.getDimensionSettings, request.cancelSource)).data ;
            await this.updateCache(FEATURES.getDimensionSettings, request.toXml(), response);
        } catch (error:any) {
            response = await this.getFromCache(FEATURES.getDimensionSettings, request.toXml());
        }

        var parsedResponse = super.sendResponse(response).GetDimensionSettingsResponse as GetDimensionSettingsResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;
    }

    public async listDimensionNode(request: IListDimensionNodeRequest): Promise<ListDimensionNodeResponse>{
        let response:any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), FEATURES.listDimensionNode, request.cancelSource)).data;
            response = this._addRootElementForRegisterList(tmp_response);
            await this.updateCache(FEATURES.listDimensionNode, request.toXml(), response);
        } catch (error:any) {
            response = await this.getFromCache(FEATURES.listDimensionNode, request.toXml());
        }

        var parsedResponse = super.sendResponse2(response,['NODE']).ListDimensionNodeResponse as ListDimensionNodeResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;
    }

    public async listUserPreferences(request: IListUserPreferencesRequest): Promise<ListUserPreferencesResponse>{
        let response:any = null;
        try {
            response = (await this.makeCall(request.toXml(), FEATURES.listUserPreferences, request.cancelSource)).data ;
            await this.updateCache(FEATURES.listUserPreferences, request.toXml(), response);
        } catch (error:any) {
            response = await this.getFromCache(FEATURES.listUserPreferences, request.toXml());
        }

        var parsedResponse = super.sendResponse2(response,['USER_PREFERENCES']).ListUserPreferencesResponse as ListUserPreferencesResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;
    }

    public async getSystemId(request: IGetSystemIdRequest): Promise<GetSystemIDResponse>{
        let response:any = null;
        try {
            response = (await this.makeCall(request.toXml(), FEATURES.getSystemId, request.cancelSource)).data ;
            await this.updateCache(FEATURES.getSystemId, request.toXml(), response);
        } catch(error: any) {
            response = await this.getFromCache(FEATURES.getSystemId, request.toXml());
        }


        var parsedResponse = super.sendResponse(response).GetSystemIDResponse as GetSystemIDResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;
    }
}
