import { View, Accordion, Disclosure, DisclosureTitle, DisclosurePanel, Flex, ComboBox, TextField, Checkbox, Button, ActionButton, Heading, Divider } from '@adobe/react-spectrum';
import { Item } from '@react-stately/collections';
import React, { useState, useEffect } from 'react';
import { ContactFormData } from '../AddNewPerson';
import { useDependency } from '../../../../../contexts/DependencyProvider';
import { Trash } from 'react-bootstrap-icons';
import { CloseIcon, EditIcon } from '../../../../../components/protected/Icons/IconsLib';
import { usePreloadAssets } from '../../../../../hooks/UsePreloadAssets';

interface IContactSectionProps{
  contacts: ContactFormData['contacts'];
  onChange: (contacts: ContactFormData['contacts']) => void;
  onAdd: () => void;
}

export const ContactSection: React.FC<IContactSectionProps> = ({ contacts, onChange, onAdd }) => {
  const { store, translateService } = useDependency();
  const [expandedSections, setExpandedSections] = useState<Set<number>>(new Set());
  const { contactSettings } = usePreloadAssets();
  
  const contactOptions = Object.values(contactSettings) as [{
    priority: string | number;
    name: string;
    id: string,
    type: string,
  }];
  contactOptions.sort((a, b) => parseInt(String(a.priority)) - parseInt(String(b.priority)));

  // Handle checkbox changes with smart logic
  const handleCheckboxChange = (index: number, field: 'isPrimary' | 'isMain', value: boolean) => {
    const newContacts = [...contacts];
    const currentContact = newContacts[index];
    
    if (field === 'isPrimary' && value) {
      // If setting as primary, uncheck all other contacts of the same typeType
      newContacts.forEach((contact, i) => {
        if (i !== index && contact.typeType === currentContact.typeType) {
          contact.isPrimary = false;
        }
      });
    }
    
    if (field === 'isMain' && value) {
      // If setting as main, uncheck all other contacts regardless of type
      newContacts.forEach((contact, i) => {
        if (i !== index) {
          contact.isMain = false;
        }
      });
    }
    
    // Set the current contact's checkbox value
    newContacts[index] = { ...currentContact, [field]: value };
    onChange(newContacts);
  };

  useEffect(() => {
    // Skip processing if contacts array is empty
    if (!contacts || contacts.length === 0) return;
  
    // Deep clone to avoid reference issues
    const newContacts = JSON.parse(JSON.stringify(contacts));
    let hasChanges = false;
  
    // Group contacts by typeType
    const typeGroups:any = {};
    
    contacts.forEach((contact, index) => {
      // Handle undefined typeType gracefully
      const typeType = contact.typeType || 'unknown';
      
      if (!typeGroups[typeType]) {
        typeGroups[typeType] = [];
      }
      
      typeGroups[typeType].push({ contact, index });
    });
    
    // Check and fix each type group
    Object.keys(typeGroups).forEach(typeType => {
      const groupContacts = typeGroups[typeType];
      
      // Check if any contact in this group is already marked as primary
      const hasPrimary = groupContacts.some((item:any) => item.contact.isPrimary === true);
      
      // If no contact is primary in this group, mark the first one
      if (!hasPrimary && groupContacts.length > 0) {
        const firstContactIndex = groupContacts[0].index;
        
        // Set as primary but don't disturb other properties
        newContacts[firstContactIndex] = {
          ...newContacts[firstContactIndex],
          isPrimary: true
        };
        
        hasChanges = true;
      }
    });
    
    // Check if any contact is marked as main
    const hasMain = contacts.some(contact => contact.isMain === true);
    
    // If no main contact, mark the first one as main
    if (!hasMain && contacts.length > 0) {
      newContacts[0] = {
        ...newContacts[0],
        isMain: true
      };
      hasChanges = true;
    }
    
    // Only trigger onChange if we made changes
    if (hasChanges) {
      onChange(newContacts);
    }
  }, [contacts]);

  const handleRemoveContact = (index: number) => {
    const updatedContacts = contacts.filter((_, i) => i !== index);
    onChange(updatedContacts);
  };

  const handleContactTypeChange = (id: any, index: number, contact: any) => {
    const filteredType = contactOptions.find((x: any) => x.id === id);
    if (filteredType) {
      const newContacts = [...contacts];
      
      // Update the current contact with the new type information
      newContacts[index] = { 
        ...contact, 
        typeType: filteredType.type, 
        type: filteredType.id, 
        typeName: filteredType.name 
      };
      
      // Check if there are other contacts of the same type that are already primary
      const sameTypeContacts = newContacts.filter(
        (c, i) => i !== index && c.typeType === filteredType.type && c.isPrimary === true
      );
      
      // If other contacts of the same type exist and are marked as primary
      if (sameTypeContacts.length > 0) {
        if (contact.isPrimary) {
          // If this contact is primary, make others non-primary
          sameTypeContacts.forEach((sameTypeContact) => {
            const contactIndex = newContacts.findIndex((c) => c === sameTypeContact);
            if (contactIndex !== -1) {
              newContacts[contactIndex] = { ...newContacts[contactIndex], isPrimary: false };
            }
          });
        } else {
          // If this contact is not primary and we have a primary one already, nothing to do
        }
      } else {
        // No other primary contacts of this type exist
        // If this is the first contact of this type, make it primary
        const typeContacts = newContacts.filter(c => c.typeType === filteredType.type);
        if (typeContacts.length === 1) {
          newContacts[index].isPrimary = true;
        }
      }
      
      onChange(newContacts);
    }
  };

  const onEdit = (data: any, index: number) => {
    setExpandedSections((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(index)) {
        newSet.delete(index);
      } else {
        newSet.add(index);
      }
      return newSet;
    });
  };

  return (
    <View padding="size-200" paddingBottom="size-0" paddingTop="size-300">
      <Heading level={5}>Contact Information</Heading>
      <Divider />
      <Flex direction="column" gap="size-200" marginTop={10}>
        {contacts.map((contact, index) => (
          <View key={index}>
            <Flex key={index} direction="row" gap="size-100" width='100%'>
              <ActionButton 
                UNSAFE_style={{marginTop:25}} 
                //isDisabled={index == 0} 
                isQuiet 
                onPress={() => handleRemoveContact(index)}
              >
                <CloseIcon size={18} />
              </ActionButton>
              <ComboBox 
                label="Contact Type"
                selectedKey={contact.type}
                onSelectionChange={(key) => handleContactTypeChange(key, index, contact)}
              >
                {contactOptions.map((option) => (
                  <Item key={option.id}>{option.name}</Item>
                ))}
              </ComboBox>
              <TextField
                label="Value"
                value={contact.content}
                onChange={(description) => {
                  const newContacts = [...contacts];
                  newContacts[index] = { ...contact, content: description };
                  onChange(newContacts);
                }} 
                width='70%'
              />
            </Flex>
            {(expandedSections.has(index) || (contact.description && contact.description.trim() !== '')) && (
              <Flex alignItems={'end'} key={index + 1} direction='column'>
                <TextField
                  label='Description'
                  value={contact.description}
                  onChange={(description) => {
                    const newContacts = [...contacts];
                    newContacts[index] = { ...contact, description };
                    onChange(newContacts);
                  }}
                  width='92.2%'
                />
              </Flex>
            )}

            <Flex gap="size-100" UNSAFE_style={{ paddingLeft: 40 }}>
              <Checkbox
                isSelected={contact.isPrimary}
                onChange={(isPrimary) => handleCheckboxChange(index, 'isPrimary', isPrimary)}
              >
                Primary
              </Checkbox>
              <Checkbox
                isSelected={contact.isMain}
                onChange={(isMain) => handleCheckboxChange(index, 'isMain', isMain)}
              >
                Preferred
              </Checkbox>
              <a onClick={() => onEdit(contact, index)}>
                <EditIcon size={20}/> 
              </a>
            </Flex>
          </View>
        ))}
        <Button variant="secondary" onPress={onAdd}>Add Contact</Button>
      </Flex>
    </View>
  );
};