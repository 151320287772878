import moment from 'moment';
import { Items } from '../../../services/soap/project/responses/ListFPAResponse';
import { SearchResultItemData } from '../Search/SearchResultData';
import { Context } from '../../../services/soap/project/responses/GetContextPathResponse';
import { ActivityAdvanced } from '../../../services/soap/project/responses/ListActivityAdvancedResponse';
import { ProjectAdvanced } from '../../../services/soap/project/responses/ListProjectAdvancedResponse';

export enum FPADataTypes {
  NONE = 'none',
  TOP = 'top',
  GROUP = 'group',
  FOLDER_TYPE = 'folder_type',
  ITEM_TYPE = 'item_type',
  FOLDER = 'folder',
  PROJECT = 'project',
  ACTIVITY = 'activity',
}

export class FPASystemTypes {
  public static FOLDER_CONTACT = 1000;
  public static FOLDER_USER = 1500;
  public static FOLDER_ASSET = 2000;
  public static FOLDER_SIMPLE = 3000;

  public static PROJECT_MASTER = 4000;
  public static PROJECT_MASTER_B2C = 4001;
  public static PROJECT_MASTER_B2B = 4002;
  public static PROJECT_SLAVE = 5000;
  public static PROJECT_SIMPLE = 6000;

  public static ACTIVITY_SIMPLE = 7000;
  public static ACTIVITY_MILESTONE = 8000;
  public static ACTIVITY_MASTER = 9000;
  public static ACTIVITY_SLAVE = 9500;
}

export function getMainTypeFromSystemType(type: number): string {
  switch (type) {
    case FPASystemTypes.FOLDER_CONTACT:
    case FPASystemTypes.FOLDER_USER:
    case FPASystemTypes.FOLDER_ASSET:
    case FPASystemTypes.FOLDER_SIMPLE:
      return 'FOLDER';
    case FPASystemTypes.PROJECT_MASTER:
    case FPASystemTypes.PROJECT_MASTER_B2C:
    case FPASystemTypes.PROJECT_MASTER_B2B:
    case FPASystemTypes.PROJECT_SLAVE:
    case FPASystemTypes.PROJECT_SIMPLE:
      return 'PROJECT';
    case FPASystemTypes.ACTIVITY_SIMPLE:
    case FPASystemTypes.ACTIVITY_MILESTONE:
    case FPASystemTypes.ACTIVITY_MASTER:
    case FPASystemTypes.ACTIVITY_SLAVE:
      return 'ACTIVITY';
    default:
      return 'NONE';
  }
}
export class FPAData {
  public id: number;
  public parent_id?: number;
  public parent_type?: FPADataTypes;
  public parent?: FPAData;
  public title: string;
  public count: number;
  public type: FPADataTypes;
  public line1: string;
  public line2: string;
  public statusName: string;
  public statusColor: string;
  public image: string;
  public item_type_id: number = 0;
  public item_type_name: string = '';
  public system_type: number = 0;
  public isOpen: boolean = false;
  public isFiltered: boolean = false;
  public isSelected: boolean = false;
  public isSearching: boolean = false;
  public page_offset: number = 0;
  public page_limit: number = 50;
  public node_level: number = 0;

  constructor(
    type: FPADataTypes = FPADataTypes.NONE,
    id: number = 0,
    title: string = '',
    line1: string = '',
    line2: string = '',
    count: number = 0,
    statusName: string = '',
    statusColor: string = 'black',
    image: string = '',
    item_type_id: number = 0,
    item_type_name: string = '',
    parent_id?: number,
    parent_type?: FPADataTypes,
    parent?: FPAData,
    level: number = -1,
    system_type: number = 0
  ) {
    this.id = id;
    this.title = title;
    this.count = count;
    this.type = type;
    this.line1 = line1;
    this.line2 = line2;
    this.statusName = statusName;
    this.statusColor = statusColor;
    this.image = image;
    this.item_type_id = item_type_id;
    this.item_type_name = item_type_name;
    this.parent_id = parent_id;
    this.parent_type = parent_type;
    this.parent = parent;
    this.isOpen = false;
    this.isFiltered = false;
    this.isSelected = false;
    this.isSearching = false;
    this.node_level = level;
    this.system_type = system_type;
  }

  toString(): string {
    return `${this.title} - ${this.type}`;
  }
}

export class FPADataFactory {
  public static create(
    type: FPADataTypes,
    id: number,
    title: string,
    line1: string,
    line2: string,
    count: number,
    statusName: string,
    statusColor: string,
    image: string,
    item_type_id: number = 0,
    item_type_name: string = '',
    parent_id?: number,
    parent_type?: FPADataTypes,
    system_type: number = 0
  ): FPAData {
    return new FPAData(type, id, title, line1, line2, count, statusName, statusColor, image, item_type_id, item_type_name, parent_id, parent_type, undefined, -1, system_type);
  }
  public static createTop(
    id: number,
    title: string,
    line1: string,
    line2: string,
    count: number,
    statusName: string,
    statusColor: string,
    image: string,
    item_type_id: number,
    item_type_name: string,
    system_type: number = 0
  ): FPAData {
    return new FPAData(FPADataTypes.TOP, id, title, line1, line2, count, statusName, statusColor, image, item_type_id, item_type_name, undefined, undefined, undefined, -1, system_type);
  }
  public static createGroup(
    id: number,
    title: string,
    line1: string,
    line2: string,
    count: number,
    statusName: string,
    statusColor: string,
    image: string,
    item_type_id: number,
    item_type_name: string,
    system_type: number = 0
  ): FPAData {
    return new FPAData(FPADataTypes.GROUP, id, title, line1, line2, count, statusName, statusColor, image, item_type_id, item_type_name, undefined, undefined, undefined, -1, system_type);
  }
  public static createFolderType(
    id: number,
    title: string,
    line1: string,
    line2: string,
    count: number,
    statusName: string,
    statusColor: string,
    image: string,
    item_type_id: number,
    item_type_name: string,
    system_type: number = 0
  ): FPAData {
    return new FPAData(FPADataTypes.FOLDER_TYPE, id, title, line1, line2, count, statusName, statusColor, image, item_type_id, item_type_name, undefined, undefined, undefined, -1, system_type);
  }
  public static createFolder(
    id: number,
    title: string,
    line1: string,
    line2: string,
    count: number,
    statusName: string,
    statusColor: string,
    image: string,
    item_type_id: number,
    item_type_name: string,
    parent_id?: number,
    parent_type?: FPADataTypes,
    parent?: FPAData,
    level: number = -1,
    system_type: number = 0
  ): FPAData {
    return new FPAData(FPADataTypes.FOLDER, id, title, line1, line2, count, statusName, statusColor, image, item_type_id, item_type_name, parent_id, parent_type, parent, level, system_type);
  }
  public static createProject(
    id: number,
    title: string,
    line1: string,
    line2: string,
    count: number,
    statusName: string,
    statusColor: string,
    image: string,
    item_type_id: number,
    item_type_name: string,
    parent_id?: number,
    parent_type?: FPADataTypes,
    parent?: FPAData,
    level: number = -1,
    system_type: number = 0
  ): FPAData {
    return new FPAData(FPADataTypes.PROJECT, id, title, line1, line2, count, statusName, statusColor, image, item_type_id, item_type_name, parent_id, parent_type, parent, level, system_type);
  }
  public static createActivity(
    id: number,
    title: string,
    line1: string,
    line2: string,
    count: number,
    statusName: string,
    statusColor: string,
    image: string,
    item_type_id: number,
    item_type_name: string,
    parent_id?: number,
    parent_type?: FPADataTypes,
    parent?: FPAData,
    level: number = -1,
    system_type: number = 0
  ): FPAData {
    return new FPAData(FPADataTypes.ACTIVITY, id, title, line1, line2, count, statusName, statusColor, image, item_type_id, item_type_name, parent_id, parent_type, parent, level, system_type);
  }
  public static createNone(): FPAData {
    return new FPAData(FPADataTypes.NONE);
  }

  public static createFromContextItem(item: Context, folderStatusMapping: any = {}, projectStatusMapping: any = {}, activityStatusMapping: any = {}): FPAData {
    switch (+item.systemType) {
      case FPASystemTypes.FOLDER_ASSET:
      case FPASystemTypes.FOLDER_CONTACT:
      case FPASystemTypes.FOLDER_SIMPLE:
      case FPASystemTypes.FOLDER_USER:
        return FPADataFactory.createFolder(
          item.id,
          item.name,
          `${item.typeName} ${item.refId ? `(${item.refId})` : ''}`,
          moment(item.created).format('DD.MM.YYYY'),
          item.subContextsCount,
          folderStatusMapping[item.customState]?.name,
          '#' + (+folderStatusMapping[item.customState]?.color).toString(16).split('').reverse().join(''),
          '/assets/icons/group-4022.svg',
          item.type,
          item.typeName,
          item.type,
          FPADataTypes.FOLDER_TYPE,
          undefined,
          -1,
          +item.systemType
        );

      case FPASystemTypes.PROJECT_MASTER:
      case FPASystemTypes.PROJECT_MASTER_B2B:
      case FPASystemTypes.PROJECT_MASTER_B2C:
      case FPASystemTypes.PROJECT_SIMPLE:
      case FPASystemTypes.PROJECT_SLAVE:
        return FPADataFactory.createProject(
          item.id,
          item.name,
          `${item.typeName} ${item.refId ? `(${item.refId})` : ''}`,
          moment(item.created).format('DD.MM.YYYY'),
          item.subContextsCount,
          projectStatusMapping[item.customState]?.name,
          '#' + (+projectStatusMapping[item.customState]?.color).toString(16).split('').reverse().join(''),
          '/assets/icons/group-403.svg',
          item.type,
          item.typeName,
          item.parentId,
          FPADataTypes.FOLDER,
          undefined,
          -1,
          +item.systemType
        );
      case FPASystemTypes.ACTIVITY_SIMPLE:
      case FPASystemTypes.ACTIVITY_MASTER:
      case FPASystemTypes.ACTIVITY_MILESTONE:
      case FPASystemTypes.ACTIVITY_SLAVE:
        return FPADataFactory.createActivity(
          item.id,
          item.name,
          `${item.typeName} ${item.refId ? `(${item.refId})` : ''}`,
          moment(item.created).format('DD.MM.YYYY'),
          item.subContextsCount,
          activityStatusMapping[item.customState]?.name,
          '#' + (+activityStatusMapping[item.customState]?.color).toString(16).split('').reverse().join(''),
          '/assets/icons/group-402.svg',
          item.type,
          item.typeName,
          item.parentId,
          FPADataTypes.PROJECT,
          undefined,
          -1,
          +item.systemType
        );
      default:
        return FPADataFactory.createNone();
    }
  }
  public static createFromSearchItem(item: SearchResultItemData, parent_id: number, parent_type: FPADataTypes): FPAData {
    switch (item.itemType) {
      case 'FOLDER':
        return FPADataFactory.createFolder(
          item.itemId!,
          item.title,
          item.subtitle,
          '',
          item.count,
          item.stateName!,
          item.stateColor!,
          item.image,
          item.contextTypeId,
          item.contextTypeName,
          parent_id,
          parent_type
        );
      case 'PROJECT':
        return FPADataFactory.createProject(
          item.itemId!,
          item.title,
          item.subtitle,
          '',
          item.count,
          item.stateName!,
          item.stateColor!,
          item.image,
          item.contextTypeId,
          item.contextTypeName,
          parent_id,
          parent_type
        );
      case 'ACTIVITY':
        return FPADataFactory.createActivity(
          item.itemId!,
          item.title,
          item.subtitle,
          '',
          item.count,
          item.stateName!,
          item.stateColor!,
          item.image,
          item.contextTypeId,
          item.contextTypeName,
          parent_id,
          parent_type
        );
      default:
        return FPADataFactory.createNone();
    }
  }

  public static createFromItem(
    item: Items,
    folderStatusMapping: any,
    projectStatusMapping: any,
    activityStatusMapping: any,
    parent_id?: number,
    parent_type?: FPADataTypes,
    parent?: FPAData,
    level: number = -1,
    system_type: number = 0
  ): FPAData {
    var padding = '';

    if (item.ITEM.ACTIVITY) {
      var activityColor = (+activityStatusMapping[item.ITEM.ACTIVITY!.customState]?.color).toString(16).split('').reverse().join('');
      padding =
        activityColor.length < 6
          ? Array(6 - activityColor.length)
              .fill('0')
              .join('')
          : '';

      return FPADataFactory.createActivity(
        item.ITEM.ACTIVITY.id,
        item.ITEM.ACTIVITY.name,
        `${item.ITEM.ACTIVITY.activityTypeName} ${item.ITEM.ACTIVITY.refId ? `(${item.ITEM.ACTIVITY.refId})` : ''}`,
        moment(item.ITEM.ACTIVITY.created).format('DD.MM.YYYY'),
        item.ITEM.subitems,
        item.ITEM.ACTIVITY.customStateName,
        `#${activityColor}${padding}`,
        '/assets/icons/group-4022.svg',
        item.ITEM.ACTIVITY.activityType,
        item.ITEM.ACTIVITY.activityTypeName,
        parent_id,
        parent_type,
        parent,
        level,
        system_type
      );
    }

    if (item.ITEM.PROJECT) {
      var projectColor = (+projectStatusMapping[item.ITEM.PROJECT!.customState]?.color).toString(16).split('').reverse().join('');
      padding =
        projectColor.length < 6
          ? Array(6 - projectColor.length)
              .fill('0')
              .join('')
          : '';

      if(!parent_id){
        parent_id = item.ITEM.PROJECT.contextParent;
      }
      if(!parent){
        parent = FPADataFactory.createFolder( item.ITEM.PROJECT.contextParent, item.ITEM.PROJECT.contextParentName, '', '', 0, '', '', '', 0, '', undefined, FPADataTypes.FOLDER);
      }
      return FPADataFactory.createProject(
        item.ITEM.PROJECT.id,
        item.ITEM.PROJECT.name,
        `${item.ITEM.PROJECT.projectTypeName} ${item.ITEM.PROJECT.refId ? `(${item.ITEM.PROJECT.refId})` : ''}`,
        moment(item.ITEM.PROJECT.created).format('DD.MM.YYYY'),
        item.ITEM.subitems,
        item.ITEM.PROJECT.customStateName,
        `#${projectColor}${padding}`,
        '/assets/icons/group-403.svg',
        +item.ITEM.PROJECT.projectType,
        item.ITEM.PROJECT.projectTypeName,
        parent_id,
        parent_type,
        parent,
        level,
        system_type
      );
    }

    if (item.ITEM.FOLDER) {
      var folderColor = (+folderStatusMapping[item.ITEM.FOLDER!.customState]?.color).toString(16).split('').reverse().join('');
      padding =
        folderColor.length < 6
          ? Array(6 - folderColor.length)
              .fill('0')
              .join('')
          : '';

      return FPADataFactory.createFolder(
        item.ITEM.FOLDER.id,
        item.ITEM.FOLDER.name,
        `${item.ITEM.FOLDER.folderTypeName} ${item.ITEM.FOLDER.referenceId ? `(${item.ITEM.FOLDER.referenceId})` : ''}`,
        moment(item.ITEM.FOLDER.created).format('DD.MM.YYYY'),
        item.ITEM.subitems,
        item.ITEM.FOLDER.customStateName,
        `#${folderColor}${padding}`,
        '/assets/icons/group-402.svg',
        +item.ITEM.FOLDER.folderType,
        item.ITEM.FOLDER.folderTypeName,
        parent_id,
        parent_type,
        parent,
        level,
        system_type
      );
    }
    return FPADataFactory.createNone();
  }

  public static createFromActivityAdvanced(item: ActivityAdvanced, activityStatusMapping: any, parent_type?: FPADataTypes, parent?: any, system_type: number = 0): FPAData {
    var padding = '';
    var activityColor = (+activityStatusMapping[item.customState]?.color).toString(16).split('').reverse().join('');
    padding =
      activityColor.length < 6
        ? Array(6 - activityColor.length)
            .fill('0')
            .join('')
        : '';

    return FPADataFactory.createActivity(
      item.id,
      item.name,
      `${item.typeName} ${item.refId ? `(${item.refId})` : ''}`,
      moment(item.created).format('DD.MM.YYYY'),
      0,
      item.customStateName,
      `#${activityColor}${padding}`,
      '/assets/icons/group-4022.svg',
      item.type,
      item.typeName,
      +item.project,
      parent_type,
      parent,
      -1,
      system_type
    );
  }

  public static createFromProjectAdvanced(item: ProjectAdvanced, projectStatusMapping: any, parent_type?: FPADataTypes, parent?: FPAData, system_type: number = 0): FPAData {
    var padding = '';
    var projectColor = (+projectStatusMapping[item.customState]?.color).toString(16).split('').reverse().join('');
    padding =
      projectColor.length < 6
        ? Array(6 - projectColor.length)
            .fill('0')
            .join('')
        : '';

    return FPADataFactory.createProject(
      item.id,
      item.name,
      `${item.projectType} ${item.refId ? `(${item.refId})` : ''}`,
      moment(item.created).format('DD.MM.YYYY'),
      0,
      item.customStateName,
      `#${projectColor}${padding}`,
      '/assets/icons/group-403.svg',
      item.projectType,
      item.projectTypeName,
      +item.folder,
      parent_type,
      parent,
      -1,
      system_type
    );
  }
}
