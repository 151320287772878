import React, { useEffect, useState } from "react";
import { ComboBox, Item } from "@adobe/react-spectrum";
import { useAsyncList } from "@react-stately/data";

// Define TypeScript types
interface Company {
    ic: string;
    nazev: string;
    ulice: string;
    mesto: string;
    psc: string;
    stat: string;
    platceDph: boolean;
    dic: string;
}

interface ICompanyNameDropDownProps{
    onChange:(data:Company)=> void;
    validationErrors: any
    textValue:string
}

const CompanyNameDropDown:React.FC<ICompanyNameDropDownProps> = ({onChange,validationErrors,textValue})=> {
    const [selectedKey, setSelectedKey] = useState<string | null>(null);
    const [inputValue, setInputValue] = useState("");
    // const handleSelectionChange = (key:any) => {
    //     setSelectedKey(key);
    //     if (key) {
    //       const selectedCompany = list.items.find((x) => x.ic === key);
    //       if (selectedCompany) {
    //         onChange(selectedCompany);
    //       }
    //     }
    //   };

    //   const handleOnBlur = ()=>{
    //      onBlur(list.filterText)
    //   }
    useEffect(()=>{
      setInputValue(textValue)
    },[textValue])
    const handleSelectionChange = (key: any) => {
      setSelectedKey(key);
      if (key) {
        const selectedCompany = list.items.find((x) => x.ic === key);
        if (selectedCompany) {
          setInputValue(selectedCompany.nazev);
          onChange(selectedCompany);
        }
      }
    };
  
    const handleInputChange = (value: string) => {
      setInputValue(value);
      setSelectedKey(null);
      list.setFilterText(value);
    };
  
    const handleOnBlur = () => {
      if (!selectedKey) {
        const updatedValue:Company = {
          ic:'',
          nazev : inputValue,
          ulice: '',
          mesto: '',
          psc: '',
          stat: '',
          platceDph: false,
          dic: ''
        }
        onChange(updatedValue);
      }
    };

  let list = useAsyncList<Company>({
    async load({ filterText }) {
      if (!filterText || filterText.length < 3) return { items: [] };

      const response = await fetch(
        `https://atollon.flexibee.eu/remote/suggest/?country=cz&format=json&orgType=all&q=${filterText}`
      );
      const data = await response.json();
      const adresar = data?.winstrom?.adresar || [];

      return {
        items: adresar.map((item: any) => ({
          ic: item.ic.toString(),
          nazev: item.nazev,
          ulice:item.ulice,
          mesto:item.mesto,
          psc:item.psc,
          stat:item.stat,
          platceDph:item.platceDph,
          dic:item.dic
        })),
      };
    },
  });

  return (
    <ComboBox
        label="Company Name"
        items={list.items}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        loadingState={list.loadingState}
        onLoadMore={list.loadMore}
        onSelectionChange={handleSelectionChange}
        validationState={validationErrors.name1 ? 'invalid' : 'valid'}
        errorMessage={validationErrors.name1}
        width="100%"
        selectedKey={selectedKey}
        isRequired
        onBlur={handleOnBlur}
        allowsCustomValue
        >
      {(item) => <Item key={item.ic}>{item.nazev}</Item>}
    </ComboBox>
  );
}

export default CompanyNameDropDown