import { useCallback, useEffect, useRef, useState } from 'react';
import { useDependency } from '../../../../contexts/DependencyProvider';
import { useDebounce } from '@uidotdev/usehooks';
import { Form, Flex, View } from '@adobe/react-spectrum';
import moment from 'moment';
import { AutoCompleteComponent, DropDownListComponent, MultiSelectComponent, CheckBoxSelection, Inject, ComboBoxComponent } from '@syncfusion/ej2-react-dropdowns';
import { useTranslation } from 'react-i18next';
import Loader from '../../../../components/ui/Loader';
import CustomDialogComponent from '../../../../components/ui/CustomDialogComponent';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { GetContactListRequest } from '../../../../services/soap/main/requests/GetContactListRequest';
import { ListStatusRequest, ListStatusType } from '../../../../services/soap/project/requests/ListStatusRequest';
import { ListProjectTypeRequest } from '../../../../services/soap/project/requests/ListProjectTypeRequest';
import { ListUserRequest } from '../../../../services/soap/access/requests/ListUserRequest';
import styles from './RecrutimentDashboard.module.css';
import { UpdateRegisterRequest } from '../../../../services/soap/features/requests/UpdateRegisterRequest';
import { ListRegisterUtils } from '../../../../utils/ListRegisterUtils';
import { ListRegisterRequest } from '../../../../services/soap/features/requests/ListRegisterRequest';

type FilterDialogTemplateProps = {
  updateFilterDialogState: (newState: boolean, reloadKanban: boolean, reqParams: object, filtersSetting: any) => void;
  dialogVisibility: boolean;
  _filtersSetting: any;
  projectStatusList: any;
};

type typefilterOptions = {
  vacancyTypeReef: string | null;
  vacancyInitialReef: boolean;
  vacancyActiveReef: boolean;
  vacancyClosedReef: boolean;
  vacancyStatusReef: string[];
  clientStatusReef: string[];
  stateInitialReef: boolean;
  stateActiveReef: boolean;
  stateClosedReef: boolean;
  contactPersonIDReef: string | null;
  contactPersonReef: string | null;
  responsibleUserReef: string | null;
  selectedColumnsReef: string[];
};

const FilterDialogTemplate = ({ updateFilterDialogState, dialogVisibility = false, _filtersSetting, projectStatusList }: FilterDialogTemplateProps): JSX.Element => {
  const contactPersonRef = useRef<AutoCompleteComponent>(null);
  const ddlRespUserRef: any = useRef(null);
  const { t } = useTranslation();

  const [contactLoaidng, setContactLoaidng] = useState<boolean>(false);
  const [filtersSetting, setFiltersSetting] = useState<any>(_filtersSetting);
  const { projectService, store, mainService, accessService, featureService } = useDependency();
  const [filterOptions, setFilterOptions] = useState<typefilterOptions>({
    vacancyTypeReef: null,
    vacancyInitialReef: true,
    vacancyActiveReef: true,
    vacancyClosedReef: false,
    vacancyStatusReef: [],
    clientStatusReef: [],
    stateInitialReef: true,
    stateActiveReef: true,
    stateClosedReef: false,
    contactPersonIDReef: null,
    contactPersonReef: null,
    responsibleUserReef: null,
    selectedColumnsReef: [],
  });
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [visibility, setVisibility] = useState<boolean>(dialogVisibility);
  const [vacancyType, setVacancyType] = useState<any>(() => JSON.parse(localStorage.getItem('rec_filter_vacancy_type') ?? '[]'));
  const [vacancyStatus, setVacancyStatus] = useState<any>(() => JSON.parse(localStorage.getItem('rec_filter_vacancy_status') ?? '[]'));
  const [clientStatus, setClientStatus] = useState<any>(() => JSON.parse(localStorage.getItem('rec_filter_client_status') ?? '[]'));
  const [respUsers, setRespUsers] = useState<any>(() => JSON.parse(localStorage.getItem('rec_filter_resp_users') ?? '[]'));
  const [respUsersCount, setRespUsersCount] = useState<any>(() => JSON.parse(localStorage.getItem('resp_user_count_res') ?? '0'));
  const [contactList, setContactList] = useState<any>(() => JSON.parse(localStorage.getItem('rec_filter_contact_list') ?? '[]'));
  const [filterTerm, setFilterTerm] = useState<string>('');
  const debouncedFilterTerm = useDebounce(filterTerm, 1000);
  let filters_temp: any = null;
  const filter_calls = [{ moduleId: ListRegisterUtils.Modules.RctDashboard, key: ListRegisterUtils.Keys.RctDashboard.FilterSettings, level: ListRegisterUtils.Levels.RctDashboard.Default }];

  useEffect(() => {
    //console.log('projectStatusList', projectStatusList);
    setShowLoader(true);
    (async () => {
      //console.log('filtersSetting', filtersSetting);
      if (filtersSetting === null || filtersSetting === undefined) {
        for (let call of filter_calls) {
          const { count, ROWS } = await featureService.listRegister(new ListRegisterRequest(store.Server, store.SessionId, call.moduleId, call.key, 1, call.level));
          if (count > 0) {
            filters_temp = { [`${call.moduleId}_${call.key}`]: ROWS[0].ROW };
            //console.log('filters_temp', filters_temp);
          }
        }
      } else {
        filters_temp = filtersSetting;
      }
      if (filters_temp !== undefined && filters_temp !== null) {
        //console.log("typeof filters_temp === 'string'", typeof filters_temp === 'string');
        if (typeof filters_temp === 'string') {
          filters_temp = JSON.parse(filters_temp);
        }

        let objectKey = `${ListRegisterUtils.Modules.RctDashboard}_${ListRegisterUtils.Keys.RctDashboard.FilterSettings}`;
        //console.log('filterOptions filters_temp', filters_temp);
        //console.log('filters_temp[objectKey]', filters_temp[objectKey]);
        if (filters_temp[objectKey]) {
          const filterOptions = filters_temp[objectKey].value ? JSON.parse(filters_temp[objectKey].value) : null;
          setFiltersSetting(filters_temp);
          setFilterOptions(filterOptions);
        }
      }

      await loadControlsData();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (debouncedFilterTerm) {
        setContactLoaidng(true);
        if (contactPersonRef.current) contactPersonRef.current.showSpinner();
        let contact_res: any = await mainService.getContactList(
          new GetContactListRequest(store.Server, store.SessionId, {
            offset: 0,
            limit: 20,
            contains: '*' + filterTerm,
          })
        );
        //('contactRepsoe', contact_res);
        if (contact_res && contact_res?.CONTACTS?.length) {
          const contacts = contact_res?.CONTACTS?.map((item: any) => item.CONTACT);
          //console.log(contacts);
          let newContactArray: { [key: string]: Object }[] = [];
          contacts.forEach((item: any) =>
            newContactArray.push({
              id: item.id,
              name: `${item.name1} ${!!item.name3 && item?.name3 !== undefined ? item.name3 : ''}`,
              phone: item.phone,
              email: item.email,
              modified: moment(item.modified).format('MM/DD/YYYY'),
            })
          );
          //console.log('newContactArray', newContactArray);
          //localStorage.setItem('rec_filter_contact_list', JSON.stringify(newContactArray));
          setContactList(newContactArray);
        } else {
          if (contactPersonRef.current) contactPersonRef.current.clear();
        }
        setContactLoaidng(false);
        if (contactPersonRef.current) contactPersonRef.current.hideSpinner();
        contactPersonRef.current?.showPopup();
      }
    })();
  }, [debouncedFilterTerm]);

  const loadControlsData = useCallback(async () => {
    try {
      let serviceCalls = [];

      if (!vacancyType.length || !vacancyStatus.length || !respUsers.length || !clientStatus.length) {
        serviceCalls.push(projectService.listProjectType(new ListProjectTypeRequest(store.Server, store.SessionId, 'com.atollon.project.fpatypecategory.recruitment.vacancy')));

        serviceCalls.push(projectService.listProjectStatus(new ListStatusRequest(store.Server, store.SessionId, ListStatusType.Project, 'com.atollon.project.fpatypecategory.recruitment.vacancy')));

        serviceCalls.push(accessService.listUser(new ListUserRequest(store.Server, store.SessionId, true, 200, 0, undefined, undefined, undefined, undefined, false, false, undefined)));

        serviceCalls.push(projectService.listFolderStatus(new ListStatusRequest(store.Server, store.SessionId, ListStatusType.Folder, 'com.atollon.project.fpatypecategory.client')));

        serviceCalls.push(accessService.listUser(new ListUserRequest(store.Server, store.SessionId, undefined, undefined, undefined, undefined, true, undefined, undefined, false, false, undefined)));
      }

      const res = serviceCalls.length ? await Promise.all(serviceCalls) : [];

      //let contact_res: any = res[0];
      let vacancy_type_res: any = res[0];
      let vacancy_status_res: any = res[1];
      let resp_user_res: any = res[2];
      let client_status_res: any = res[3];
      let resp_user_count_res: any = res[4];

      //('contact_res', contact_res);
      // if (contact_res && contact_res.result === 'OK') {
      //   const contacts = contact_res.CONTACTS.map((item: any) => item.CONTACT);
      //   //console.log(contacts);
      //   let newContactArray: { [key: string]: Object }[] = [];
      //   contacts.forEach((item: any) =>
      //     newContactArray.push({
      //       id: item.id,
      //       name: `${item.name1} ${!!item.name3 && item?.name3 !== undefined ? item.name3 : ''}`,
      //       phone: item.phone,
      //       email: item.email,
      //       modified: moment(item.modified).format('MM/DD/YYYY'),
      //     })
      //   );
      //   //console.log('newContactArray', newContactArray);
      //   localStorage.setItem('rec_filter_contact_list', JSON.stringify(newContactArray));
      //   setContactList(newContactArray);
      // }

      //console.log('vacancy_type_res', vacancy_type_res);
      if (vacancy_type_res && vacancy_type_res.result === 'OK' && vacancy_type_res?.TYPE_LIST) {
        const vacancy_type = vacancy_type_res.TYPE_LIST;
        //console.log('vacancy_type', vacancy_type);
        let newVacancyTypeArray: { [key: string]: Object }[] = [];

        if (Array.isArray(vacancy_type)) {
          vacancy_type.forEach((item: any) =>
            newVacancyTypeArray.push({
              id: `${item.TYPE.id}`,
              name: `${item.TYPE.name}`,
            })
          );
        } else {
          newVacancyTypeArray = [{ id: `${vacancy_type.TYPE.id}`, name: `${vacancy_type.TYPE.name}` }];
        }

        localStorage.setItem('rec_filter_vacancy_type', JSON.stringify(newVacancyTypeArray));
        setVacancyType(newVacancyTypeArray);
      }

      //console.log('vacancy_status_res', vacancy_status_res);
      if (vacancy_status_res && vacancy_status_res.result === 'OK' && vacancy_status_res?.STATUS_LIST) {
        const vacancy_status = vacancy_status_res.STATUS_LIST;
        //console.log(vacancy_status);
        let newVacancyStatusArray: { [key: string]: Object }[] = [];
        vacancy_status.forEach((item: any) =>
          newVacancyStatusArray.push({
            id: `${item.STATUS.id}`,
            name: `${item.STATUS.name}`,
          })
        );
        localStorage.setItem('rec_filter_vacancy_status', JSON.stringify(newVacancyStatusArray));
        setVacancyStatus(newVacancyStatusArray);
      }

      //console.log('resp_user_res', resp_user_res);
      if (resp_user_res && resp_user_res.result === 'OK' && resp_user_res?.USERS) {
        const resp_users = resp_user_res.USERS.map((item: any) => item.USER);
        //console.log(resp_users);
        let newRespUserArray: { [key: string]: Object }[] = [];
        resp_users.forEach((item: any) =>
          newRespUserArray.push({
            id: item.id,
            name: `${item.name1} ${!!item.name3 && item?.name3 !== undefined ? item.name3 : ''}`,
          })
        );
        localStorage.setItem('rec_filter_resp_users', JSON.stringify(newRespUserArray));
        localStorage.setItem('resp_user_count_res', resp_user_count_res.count);
        setRespUsers(newRespUserArray);
        setRespUsersCount(resp_user_count_res.count);
      }

      //console.log('client_status_res', client_status_res);
      if (client_status_res && client_status_res.result === 'OK' && client_status_res?.STATUS_LIST) {
        const client_status_list = client_status_res.STATUS_LIST.map((item: any) => item.STATUS);
        //console.log(client_status_list);
        let newClientStatusArray: { [key: string]: Object }[] = [];
        client_status_list.forEach((item: any) =>
          newClientStatusArray.push({
            id: item.id,
            name: item.name,
          })
        );
        localStorage.setItem('rec_filter_client_status', JSON.stringify(newClientStatusArray));
        setClientStatus(newClientStatusArray);
      }
    } finally {
      //console.log('finally');
      setShowLoader(false);
    }
  }, []);

  const buttons: any = [
    {
      buttonModel: {
        content: t('default', { ns: 'recruitment' }),
        cssClass: 'e-flat-default float-left',
      },
      click: async () => {
        await reset();
      },
    },
    {
      buttonModel: {
        content: t('cancel', { ns: 'recruitment' }),
        cssClass: 'e-flat-cancel',
      },
      click: () => {
        updateFilterDialogState(false, false, {}, filtersSetting);
        setVisibility(false);
      },
    },
    {
      buttonModel: {
        content: t('apply', { ns: 'recruitment' }),
        cssClass: 'e-flat-apply',
        isPrimary: true,
      },
      click: () => {
        handleSubmit();
      },
    },
  ];

  const handleClose = async (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Escape') {
      updateFilterDialogState(false, false, {}, filtersSetting);
      setVisibility(false);
      return;
    }
  };

  const handleChange = (value: any, name: keyof typefilterOptions, controlType: string, controlTypeID: string = '') => {
    //console.log('value', value.value);

    switch (controlType) {
      case 'TextBox':
      case 'DropDown':
        setFilterOptions({
          ...filterOptions,
          [name]: value.value ? value.value : null,
        });
        break;
      case 'CheckBox':
        setFilterOptions({
          ...filterOptions,
          [name]: value.checked ? value.checked : false,
        });
        break;
      case 'AutoComplete':
        if (contactPersonRef.current) {
          contactPersonRef.current.hideSpinner();
        }
        setFilterOptions({
          ...filterOptions,
          [name]: value.itemData ? value.itemData?.name : null,
          [controlTypeID]: value.itemData ? value.itemData?.id : null,
        });
        break;

      case 'CheckBoxList':
        setFilterOptions({
          ...filterOptions,
          [name]: value.value,
        });
        break;
      default:
        break;
    }

    //console.log(filterOptions);
  };

  const handleSubmit = async () => {
    localStorage.removeItem('rec_search_value');

    let objParam: any = {
      listMasterFilter: {
        limit: 20,
        offset: 0,
        masterProjectCategory: 'com.atollon.project.fpatypecategory.recruitment.vacancy',
        masterFolderCategory: 'com.atollon.project.fpatypecategory.recruitment.client',
        masterFolderRespUserId: 'Responsible User',
        masterFolderCustomStateIdList: 'Client Status',
        onlyPrimaryMasterFolders: 1,
        FILTER_MASTER_PROJECT: {
          COL: [
            {
              name: 'typeId',
              type: 'equals',
              value: 'Vacancy Type',
            },
            {
              name: 'fixedState',
              type: 'in',
              value: 'Vacancy Status Checkbox',
            },
            {
              name: 'customStateId',
              type: 'in',
              value: ['Vacancy Status Dropdown'],
            },
            {
              name: 'respUserId',
              type: 'equals',
              value: 'Responsible User',
            },
            {
              name: 'externalContactId',
              type: 'equals',
              value: 'Contact Person',
            },
          ],
        },
        FILTER_SLAVE_PROJECT: {
          COL: {
            name: 'fixedState',
            type: 'in',
            value: 'show applicaion with selection',
          },
        },
      },
    };

    if (filterOptions.vacancyTypeReef && filterOptions.vacancyTypeReef !== '') {
      objParam.listMasterFilter.FILTER_MASTER_PROJECT.COL.forEach((item: any) => {
        if (item.name === 'typeId') {
          item.value = filterOptions.vacancyTypeReef;
        }
      });
    } else {
      objParam.listMasterFilter.FILTER_MASTER_PROJECT.COL = objParam.listMasterFilter.FILTER_MASTER_PROJECT.COL.filter((item: any) => item.name !== 'typeId');
    }

    let vacancyStausValues = '';
    if (filterOptions.vacancyInitialReef) {
      vacancyStausValues += '1';
    }
    if (filterOptions.vacancyActiveReef) {
      vacancyStausValues += ',2';
    }
    if (filterOptions.vacancyClosedReef) {
      vacancyStausValues += ',3';
    }
    //Remove , from Start
    vacancyStausValues = vacancyStausValues.replace(/^,/, '');

    if (vacancyStausValues.length > 0) {
      objParam.listMasterFilter.FILTER_MASTER_PROJECT.COL.forEach((item: any) => {
        if (item.name === 'fixedState') {
          item.value = vacancyStausValues;
        }
      });
    } else {
      objParam.listMasterFilter.FILTER_MASTER_PROJECT.COL = objParam.listMasterFilter.FILTER_MASTER_PROJECT.COL.filter((item: any) => item.name !== 'fixedState');
    }

    if (filterOptions?.vacancyStatusReef?.length) {
      objParam.listMasterFilter.FILTER_MASTER_PROJECT.COL.forEach((item: any) => {
        if (item.name === 'customStateId') {
          item.value = filterOptions.vacancyStatusReef.join(', ');
        }
      });
    } else {
      objParam.listMasterFilter.FILTER_MASTER_PROJECT.COL = objParam.listMasterFilter.FILTER_MASTER_PROJECT.COL.filter((item: any) => item.name !== 'customStateId');
    }

    if (filterOptions.responsibleUserReef && filterOptions.responsibleUserReef !== '') {
      objParam.listMasterFilter.masterFolderRespUserId = filterOptions.responsibleUserReef;
      objParam.listMasterFilter.FILTER_MASTER_PROJECT.COL.forEach((item: any) => {
        if (item.name === 'respUserId') {
          item.value = filterOptions.responsibleUserReef !== null ? +filterOptions.responsibleUserReef : null;
        }
      });
    } else {
      delete objParam.listMasterFilter.masterFolderRespUserId;
      objParam.listMasterFilter.FILTER_MASTER_PROJECT.COL = objParam.listMasterFilter.FILTER_MASTER_PROJECT.COL.filter((item: any) => item.name !== 'respUserId');
    }

    if (filterOptions.contactPersonReef && filterOptions.contactPersonReef !== '') {
      objParam.listMasterFilter.FILTER_MASTER_PROJECT.COL.forEach((item: any) => {
        if (item.name === 'externalContactId') {
          item.value = filterOptions.contactPersonIDReef;
        }
      });
    } else {
      objParam.listMasterFilter.FILTER_MASTER_PROJECT.COL = objParam.listMasterFilter.FILTER_MASTER_PROJECT.COL.filter((item: any) => item.name !== 'externalContactId');
    }

    let stateStausValues = '';
    if (filterOptions.stateInitialReef) {
      stateStausValues += '1';
    }

    if (filterOptions.stateActiveReef) {
      stateStausValues += ',2';
    }

    if (filterOptions.stateClosedReef) {
      stateStausValues += ',3';
    }
    //Remove , from Start
    stateStausValues = stateStausValues.replace(/^,/, '');

    if (stateStausValues.length > 0) {
      objParam.listMasterFilter.FILTER_SLAVE_PROJECT.COL.value = stateStausValues;
    } else {
      delete objParam.listMasterFilter.FILTER_SLAVE_PROJECT;
    }

    if (filterOptions?.clientStatusReef?.length) {
      objParam.listMasterFilter.masterFolderCustomStateIdList = filterOptions.clientStatusReef.join(', ');
    } else {
      delete objParam.listMasterFilter.masterFolderCustomStateIdList;
    }

    if (objParam.listMasterFilter.FILTER_MASTER_PROJECT.COL.length === 0) {
      // Remove FILTER_MASTER_PROJECT if COL is empty
      delete objParam.listMasterFilter.FILTER_MASTER_PROJECT;
    }
    //console.log('objParam', objParam);

    await updateRegisters(filter_calls, store, filtersSetting, filterOptions);

    //console.log('filtersSetting FilterDialog', filtersSetting);
    updateFilterDialogState(false, true, objParam, filtersSetting);
    setVisibility(false);
  };

  async function updateRegisters(filter_calls: any[], store: any, filtersSetting: any, filterOptions: any) {
    //console.log('filterOptions', filterOptions);

    var filter_key = `${ListRegisterUtils.Modules.RctDashboard}_${ListRegisterUtils.Keys.RctDashboard.FilterSettings}`;
    //console.log('filtersSetting', filtersSetting[filter_key]);
    if (filtersSetting[filter_key] === undefined) return;
    //console.log('filtersSetting', filtersSetting[filter_key]);
    const filter_settings = filtersSetting[filter_key] ? filtersSetting[filter_key] : {};
    filtersSetting[filter_key].value = JSON.stringify(filterOptions);
    for (let call of filter_calls) {
      let response = await featureService.updateRegister(
        new UpdateRegisterRequest(store.Server, store.SessionId, filter_settings.id, undefined, call.moduleId, undefined, call.key, call.level, JSON.stringify(filterOptions), filter_settings.assignTo)
      );
      console.log('response', response);
    }
  }

  async function reset(): Promise<void> {
    setFilterOptions({
      vacancyTypeReef: null,
      vacancyInitialReef: true,
      vacancyActiveReef: true,
      vacancyClosedReef: false,
      vacancyStatusReef: [],
      clientStatusReef: [],
      stateInitialReef: true,
      stateActiveReef: true,
      stateClosedReef: false,
      contactPersonIDReef: null,
      contactPersonReef: null,
      responsibleUserReef: null,
      selectedColumnsReef: filterOptions.selectedColumnsReef,
    });

    await updateRegisters(filter_calls, store, filtersSetting, filterOptions);

    //localStorage.removeItem('rec_filter_param');
  }

  function contactHeaderTemplate(data: any): JSX.Element {
    return (
      <table className="table table-striped table-hover">
        <thead>
          <tr>
            <th scope="col" style={{ width: '35%' }}>
              Name
            </th>
            <th scope="col" style={{ width: '15%' }}>
              Phone
            </th>
            <th scope="col" style={{ width: '30%' }}>
              E-Mail
            </th>
            <th scope="col" style={{ width: '20%' }}>
              Last Modified
            </th>
          </tr>
        </thead>
      </table>
    );
  }

  function contactItemTemplate(data: any): JSX.Element {
    return (
      <table className="table table-striped table-hover">
        <thead>
          <tr>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td scope="row" style={{ width: '35%' }}>
              {data.name.length > 30 ? data.name.substring(0, 30) + '...' : data.name}
            </td>
            <td scope="row" style={{ width: '15%' }}>
              {data.phone}
            </td>
            <td scope="row" style={{ width: '35%' }}>
              {data?.email?.length > 30 ? data.email.substring(0, 30) + '...' : data.email}
            </td>
            <td scope="row" style={{ width: '15%' }}>
              {data.modified}
            </td>
          </tr>
        </tbody>
      </table>
    );
  }

  function filterDalogheader(): JSX.Element {
    return <b>{t('advance_search_filter', { ns: 'recruitment' })}</b>;
  }

  const handleActionComplete = async (e: any) => {
    if (contactPersonRef.current) {
      //contactPersonRef.current.showPopup();
    }
  };

  // const onOpen = (args: any) => {
  //   var end = 0;
  //   const userCount = respUsers.length ?? 0;
  //   console.log('respUsers.lenght < respUsersCount', userCount, respUsersCount);
  //   if (ddlRespUserRef.current && userCount  < userCount) {
  //     var listElement = ddlRespUserRef.current.list;

  //     listElement.addEventListener('scroll', async () => {
  //       if (listElement.scrollTop + listElement.offsetHeight >= listElement.scrollHeight - 5) {
  //         end += 50;
  //         ddlRespUserRef.current.showSpinner();
  //         if (ddlRespUserRef.current) {
  //           console.log('end', end);
  //           let resp_user_res = await accessService.listUser(
  //             new ListUserRequest(store.Server, store.SessionId, undefined, 50, end, undefined, undefined, undefined, undefined, false, false, undefined)
  //           );
  //           console.log(resp_user_res);
  //           if (+resp_user_res.count > 0) {
  //             const resp_users = resp_user_res.USERS.map((item: any) => item.USER);
  //             //console.log(resp_users);
  //             let newRespUserArray: { [key: string]: Object }[] = respUsers;
  //             resp_users.forEach((item: any) =>
  //               newRespUserArray.push({
  //                 id: item.id,
  //                 name: `${item.name1} ${!!item.name3 && item?.name3 !== undefined ? item.name3 : ''}`,
  //               })
  //             );

  //             resp_users.forEach((item: any) => ddlRespUserRef.current.addItem({ id: item.id, name: `${item.name1} ${!!item.name3 && item?.name3 !== undefined ? item.name3 : ''}` }));

  //             localStorage.setItem('rec_filter_resp_users', JSON.stringify(newRespUserArray));
  //             setRespUsers(newRespUserArray);
  //           }

  //           //ddlRespUserRef.current.addItem(newRespUserArray);
  //         }
  //         ddlRespUserRef.current.hideSpinner();
  //       }
  //     });
  //   }
  // };

  function filterDalogContent(): any {
    //console.log(respUsers);

    const contactPersonFields = { value: 'name' };
    const vacancyTypeFields = { text: 'name', value: 'id' };
    const vacancyStatusFields = { text: 'name', value: 'id' };
    const respUserFields = { text: 'name', value: 'id' };
    const clientStatusFields = { text: 'name', value: 'id' };
    const columnSelection = { text: 'name', value: 'id' };

    return (
      <Form onSubmit={handleSubmit}>
        <Flex gap="size-100" direction={{ base: 'column', L: 'row' }}>
          <View width="130px">
            <b>{t('vacancy_type', { ns: 'recruitment' })}</b>
          </View>
          <View width="size-2500">
            <DropDownListComponent
              name="vacancyTypeReef"
              dataSource={vacancyType}
              fields={vacancyTypeFields}
              value={filterOptions.vacancyTypeReef}
              showClearButton={true}
              placeholder={t('select_vacancy_type', { ns: 'recruitment' })}
              change={arg => handleChange(arg, 'vacancyTypeReef', 'DropDown')}
              allowFiltering={true}
              filterType="Contains"
            />
          </View>
          <View width="size-1400">
            <b>{t('vacancy_status', { ns: 'recruitment' })}</b>
          </View>

          <View width="size-3500">
            <Flex gap="size-200">
              <CheckBoxComponent
                name="vacancyInitialReef"
                checked={filterOptions.vacancyInitialReef}
                label={t('initial', { ns: 'recruitment' })}
                change={arg => handleChange(arg, 'vacancyInitialReef', 'CheckBox')}
              />
              <CheckBoxComponent
                name="vacancyActiveReef"
                checked={filterOptions.vacancyActiveReef}
                label={t('active', { ns: 'recruitment' })}
                change={arg => handleChange(arg, 'vacancyActiveReef', 'CheckBox')}
              />
              <CheckBoxComponent
                name="vacancyClosedReef"
                checked={filterOptions.vacancyClosedReef}
                label={t('closed', { ns: 'recruitment' })}
                change={arg => handleChange(arg, 'vacancyClosedReef', 'CheckBox')}
              />
            </Flex>
          </View>
        </Flex>
        <Flex gap="size-100" direction={{ base: 'column', L: 'row' }}>
          <View width="130px">
            <b>{t('vacancy_status', { ns: 'recruitment' })}</b>
          </View>
          <View width="size-2500">
            <MultiSelectComponent
              name="vacancyStatusReef"
              width={'210px'}
              dataSource={vacancyStatus}
              fields={vacancyStatusFields}
              placeholder={t('select_vacancy_status', { ns: 'recruitment' })}
              value={filterOptions.vacancyStatusReef}
              mode="CheckBox"
              showSelectAll={true}
              selectAllText={t('select_all', { ns: 'recruitment' })}
              unSelectAllText={t('unselect_all', { ns: 'recruitment' })}
              showDropDownIcon={true}
              enableSelectionOrder={true}
              filterBarPlaceholder={t('select_vacancy_status', { ns: 'recruitment' })}
              popupHeight="350px"
              change={arg => handleChange(arg, 'vacancyStatusReef', 'DropDown')}
            >
              <Inject services={[CheckBoxSelection]} />
            </MultiSelectComponent>
          </View>
          <View width="size-4000">
            <b>{t('show_application_with_selection_procedure_states', { ns: 'recruitment' })}</b>
          </View>
        </Flex>
        <Flex gap="size-100" direction={{ base: 'column', L: 'row' }}>
          <View width="130px">
            <b>{t('client_status', { ns: 'recruitment' })}</b>
          </View>
          <View width="size-2500">
            <MultiSelectComponent
              name="clientStatusReef"
              width={'210px'}
              dataSource={clientStatus}
              fields={clientStatusFields}
              placeholder={t('select_client_status', { ns: 'recruitment' })}
              value={filterOptions.clientStatusReef}
              mode="CheckBox"
              showSelectAll={true}
              selectAllText={t('select_all', { ns: 'recruitment' })}
              unSelectAllText={t('unselect_all', { ns: 'recruitment' })}
              showDropDownIcon={true}
              enableSelectionOrder={true}
              filterBarPlaceholder={t('select_client_status', { ns: 'recruitment' })}
              popupHeight="350px"
              change={arg => handleChange(arg, 'clientStatusReef', 'DropDown')}
            >
              <Inject services={[CheckBoxSelection]} />
            </MultiSelectComponent>
          </View>
          <View width="95px"></View>

          <View width="size-3500">
            <Flex gap="size-200">
              <CheckBoxComponent
                name="stateInitialReef"
                checked={filterOptions.stateInitialReef}
                label={t('initial', { ns: 'recruitment' })}
                change={arg => handleChange(arg, 'stateInitialReef', 'CheckBox')}
              />
              <CheckBoxComponent
                name="stateActiveReef"
                checked={filterOptions.stateActiveReef}
                label={t('active', { ns: 'recruitment' })}
                change={arg => handleChange(arg, 'stateActiveReef', 'CheckBox')}
              />
              <CheckBoxComponent
                name="stateClosedReef"
                checked={filterOptions.stateClosedReef}
                label={t('closed', { ns: 'recruitment' })}
                change={arg => handleChange(arg, 'stateClosedReef', 'CheckBox')}
              />
            </Flex>
          </View>
        </Flex>
        <Flex gap="size-100" direction={{ base: 'column', L: 'row' }}>
          <View width="130px">
            <b>{t('contact_person', { ns: 'recruitment' })}</b>
          </View>
          <View width="calc(100% - size-2000)">
            <AutoCompleteComponent
              ref={contactPersonRef}
              id="contactPersonReef"
              readOnly={contactLoaidng}
              highlight={true}
              //query={this.query}
              value={filterOptions.contactPersonReef}
              headerTemplate={contactHeaderTemplate}
              dataSource={contactList}
              //sortOrder={sortOrder}
              itemTemplate={contactItemTemplate}
              fields={contactPersonFields}
              placeholder={t('find_a_contact_person', { ns: 'recruitment' })}
              allowCustom={true}
              autofill={true}
              change={arg => handleChange(arg, 'contactPersonReef', 'AutoComplete', 'contactPersonID')}
              filtering={handleFiltering}
              actionComplete={handleActionComplete}
              showClearButton={true}
              showPopupButton={true}
              noRecordsTemplate={contactLoaidng ? t('loading', { ns: 'recruitment' }) : t('type_to_find_contacts', { ns: 'recruitment' })}
            />
          </View>
        </Flex>
        <Flex gap="size-100" direction={{ base: 'column', L: 'row' }}>
          <View width="130px">
            <b>{t('responsible_user', { ns: 'recruitment' })}</b>
          </View>

          <View width="calc(100% - size-2000)">
            <Flex gap="size-200">
              <DropDownListComponent
                name="responsibleUserReef"
                ref={ddlRespUserRef}
                //open={onOpen}
                dataSource={respUsers}
                fields={respUserFields}
                value={filterOptions.responsibleUserReef}
                showClearButton={true}
                placeholder={t('select_responsible_user', { ns: 'recruitment' })}
                change={arg => handleChange(arg, 'responsibleUserReef', 'DropDown')}
                allowFiltering={true}
                filterType="Contains"
                //popupHeight="250px"
                //allowCustom={true}
              />
            </Flex>
          </View>
        </Flex>
        <Flex gap="size-100" direction={{ base: 'column', L: 'row' }}>
          <View width="130px">
            <b>{t('displayed_column', { ns: 'recruitment' })}</b>
          </View>

          <View width="calc(100% - size-2000)">
            <MultiSelectComponent
              id="checkbox"
              dataSource={projectStatusList}
              fields={columnSelection}
              value={filterOptions.selectedColumnsReef}
              placeholder={t('select_displayed_column', { ns: 'recruitment' })}
              mode="CheckBox"
              selectAllText={t('select_all', { ns: 'recruitment' })}
              unSelectAllText={t('unselect_all', { ns: 'recruitment' })}
              showSelectAll={true}
              showClearButton={false}
              showDropDownIcon={true}
              change={arg => handleChange(arg, 'selectedColumnsReef', 'CheckBoxList')}
            >
              <Inject services={[CheckBoxSelection]} />
            </MultiSelectComponent>
          </View>
        </Flex>
      </Form>
    );
  }

  const closeDialog = () => {
    updateFilterDialogState(false, false, {}, filtersSetting);
    setVisibility(false);
  };

  const handleFiltering = async (e: any) => {
    if (e.text === '') return;
    setFilterTerm(e.text);
  };

  return (
    <span className={styles.overlay} onKeyUp={handleClose}>
      {showLoader ? (
        <Loader />
      ) : (
        <CustomDialogComponent
          width="770px"
          isModal={true}
          buttons={buttons}
          visible={visibility}
          header={filterDalogheader}
          dialogContent={filterDalogContent}
          closeDialog={closeDialog}
        ></CustomDialogComponent>
      )}
    </span>
  );
};

export default FilterDialogTemplate;
