import { BaseRequest, IBaseRequest } from '../../BaseRequest';

export interface IListFolderOrganizationRequest extends IBaseRequest {
    countOnly?: string;
    offset?:    string;
    limit?:     string;
    contactId?: string;
}

export class ListFolderOrganizationRequest extends BaseRequest implements IListFolderOrganizationRequest {
    public countOnly?: string;
    public offset?:    string;
    public limit?:     string;
    public contactId?: string;

    constructor(server: string, session: string, otherOptions: any) {
        super(server, session);
        this.countOnly = otherOptions.countOnly;
        this.offset = otherOptions.offset;
        this.limit = otherOptions.limit;
        this.contactId = otherOptions.contactId;
    }

    public toXml(): string {
        var parameters: string = super.toXml();

        if (this.countOnly) parameters += `<countOnly>${this.countOnly}</countOnly>`;
        if (this.offset) parameters += `<offset>${this.offset}</offset>`;
        if (this.limit) parameters += `<limit>${this.limit}</limit>`;
        if (this.contactId) parameters += `<contactId>${this.contactId}</contactId>`;
        
        return super.wrapperXml('ListFolderOrganization', parameters);
    }
}
