import { BaseRequest, IBaseRequest } from '../../BaseRequest';
import { Filter, toFilterXml } from '../../dataObjects/Filter';
import { Order, toOrderXml } from '../../dataObjects/Order';

export interface IListCompanyPersonRelationsRequest extends IBaseRequest {
    FILTER?:    Filter;
    ORDER?:     Order;
}

export class ListCompanyPersonRelationsRequest extends BaseRequest implements IListCompanyPersonRelationsRequest {
    public FILTER?: Filter;
    public ORDER?:  Order;

    constructor(server: string, session: string, otherOptions: any) {
        super(server, session);
        this.FILTER = otherOptions.FILTER;
        this.ORDER = otherOptions.ORDER;
    }

    public toXml(): string {
        var parameters: string = super.toXml();

        if (this.FILTER) parameters += toFilterXml(this.FILTER);
        if (this.ORDER) parameters += toOrderXml(this.ORDER);

        return super.wrapperXml('ListCompanyPersonRelations', parameters);
    }
}
