import { Flex, View } from '@adobe/react-spectrum';
import { Paperclip } from 'react-bootstrap-icons';
import moment from 'moment';
import { truncateText } from '../../../../../utils/helperUtil';
import {
  DocumentIcon,
  InvoiceIcon,
  MailInCommingIcon,
  MailOutCommingIcon,
  MassmailGreen,
  NoticeIcon,
  PhoneInComingIcon,
  PhoneInOutGoingIcon,
  PhoneMissedIcon,
  TaskIcon,
} from '../../../Icons/IconsLib';
import styles from './card_component.module.css';

interface ICardComponentProps {
  item: any;
}

const CardComponent: React.FC<ICardComponentProps> = ({ item }) => {
  const formatRecipients = (recipients: any): string => {
    const formatContent = (label: string, data: any, prop: any): string => {
      if (Array.isArray(data)) {
        return `${label}: ${data.map(item => item[prop]).join(', ')}`;
      } else if (data && typeof data === 'object') {
        return `${label}: ${data[prop]}`;
      }
      return '';
    };

    const toContent = formatContent('To', recipients?.TO, 'CONTENT');
    const ccContent = formatContent('Cc', recipients?.CC, 'CONTENT');
    const bccContent = formatContent('Bcc', recipients?.BCC, 'CONTENT');
    const userContent = formatContent('Notify', recipients?.USER, 'contact');

    return [toContent, ccContent, bccContent, userContent].filter(Boolean).join(' | ');
  };

  const getSenderEmail = (email: string) => {
    const match = /<(.+?)>/.exec(email);
    return match ? match[1] : email;
  };

  const getSenderName = (sender: string) => {
    const match = /^([^<]+)/.exec(sender);
    return match ? match[1].trim() : sender;
  };

  const emailTypeImg = () => {
    switch (item.type) {
      case '1':
        return (
          <View UNSAFE_className={styles.email_type_img}>
            <NoticeIcon size={24} />
          </View>
        );
      case '2':
      case '3':
      case '7':
        if (item.senderContactId) {
          return (
            <View UNSAFE_className={styles.email_type_img}>
              <MailOutCommingIcon size={24} />
            </View>
          );
        } else {
          return (
            <View UNSAFE_className={styles.email_type_img}>
              <MailInCommingIcon size={24} />
            </View>
          );
        }
      case '9':
      case '12':
      case '14':
      case '15':
      case '11':
        return (
          <View UNSAFE_className={styles.email_type_img}>
            <MailOutCommingIcon size={24} />
          </View>
        );
      case '4':
      case '5':
        return (
          <View UNSAFE_className={styles.email_type_img}>
            <DocumentIcon size={24} />
          </View>
        );
      case '6':
        if (item.status === '131072') {
          return (
            <View UNSAFE_className={styles.email_type_img}>
              <PhoneInComingIcon size={24} />
            </View>
          );
        } else if (item.status === '262144') {
          return (
            <View UNSAFE_className={styles.email_type_img}>
              <PhoneInOutGoingIcon size={18} />
            </View>
          );
        } else {
          return (
            <View UNSAFE_className={styles.email_type_img}>
              <PhoneMissedIcon size={18} />
            </View>
          );
        }
      // return (
      //   <View UNSAFE_className={styles.email_type_img}>
      //     <PhoneInComingIcon size={24} />
      //   </View>
      // );
      case '8':
        return (
          <View UNSAFE_className={styles.email_type_img}>
            <MassmailGreen size={24} />
          </View>
        );
      case '10':
        return (
          <View UNSAFE_className={styles.email_type_img}>
            <TaskIcon size={24} />
          </View>
        );
      case '13':
        return (
          <View UNSAFE_className={styles.email_type_img}>
            <InvoiceIcon size={24} />
          </View>
        );
    }
  };

  return (
    <View width={'340px'} position={'relative'} height={'81px'}>
      <Flex direction={'row'} position={'absolute'} top={'5px'} left={'50px'} alignItems={'center'} justifyContent={'start'} gap={'8px'} UNSAFE_className={styles.user_detail_parent}>
        <View position={'relative'} UNSAFE_className={item?.seen == '1' ? styles.user_name_read : styles.user_name_unread}>
          <span title={item?.sender}>{truncateText(getSenderName(item?.sender), 30)}</span>
        </View>
        {getSenderEmail(item?.mailFrom) && <View width={'1px'} position={'relative'} height={'12px'} UNSAFE_className={styles.user_detail_parent_line}></View>}
        <View position={'relative'} UNSAFE_className={styles.user_email}>
          <span title={getSenderEmail(item?.mailFrom)}>{truncateText(getSenderEmail(item?.mailFrom), 25)}</span>
        </View>
        <View position={'absolute'} right={'15px'} top={'25px'} UNSAFE_className={styles.user_email_other_options}>
          <span title={formatRecipients(item?.RECIPIENT)}>
            {/* <div dangerouslySetInnerHTML={{ __html: formatRecipients(item?.RECIPIENT) }} /> */}
            {truncateText(formatRecipients(item?.RECIPIENT), 20)}
          </span>
        </View>
      </Flex>
      <View position={'absolute'} top={'27px'} left={'50px'} UNSAFE_className={item?.seen == '1' ? styles.email_subject_read : styles.email_subject_unread}>
        <span title={item?.subject}>{truncateText(item?.subject, 20)}</span>
      </View>
      <View position={'absolute'} top={'50px'} left={'50px'} width={'90px'} UNSAFE_className={styles.email_date}>
        {moment(item?.date).format('DD.MM.YYYY HH:mm')}
      </View>
      <View position={'absolute'} top={'45px'} left={'250px'} width={'75px'} UNSAFE_className={styles.attachment_icon}>
        {item?.numberOfAttachments > 0 && <Paperclip size={12} />}
      </View>
      {emailTypeImg()}
    </View>
  );
};

export default CardComponent;
