export const MAIN = {
    login: "Login",
    logout: "Logout",
    contactDataTransfer: "ContactDataTransfer",
    getContact: "GetContact",
    listDistributionGroup: "ListDistributionGroup",
    getContactList: "GetContactList",
    listReference: "ListReference",
    listContactList: "ListContactList",
    createContact: "CreateContact",
    getFromContactList: "GetFromContactList",
    countIndustry: "CountIndustry",
    listIndustry: "ListIndustry",
    countPosition: "CountPosition",
    listPosition: "ListPosition",
    countDepartment: "CountDepartment",
    listDepartment: "ListDepartment",
    createCompanyPersonRelation: "CreateCompanyPersonRelation",
    listContactSettings: "ListContactSettings",
    identifyContact: "IdentifyContact",
    listCompanyPersonRelations: "ListCompanyPersonRelations",
    listContactsContact: "ListContactsContact",
    updateContact: "UpdateContact",
    updateCompanyPersonRelation: "UpdateCompanyPersonRelation",
    deleteContact: "DeleteContact",
};

export const DOCUMENT_LIBRARY = {
    listDocument: "ListDocument",
    listDocumentType: "ListDocumentType",
    listDocumentCategory: "ListDocumentCategory",
    listDocumentLocation: "ListDocumentLocation",
    permanentlyDeleteDocument: "PermanentlyDeleteDocument",
    createDocumentInStorage: "CreateDocumentInStorage",
};

export const FEATURES = {
    listApplications: "ListApplications",
    updateRegister: "UpdateRegister",
    listRegister: "ListRegister",
    createRegister: "CreateRegister",
    getFormPanel: "GetFormPanel",
    listTimeline: "ListTimeline",
    listFormSettings: "ListFormSettings",
    getFormSettings: "GetFormSettings",
    checkWorkFlowQueue: "CheckWorkFlowQueue",
    getDimensionSettings: "GetDimensionSettings",
    listDimensionNode: "ListDimensionNode",
    listUserPreferences: "ListUserPreferences",
    getSystemId: "GetSystemId",
}

export const INVOICE = {
    listInvoice: "ListInvoice"
}

export const ACCESS = {
    listUser: "ListUser",
    getUser: "GetUser",
    listRole: "ListRole",
    listProfile: "ListProfile",
};

export const PROJECT = {
    listFPA: "ListFPA",
    listContext: "ListContext",
    updateFavouriteContext: "UpdateFavouriteContext",
    listFolderStatus: "ListFolderStatus",
    listProjectStatus: "ListProjectStatus",
    listActivityStatus: "ListActivityStatus",
    listMasterWithSlaves: "ListMasterWithSlaves",
    listProjectType: "ListProjectType",
    listFolderType: "ListFolderType",
    getProject: "GetProject",
    getActivity: "GetActivity",
    getMarketingChannelsStats: "GetMarketingChannelsStats",
    getContextPath: "GetContextPath",
    getFolder: "GetFolder",
    listFPATypeCategory: "ListFPATypeCategory",
    updateActivity: "UpdateActivity",
    updateFolder: "UpdateFolder",
    updateProject: "UpdateProject",
    getFolderProjectType: "GetFolderProjectType",
    listActivityType: "ListActivityType",
    listFolderTemplate: "ListFolderTemplate",
    getFolderTemplate: "GetFolderTemplate",
    getProjectTemplate: "GetProjectTemplate",
    createSimpleFolder: "CreateSimpleFolder",
    getActivityTemplate: "GetActivityTemplate",
    listActivityAdvanced: "ListActivityAdvanced",
    listActivityTemplate: "ListActivityTemplate",
    listProjectAdvanced: "ListProjectAdvanced",
    listProjectTemplate: "ListProjectTemplate",
    updateExternalContact: "UpdateExternalContact",
    createActivity: "CreateActivity",
    listFPATypeDocumentPars: "ListFPATypeDocumentPars",
    createProject: "CreateProject",
    listFolderOrganization: "ListFolderOrganization",
    getOrganization: "GetOrganization",
    deleteActivity: "DeleteActivity",
    deleteFolder: "DeleteFolder",
    deleteProject: "DeleteProject",
}

export const RECRUITMENT = {
    listCandidateVacancy: "ListCandidateVacancy",
    updateCandidateVacancy: "UpdateCandidateVacancy",
    listClientProject: "ListClientProject",
    listClientProjectPackage: 'ListClientProjectPackage',
    listPlacementConfiguration: 'ListPlacementConfiguration',
    getSelectionProcedureStats: 'GetSelectionProcedureStats',
}

export const SCHEDULER = {
    getSchedulerSettings: "GetSchedulerSettings",
    listTimeSheet: "ListTimeSheet",
    createTimeSheet: "CreateTimeSheet",
    updateTimeSheet: "UpdateTimeSheet",
    deleteTimeSheet: "DeleteTimeSheet",
    getTypeOfWorkForContext: "GetTypeOfWorkForContext",
    evaluateTimeSheet: "EvaluateTimeSheet",
    createTask: "CreateTask",
    updateTask: "UpdateTask",
    listTask: "ListTask",
    deleteTask: "DeleteTask",
    listEvent: "ListEvent",
}

export const ITEM = {
    listProjectItemCalculation: "ListProjectItemCalculation",
    listProjectItem: "ListProjectItem",
    listItem: "ListItem",
    deleteProjectItem: "DeleteProjectItem",
    createProjectItem: "CreateProjectItem",
    updateProjectItem: "UpdateProjectItem",
    getItem: "GetItem",
    listItemPriceLists: "ListItemPriceLists",
    createActivityItem: "CreateActivityItem",
    getActivityItem: "GetActivityItem",
    deleteItem: "DeleteItem",
}

export const FORM = {
    listItemValue: "ListItemValue",
    updateFormItemValues: "UpdateFormItemValues",
    updateItemValue: "UpdateItemValue",
    createFormValue: "CreateFormValue",
    listForms: "ListForms",
    getForm: "GetForm",
}

export const TREE = {
    nodeToPath: "NodeToPath",
}

export const TRANSLATE = {
    listLanguages: "ListLanguages",
    listCountry: "ListCountry",
    listZip: "ListZip",
    listCity: "ListCity",
    listRegion: "ListRegion",
}

export const FINANCE = {
    getFinanceSettings: "GetFinanceSettings",
    listCurrencyRate: "ListCurrencyRate",
    listTransactionShares: "ListTransactionShares",
}

export const GLOBAL = {
    listCurrency: "ListCurrency",
    listVAT: "ListVAT",
}

export const TEAM = {
    listActivityUser: "ListActivityUser",
    newActivityUser: "NewActivityUser",
    listProjectUser: "ListProjectUser",
    listFolderUser: "ListFolderUser",
    updateActivityUser: "UpdateActivityUser",
    deleteActivityUser: "DeleteActivityUser",
    updateProjectUser: "UpdateProjectUser",
    deleteProjectUser: "DeleteProjectUser",
    updateFolderUser: "UpdateFolderUser",
    deleteFolderUser: "DeleteFolderUser",
    newFolderUser: "NewFolderUser",
    newProjectUser: "NewProjectUser",
}

export const MESSAGE = {
    newMessage: "NewMessage",
    listMessage: "ListMessage",
    listMessageTemplate: "ListMessageTemplate",
    listMessageContainerNotification: "ListMessageContainerNotification",
    listMessageAction: "ListMessageAction",
    setSeenMessage: "SetSeenMessage",
    getMessage: "GetMessage",
    listMessageType: "ListMessageType",
    deleteMessage: "DeleteMessage",
    getListMessage: "GetListMessage",
    newMessageTemplate: "NewMessageTemplate",
}

export const MAIL = {
    newMail: "NewMail",
    listMailbox: "ListMailbox",
    getMailbox: "GetMailbox"
} as const;