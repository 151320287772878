import { BaseRequest, IBaseRequest } from '../../BaseRequest';

export interface IGetMessageRequest extends IBaseRequest {
    messageId: string;
    maxLength?: string;
    notViewed?: string;
}

export class GetMessageRequest extends BaseRequest implements IGetMessageRequest {
    public messageId: string;
    public maxLength?: string;
    public notViewed?: string;
    constructor(server: string, session: string, messageId: string, maxLength?: string, notViewed?: string) {
        super(server, session);
        this.messageId = messageId;
        this.maxLength = maxLength;
        this.notViewed = notViewed;
    }

    public toXml(): string {
        var parameters: string = super.toXml();

        parameters += `<messageId>${this.messageId}</messageId>`;
        if (this.maxLength) parameters += `<maxLength>${this.maxLength}</maxLength>`;
        if (this.notViewed) parameters += `<notViewed>${this.notViewed}</notViewed>`;
        
        return super.wrapperXml('GetMessage', parameters);
    }
}
