import { useCallback, useEffect, useRef, useState } from 'react';
import { FPAData, FPADataTypes } from '../../../infra/protected/FPA/FPAData';
import { useDependency } from '../../../contexts/DependencyProvider';
import { ListFolderTemplateRequest } from '../../../services/soap/project/requests/ListFolderTemplateRequest';
import { FPATemplateItem } from '../../../infra/protected/FPA/FPATemplateItem';
import { GetFolderProjectTypeRequest } from '../../../services/soap/project/requests/GetFolderProjectTypeRequest';
import { GetFolderTemplateRequest } from '../../../services/soap/project/requests/GetFolderTemplateRequest';
import { ToastQueue } from '@react-spectrum/toast';
import { GetFormSettingsRequest } from '../../../services/soap/features/requests/GetFormSettingsRequest';
import { SettingItem } from '../../../services/soap/features/responses/GetFormSettingsResponse';
import { usePreloadAssets } from '../../../hooks/UsePreloadAssets';
import { GetProjectTemplateRequest } from '../../../services/soap/project/requests/GetProjectTemplateRequest';
import { GetActivityTemplateRequest } from '../../../services/soap/project/requests/GetActivityTemplateRequest';
import { CreateContactRequest } from '../../../services/soap/main/requests/CreateContactRequest';
import { GetContactRequest } from '../../../services/soap/main/requests/GetContactRequest';
import { Company } from '../../../services/soap/main/responses/GetContactResponse';
import { CreateSimpleFolderRequest } from '../../../services/soap/project/requests/CreateSimpleFolderRequest';
import { GetFolderRequest } from '../../../services/soap/project/requests/GetFolderRequest';
import { Folder } from '../../../services/soap/project/responses/GetFolderResponse';
import { CheckWorkFlowQueueRequest } from '../../../services/soap/features/requests/CheckWorkFlowQueueRequest';
import { UpdateExternalContactRequest } from '../../../services/soap/project/requests/UpdateExternalContactRequest';
import { ItemValue, UpdateItemValueRequest } from '../../../services/soap/form/requests/UpdateItemValueRequest';
import { CreateCompanyPersonRelationRequest } from '../../../services/soap/main/requests/CreateCompanyPersonRelationRequest';
import { Person } from '../../../services/soap/dataObjects/Person';
import { ListProjectTemplateRequest } from '../../../services/soap/project/requests/ListProjectTemplateRequest';
import { ListActivityTemplateRequest } from '../../../services/soap/project/requests/ListActivityTemplateRequest';
import { CreateActivityRequest } from '../../../services/soap/project/requests/CreateActivityRequest';
import { FolderTemplate } from '../../../services/soap/project/responses/GetFolderTemplateResponse';
import { ProjectTemplate } from '../../../services/soap/project/responses/GetProjectTemplateResponse';
import { ActivityTemplate } from '../../../services/soap/project/responses/GetActivityTemplateResponse';
import { Activity } from '../../../services/soap/dataObjects/Activity';
import { NewActivityUserRequest } from '../../../services/soap/team/requests/NewActivityUserRequest';
import { GetActivityRequest } from '../../../services/soap/project/requests/GetActivityRequest';
import { UpdateActivityRequest } from '../../../services/soap/project/requests/UpdateActivityRequest';
import { UpdateFavouriteContextRequest } from '../../../services/soap/project/requests/UpdateFavouriteContextRequest';
import { invokeFinderApp } from '../../../utils/NavigationUtils';
import { CreateProjectRequest } from '../../../services/soap/project/requests/CreateProjectRequest';
import { NewProjectUserRequest } from '../../../services/soap/team/requests/NewProjectUserRequest';
import { GetProjectRequest } from '../../../services/soap/project/requests/GetProjectRequest';
import { Project } from '../../../services/soap/project/responses/GetProjectResponse';
import { UpdateProjectRequest } from '../../../services/soap/project/requests/UpdateProjectRequest';
import { addKey, useKeyboardShortcuts } from '../../../hooks/UseKeyboardShortcuts';
import { ContextFieldsMapping } from './ContextFieldsMapping';
import { createProjectProductItem } from '../../../services/soap/item/requests/CreateProjectItemOptions';
import { CreateProjectItemRequest } from '../../../services/soap/item/requests/CreateProjectItemRequest';
import { useTranslation } from 'react-i18next';
import { ContextNewDialogMode } from './ContextNewDialog';
import { ListItemValueRequest } from '../../../services/soap/form/requests/ListItemValueRequest';
import { CreateActivityItemRequest } from '../../../services/soap/item/requests/CreateActivityItemRequest';
export interface IUseContextNewDialogProps {
  parentItem: FPAData | null;
  onClose: (reload_flag: boolean) => void;
  additionalMode: boolean;
  additionalData?: { fpaTypeCategory: string; fpaType: 'FOLDER' | 'PROJECT' | 'ACTIVITY'; haveMasterActivity: boolean };
  mode?: ContextNewDialogMode;
}

export function useContextNewDialog({ onClose, parentItem, additionalMode, additionalData, mode }: IUseContextNewDialogProps) {
  const [currentStep, setCurrentStep] = useState(1);
  const [treeData, setTreeData] = useState<FPATemplateItem[]>([]);
  const [settings, setSettings] = useState<SettingItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState<FPATemplateItem | null>(null);
  const [contextTypeFormId, setContextTypeFormId] = useState('');
  const [activityType, setActivityType] = useState(0);
  const [projectType, setProjectType] = useState(0);
  const [contextFilter, setContextFilter] = useState('');

  const { store, projectService, featureService, mainService, formService, teamService, itemService } = useDependency();
  const { formSettings, contactSettings, addressTypes } = usePreloadAssets();
  const { t } = useTranslation();

  const template_detail = useRef<FolderTemplate | ProjectTemplate | ActivityTemplate | null>(null);
  const formDataRef = useRef<{ [key: string]: any }>({});
  const newFlagRef = useRef<{ [key: string]: boolean }>({});
  const reqFlagRef = useRef<{ [key: string]: boolean }>({});
  const firstTimeRef = useRef<number>(0);
  const selectedIndexRef = useRef<number>(0);
  const totalDataRef = useRef<FPATemplateItem[]>([]);

  const fillProjectValues = async (): Promise<number | null> => {
    if (parentItem) {
      var project = await getProject(parentItem.id);

      if (project) {
        setFormData(ContextFieldsMapping.PROJECT.NAME, project.name, true);
        setFormData(ContextFieldsMapping.PROJECT.REF_ID, project.refId, true);

        if (project.formValues) {
          var formValues = await formService.listItemValue(new ListItemValueRequest(store.Server, store.SessionId, project.formValues));
          if (formValues.ROWS) {
            formValues.ROWS.forEach(item => {
              setFormData(`CF_${item.ITEMVALUE.formItem}`, item.ITEMVALUE.value, true);
            });
          }
        }

        if (project.folder) {
          var folder = await getFolder(+project.folder);
          if (folder) {
            var template_item: FPATemplateItem = {
              id: +folder.id,
              title: folder.name,
              key: folder.id + '',
              type: FPADataTypes.FOLDER,
              itemType: +folder.folderType,
              childItems: [],
            };

            var [child_templates] = await loadChildTemplates(template_item);
            var selected_template = child_templates.find(x => project!.projectType && +x.itemType === +project!.projectType);

            if (selected_template) {
              setSelectedItem(selected_template);
              return +selected_template.id;
            }
          }
        }
      }
    }
    return null;
  };

  const fillActivityValues = async (): Promise<FPATemplateItem | null> => {
    if (parentItem) {
      var activity = await getActivity(parentItem.id);

      if (activity) {
        setFormData(ContextFieldsMapping.ACTIVITY.NAME, activity.name, true);
        setFormData(ContextFieldsMapping.ACTIVITY.REF_ID, activity.refId, true);

        if (activity.formValues) {
          var formValues = await formService.listItemValue(new ListItemValueRequest(store.Server, store.SessionId, activity.formValues));
          if (formValues.ROWS) {
            formValues.ROWS.forEach(item => {
              setFormData(`CF_${item.ITEMVALUE.formItem}`, item.ITEMVALUE.value, true);
            });
          }
        }

        if (activity.project) {
          var project = await getProject(+activity.project);
          if (project) {
            var template_item: FPATemplateItem = {
              id: +project.id,
              title: project.name,
              key: project.id + '',
              type: FPADataTypes.PROJECT,
              itemType: +project.projectType,
              childItems: [],
            };

            var [child_templates] = await loadChildTemplates(template_item);
            var selected_template = child_templates.find(x => activity!.activityType && +x.itemType === +activity!.activityType);

            if (selected_template) {
              setSelectedItem(selected_template);
              return selected_template;
            }
          }
        }
      }
    }
    return null;
  };

  const proceedWithCopy = async () => {
    if (parentItem) {
      setSelectedItem({
        id: parentItem.item_type_id,
        title: parentItem.item_type_name,
        key: parentItem.item_type_id + '',
        type: parentItem.type,
        itemType: parentItem.item_type_id,

        childItems: [],
      });

      switch (parentItem.type) {
        case FPADataTypes.PROJECT:
          var folder_type_id = await fillProjectValues();
          if (folder_type_id) {
            await loadProjectTemplate(folder_type_id);
          }
          break;
        case FPADataTypes.ACTIVITY:
          var activity_template_item = await fillActivityValues();
          if (activity_template_item) {
            await loadActivityTemplate(activity_template_item);
          }
          break;

        default:
          break;
      }

      if (parentItem.type === FPADataTypes.PROJECT || parentItem.type === FPADataTypes.ACTIVITY) {
        setCurrentStep(2);
      }
    }
  };

  useEffect(() => {
    if (formSettings && Object.keys(formSettings).length > 0) {
      switch (mode) {
        case 'new':
          setCurrentStep(1);
          break;
        case 'copy':
          proceedWithCopy();
          break;
      }
    }
  }, [mode, formSettings]);

  const handleArrowUpKey = () => {
    if (selectedIndexRef.current > 0) {
      selectedIndexRef.current -= 1;
    } else {
      selectedIndexRef.current = treeData.length - 1;
    }
    setSelectedItem(treeData[selectedIndexRef.current]);
  };
  const handleArrowDownKey = () => {
    if (selectedIndexRef.current < treeData.length - 1) {
      selectedIndexRef.current += 1;
    } else {
      selectedIndexRef.current = 0;
    }
    setSelectedItem(treeData[selectedIndexRef.current]);
  };
  useKeyboardShortcuts([...addKey('Enter', { ctrl: true }, () => nextStep()), ...addKey('ArrowUp', {}, handleArrowUpKey), ...addKey('ArrowDown', {}, handleArrowDownKey)], true);

  const _t = (key: string) => t(`newContextDialog.${key}`, { ns: 'finder' });

  const getFormData = (key: string) => {
    return formDataRef.current[key];
  };

  const setFormData = (key: string, value: any, isNew: boolean) => {
    newFlagRef.current[key] = isNew;
    formDataRef.current[key] = value;
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps

  const onSelection = async (long_key: string) => {
    var item = findItemById(treeData, long_key);

    if (!item) {
      setSelectedItem(null);
      return;
    }
    var [items, systemType] = await loadChildTemplates(item);

    addChildrenUnderParent(treeData, long_key, items);
    const tree_data = JSON.parse(JSON.stringify(treeData));
    setTreeData(tree_data);
    totalDataRef.current = tree_data;
    setSelectedItem({ ...item, systemType });
  };

  const loadFolderTemplate = async () => {
    var template = await projectService.getFolderTemplate(new GetFolderTemplateRequest(store.Server, store.SessionId, selectedItem!.id));
    if (template.FOLDERTEMPLATE) {
      template_detail.current = template.FOLDERTEMPLATE;
      var setting_id = template.FOLDERTEMPLATE.formSetting;
      if (!setting_id) {
        setting_id = formSettings['TEditNewFolder'].id;
      }
      var response = await featureService.getFormSettings(new GetFormSettingsRequest(store.Server, store.SessionId, setting_id));
      setSettings(response.SETTING[0].ITEM);
    }
  };

  const loadProjectTemplate = async (templateId?: number) => {
    var template = await projectService.getProjectTemplate(new GetProjectTemplateRequest(store.Server, store.SessionId, templateId || selectedItem!.id));
    if (template.PROJECTTEMPLATE) {
      template_detail.current = template.PROJECTTEMPLATE;

      var setting_id = template.PROJECTTEMPLATE.formSetting;
      if (!setting_id) {
        setting_id = formSettings['TEditNewProject'].id;
      }
      var response = await featureService.getFormSettings(new GetFormSettingsRequest(store.Server, store.SessionId, setting_id));
      setProjectType(template.PROJECTTEMPLATE.type);
      setSettings(response.SETTING[0].ITEM);
    }
  };

  const loadActivityTemplate = async (new_selectedItem?: FPATemplateItem) => {
    var template = await projectService.getActivityTemplate(new GetActivityTemplateRequest(store.Server, store.SessionId, new_selectedItem ? new_selectedItem.id : selectedItem!.id));
    if (template.TEMPLATE) {
      template_detail.current = template.TEMPLATE;

      var setting_id = template.TEMPLATE.formSetting;
      if (!setting_id) {
        setting_id = formSettings['TEditNewActivity'].id;
      }
      var response = await featureService.getFormSettings(new GetFormSettingsRequest(store.Server, store.SessionId, setting_id));
      setActivityType(template.TEMPLATE.type);
      setSettings(response.SETTING[0].ITEM);
      if (new_selectedItem) {
        setSelectedItem({ ...new_selectedItem, formId: +template.TEMPLATE.form });
      } else {
        setSelectedItem({ ...selectedItem!, formId: +template.TEMPLATE.form });
      }
    }
  };

  const saveCompany = async (): Promise<number> => {
    var address_types: string[] = Object.keys(addressTypes).filter(key => addressTypes[key].companyAddress == 1 && addressTypes[key].invoiceAddress == 1);

    var companyInfo: Person = {
      name1: formDataRef.current['company.name1'],
      consentStatus: '1',
      anonymized: '0',
      massmailEnabled: '0',
    };

    if (formDataRef.current['company.country']) {
      companyInfo.ADDRESSES = {
        ADDRESS: [
          {
            isPrimary: '1',
            country: formDataRef.current['company.country'],
            type: address_types[0],
          },
        ],
      };
    }

    if (formDataRef.current['company.industry']) {
      companyInfo.INDUSTRIES = {
        INDUSTRY: [
          {
            industry: formDataRef.current['company.industry'],
            isPrimary: '1',
          },
        ],
      };
    }

    const company_response = await mainService.createContact(
      new CreateContactRequest(store.Server, store.SessionId, undefined, companyInfo)
    );
    if (company_response.EXCEPTION) {
      ToastQueue.negative(company_response.EXCEPTION.message, { timeout: 1000 });
      return -1;
    }
    return company_response.newId;
  };

  const getCompany = async (contactId: number):Promise<Company> => {
      const contact_response = await mainService.getContact(new GetContactRequest(store.Server, store.SessionId, undefined, undefined, contactId));
      return contact_response.COMPANY;

  }
  const createSimpleFolder = async (template_id: number, contact_name: string, contact_id: number, parent?: string): Promise<number> => {
    const folder_response = await projectService.createSimpleFolder(
      new CreateSimpleFolderRequest(store.Server, store.SessionId, {
        name: contact_name,
        organization: store.session.sessionInfo.organizationId,
        template: template_id + '',
        contact: contact_id ? contact_id + '' : undefined,
        referenceId: formDataRef.current['folder.refId'],
        comment: formDataRef.current['folder.description'],
        parent: parent ? parent : store.session.sessionInfo.organizationId,
      })
    );

    if (folder_response.EXCEPTION) {
      ToastQueue.negative(folder_response.EXCEPTION.message, { timeout: 1000 });
      return -1;
    }

    return +folder_response.newId;
  };

  const getFolder = async (folderId: number): Promise<Folder | null> => {
    const folder_response = await projectService.getFolder(new GetFolderRequest(store.Server, store.SessionId, folderId));
    if (folder_response.EXCEPTION) {
      ToastQueue.negative(folder_response.EXCEPTION.message, { timeout: 1000 });
      return null;
    }

    return folder_response.FOLDER;
  };

  const doWorkFlowQueue = async () => {
    const workflow_response = await featureService.checkWorkFlowQueue(new CheckWorkFlowQueueRequest(store.Server, store.SessionId));
    if (workflow_response.EXCEPTION) {
      ToastQueue.negative(workflow_response.EXCEPTION.message, { timeout: 1000 });
      return;
    }
  };

  const savePersonContact = async (companyContactId?: number): Promise<number> => {
    var contact_types: { [key: string]: any } = Object.keys(contactSettings)
      .filter(key => ['1', '2', '3'].includes(contactSettings[key].type) && (!contactSettings[key].category || contactSettings[key].category == '0'))
      .reduce((acc, key) => ({ ...acc, [contactSettings[key].type]: contactSettings[key] }), {});

    var address_types: string[] = Object.keys(addressTypes).filter(key => addressTypes[key].personAddress == 1 && addressTypes[key].invoiceAddress == 1);

    var personInfo: Person = {
      name1: formDataRef.current['person.person.name1'],
      name2: formDataRef.current['person.person.name2'],
      name3: formDataRef.current['person.person.name3'],
      consentStatus: '1',
      anonymized: '0',
      massmailEnabled: '0',
      CONTACTS: {
        CONTACT: [
        ],
      },
      PERSONINFO: {
        prefixTitle: formDataRef.current['person.prefix'],
        postfixTitle: formDataRef.current['person.person.suffix'],
        sex: '0',
        maritalStatus: '0',
        salutation: formDataRef.current['person.salutation'],
        preferedLang: formDataRef.current['person.preferedLanguageId'],
      },
    };

    if (formDataRef.current['person.country']) {
      personInfo.ADDRESSES = {
        ADDRESS: [
          {
            isPrimary: '1',
            country: formDataRef.current['person.country'],
            type: address_types[0],
          },
        ],
      };
    }

    if (formDataRef.current['person.email']) {
      personInfo.CONTACTS!.CONTACT!.push({
        content: formDataRef.current['person.email'],
        isMain: '1',
        isPrimary: '1',
        typeType: '1',
        type: contact_types['1'].id,
        typeName: contact_types['1'].name,
      });
    }

    if (formDataRef.current['person.phone']) {
      personInfo.CONTACTS!.CONTACT!.push({
        content: formDataRef.current['person.phone'],
        isMain: '0',
        isPrimary: '1',
        typeType: '2',
        type: contact_types['2'].id,
        typeName: contact_types['2'].name,
      });
    }

    if (formDataRef.current['person.person.mobile']) {
      personInfo.CONTACTS!.CONTACT!.push({
        content: formDataRef.current['person.person.mobile'],
        isMain: '0',
        isPrimary: '1',
        typeType: '3',
        type: contact_types['3'].id,
        typeName: contact_types['3'].name,
      });
    }

    if (companyContactId) {
      personInfo = {
        ...personInfo,
        EMPLOYMENTS: {
          COMPANY: [
            {
              id: `${companyContactId}`,
              name: formDataRef.current['company.name1'],
              department: formDataRef.current['relatedPerson.departmentId'],
              isPrimary: '0',
              position: formDataRef.current['relatedPerson.positionId'],
              positionInfo: formDataRef.current['relatedPerson.positionInfo'],
            },
          ],
        },
      };
    }
    const person_response = await mainService.createContact(new CreateContactRequest(store.Server, store.SessionId, personInfo, undefined));

    if (person_response.EXCEPTION) {
      ToastQueue.negative(person_response.EXCEPTION.message, { timeout: 1000 });
      return -1;
    }

    return person_response.newId;
  };

  const bindPersonToFolder = async (personId: number, folderId: number): Promise<boolean> => {
    var folder_response = await projectService.updateExternalContact(
      new UpdateExternalContactRequest(store.Server, store.SessionId, {
        contextId: folderId + '',
        contactId: personId + '',
      })
    );

    if (folder_response.EXCEPTION) {
      ToastQueue.negative(folder_response.EXCEPTION.message, { timeout: 1000 });
      return false;
    }

    return true;
  };

  const bindPersonToCompany = async (personId: number, companyContactId: number): Promise<boolean> => {
    var folder_response = await mainService.createCompanyPersonRelation(
      new CreateCompanyPersonRelationRequest(store.Server, store.SessionId, {
        company: companyContactId + '',
        contact: personId + '',
        isPrimary: true,
        department: formDataRef.current['relatedPerson.departmentId'],
        position: formDataRef.current['relatedPerson.positionId'],
        positionInfo: formDataRef.current['relatedPerson.positionInfo'],
      })
    );

    if (folder_response.EXCEPTION) {
      ToastQueue.negative(folder_response.EXCEPTION.message, { timeout: 1000 });
      return false;
    }

    return true;
  };
  const saveCustomForm = async (formValuesId?: number): Promise<boolean> => {
    if (!formValuesId) {
      ToastQueue.negative(_t('toast.form_values_not_found'), { timeout: 1000 });
      return false;
    }

    const passed_values = Object.keys(formDataRef.current)
      .filter(key => key.startsWith('CF_'))
      .map(key => {
        var value = formDataRef.current[key];
        var formItem = key.substring(3);
        return new ItemValue(undefined, undefined, +formValuesId, +formItem, undefined, value, undefined, undefined);
      });

    if (passed_values.length === 0) return true;

    var save_response = await formService.updateItemValue(new UpdateItemValueRequest(store.Server, store.SessionId, passed_values));

    if (save_response.EXCEPTION) {
      ToastQueue.negative(save_response.EXCEPTION.message, { timeout: 1000 });
      return false;
    }

    return true;
  };

  const createActivity = async (): Promise<number> => {
    var activity_template = template_detail.current as ActivityTemplate;
    if (!activity_template) {
      ToastQueue.negative(_t('toast.activity.template_not_found'), { timeout: 1000 });
      return -1;
    }

    var activity_data = {
      activityType: activityType + '',
      customState: activity_template.initialStatus + '',
      folder: formDataRef.current['folder'] + '',
      formId: activity_template.form ? activity_template.form + '' : undefined,
      project: formDataRef.current['project'] + '',
      name: formDataRef.current['activity.name1'],
      comment: formDataRef.current['activity.description'],
      masterActivity: formDataRef.current['activity.masterActivity'],
      billingItems: formDataRef.current['billingItems'],
      servicedItems: formDataRef.current['servicedItems'],
    };

    const activity_response = await projectService.createActivity(new CreateActivityRequest(store.Server, store.SessionId, activity_data));

    if (activity_response.EXCEPTION) {
      ToastQueue.negative(activity_response.EXCEPTION.message, { timeout: 1000 });
      return -1;
    }

    return +activity_response.newId;
  };

  const getActivity = async (activityId: number): Promise<Activity | null> => {
    const activity_response = await projectService.getActivity(new GetActivityRequest(store.Server, store.SessionId, activityId));
    if (activity_response.EXCEPTION) {
      ToastQueue.negative(activity_response.EXCEPTION.message, { timeout: 1000 });
      return null;
    }

    return activity_response.ACTIVITY;
  };

  const addUserInActivity = async (activityId: number, isPrimary: number, contact: number): Promise<boolean> => {
    var activity = await getActivity(activityId);
    if (activity == null) {
      ToastQueue.negative(_t('toast.activity.not_found'), { timeout: 1000 });
      return false;
    }

    var update = await projectService.updateActivity(
      new UpdateActivityRequest(store.Server, store.SessionId, {
        ...activity,
        EXTERNAL_CONTACTS: {
          count: '1',
          ROWS: [
            {
              EXTERNAL_CONTACT: {
                contactId: contact,
                isPrimary: isPrimary,
                deleteContact: 0,
              },
            },
          ],
        },
      })
    );

    if (update.EXCEPTION) {
      ToastQueue.negative(update.EXCEPTION.message, { timeout: 1000 });
      return false;
    }

    return true;
  };

  const newActivityUser = async (activityId: number, userId: number, isPrimary: number, contact: number): Promise<number> => {
    var activity_user_response = await teamService.newActivityUser(new NewActivityUserRequest(store.Server, store.SessionId, userId, activityId, isPrimary, contact, {}));

    if (activity_user_response.EXCEPTION) {
      ToastQueue.negative(activity_user_response.EXCEPTION.message, { timeout: 1000 });
      return -1;
    }

    return activity_user_response.itemId;
  };

  const updateFavoriteContext = async ({ folderId, projectId, activityId }: { folderId?: number; projectId?: number; activityId?: number }): Promise<boolean> => {
    var update_favorite_response = await projectService.updateFavouriteContext(new UpdateFavouriteContextRequest(store.Server, store.SessionId, folderId, projectId, activityId));

    if (update_favorite_response.EXCEPTION) {
      ToastQueue.negative(update_favorite_response.EXCEPTION.message, { timeout: 1000 });
      return false;
    }

    return true;
  };
  const saveFolder = async () => {
    if (!selectedItem) {
      ToastQueue.negative(_t('toast.select_an_item'), { timeout: 1000 });
      return;
    }

    var allowCompany = selectedItem.contactType == 2;
    var allowPerson = selectedItem.contactType == 1;

    var personRequired = ['person.email', 'person.person.name1', 'person.person.name3', 'person.person.suffix'].some(key => reqFlagRef.current[key] !== undefined && reqFlagRef.current[key]);
    var personSave = ['person.email', 'person.person.name1', 'person.person.name3', 'person.person.suffix'].some(key => formDataRef.current[key] !== undefined && formDataRef.current[key] !== '');

    var companyContactId = undefined;
    var personId = undefined;
    var folderId = undefined;
    var folder = undefined;
    var company = undefined;
    var companyContact = undefined;

    if (allowCompany) {
      companyContactId = newFlagRef.current['company.name1'] ? await saveCompany() : formDataRef.current['company.name1'];

      if (companyContactId == -1) {
        return;
      }

      companyContact = await getCompany(companyContactId);
    }

    if (allowPerson || allowCompany) {
      // personId = (newFlagRef.current['person.email'] == undefined || newFlagRef.current['person.email'])  ? await savePersonContact(companyContactId) : formDataRef.current['person.email'];

      if (newFlagRef.current['person.email'] === false) {
        personId = +formDataRef.current['person.email'];
      } else if (newFlagRef.current['person.person.name1'] === false) {
        personId = +formDataRef.current['person.person.name1'];
      } else if (personRequired || personSave) {
        personId = await savePersonContact();
      }

      if (personId == -1) {
        return;
      }
    }

    var contact_name = companyContact ? companyContact.name1 : formDataRef.current['company.name1'];
    folderId = await createSimpleFolder(selectedItem?.id!, contact_name, companyContactId, parentItem ? parentItem.id.toString() : undefined);

    if (folderId == -1) {
      return;
    }

    if ((allowPerson || allowCompany) && personId) {
      folder = await bindPersonToFolder(personId!, folderId);

      if (!folder) {
        return;
      }
    }

    if (allowCompany && personId) {
      company = await bindPersonToCompany(personId!, companyContactId);

      if (!company) {
        return;
      }
    }

    folder = await getFolder(folderId);

    if (!folder) {
      ToastQueue.negative(_t('toast.folder.not_found'), { timeout: 1000 });
      return false;
    }

    await saveCustomForm(+folder.formValues);

    await doWorkFlowQueue();

    var favoriate = await updateFavoriteContext({ folderId });
    if (!favoriate) {
      return;
    }

    ToastQueue.positive(_t('toast.folder.context_created'), {
      timeout: 3000,
      actionLabel: _t('toast.folder.open_context'),
      shouldCloseOnAction: true,
      onAction: () => invokeFinderApp(folderId!, 'FOLDER'),
    });
    onClose(true);
  };

  const createProject = async (): Promise<number> => {
    var project_template = template_detail.current as ProjectTemplate;
    var create_project_response = await projectService.createProject(
      new CreateProjectRequest(store.Server, store.SessionId, {
        archived: '0',
        comment: formDataRef.current['project.description'],
        masterProjectId: formDataRef.current['project.masterProjectId'],
        customState: project_template.initialStatus + '',
        folder: formDataRef.current['folder'] + '',
        formId: project_template.formId ? project_template.formId + '' : undefined,
        isMasterProject: '0',
        name: formDataRef.current['project.name'],
        projectTemplate: selectedItem?.id + '',
        projectType: projectType + '',
        refId: formDataRef.current['project.refId'],
        typeFormId: '0',
      })
    );

    if (create_project_response.EXCEPTION) {
      ToastQueue.negative(create_project_response.EXCEPTION.message, { timeout: 1000 });
      return -1;
    }

    return +create_project_response.newId;
  };

  const getProject = async (projectId: number): Promise<Project | null> => {
    const project_response = await projectService.getProject(new GetProjectRequest(store.Server, store.SessionId, projectId));
    if (project_response.EXCEPTION) {
      ToastQueue.negative(project_response.EXCEPTION.message, { timeout: 1000 });
      return null;
    }

    return project_response.PROJECT;
  };

  const newProjectUser = async (projectId: number, userId: number, isPrimary: number, contact: number): Promise<number> => {
    var project_user_response = await teamService.newProjectUser(
      new NewProjectUserRequest(store.Server, store.SessionId, {
        projectId: projectId,
        userId: userId,
        isPrimary: isPrimary,
        contact: contact,
      })
    );

    if (project_user_response.EXCEPTION) {
      ToastQueue.negative(project_user_response.EXCEPTION.message, { timeout: 1000 });
      return -1;
    }

    return project_user_response.itemId;
  };

  const addUserInProject = async (projectId: number, isPrimary: number, contact: number): Promise<boolean> => {
    var project = await getProject(projectId);
    if (project == null) {
      ToastQueue.negative(_t('toast.project.not_found'), { timeout: 1000 });
      return false;
    }

    var update = await projectService.updateProject(
      new UpdateProjectRequest(store.Server, store.SessionId, {
        ...project,
        EXTERNAL_CONTACTS: {
          count: '1',
          EXTERNAL_CONTACT: [
            {
              contactId: contact + '',
              isPrimary: isPrimary + '',
              deleteContact: '0',
            },
          ],
        },
      })
    );

    if (update.EXCEPTION) {
      ToastQueue.negative(update.EXCEPTION.message, { timeout: 1000 });
      return false;
    }

    return true;
  };
  const saveProjectItems = async ({ folderId, projectId, activityId }: { folderId?: string; projectId?: string; activityId?: string }): Promise<boolean> => {
    var total_items: any[] = [];
    var items = [];

    if (formDataRef.current[ContextFieldsMapping.TECHNOLOGY_ITEMS]) {
      items = formDataRef.current[ContextFieldsMapping.TECHNOLOGY_ITEMS].map((item: any) => ({ ...item, type: ContextFieldsMapping.PROJECT_ITEMS_TYPE.TECHNOLOGY_ITEMS }));
      total_items = [...total_items, ...items];
    }

    if (formDataRef.current[ContextFieldsMapping.SALES_ITEMS]) {
      items = formDataRef.current[ContextFieldsMapping.SALES_ITEMS].map((item: any) => ({ ...item, type: ContextFieldsMapping.PROJECT_ITEMS_TYPE.SALES_ITEMS, billingStatus: '0' }));
      total_items = [...total_items, ...items];
    }

    if (formDataRef.current[ContextFieldsMapping.BILLING_ITEMS]) {
      items = formDataRef.current[ContextFieldsMapping.BILLING_ITEMS].map((item: any) => ({ ...item, type: ContextFieldsMapping.PROJECT_ITEMS_TYPE.BILLING_ITEMS, billingStatus: '1' }));
      total_items = [...total_items, ...items];
    }

    if (total_items.length === 0) {
      return true;
    }

    for (var record of total_items) {
      let result = await itemService.createProjectItem(
        new CreateProjectItemRequest(
          store.Server,
          store.SessionId,
          createProjectProductItem(
            folderId,
            projectId,
            activityId,
            record.partID,
            record.productName,
            record.quantity,
            record.unitPrice,
            record.totalPrice,
            record.totalAmountWithVat || 0,
            record.currency,
            record.vatValue || 0,
            record.purchasePrice,
            record.attrib0,
            record.attrib1,
            record.attrib2,
            record.supplier,
            record.refId,
            record.billingDate,
            record.billingStatus,
            { type: record.type }
          )
        )
      );
      if (result.EXCEPTION) {
        ToastQueue.neutral(result.EXCEPTION.message, { timeout: 1000 });
      }
    }
    return true;
  };
  const saveProject = async () => {
    var projectId = await createProject();

    if (projectId == -1) {
      return;
    }

    var project = await getProject(projectId);

    if (!project) return;

    if (project.formValues) await saveCustomForm(+project.formValues);

    if (formDataRef.current['responsibleUser']) {
      const toks = formDataRef.current['responsibleUser'].split('_');
      const user = await newProjectUser(projectId, +toks[0], 1, +toks[1]);
      if (user == -1) {
        return;
      }
    }

    if (
      formDataRef.current['person.email'] ||
      formDataRef.current['person.prefix'] ||
      formDataRef.current['person.person.name1'] ||
      formDataRef.current['person.person.name3'] ||
      formDataRef.current['person.person.suffix']
    ) {
      var personId = -1;
      if (newFlagRef.current['person.email'] === false) {
        personId = +formDataRef.current['person.email'];
      } else if (newFlagRef.current['person.person.name1'] === false) {
        personId = +formDataRef.current['person.person.name1'];
      } else {
        personId = await savePersonContact();
      }

      if (personId == -1) {
        return;
      }

      var user = await addUserInProject(projectId, 1, personId);

      if (user == false) {
        return;
      }
    }

    var items = await saveProjectItems({ projectId: `${projectId}` });
    if (!items) {
      return;
    }

    var favoriate = await updateFavoriteContext({ projectId });
    if (!favoriate) {
      return;
    }
    ToastQueue.positive(_t('toast.project.context_created'), {
      timeout: 3000,
      actionLabel: _t('toast.project.open_context'),
      shouldCloseOnAction: true,
      onAction: () => invokeFinderApp(projectId + '', 'FOLDER'),
    });

    await doWorkFlowQueue();
    onClose(true);
  };

  const saveActivityItems = async ({ folderId, projectId, activityId }: { folderId?: string; projectId?: string; activityId?: string }): Promise<boolean> => {
    var total_items: any[] = [];
    var items = [];

    if (formDataRef.current[ContextFieldsMapping.TECHNOLOGY_ITEMS]) {
      items = formDataRef.current[ContextFieldsMapping.TECHNOLOGY_ITEMS].map((item: any) => ({ ...item, type: ContextFieldsMapping.PROJECT_ITEMS_TYPE.TECHNOLOGY_ITEMS }));
      total_items = [...total_items, ...items];
    }

    if (formDataRef.current[ContextFieldsMapping.SALES_ITEMS]) {
      items = formDataRef.current[ContextFieldsMapping.SALES_ITEMS].map((item: any) => ({ ...item, type: ContextFieldsMapping.PROJECT_ITEMS_TYPE.SALES_ITEMS, billingStatus: '0' }));
      total_items = [...total_items, ...items];
    }

    if (formDataRef.current[ContextFieldsMapping.BILLING_ITEMS]) {
      items = formDataRef.current[ContextFieldsMapping.BILLING_ITEMS].map((item: any) => ({ ...item, type: ContextFieldsMapping.PROJECT_ITEMS_TYPE.BILLING_ITEMS, billingStatus: '1' }));
      total_items = [...total_items, ...items];
    }

    if (formDataRef.current[ContextFieldsMapping.SERVICED_ITEMS]) {
      items = formDataRef.current[ContextFieldsMapping.SERVICED_ITEMS].map((item: any) => ({ ...item, type: ContextFieldsMapping.PROJECT_ITEMS_TYPE.SERVICED_ITEMS, billingStatus: '1' }));
      total_items = [...total_items, ...items];
    }

    if (total_items.length === 0) {
      return true;
    }

    for (var record of total_items) {
      let result = await itemService.createProjectItem(
        new CreateProjectItemRequest(
          store.Server,
          store.SessionId,
          createProjectProductItem(
            folderId,
            projectId,
            activityId,
            record.partID,
            record.productName,
            record.quantity,
            record.unitPrice,
            record.totalPrice,
            record.totalAmountWithVat || 0,
            record.currency,
            record.vatValue || 0,
            record.purchasePrice,
            record.attrib0,
            record.attrib1,
            record.attrib2,
            record.supplier,
            record.refId,
            record.billingDate,
            record.billingStatus,
            { type: record.type }
          )
        )
      );
      if (result.EXCEPTION) {
        ToastQueue.neutral(result.EXCEPTION.message, { timeout: 1000 });
      }
    }
    return true;
  };

  const saveActivity = async () => {
    var activityId = await createActivity();

    if (activityId == -1) {
      return;
    }

    var activity = await getActivity(activityId);

    if (!activity) return;

    if (activity.formValues) await saveCustomForm(+activity.formValues);

    if (formDataRef.current['responsibleUser']) {
      const toks = formDataRef.current['responsibleUser'].split('_');
      const user = await newActivityUser(activityId, +toks[0], 1, +toks[1]);
      if (user == -1) {
        return;
      }
    }

    if (
      formDataRef.current['person.email'] ||
      formDataRef.current['person.prefix'] ||
      formDataRef.current['person.person.name1'] ||
      formDataRef.current['person.person.name3'] ||
      formDataRef.current['person.person.suffix']
    ) {
      var personId = -1;
      if (newFlagRef.current['person.email'] === false) {
        personId = +formDataRef.current['person.email'];
      } else if (newFlagRef.current['person.person.name1'] === false) {
        personId = +formDataRef.current['person.person.name1'];
      } else {
        personId = await savePersonContact();
      }

      if (personId == -1) {
        return;
      }

      var user = await addUserInActivity(activityId, 1, personId);

      if (user == false) {
        return;
      }
    }

    var items = await saveActivityItems({
      activityId: `${activityId}`,
      projectId: `${activity.project}`,
      folderId: `${activity.folder}`,
    });
    if (!items) {
      return;
    }
    await doWorkFlowQueue();

    var favoriate = await updateFavoriteContext({ activityId });
    if (!favoriate) {
      return;
    }
    ToastQueue.positive(_t('toast.activity.context_created'), {
      timeout: 3000,
      actionLabel: _t('toast.activity.open_context'),
      shouldCloseOnAction: true,
      onAction: () => invokeFinderApp(activityId + '', 'FOLDER'),
    });
    onClose(true);
  };

  const checkRequiredFields = () => {
    for (var key in reqFlagRef.current) {
      if (reqFlagRef.current[key] && !formDataRef.current[key]) {
        console.log('checkRequiredFields', key);
        return false;
      }
    }
    return true;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const nextStep = async () => {
    switch (currentStep) {
      case 1:
        if (!selectedItem) {
          ToastQueue.negative(_t('toast.select_an_item'), { timeout: 1000 });
          return;
        }

        switch (selectedItem.type) {
          case FPADataTypes.FOLDER:
            await loadFolderTemplate();
            break;
          case FPADataTypes.PROJECT:
            await loadProjectTemplate();
            break;
          case FPADataTypes.ACTIVITY:
            await loadActivityTemplate();
            break;
        }
        setCurrentStep(2);
        break;
      case 2:
        if (!selectedItem) {
          ToastQueue.negative(_t('toast.select_an_item'), { timeout: 1000 });
          return;
        }

        // console.log('formDataRef.current', formDataRef.current);
        // console.log('reqFlagRef.current', reqFlagRef.current);

        if (!checkRequiredFields()) {
          ToastQueue.negative(_t('toast.fill_all_fields'), { timeout: 1000 });
          return;
        }

        if (formDataRef.current['subFolder'] && formDataRef.current['subFolder'] != '') {
          formDataRef.current['folder'] = formDataRef.current['subFolder'];
        }

        setLoading(true);

        switch (selectedItem.type) {
          case FPADataTypes.FOLDER:
            await saveFolder();
            break;
          case FPADataTypes.PROJECT:
            await saveProject();
            break;
          case FPADataTypes.ACTIVITY:
            await saveActivity();
            break;
        }
        setLoading(false);
        break;
    }
  };

  const prevStep = () => {
    setCurrentStep(1);
  };
  const toTemplateItem = (item: FPAData): FPATemplateItem => {
    return {
      key: item.id.toString(),
      id: +item.id,
      title: item.title,
      type: item.type,
      itemType: item.item_type_id,
      childItems: [] as FPATemplateItem[],
    };
  };
  const findItemById = (items: FPATemplateItem[], id: string): FPATemplateItem | null => {
    for (let i = 0; i < items.length; i++) {
      if (items[i].key == id) {
        return items[i];
      }
      if (items[i].childItems.length > 0) {
        var item = findItemById(items[i].childItems, id);
        if (item) {
          return item;
        }
      }
    }
    return null;
  };
  const addChildrenUnderParent = (items: FPATemplateItem[], parent: string, children: FPATemplateItem[]) => {
    items.forEach((item: FPATemplateItem) => {
      if (item.key == parent) {
        item.childItems = children.map(v => ({ ...v, key: `${parent}_${v.id}` }));
      } else if (item.childItems.length > 0) {
        addChildrenUnderParent(item.childItems, parent, children);
      }
    });
  };
  var loadRootTemplates = useCallback(async () => {
    setLoading(true);
    var items: FPATemplateItem[] = [];
    try {
      var res = await projectService.listFolderTemplate(new ListFolderTemplateRequest(store.Server, store.SessionId, { canBeRoot: true }));

      if (res.count > 0) {

        items = res.FOLDERTEMPLATE.map(item => {
          return {
            key: item.id.toString(),
            id: item.id,
            title: item.name,
            type: FPADataTypes.FOLDER,
            itemType: item.folderType,
            contactType: item.contactType,
            folderType: item.folderType,
            formId: item.formId ? item.formId : undefined,
            onlyOneForContact: item.onlyOneForContact,
            formSetting: item.formSetting,
            childItems: [] as FPATemplateItem[],
          };
        });
      }
    } catch (error) {
      console.log('error', error);
    }

    setTreeData(items);
    totalDataRef.current = items;
    setLoading(false);
  }, [projectService, store.Server, store.SessionId]);

  var loadProjectTemplates = useCallback(async () => {
    setLoading(true);
    var items: FPATemplateItem[] = [];
    try {
      var res = await projectService.listProjectTemplate(new ListProjectTemplateRequest(store.Server, store.SessionId, { fpaTypeCategory: additionalData?.fpaTypeCategory }));
      if (res.count > 0) {
        items = res.PROJECTTEMPLATE.map(item => {
          return {
            key: item.id.toString(),
            id: +item.id,
            title: item.name,
            type: FPADataTypes.PROJECT,
            itemType: item.formSetting,
            formSetting: item.formSetting + '',
            childItems: [] as FPATemplateItem[],
          };
        });
      }
    } catch (error) {
      console.log('error', error);
    }
    setTreeData(items);
    totalDataRef.current = items;
    setLoading(false);
  }, []);

  var loadActivityTemplates = useCallback(async () => {
    setLoading(true);
    var items: FPATemplateItem[] = [];
    try {
      var res = await projectService.listActivityTemplate(new ListActivityTemplateRequest(store.Server, store.SessionId, { fpaTypeCategory: additionalData?.fpaTypeCategory }));
      if (res.count > 0) {
        items = res.TEMPLATE.map(item => {
          return {
            key: item.id.toString(),
            id: +item.id,
            title: item.name,
            type: FPADataTypes.ACTIVITY,
            itemType: item.formSetting,
            formSetting: item.formSetting + '',
            childItems: [] as FPATemplateItem[],
          };
        });
      }
    } catch (error) {
      console.log('error', error);
    }
    setTreeData(items);
    totalDataRef.current = items;
    setLoading(false);
  }, []);

  var loadChildTemplates = useCallback(
    async (item: FPATemplateItem): Promise<[FPATemplateItem[], number]> => {
      setLoading(true);
      var items: FPATemplateItem[] = [];
      var system_type = 0;
      try {
        var res = await projectService.getFolderProjectType(new GetFolderProjectTypeRequest(store.Server, store.SessionId, item.itemType));
        if (res.count > 0) {
          var folder_templates = [] as FPATemplateItem[];
          var project_templates = [] as FPATemplateItem[];
          var activity_templates = [] as FPATemplateItem[];

          system_type = +res.TYPE[0].systemType;
          var folderTemplate = res.TYPE[0].FOLDERTEMPLATES;
          if (folderTemplate) {
            folder_templates = folderTemplate.TEMPLATE.map(item => ({
              key: item.folderTemplate.toString(),
              id: item.folderTemplate,
              title: item.folderTemplateName,
              type: FPADataTypes.FOLDER,
              itemType: item.folderType,
              childItems: [] as FPATemplateItem[],
            }));
          }

          if (res.TYPE[0].formId) {
            setContextTypeFormId(res.TYPE[0].formId);
          }

          if (res.TYPE[0].PROJECTTEMPLATES) {
            project_templates = res.TYPE[0].PROJECTTEMPLATES.TEMPLATE.map(item => ({
              key: item.projectTemplate.toString(),
              id: item.projectTemplate,
              title: item.projectTemplateName,
              type: FPADataTypes.PROJECT,
              itemType: item.projectType,
              childItems: [] as FPATemplateItem[],
            }));
          }

          if (res.TYPE[0].ACTIVITYTEMPLATES) {
            activity_templates = res.TYPE[0].ACTIVITYTEMPLATES.TEMPLATE.map(item => ({
              key: item.activityTemplate.toString(),
              id: item.activityTemplate,
              title: item.activityTemplateName,
              type: FPADataTypes.ACTIVITY,
              itemType: item.activityType,
              childItems: [] as FPATemplateItem[],
            }));
          }
          items = [...folder_templates, ...project_templates, ...activity_templates];
        }
      } catch (error) {
        console.log('error', error);
      }

      setLoading(false);
      return [items, system_type];
    },
    [projectService, store.Server, store.SessionId]
  );

  useEffect(() => {
    if (mode === 'copy') {
      return;
    }
    if (additionalMode && additionalData) {
      switch (additionalData.fpaType) {
        case 'PROJECT':
          loadProjectTemplates();
          break;
        case 'ACTIVITY':
          if (additionalData.haveMasterActivity) {
            var template_item = toTemplateItem(parentItem!);
            loadChildTemplates(template_item).then(([items]) => {
              setTreeData(items);
              totalDataRef.current = items;
            });
          } else loadActivityTemplates();
          break;
      }
    } else if (!parentItem) {
      loadRootTemplates();
    }
  }, [loadRootTemplates, parentItem, mode, additionalMode, additionalData]);

  useEffect(() => {
    if (mode === 'copy') {
      return;
    }
    if (parentItem && !additionalMode) {
      var item = toTemplateItem(parentItem);
      loadChildTemplates(item).then(([items]) => {
        setTreeData(items);
      });
    }
  }, []);

  useEffect(() => {
    if (settings) {
      switch (selectedItem?.type) {
        case FPADataTypes.ACTIVITY:
          reqFlagRef.current = {
            folder: true,
            project: true,
          };
          break;
        default:
          reqFlagRef.current = {};
          break;
      }

      settings.forEach(setting => {
        if (setting.mandatory == 1) {
          reqFlagRef.current[setting.itemname] = true;
        }
      });
    } else {
      console.log('settings not set');
    }
  }, [settings]);

  useEffect(() => {
    if (treeData.length === 1 && treeData.length === totalDataRef.current.length) {
      switch (firstTimeRef.current) {
        case 0:
          firstTimeRef.current++;
          onSelection(treeData[0].key);
          break;
        case 1:
          if (selectedItem) {
            firstTimeRef.current++;
            nextStep();
          }
          break;
        default:
          break;
      }
    }
  }, [treeData, onSelection, selectedItem, nextStep]);

  const handleContextFilterChange = (value: string) => {
    setContextFilter(value);
    if (value === '') {
      setTreeData(totalDataRef.current);
    } else {
      var items = totalDataRef.current.filter(item => item.title.toLowerCase().includes(value.toLowerCase()));
      setTreeData(items);
    }
  };

  return {
    treeData,
    onSelection,
    loading,
    currentStep,
    nextStep,
    prevStep,
    selectedItem,
    settings,
    setFormData,
    getFormData,
    contextTypeFormId,
    activityType,
    contextFilter,
    handleContextFilterChange,
  };
}
