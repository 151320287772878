import { ITEM } from "../../../utils/ServiceUtils";
import { ServiceBase } from "../ServiceBase";
import { ICreateActivityItemRequest } from "./requests/CreateActivityItemRequest";
import { ICreateProjectItemRequest } from "./requests/CreateProjectItemRequest";
import { IDeleteProjectItemRequest } from "./requests/DeleteProjectItemRequest";
import { IGetItemRequest } from "./requests/GetItemRequest";
import { IListItemPriceListsRequest } from "./requests/ListItemPriceListsRequest";
import { IListItemRequest } from "./requests/ListItemRequest";
import { IListProjectItemCalculationRequest } from "./requests/ListProjectItemCalculationRequest";
import { IListProjectItemRequest } from "./requests/ListProjectItemRequest";
import { IUpdateProjectItemRequest } from "./requests/UpdateProjectItemRequest";
import { CreateActivityItemResponse } from "./responses/CreateActivityItemResponse";
import { CreateProjectItemResponse } from "./responses/CreateProjectItemResponse";
import { DeleteProjectItemResponse } from "./responses/DeleteProjectItemResponse";
import { GetItemResponse } from "./responses/GetItemResponse";
import { ListItemPriceListsResponse } from "./responses/ListItemPriceListsResponse";
import { ListItemResponse } from "./responses/ListItemResponse";
import { ListProjectItemCalculationResponse } from "./responses/ListProjectItemCalculationResponse";
import { ListProjectItemResponse } from "./responses/ListProjectItemResponse";
import { UpdateProjectItemResponse } from "./responses/UpdateProjectItemResponse";
import { GetActivityItemResponse } from "./responses/GetActivityItemResponse";
import { IGetActivityItemRequest } from "./requests/GetActivityItemRequest";
import { IDeleteItemRequest } from "./requests/DeleteItemRequest";
import { DeleteItemResponse } from "./responses/DeleteItemResponse";

export interface IItemService {
    listProjectItemCalculation(request: IListProjectItemCalculationRequest): Promise<ListProjectItemCalculationResponse>;
    listProjectItem(request: IListProjectItemRequest): Promise<ListProjectItemResponse>;
    listItem(request: IListItemRequest): Promise<ListItemResponse>;
    deleteProjectItem(request: IDeleteProjectItemRequest): Promise<DeleteProjectItemResponse>;
    createProjectItem(request: ICreateProjectItemRequest): Promise<CreateProjectItemResponse>;
    updateProjectItem(request: IUpdateProjectItemRequest): Promise<UpdateProjectItemResponse>;
    getItem(request: IGetItemRequest): Promise<GetItemResponse>;
    listItemPriceLists(request: IListItemPriceListsRequest): Promise<ListItemPriceListsResponse>;
    createActivityItem(request: ICreateActivityItemRequest): Promise<CreateActivityItemResponse>;
    getActivityItem(request: IGetActivityItemRequest): Promise<GetActivityItemResponse>;
    deleteItem(request: IDeleteItemRequest): Promise<DeleteItemResponse>;
}

export class ItemService extends ServiceBase implements IItemService {
    private _addRootElementForItem(xml:string, call:string):string{
        xml = xml.replaceAll('</count><ITEM>', '</count><ROWS><ITEM>');
        xml = xml.replaceAll(`</ITEM></ns1:${call}>`, `</ITEM></ROWS></ns1:${call}>`);
        return xml;
    }
    public async listProjectItemCalculation(request: IListProjectItemCalculationRequest): Promise<ListProjectItemCalculationResponse> {
        let response: any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), ITEM.listProjectItemCalculation, request.cancelSource));
            response = (tmp_response.data);
            await this.updateCache(ITEM.listProjectItemCalculation, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(ITEM.listProjectItemCalculation, request.toXml());
        }

        var parsedResponse = super.sendResponse(response).ListProjectItemCalculationResponse as ListProjectItemCalculationResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;        
    }

    public async listProjectItem(request: IListProjectItemRequest): Promise<ListProjectItemResponse> {
        let response: any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), ITEM.listProjectItem, request.cancelSource));
            response = this._addRootElementForItem(tmp_response.data, 'ListProjectItemResponse');
            await this.updateCache(ITEM.listProjectItem, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(ITEM.listProjectItem, request.toXml());
        }

        var parsedResponse = super.sendResponse(response).ListProjectItemResponse as ListProjectItemResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;        
    }

    public async listItem(request: IListItemRequest): Promise<ListItemResponse> {
        let response: any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), ITEM.listItem, request.cancelSource));
            response = this._addRootElementForItem(tmp_response.data, 'ListItemResponse');
            await this.updateCache(ITEM.listItem, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(ITEM.listItem, request.toXml());
        }

        var parsedResponse = super.sendResponse(response).ListItemResponse as ListItemResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;
    }

    public async deleteProjectItem(request: IDeleteProjectItemRequest): Promise<DeleteProjectItemResponse> {
        let response: any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), ITEM.deleteProjectItem, request.cancelSource));
            response = (tmp_response.data);
            await this.updateCache(ITEM.deleteProjectItem, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(ITEM.deleteProjectItem, request.toXml());
        }

        var parsedResponse = super.sendResponse(response).DeleteProjectItemResponse as DeleteProjectItemResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;
    }

    public async createProjectItem(request: ICreateProjectItemRequest): Promise<CreateProjectItemResponse> {
        let response: any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), ITEM.createProjectItem, request.cancelSource));
            response = (tmp_response.data);
            await this.updateCache(ITEM.createProjectItem, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(ITEM.createProjectItem, request.toXml());
        }

        var parsedResponse = super.sendResponse(response).CreateProjectItemResponse as CreateProjectItemResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;
    }

    public async updateProjectItem(request: IUpdateProjectItemRequest): Promise<UpdateProjectItemResponse> {
        let response: any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), ITEM.updateProjectItem, request.cancelSource));
            response = (tmp_response.data);
            await this.updateCache(ITEM.updateProjectItem, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(ITEM.updateProjectItem, request.toXml());
        }

        var parsedResponse = super.sendResponse(response).UpdateProjectItemResponse as UpdateProjectItemResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;
    }

    public async getItem(request: IGetItemRequest): Promise<GetItemResponse> {
        let response: any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), ITEM.getItem, request.cancelSource));
            response = (tmp_response.data);
            await this.updateCache(ITEM.getItem, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(ITEM.getItem, request.toXml());
        }

        var parsedResponse = super.sendResponse(response).GetItemResponse as GetItemResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;
    }

    public async listItemPriceLists(request: IListItemPriceListsRequest): Promise<ListItemPriceListsResponse> {
        let response: any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), ITEM.listItemPriceLists, request.cancelSource));
            response = this._addRootElementForItem(tmp_response.data, 'ListItemPriceListsResponse');
            await this.updateCache(ITEM.listItemPriceLists, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(ITEM.listItemPriceLists, request.toXml());
        }

        var parsedResponse = super.sendResponse2(response,['priceList', 'itemPrice']).ListItemPriceListsResponse as ListItemPriceListsResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;
    }

    public async createActivityItem(request: ICreateActivityItemRequest): Promise<CreateActivityItemResponse> {
        let response: any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), ITEM.createActivityItem, request.cancelSource));
            response = (tmp_response.data);
            await this.updateCache(ITEM.createActivityItem, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(ITEM.createActivityItem, request.toXml());
        }

        var parsedResponse = super.sendResponse(response).CreateActivityItemResponse as CreateActivityItemResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;
    }

    public async getActivityItem(request: IGetActivityItemRequest): Promise<GetActivityItemResponse> {
        let response: any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), ITEM.getActivityItem, request.cancelSource));
            response = (tmp_response.data);
            await this.updateCache(ITEM.getActivityItem, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(ITEM.getActivityItem, request.toXml());
        }

        var parsedResponse = super.sendResponse2(response,['ACTIVITY_ITEM']).GetActivityItemResponse as GetActivityItemResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;
        }

    public async deleteItem(request: IDeleteItemRequest): Promise<DeleteItemResponse> {
        let response: any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), ITEM.deleteItem, request.cancelSource));
            response = (tmp_response.data);
            await this.updateCache(ITEM.deleteItem, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(ITEM.deleteItem, request.toXml());
        }

        var parsedResponse = super.sendResponse(response).DeleteItemResponse as DeleteItemResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;
    }
}
