// import { View, Accordion, Disclosure, DisclosureTitle, DisclosurePanel, Flex, TextField, Button, ComboBox, Item, Heading, Divider } from '@adobe/react-spectrum';
// import React, { useState } from 'react';
// import { ContactFormData } from '../AddNewPerson';
// import ZipCodeDropDown from '../formComponents/ZipCodeDropDown';
// import { useDependency } from '../../../../../contexts/DependencyProvider';
// import { ListRegionRequest } from '../../../../../services/soap/translate/reqeusts/ListRegionRequest';
// import { ListCountryRequest } from '../../../../../services/soap/translate/reqeusts/ListCountryRequest';
// import { ListCityRequest } from '../../../../../services/soap/translate/reqeusts/ListCityRequest';
// import CityDropDown from '../formComponents/CityDropDown';
// import RegionDropDown from '../formComponents/RegionDropDown';
// import CountriesDropDown from '../formComponents/CountriesDropDown';
// import { usePreloadAssets } from '../../../../../hooks/UsePreloadAssets';

// interface IAddressSectionProps{
//   addresses: ContactFormData['addresses'];
//   onChange: (addresses: ContactFormData['addresses']) => void;
//   onAdd: () => void;
// }
// export const AddressSection: React.FC<IAddressSectionProps> = ({ addresses, onChange, onAdd }) => {
//   const{store,translateService} = useDependency();
//   const{addressTypes} = usePreloadAssets();
//   const handleZipChange = async (cityId:string,index:number,address:any)=>{
//     const newAddresses = [...addresses];
//     const city = await translateService.listCity(new ListCityRequest(store.Server,store.SessionId,{cityId:cityId}))
//     if(city.ROWS.ROW.length > 0){
//       const {id,cityName} = city.ROWS.ROW[0];
//       const region = await translateService.listRegion(new ListRegionRequest(store.Server,store.SessionId,{regionId:city.ROWS.ROW[0].region}))
//       if(region.ROWS.ROW.length > 0){
//           const {regionName,country} = region.ROWS.ROW[0];
//           const countries = await translateService.listCountry(new ListCountryRequest(store.Server,store.SessionId,{countryId:country})); 
//           if(countries.ROWS.ROW.length > 0){
//             const {countryName,countryId} = countries.ROWS.ROW[0];
//             newAddresses[index] = { ...address, city:cityName,region:regionName,country:countryName };
//             onChange(newAddresses);
//           }
//         }
//     }
//   }

//   const handleRemove = (index:any) => {
//     const newAddresses = addresses.filter((_, i) => i !== index);
//     onChange(newAddresses);
//   };

//   const addressOptions = Object.values(addressTypes) as [{
//     companyAddress:string,
//     employerAddress:string,
//     id:string,
//     invoiceAddress:string,
//     name:string,
//     permanentAddress:string,
//     personAddress:string,
//     shiptoAddress:string,
//     temporaryAddress:string
//   }];
//   const filteredAddressOptions = addressOptions.filter((a) => a.personAddress === '1');
//   console.log(filteredAddressOptions)
//   const handleAddressTypeChange = (id:any,index:number,address:any)=>{
//     const filteredType = filteredAddressOptions.find((x:any)=> x.id === id);
//     if(filteredType){
//         const newContacts = [...addresses];
//         newContacts[index] = { ...address, type:filteredType.id};
//         onChange(newContacts);
//     }
//   }
//   return(
//   <View padding="size-200" paddingBottom="size-0" paddingTop="size-300">
//     <Heading level={5}>Address</Heading>
//     <Divider />
//     {addresses.map((address, index) => (
//       <Accordion key={index} defaultExpandedKeys={["address"]} isQuiet>
//         <Disclosure id="address">
//           <DisclosureTitle>Addresses</DisclosureTitle>
//           <DisclosurePanel>
//             <Flex key={index} direction="column" gap="size-100" width='100%'>
//               <ComboBox
//                  label="Address Type"
//                  selectedKey={address.type}
//                  onSelectionChange={(key) => handleAddressTypeChange(key,index,address)}
//                  width='100%'
//                 >
//                  {filteredAddressOptions.map((option) => (
//                    <Item key={option.id}>{option.name}</Item>
//                    ))}
//               </ComboBox>
//               <TextField
//                 label="Street"
//                 value={address.street}
//                 onChange={(street) => {
//                   const newAddresses = [...addresses];
//                   newAddresses[index] = { ...address, street };
//                   onChange(newAddresses);
//                 }}
//                 width='100%' />
//               <ZipCodeDropDown onChange={(data) => {
//                   const {zip,cityName,city} = data
//                   const newAddresses = [...addresses];
//                   newAddresses[index] = { ...address, zip};
//                   onChange(newAddresses);
//                   handleZipChange(city,index,address)
//                 }} />
              
//               <CityDropDown selectedCityId={address.city || ''} onChange={(data) => {
//                     const {cityName,city} = data
//                     const newAddresses = [...addresses];
//                     newAddresses[index] = { ...address, city:cityName};
//                     onChange(newAddresses); 
//                 }}/>
//                 <RegionDropDown selectedRegion={address.region || ''} onChange={(data) => {
//                     const {regionName} = data
//                     const newAddresses = [...addresses];
//                     newAddresses[index] = { ...address, region:regionName};
//                     onChange(newAddresses); 
//                 }}
//                 />
//                 <CountriesDropDown selectedCountryId={address.country || ''} 
//                 onChange={(data) => {
//                   const {countryName} = data
//                   const newAddresses = [...addresses];
//                   newAddresses[index] = { ...address, country:countryName };
//                   onChange(newAddresses);
//                 }}
                  
//                 />
//                 <Button isHidden={index == 0} width="100%" variant="negative" onPress={() => handleRemove(index)}>
//                   Remove Address
//                 </Button>
//             </Flex>
//           </DisclosurePanel>
//         </Disclosure>
//       </Accordion>
//     ))}
//     <Button variant="secondary" onPress={onAdd}>Add Address</Button>
//   </View>
// );
// }
import { View, Accordion, Disclosure, DisclosureTitle, DisclosurePanel, Flex, TextField, Button, ComboBox, Item, Heading, Divider } from '@adobe/react-spectrum';
import React, { useEffect } from 'react';
import { ContactFormData } from '../AddNewPerson';
import ZipCodeDropDown from '../formComponents/ZipCodeDropDown';
import { useDependency } from '../../../../../contexts/DependencyProvider';
import { ListRegionRequest } from '../../../../../services/soap/translate/reqeusts/ListRegionRequest';
import { ListCountryRequest } from '../../../../../services/soap/translate/reqeusts/ListCountryRequest';
import { ListCityRequest } from '../../../../../services/soap/translate/reqeusts/ListCityRequest';
import CityDropDown from '../formComponents/CityDropDown';
import RegionDropDown from '../formComponents/RegionDropDown';
import CountriesDropDown from '../formComponents/CountriesDropDown';
import { usePreloadAssets } from '../../../../../hooks/UsePreloadAssets';

interface IAddressSectionProps {
  addresses: ContactFormData['addresses'];
  onChange: (addresses: ContactFormData['addresses']) => void;
}

export const AddressSection: React.FC<IAddressSectionProps> = ({ addresses, onChange }) => {
  const { store, translateService } = useDependency();
    const handleZipChange = async (zip:string,cityId:string,index:number,address:any)=>{
    const newAddresses = [...addresses];
    const currentCity = await translateService.listCity(new ListCityRequest(store.Server,store.SessionId,{cityId:cityId}))
    if(currentCity.ROWS.ROW.length > 0){
      const {id,city,cityName} = currentCity.ROWS.ROW[0];
      const currentRegion = await translateService.listRegion(new ListRegionRequest(store.Server,store.SessionId,{regionId:currentCity.ROWS.ROW[0].region}))
      if(currentRegion.ROWS.ROW.length > 0){
          const {regionName,region,country} = currentRegion.ROWS.ROW[0];
          const countries = await translateService.listCountry(new ListCountryRequest(store.Server,store.SessionId,{countryId:country})); 
          if(countries.ROWS.ROW.length > 0){
            const {countryName,country,countryId} = countries.ROWS.ROW[0];
            newAddresses[index] = { ...address, zip,city,region,country };
            onChange(newAddresses);
          }
        }
    }
  }
  return (
    <View padding="size-200" paddingBottom="size-0" paddingTop="size-300">
      <Heading level={5}>Address</Heading>
      <Divider aria-label='' />
      {addresses.map((address, index) => (
        <Accordion key={index} defaultExpandedKeys={["address_0"]} isQuiet>
          <Disclosure id={"address_"+index}>
            <DisclosureTitle>{address.name}</DisclosureTitle>
            <DisclosurePanel>
            <Flex key={index} direction="column" gap="size-100" width='100%'>
              <TextField
                label="Street"
                aria-label='Street'
                value={address.street}
                onChange={(street:any) => {
                  const newAddresses = [...addresses];
                  newAddresses[index] = { ...address, street };
                  onChange(newAddresses);
                }}
                width='100%' />
              <ZipCodeDropDown 
              selectedZip={address.zip} 
              onChange={(data) => {
                  const {zip,cityName,city} = data
                  handleZipChange(zip,city,index,address)
                }} />
              
              <CityDropDown 
              selectedCityId={address.city || ''}
              onChange={(data) => {
                    const {cityName,city} = data
                    const newAddresses = [...addresses];
                    newAddresses[index] = { ...address, city,cityName};
                    onChange(newAddresses); 
                }}/>
                <RegionDropDown selectedRegion={address.region || ''} onChange={(data) => {
                    const {region,regionName} = data
                    const newAddresses = [...addresses];
                    newAddresses[index] = { ...address, region,regionName};
                    onChange(newAddresses); 
                }}
                />
                <CountriesDropDown selectedCountryId={address.country || ''} 
                onChange={(data) => {
                  const {country,countryName} = data
                  const newAddresses = [...addresses];
                  newAddresses[index] = { ...address, country:country.toString()};
                  onChange(newAddresses);
                }}
                  
                />
                {/* <Button isHidden={index == 0} width="100%" variant="negative" onPress={() => handleRemove(index)}>
                  Remove Address
                </Button> */}
            </Flex>
            </DisclosurePanel>
          </Disclosure>
        </Accordion>
      ))}
    </View>
  );
};
