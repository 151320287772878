import { useState, useEffect } from "react";
import { ComboBox, Item, TagGroup, View, Flex, Divider, TextField, Heading, Checkbox } from "@adobe/react-spectrum";
import { useDependency } from "../../../../../contexts/DependencyProvider";
import { ListDistributionGroupRequest } from "../../../../../services/soap/main/requests/ListDistributionGroupRequest";
import { ContactFormData } from "../AddNewCompany";
import { EditIcon } from "../../../../../components/protected/Icons/IconsLib";
import { usePreloadAssets } from "../../../../../hooks/UsePreloadAssets";

interface IIndustrySectionProps {
  data: ContactFormData["Industries"];
  onChange: (data: ContactFormData["Industries"]) => void;
}

const IndustrySection: React.FC<IIndustrySectionProps> = ({ data, onChange }) => {
  const { store, mainService } = useDependency();
  const {industries} = usePreloadAssets();
  const [selectedIndustries, setSelectedIndustries] = useState<any[]>(data);
  const [expandedSections, setExpandedSections] = useState<Set<number>>(new Set());
  const [highlightedGroupId, setHighlightedGroupId] = useState<string | null>(null);

  const handleGroupSelection = (industryId: any) => {
    const existingIndex = data.findIndex((g) => g.industry === industryId);
    if (existingIndex !== -1) {
      setHighlightedGroupId(industryId);
      setTimeout(() => setHighlightedGroupId(null), 1000);
    } else {
      const selectedIndustry = industries.find((g) => g.id === industryId);
      if (selectedIndustry) {
        const newIndustry = { industry: selectedIndustry.id, industryName: selectedIndustry.name, description: "" };
        const newIndustries = [...data, newIndustry];
        //setSelectedIndustries(newIndustries);
        onChange(newIndustries);
      }
    }
  };

  const handleRemoveGroup = (index: number) => {
    const newGroups = data.filter((_, i) => i !== index);
    //setSelectedIndustries(newGroups);
    onChange(newGroups);
  };

  const onEdit = (index: number) => {
    setExpandedSections((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(index)) newSet.delete(index);
      else newSet.add(index);
      return newSet;
    });
  };

  const updatePrimary = (value: boolean, index: number) => {
    const primaryCount = data.filter(item => item.isPrimary).length;
    if (!value && primaryCount === 1) {
      return;
    }
    const newData = data.map((item, i) => ({
      ...item,
      isPrimary: i === index ? value === true ? '1' : '0' : '0',
    }));
    onChange(newData);
  };

  return (
    <View padding="size-200" paddingBottom="size-0" paddingTop="size-300">
      <Heading level={5}>Industries</Heading>
      <Divider aria-label="" />
      <Flex direction="column" gap="size-100" width="100%" marginTop={10}>
        {data.map((tag, index) => (
          <View
            key={index}
            UNSAFE_style={{
              transition: "color 0.5s ease-in-out",
              color: highlightedGroupId === tag.industry ? "red" : "black",
            }}
          >
            <Flex key={index} gap="size-100" direction="row" width="100%">
              <TagGroup
                items={data}
                onRemove={() => handleRemoveGroup(index)}
                aria-label="Removable TagGroup example"
                width="100%"
              >
                <Item aria-label={tag.industryName}>{tag.industryName}</Item>
              </TagGroup>
              {/* <a onClick={() => onEdit(index)}>
                <EditIcon size={20} />
              </a> */}
              <Checkbox isSelected={data[index].isPrimary === '1'}
              onChange={(value)=>updatePrimary(value,index)}></Checkbox>
            </Flex>
            <TextField
                    aria-label="Notes"
                    value={tag.description}
                    onChange={(description) => {
                      const newData = [...data];
                      newData[index] = { ...tag, description };
                      onChange(newData);
                    }}
                    width="100%"
                  />
          </View>
        ))}
        {data.length > 0 && 
          <Divider aria-label="" />
        }
        <ComboBox
          label="Industries"
          aria-label="Industries"
          onSelectionChange={(selectedKey) => handleGroupSelection(selectedKey)}
          width='100%'
        >
          {industries.map((industry) => (
            <Item aria-label={industry.name} key={industry.id}>{industry.name}</Item>
          ))}
        </ComboBox>
      </Flex>
    </View>
  );
};

export default IndustrySection;
