import { BaseRequest, IBaseRequest } from '../../BaseRequest';

export interface ISetSeenMessageRequest extends IBaseRequest {
    messageId: string;
}

export class SetSeenMessageRequest extends BaseRequest implements ISetSeenMessageRequest {
    public messageId: string;
    constructor(server: string, session: string, messageId: string) {
        super(server, session);
        this.messageId = messageId;
    }

    public toXml(): string {
        var parameters: string = super.toXml();
        parameters += '<messageId>' + this.messageId + '</messageId>';
        return super.wrapperXml('SetSeenMessage', parameters);
    }
}
