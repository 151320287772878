import { DialogContainer } from "@adobe/react-spectrum";

import { AlertDialog } from "@adobe/react-spectrum";
import { useTranslation } from "react-i18next";

interface DeleteConfirmationDialogProps {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
    itemName?: string;
    namespace?: string;
}

export const DeleteConfirmationDialog = ({ 
    isOpen, 
    onClose, 
    onConfirm, 
    itemName,
    namespace = 'finder' 
}: DeleteConfirmationDialogProps) => {
    const { t } = useTranslation();
    const _t = (key: string, params: any = {}) => t(`deleteConfirmation.${key}`, { ns: namespace, ...params });
    return (
        <DialogContainer onDismiss={onClose}>
            {isOpen && (
                <AlertDialog
                    variant="destructive"
                    title={_t('title').toString()}
                    primaryActionLabel={_t('delete').toString()}
                    cancelLabel={_t('cancel').toString()}
                    onPrimaryAction={() => {
                        onConfirm();
                        onClose();
                    }}
                    onCancel={onClose}
                >
                    {_t('message', { item: itemName }).toString()}
                </AlertDialog>
            )}
        </DialogContainer>
    );
};