import { useState, useEffect } from "react";
import { ComboBox, Item, TagGroup, View, Flex, Divider, TextField, Heading } from "@adobe/react-spectrum";
import { useDependency } from "../../../../../contexts/DependencyProvider";
import { ListDistributionGroupRequest } from "../../../../../services/soap/main/requests/ListDistributionGroupRequest";
import { ContactFormData } from "../AddNewPerson";
import { EditIcon } from "../../../../../components/protected/Icons/IconsLib";

interface IDistributedGroupsSectionProps {
  data: ContactFormData["DGroups"];
  onChange: (data: ContactFormData["DGroups"]) => void;
}

const DistributedGroupsSection: React.FC<IDistributedGroupsSectionProps> = ({ data, onChange }) => {
  const { store, mainService } = useDependency();
  const [distributionGroups, setDistributionGroups] = useState<any[]>([]);
  const [selectedGroups, setSelectedGroups] = useState<any[]>(data);
  const [expandedSections, setExpandedSections] = useState<Set<number>>(new Set());
  const [highlightedGroupId, setHighlightedGroupId] = useState<string | null>(null);

  useEffect(() => {
    (async () => {
      try {
        let res = await mainService.listDistributionGroup(
          new ListDistributionGroupRequest(store.Server, store.SessionId)
        );
        if (res.result === "OK" && res.DGROUP.length > 0) {
          setDistributionGroups(res.DGROUP);
        }
      } catch (error) {
        console.log("Error fetching distribution groups", error);
      }
    })();
  }, []);

  const handleGroupSelection = (groupId: any) => {
    const existingIndex = selectedGroups.findIndex((g) => g.id === groupId);

    if (existingIndex !== -1) {
      // Animate the already selected group by changing color to red then back to black
      setHighlightedGroupId(groupId);
      setTimeout(() => setHighlightedGroupId(null), 1000); // Reset color after 1 second
    } else {
      // Add the new group
      const selectedGroup = distributionGroups.find((g) => g.id === groupId);
      if (selectedGroup) {
        const newGroup = { id: selectedGroup.id, name: selectedGroup.name, comment: "" };
        const newGroups = [...selectedGroups, newGroup];
        setSelectedGroups(newGroups);
        onChange(newGroups);
      }
    }
  };

  const handleRemoveGroup = (index: number) => {
    const newGroups = selectedGroups.filter((_, i) => i !== index);
    setSelectedGroups(newGroups);
    onChange(newGroups);
  };

  const onEdit = (index: number) => {
    setExpandedSections((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(index)) newSet.delete(index);
      else newSet.add(index);
      return newSet;
    });
  };

  return (
    <View padding="size-200" paddingBottom="size-0" paddingTop="size-300">
      <Heading level={5}>Distribution Groups</Heading>
      <Divider aria-label="" />
      <Flex direction="column" gap="size-100" width="100%" marginTop={10}>
        {data.map((tag, index) => (
          <View
            key={index}
            UNSAFE_style={{
              transition: "color 0.5s ease-in-out",
              color: highlightedGroupId === tag.id ? "red" : "black",
            }}
          >
            <Flex key={index} gap="size-100" direction="row" width="100%">
              <TagGroup
                items={selectedGroups}
                onRemove={() => handleRemoveGroup(index)}
                aria-label="Removable TagGroup example"
                width="100%"
              >
                <Item aria-label={tag.name}>{tag.name}</Item>
              </TagGroup>
              <a onClick={() => onEdit(index)}>
                <EditIcon size={20} />
              </a>
            </Flex>

            {(expandedSections.has(index) || !!data[index].comment) &&(
              <Flex direction="column">
                <Flex direction="row" gap="size-100">
                  <TextField
                    aria-label="Notes"
                    value={tag.comment}
                    onChange={(description) => {
                      const newData = [...data];
                      newData[index] = { ...tag, comment: description };
                      onChange(newData);
                    }}
                    width="100%"
                  />
                </Flex>
              </Flex>
            )}
          </View>
        ))}
        {data.length > 0 && 
          <Divider aria-label="" />
        }
        <ComboBox
          label="Distribution Groups"
          aria-label="Distribution Groups"
          onSelectionChange={(selectedKey) => handleGroupSelection(selectedKey)}
          width='100%'
        >
          {distributionGroups.map((group) => (
            <Item aria-label={group.name} key={group.id}>{group.name}</Item>
          ))}
        </ComboBox>
      </Flex>
    </View>
  );
};

export default DistributedGroupsSection;
