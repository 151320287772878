import { FPAData, FPADataTypes } from '../../../../infra/protected/FPA/FPAData';
import { ComponentFactory } from '../../../../components/protected/layout-components/ComponentFactory';
import { useCallback, useEffect, useState } from 'react';
import { useDependency } from '../../../../contexts/DependencyProvider';
import { GetFormPanelRequest } from '../../../../services/soap/features/requests/GetFormPanelRequest';
import { SoapUtils } from '../../../../utils/SoapUtils';
import { LayoutDefinition } from '../../../../infra/protected/Layout/LayoutDefinitions';
import { View } from '@adobe/react-spectrum';
import styles from './FinderDetailsMobile.module.css';
import { DeleteConfirmationDialog } from './DeleteConfirmationDialog';
import { DeleteFolderRequest } from '../../../../services/soap/project/requests/DeleteFolderRequest';
import { DeleteProjectRequest } from '../../../../services/soap/project/requests/DeleteProjectRequest';
import { DeleteActivityRequest } from '../../../../services/soap/project/requests/DeleteActivityRequest';
import { ToastQueue } from '@react-spectrum/toast';
import { useTranslation } from 'react-i18next';

export interface IFinderDetailsMobileProps {
    selectedItem: FPAData | null;
    onClicked?: (item: FPAData) => void;
    onDelete?: (item: FPAData) => void;
}

export function FinderDetailsMobile ({
    selectedItem,
    onClicked,
    onDelete
}: IFinderDetailsMobileProps) {
    const { store, featureService, projectService } = useDependency();
    const [definition, setDefinition] = useState<LayoutDefinition | null>(null);
    const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const { t } = useTranslation();

    const _t = (key: string) => t(key, { ns: 'finder' });

    const getLayout = useCallback(async () => {
        try{
            if(selectedItem === null || selectedItem === undefined) return;
            if(selectedItem.type === FPADataTypes.FOLDER_TYPE || selectedItem.type === FPADataTypes.ITEM_TYPE) return;
            
            const layout = await featureService.getFormPanel(new GetFormPanelRequest(store.Server, store.SessionId, ""+selectedItem!.item_type_id));

            if(layout.count > 0) {
                var scheme = SoapUtils.parseXmlString2(layout.ROW.SCHEME);
                var layoutObject = JSON.parse(scheme.ActivityPanelSettings.layoutObject);
                setDefinition(layoutObject);    
            } else {
                setDefinition(null);
            }
        } catch (err){
            console.error('getlayout error: ',err);
        }
    }, [featureService, selectedItem, store.Server, store.SessionId]);

    useEffect(() => {
        getLayout();
    }, [getLayout]);

    if(selectedItem === null || selectedItem === undefined) return (<>Nothing is selected</>);
    if(selectedItem.type === FPADataTypes.FOLDER_TYPE || selectedItem.type === FPADataTypes.ITEM_TYPE) return (<>Folder type or Item type is selected</>);

    const styleWithVar: React.CSSProperties & { "--status-color"?: string } = {"--status-color" : selectedItem.statusColor};

    const handleDelete = (item: FPAData | null) => {
        if(item === null) return;
        setDeleteDialogOpen(true);
    }

    const handleConfirmDelete = async () => {
        if(selectedItem === null) return;
        if(onDelete) onDelete(selectedItem);
    }

    return (
    <>
        <div className={styles.container} onClick={() => { if(onClicked) onClicked(selectedItem); }}>
            <View UNSAFE_className={styles.container} UNSAFE_style={ styleWithVar } padding={20}>
                <ComponentFactory definition={definition} 
                    selectedItem={selectedItem}
                    systemAttributes={{
                        onPrint: (item) => console.log('onPrint', item),
                        onEdit: (item) => console.log('onEdit', item),
                        onDelete: (item) => handleDelete(item),
                        onChat: (item) => console.log('onChat', item),
                        onConfig: (item, type) => console.log('onConfig', item, type)
                    }} />
            </View>
        </div>
        <DeleteConfirmationDialog
            isOpen={isDeleteDialogOpen}
            onClose={() => setDeleteDialogOpen(false)}
            onConfirm={handleConfirmDelete}
            itemName={selectedItem?.title}
            namespace="finder"
        />
    </>);
}
