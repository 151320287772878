import { useCallback, useEffect, useRef, useState } from 'react';
import { LayoutComponent } from '../LayoutComponent';
import { Button, Content, Flex, Heading, IllustratedMessage, ProgressCircle, View } from '@adobe/react-spectrum';
import Unavailable from '@spectrum-icons/illustrations/Unavailable';
import { FPAData, FPADataTypes } from '../../../../infra/protected/FPA/FPAData';
import { useDependency } from '../../../../contexts/DependencyProvider';
import { GetActivityRequest } from '../../../../services/soap/project/requests/GetActivityRequest';
import { GetProjectRequest } from '../../../../services/soap/project/requests/GetProjectRequest';
import { GetFolderRequest } from '../../../../services/soap/project/requests/GetFolderRequest';
import { ListFormsRequest } from '../../../../services/soap/form/requests/ListFormsRequest';
import { Component, FormDefinition } from './components/FormDefinitions';
import { SoapUtils } from '../../../../utils/SoapUtils';
import { FormControl } from './components/FormControl';
import { ListItemValueRequest } from '../../../../services/soap/form/requests/ListItemValueRequest';
import { Rows } from '../../../../services/soap/form/responses/ListItemValueResponse';
import { ItemValue, UpdateItemValueRequest } from '../../../../services/soap/form/requests/UpdateItemValueRequest';
import { ToastQueue } from '@react-spectrum/toast';
import { GetFolderProjectTypeRequest } from '../../../../services/soap/project/requests/GetFolderProjectTypeRequest';
import { useTranslation } from 'react-i18next';
import { AtollonEvent } from '../../../../infra/events/AtollonEvent';
import { usePreloadAssets } from '../../../../hooks/UsePreloadAssets';
import { ListCurrencyRateRequest } from '../../../../services/soap/finance/requests/ListCurrencyRateRequest';

export interface IFormViewProps {
    formIdType?: string;
    formId?: string;
    formIdMode?: string;
    isSaveEnabled?: boolean;
    isSaveOnRightSide?: boolean;
    selectedItem?: FPAData;
    isNew?: boolean;
    setFormViewData?: (key: string, value: any) => void;
    getFormViewData?: (key: string, default_value: any) => any;
}

function FormView ({
    formIdType,
    formId,
    formIdMode,
    isSaveEnabled,
    isSaveOnRightSide,
    selectedItem,
    isNew,
    setFormViewData,
    getFormViewData
}: IFormViewProps) {
  // const selectedItem = useSelector((state: RootState) => state.finder.selectedItem);

  const { 
    formService,
    projectService, 
    financeService,
    store 
  } = useDependency();

  const [formDefinition, setFormDefinition] = useState<FormDefinition|null>(null);
  const [formData, setFormData] = useState<Map<string, any>>(new Map<string, any>());
  const realFormId = useRef('0');
  const formAtollonIdToFormItemId = useRef<Map<string, string>>(new Map<string, string>());
  const scriptTimerRef = useRef<NodeJS.Timer | null>(null);
  const { t } = useTranslation();
  const [showLoader, setShowLoader] = useState<boolean>(true);
  const { currencies } = usePreloadAssets();
  const currencyRates = useRef<Map<string, any>>(new Map<string, any>());

  const _t = (key: string) => t( `formView.${key}`, { ns: 'layout-components' });

  var self: FPAData | undefined | null = null;

  switch(formIdType) {
    case 'self':
      self = selectedItem;
      break;
    case 'project':
      self = selectedItem;
      while(self && self.type !== FPADataTypes.PROJECT) {
        self = self.parent;
      };
      break;
    case 'folder':
      self = selectedItem;
      while(self && self.type !== FPADataTypes.FOLDER) {
        self = self.parent;
      };
      break;
  }

  const generateIdToFormItemId = (components: Component[]) => {
    components.forEach((component: Component) => {
      if(component.PROPERTIES.id && component.FORMITEM) {
        formAtollonIdToFormItemId.current.set(component.PROPERTIES.id, component.FORMITEM);
        formAtollonIdToFormItemId.current.set(component.FORMITEM, component.PROPERTIES.id);
      }

      if(component.CHILDREN) {
        generateIdToFormItemId(component.CHILDREN.COMPONENT);
      }
    });
  }

  const handleSaveForm = async () => {
      const valuesArray = Array.from(formData.values());

      const passed_values = valuesArray.map((value: any) => {
        return new ItemValue( value.id, value.acl, value.formInfo, value.formItem, value.formItemName, value.value, value.typ, value.context);
      });

      var save_response = await formService.updateItemValue(new UpdateItemValueRequest(store.Server, store.SessionId, passed_values));
      if(save_response.result === 'OK') {
        ToastQueue.positive('Form saved successfully', { timeout: 3000 });
      } else {
        ToastQueue.negative('Error saving form', { timeout: 3000 });
      }
  };

  const loadFormCallback = useCallback(async () => {
    setShowLoader(true);
    try{
      if(self === null || self === undefined) return;
      if(self.type === FPADataTypes.FOLDER_TYPE || self.type === FPADataTypes.ITEM_TYPE) return;
      
      var form_id = null; 
      var formValuesId = null;

      if(isNew) {
        form_id = formId;
      } else {
        switch(self.type) {
            case FPADataTypes.ACTIVITY:
              var activity = (await projectService.getActivity(new GetActivityRequest(store.Server, store.SessionId, self.id))).ACTIVITY;
              form_id = formIdMode === 'template' ? activity.formId : formId;
              formValuesId = activity.formValues;
              break;
            case FPADataTypes.PROJECT:
              var project = (await projectService.getProject(new GetProjectRequest(store.Server, store.SessionId, self.id))).PROJECT;
              form_id = formIdMode === 'template' ? project.formId : formId;
              formValuesId = project.formValues;
              break;
            case FPADataTypes.FOLDER:
              var folder = (await projectService.getFolder(new GetFolderRequest(store.Server, store.SessionId, self.id))).FOLDER;
              form_id = formIdMode === 'template' ? folder.formId : formId;
              formValuesId = folder.formValues;
              break;
        }
      }
  
      if(formIdMode === 'type'){
        var type_info = await projectService.getFolderProjectType(new GetFolderProjectTypeRequest(store.Server, store.SessionId, self.item_type_id));
        form_id = type_info.TYPE[0].formId;
      }
  
      if(!form_id) return;
  
      realFormId.current = formValuesId!;
      var form = await formService.listForms(new ListFormsRequest(store.Server, store.SessionId, form_id!));
  
      var jsonObject = SoapUtils.parseXmlString2(form.ROW.definition, ['COMPONENT']);
      setFormDefinition(jsonObject);
      generateIdToFormItemId(jsonObject.definition.gui.COMPONENT);
      
      if(jsonObject.definition && jsonObject.definition.scripts) {
        eval(jsonObject.definition.scripts);
      }
      if(!isNew) {
        var formValues = await formService.listItemValue(new ListItemValueRequest(store.Server, store.SessionId, formValuesId!));
        
        if(formValues.count > 0) {
          var valuesMap = formValues.ROWS.reduce((acc: Map<string, any>, row: Rows) => { 
            acc.set(row.ITEMVALUE.formItem, row.ITEMVALUE);              
            return acc
          }, new Map<string, any>());
          setFormData(valuesMap);
        }
      } 
    } catch (e) {
      console.error(e);
    } finally {
      setShowLoader(false);
    }

  }, [self]);

  const updateData = (key: string, value: any) => {
    if(setFormViewData){
      setFormViewData(key, value);
    }
    setFormData(prevFormData => {
      const newMap = new Map(prevFormData);
      let modified_item = null;
      
      if(newMap.has(key)) {
        modified_item = {...newMap.get(key), value: value};
      } else {
        modified_item = {
          value: value,
          id: null,
          acl: '',
          formInfo: realFormId.current,
          formItem: key,
          formItemName: '',
          typ: '',
          context: ''
        }
      }
      
      newMap.set(key, modified_item);
      return newMap;
    });

    const formItemId = formAtollonIdToFormItemId.current.get(key);
    if(formItemId) {
      window.dispatchEvent(new AtollonEvent(AtollonEvent.FORM_VALUE_UPDATED, {componentId: formItemId, value: value}));
    }
  };

  const getData = (key: string, default_value: any) => {
    if(getFormViewData && isNew){
      return getFormViewData(key, default_value);
    }
    var item = formData.get(key);
    return formData.has(key) && item.value ? item.value : default_value;
  }

  useEffect(() => {
    loadFormCallback();
  }, [loadFormCallback]);

  function handleFormValueChanged(event: AtollonEvent) {
    console.log('FORM_VALUE_CHANGED', event.componentId, event.Value);
    if(event.componentId && formAtollonIdToFormItemId.current.has(event.componentId)) {
      updateData(formAtollonIdToFormItemId.current.get(event.componentId)!, event.Value);
    }
  }

  async function handleRequestData(event: AtollonEvent) {
    console.log('REQUEST_DATA', event.componentId, event.Value);
    if(!event.componentId){
      window.dispatchEvent(new AtollonEvent(AtollonEvent.DATA_ERROR, {componentId: event.componentId, value: event.Value, error: 'Component ID is required'}));
      return;
    }
    
    if(AtollonEvent.ALLOWED_REQUEST_DATA_COMPONENTS.includes(event.componentId)) {
      switch(event.componentId) {
        case 'currency':
          window.dispatchEvent(new AtollonEvent(AtollonEvent.DATA_RECEIVED, {componentId: event.componentId, value: event.Value}));
          break;
        case 'currencyRate':
          var currency = currencies.find((currency: any) => currency.code === event.Value.value);
          if(currency) {
            if(currencyRates.current.has(currency.id)) {
              window.dispatchEvent(new AtollonEvent(AtollonEvent.DATA_RECEIVED, {componentId: event.componentId, value: { rate: currencyRates.current.get(currency.id).rate, tag: event.Value.tag }}));
            } else {
              var currency_rate = await financeService.listCurrencyRate(new ListCurrencyRateRequest(store.Server, store.SessionId, {
                currency: currency.id,
                rateDate: new Date().toISOString().split('T')[0]
              }));
              console.log('currency_rate', currency_rate);
              if(currency_rate.EXCEPTION) {
                window.dispatchEvent(new AtollonEvent(AtollonEvent.DATA_ERROR, {componentId: event.componentId, value: event.Value, error: 'Currency rate not found'}));
              } else {
                currencyRates.current.set(currency.id, { rate: +currency_rate.currencyRate.rate, tag: event.Value.tag });
                window.dispatchEvent(new AtollonEvent(AtollonEvent.DATA_RECEIVED, {componentId: event.componentId, value: { rate: +currency_rate.currencyRate.rate, tag: event.Value.tag }}));
              }
            }
          } else {
            window.dispatchEvent(new AtollonEvent(AtollonEvent.DATA_ERROR, {componentId: event.componentId, value: event.Value, error: 'Currency not found'}));
          }
          break;
      }
    } else {
      window.dispatchEvent(new AtollonEvent(AtollonEvent.DATA_ERROR, {componentId: event.componentId, value: event.Value, error: 'Component ID is not allowed to request data'}));
    }
  }

  useEffect(() => {
    window.addEventListener(AtollonEvent.FORM_VALUE_CHANGED, handleFormValueChanged);
    window.addEventListener(AtollonEvent.REQUEST_DATA, handleRequestData);

    if(!scriptTimerRef.current) {
      scriptTimerRef.current = setInterval(() => {
        if((window as any).removeScriptListeners) {
          console.log('sending component loaded event');
          window.dispatchEvent(new AtollonEvent(AtollonEvent.FORM_COMPONENT_LOADED, {componentId: 'currency', value: '1'}));
          clearInterval(scriptTimerRef.current!);
        }
      }, 100);
    }

    return () => {
      window.removeEventListener(AtollonEvent.FORM_VALUE_CHANGED, handleFormValueChanged);
      window.removeEventListener(AtollonEvent.REQUEST_DATA, handleRequestData);

      if((window as any).removeScriptListeners) {
        console.log('Removing script listeners');
        (window as any).removeScriptListeners();
      } else {
        console.warn('No removeScriptListeners function found');
      }
      (window as any).removeScriptListeners = null;
      if(scriptTimerRef.current) {
        clearInterval(scriptTimerRef.current);
        scriptTimerRef.current = null;
      }
    }
  }, [currencies]);

  if (showLoader) {
    return (
      <Flex width="100%" justifyContent={'center'} marginTop={10}>
        <ProgressCircle aria-label="Loading…" isIndeterminate />
      </Flex>
    );
  }
  
  // if(formDefinition === null)
  //   return (
  //     <>
  //       <IllustratedMessage>
  //         <Unavailable />
  //         <Heading>{_t('missingDefinition.title')}</Heading>
  //         <Content>
  //           {_t('missingDefinition.message')}
  //         </Content>

  //       </IllustratedMessage>
  //     </>
  //   );
  // else 
  if (formDefinition === null || !formDefinition.definition || !formDefinition.definition.gui || !formDefinition.definition.gui.COMPONENT)
    return (
      <>
       
      </>
    );
  else
    return (
      <>
      <Flex direction={'column'} gap={10}>
        <View>
          <FormControl component={formDefinition.definition.gui.COMPONENT[0]} getData={getData} updateData={updateData} />
        </View>
        {isSaveEnabled && (<View alignSelf={isSaveOnRightSide ? 'end' : 'start'}>
          <Button variant="cta" onPress={() => handleSaveForm()}>{_t('button.save')}</Button>
        </View>)}
      </Flex>
      </>
    );
}

export const FormViewComponent = LayoutComponent(FormView);