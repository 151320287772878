import * as React from 'react';
import { LayoutDefinition } from '../../../infra/protected/Layout/LayoutDefinitions';
import { FPAData } from '../../../infra/protected/FPA/FPAData';

export interface ILayoutComponentProps {
  percentWidth?: number;
  percentHeight?: number;
  width?: number;
  height?: number;
  gap?: number;
  children?: React.ReactNode | LayoutDefinition[];
  selectedItem?: FPAData;
}

export function LayoutComponent<P extends object>(WrappedComponent: React.ComponentType<P>) {
  const withLayoutPropsComponent: React.FC<P & ILayoutComponentProps> = ({
    percentHeight, 
    percentWidth, 
    width, 
    height, 
    ...props
  }) => {
    const getComputedStyle = () => {
      let style: any = {};
      if (percentWidth) {
        style.width = `${percentWidth}%`;
      }
      if (percentHeight) {
        style.height = `${percentHeight}%`;
      }
      if (width) {
        style.width = `${width}px`;
      }
      if (height) {
        style.height = `${height}px`;
      }
      // style.border = "solid 3px red";

      return style;
    }
    return (
      <div style={getComputedStyle()}>
        <WrappedComponent {...props as P} />
      </div>
    );
  }

  return withLayoutPropsComponent;
}
