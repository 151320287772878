import { useCallback, useEffect, useMemo, useState, lazy, useRef } from 'react';
import async from 'async';
import { useDependency } from '../../../contexts/DependencyProvider';
import { ActionButton, Flex, View, Text } from '@adobe/react-spectrum';
import useDeviceType from '../../../hooks/UseDeviceType';
import { AllVacanciesIcon, MyVacanciesIcon, EmptyIcon } from '../../../components/protected/Icons/IconsLib';
import Search from '@spectrum-icons/workflow/Search';
import Filter from '@spectrum-icons/workflow/Filter';
//import kanbanData from './data_new.json';
import FilterDialogTemplate from './components/FilterDialogTemplate';
import { RctDashboardRequest } from '../../../services/rest/recruitment/requests/RctDashboardRequest';
import { ListStatusRequest } from '../../../services/soap/project/requests/ListStatusRequest';
import Loader from '../../../components/ui/Loader';
import { ToastQueue } from '@react-spectrum/toast';
import memoizeOne from 'memoize-one';
import SearchDialogTemplate from './components/SearchDialogTemplate';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { updateAppInfo } from '../../../state/userWebLayout/userWebLayoutSlice';
import { ListPlacementConfigurationRequest } from '../../../services/soap/recruitment/requests/ListPlacementConfigurationRequest';
import { addKey, useKeyboardShortcuts } from '../../../hooks/UseKeyboardShortcuts';
import { ListRegisterUtils } from '../../../utils/ListRegisterUtils';
import { ListRegisterRequest } from '../../../services/soap/features/requests/ListRegisterRequest';
import { CreateRegisterRequest } from '../../../services/soap/features/requests/CreateRegisterRequest';
import useResize from '../../../hooks/UseResize';
import './recruitment.css';
import { UpdateRegisterRequest } from '../../../services/soap/features/requests/UpdateRegisterRequest';

enum ColumnType {
  First_Column = 'Vacancy',
  Second_Column = 'Current status',
  Third_Column = 'Next steps',
  Fourth_Column = 'No Applicant',
}

const RecruitmentComponent = lazy(() => import('./components/RecruitmentComponent'));
export function RecruitmentDashboard() {
  const PAGE_LIMIT: number = 20;
  const PAGE_OFFSET: number = 20;
  const dataStore = useSelector((state: any) => state.userWebLayout.appInfos);
  //const { projectStatusMapping } = usePreloadAssets();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { store, recruitmentRestService, projectService, recruitmentService, featureService } = useDependency();
  const [showFilterDialog, setShowFilterDialog] = useState<boolean>(false);
  const [showSearchDialog, setShowSearchDialog] = useState<boolean>(false);

  const [slaveData, setSlaveData] = useState<any>([]);
  const [masterData, setMasterData] = useState<any>([]);
  const [uniqueStatus, setUniqueStatus] = useState<any>([]);
  const [showLoader, setShowLoader] = useState<boolean>(true);
  const [vacancyFormLabels, setVacancyFormLabels] = useState<any>({});
  const [isShowSearch, setIsShowSearch] = useState(false);
  const [noRecord, setNoRecord] = useState<boolean>(false);
  const [pageOffset, setPageOffset] = useState<number>(0);
  const [scrollTopValue, setScrollTopValue] = useState<any>(null);
  const [scrollTopCurrentValue, setScrollTopCurrentValue] = useState<any>(null);
  const [queryParams, setQueryParams] = useState<any>('');
  const [filtersSetting, setFiltersSetting] = useState<any>(null);
  const [projectStatusMapping, setProjectStatusMapping] = useState<any>(null);
  const [projectStatusList, setProjectStatusList] = useState<any>([]);
  const [projectStatusPriorty, setProjectStatusPriorty] = useState<any>({});
  const [selectedColumn, setSelectedColumn] = useState<any>([]);
  const [width, height] = useResize();
  const dataRef = useRef<any>({});
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const { isSmallDevice } = useDeviceType();
  const { t } = useTranslation();
  let timeoutId: any;

  var column_temp: any = null;
  const filter_calls = [{ moduleId: ListRegisterUtils.Modules.RctDashboard, key: ListRegisterUtils.Keys.RctDashboard.FilterSettings, level: ListRegisterUtils.Levels.RctDashboard.Default }];

  useEffect(() => {
    dataRef.current.vacancyFormLabels = vacancyFormLabels;
  }, [vacancyFormLabels]);

  useEffect(() => {
    if (state.appKey && dataStore[state.appKey]) {
      if (dataStore[state.appKey].masterData) {
        dataRef.current.pageOffset = dataStore[state.appKey].pageOffset;
        setPageOffset(dataStore[state.appKey].pageOffset);

        dataRef.current.masterData = JSON.parse(dataStore[state.appKey].masterData);
        setMasterData(JSON.parse(dataStore[state.appKey].masterData));

        dataRef.current.slaveData = JSON.parse(dataStore[state.appKey].slaveData);
        setSlaveData(JSON.parse(dataStore[state.appKey].slaveData));

        dataRef.current.uniqueStatus = JSON.parse(dataStore[state.appKey].uniqueStatus);
        setUniqueStatus(JSON.parse(dataStore[state.appKey].uniqueStatus));

        setVacancyFormLabels(JSON.parse(dataStore[state.appKey].vacancyFormLabels));

        setProjectStatusMapping(JSON.parse(dataStore[state.appKey].projectStatusMapping));
        dataRef.current.projectStatusMapping = JSON.parse(dataStore[state.appKey].projectStatusMapping);

        setProjectStatusList(JSON.parse(dataStore[state.appKey].projectStatusList));
        dataRef.current.projectStatusList = JSON.parse(dataStore[state.appKey].projectStatusList);

        setProjectStatusPriorty(JSON.parse(dataStore[state.appKey].projectStatusPriorty));
        dataRef.current.projectStatusPriorty = JSON.parse(dataStore[state.appKey].projectStatusPriorty);

        setScrollTopValue(dataStore[state.appKey].scrollTopValue);
        dataRef.current.scrollTopCurrentValue = dataStore[state.appKey].scrollTopValue;

        setQueryParams(dataStore[state.appKey].queryParams ? JSON.parse(dataStore[state.appKey].queryParams) : null);
        dataRef.current.queryParams = dataStore[state.appKey].queryParams;

        setFiltersSetting(dataStore[state.appKey].filtersSetting ? JSON.parse(dataStore[state.appKey].filtersSetting) : null);
        dataRef.current.filtersSetting = dataStore[state.appKey].filtersSetting;

        setSelectedColumn(dataStore[state.appKey].selectedColumn);
        dataRef.current.selectedColumn = dataStore[state.appKey].selectedColumn;

        setTimeout(() => {
          //console.log('Set Time Out', dataStore[state.appKey].scrollTopValue);
          setShowLoader(false);
          resetScrollPosition(dataStore[state.appKey].scrollTopValue);
        }, 2000);
        return;
      }
    }
    localStorage.removeItem('kanbanRCT_Kanban');
    loadData();
  }, [dataStore.appInfos, state.appKey]);

  useEffect(() => {
    //Restore scroll position on page load
    if (scrollRef.current && localStorage.getItem('kanban_scrollPosition_saved') !== null) {
      //console.log('scrollPos userEffect', localStorage.getItem('kanban_scrollPosition_saved'));
      timeoutId = setTimeout(() => {
        //console.log("localStorage.getItem('kanban_scrollPosition_saved')", localStorage.getItem('kanban_scrollPosition_saved'));
        if (scrollRef.current) scrollRef.current.scrollTop = Number(localStorage.getItem('kanban_scrollPosition_saved') ?? 0);
        setScrollTopCurrentValue(localStorage.getItem('kanban_scrollPosition_saved'));
        localStorage.removeItem('kanban_scrollPosition_saved');
      }, 2000);

      return () => {
        //console.log('Cleaning up timeout');
        clearTimeout(timeoutId);
      };
    }
  }, [uniqueStatus]);

  useEffect(() => {
    return () => {
      // console.log('dataRef.current.masterData', dataRef.current.masterData);
      // console.log('dataRef.current.slaveData', JSON.stringify(dataRef.current.slaveData));
      // console.log('dataRef.current.uniqueStatus', JSON.stringify(dataRef.current.uniqueStatus));
      // console.log('dataRef.current.vacancyFormLabels', JSON.stringify(dataRef.current.vacancyFormLabels));
      // console.log('dataRef.current.scrollTopCurrentValue', dataRef.current.scrollTopCurrentValue);
      // console.log('dataRef.current.projectStatusMapping', JSON.stringify(dataRef.current.projectStatusMapping));
      // console.log('dataRef.current.projectStatusPriorty', JSON.stringify(dataRef.current.projectStatusPriorty));
      // console.log('dataRef.current.projectStatusList', JSON.stringify(dataRef.current.projectStatusList));
      // console.log('dataRef.current.queryParams', dataRef.current.queryParams);
      // console.log('dataRef.current.filtersSetting', dataRef.current.filtersSetting);
      // console.log('dataRef.current.selectedColumn', dataRef.current.selectedColumn);

      dispatch(
        updateAppInfo({
          key: state.appKey,
          data: {
            pageOffset: dataRef.current.pageOffset,
            masterData: JSON.stringify(dataRef.current.masterData),
            slaveData: JSON.stringify(dataRef.current.slaveData),
            uniqueStatus: JSON.stringify(dataRef.current.uniqueStatus),
            vacancyFormLabels: JSON.stringify(dataRef.current.vacancyFormLabels),
            scrollTopValue: dataRef.current.scrollTopCurrentValue,
            projectStatusMapping: JSON.stringify(dataRef.current.projectStatusMapping),
            projectStatusPriorty: JSON.stringify(dataRef.current.projectStatusPriorty),
            projectStatusList: JSON.stringify(dataRef.current.projectStatusList),
            queryParams: dataRef.current.queryParams,
            filtersSetting: dataRef.current.filtersSetting,
            selectedColumn: dataRef.current.selectedColumn,
          },
        })
      );
    };
  }, []);

  function resetScrollPosition(value: any) {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = Number(value);
    }
  }

  useKeyboardShortcuts([...addKey('f', { ctrl: true, shift: false }, () => searchDialogOpen())]);

  //Get Vacancy Form Label
  const getFormLabelText = (projectStatusMapping: any) => {
    recruitmentService.listPlacementConfiguration(new ListPlacementConfigurationRequest(store.Server, store.SessionId)).then(async (res: any) => {
      if (res.result === 'OK') {
        //console.log('listPlacementConfiguration', res.ROWS[0].ROW);
        let placementConfigration = res.ROWS[0].ROW;

        const updatedPlacementConfigration: { [key: string]: any } = {};
        //console.log(placementConfigration);
        Object.keys(projectStatusMapping).length &&
          async.forEachOf(placementConfigration, (value: any, key: any) => {
            if (projectStatusMapping.hasOwnProperty(value)) {
              updatedPlacementConfigration[key] = projectStatusMapping[value].name;
            }
            //console.log(updatedPlacementConfigration);
            Object.keys(updatedPlacementConfigration).length && setVacancyFormLabels(updatedPlacementConfigration);
          });
      }
    });
  };

  // Helper function to check if an object with the same id exists in the array
  const hasObjectWithId = (arr: any[], id: string, stateName: string) => {
    return arr.some(obj => obj.id === id && obj.slave_customStateName == stateName);
  };

  const getStatusArray = async () => {
    const statusArray: any = [];
    let res: any = await projectService.listProjectStatus(new ListStatusRequest(store.Server, store.SessionId, 1, 'com.atollon.project.fpatypecategory.recruitment.placement'));

    if (res.result === 'OK' && res.STATUS_LIST?.length > 0) {
      const projectStatusColor: any = res.STATUS_LIST.reduce((acc: any, val: any) => ({ ...acc, [val.STATUS.id]: { color: val.STATUS.color, name: val.STATUS.name } }), {});

      let projectStatusPriorty = res.STATUS_LIST.reduce((acc: any, val: any) => ({ ...acc, [val.STATUS.name]: { priority: val.STATUS.priority } }), {});

      res?.STATUS_LIST.forEach((status: any) => {
        // Extract id and name from each STATUS object
        const { id, name } = status.STATUS;
        // Push an object containing id and name to the statusArray
        statusArray.push({ id, name });
      });
      //console.log('statusArray', statusArray);
      setProjectStatusList(statusArray);
      dataRef.current.projectStatusList = statusArray;
      setProjectStatusMapping(projectStatusColor);
      dataRef.current.projectStatusMapping = projectStatusColor;
      getFormLabelText(projectStatusColor);

      //console.log('projectStatusPriorty', projectStatusPriorty);
      setProjectStatusPriorty(projectStatusPriorty);
      dataRef.current.projectStatusPriorty = projectStatusPriorty;
    }
    return statusArray;
  };

  const getSelectedColumns = async (filtersSetting: any, statusArray: any) => {
    let selected_column_temp: any[] = [];

    if (filtersSetting === null) {
      for (let call of filter_calls) {
        const { count, ROWS } = await featureService.listRegister(new ListRegisterRequest(store.Server, store.SessionId, call.moduleId, call.key, 1, call.level));
        if (count > 0) {
          column_temp = { [`${call.moduleId}_${call.key}`]: ROWS[0].ROW };
          //console.log('column_temp', column_temp);
          if (column_temp === undefined || column_temp === null) return selected_column_temp;
          let column_key = `${ListRegisterUtils.Modules.RctDashboard}_${ListRegisterUtils.Keys.RctDashboard.FilterSettings}`;
          //console.log('column_temp', column_temp[column_key]);
          const column_settings = column_temp[column_key].value ? JSON.parse(column_temp[column_key].value) : null;
          setFiltersSetting(column_temp);
          dataRef.current.filtersSetting = JSON.stringify(column_temp);
          selected_column_temp = column_settings.selectedColumnsReef;
        } else {
          const columnIDs = statusArray.map((item: any) => item.id);
          selected_column_temp = columnIDs;
          let filterObject = {
            vacancyTypeReef: null,
            vacancyInitialReef: true,
            vacancyActiveReef: true,
            vacancyClosedReef: true,
            vacancyStatusReef: [],
            clientStatusReef: [],
            stateInitialReef: true,
            stateActiveReef: true,
            stateClosedReef: true,
            contactPersonIDReef: '',
            contactPersonReef: null,
            responsibleUserReef: null,
            contactPersonID: null,
            selectedColumnsReef: selected_column_temp.map((item: any) => item),
          };
          const newRegister = await featureService.createRegister(new CreateRegisterRequest(store.Server, store.SessionId, call.moduleId, call.key, call.level, JSON.stringify(filterObject)));
          //console.log('Dashboard newRegister', newRegister);
        }
      }
    } else {
      //console.log('filtersSetting else', filtersSetting);
      if (filtersSetting !== null) {
        var objectKey = `${ListRegisterUtils.Modules.RctDashboard}_${ListRegisterUtils.Keys.RctDashboard.FilterSettings}`;
        const filterOptions = filtersSetting[objectKey] ? JSON.parse(filtersSetting[objectKey].value) : null;
        //console.log('filtersSetting else filterOptions', filterOptions);
        //console.log('filtersSetting.selectedColumnsReef', selected_column_temp);
        selected_column_temp = filterOptions?.selectedColumnsReef;
      }
    }

    return selected_column_temp;
  };

  const memoizedLoadKanbanData = memoizeOne(async ({ ...params }, selected_column_temp, statusArray) => {
    try {
      //console.log('params', params);

      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (key?.startsWith('kanban_data_')) {
          localStorage.removeItem(key);
        }
      }

      // const statusArray = await getStatusArray();
      // //GET SELECTED COLUMN FROM SETTING
      // const selected_column_temp = await getSelectedColumns(filtersSetting, statusArray);

      let response = await recruitmentRestService.getRctDashboard(new RctDashboardRequest(store.Server, store.SessionId, params));
      //console.log('response', response.items);

      if (response.items.length) {
        let data = response.items;

        let _masterData = data.filter((item: any) => item.recordType !== 'slave');

        console.log('masterData', _masterData);
        setMasterData(_masterData);
        dataRef.current.masterData = _masterData;

        let slaveData = data.filter((item: any) => item.recordType === 'slave');

        //Add missing master record in to Slave that don't have Applicants

        const isRecordExist = (id: number) => {
          return slaveData.some((record: any) => record.id === id);
        };

        const _masterDataUpdate = _masterData.map(item => {
          if (item.recordType === 'clientwithoutvacancy') {
            return {
              ...item,
              id: item.folderId,
            };
          }
          return item;
        });

        // Iterate over each record in the second array
        _masterDataUpdate.forEach((record: any) => {
          // Check if the recordType is 'master' and its id is not present in the first array
          if ((record.recordType === 'master' || record.recordType === 'clientwithoutvacancy') && !isRecordExist(record.id)) {
            // Create a new record with recordType changed to 'slave' and missing properties added
            const newRecord = {
              ...record,
              recordType: 'slave',
              slave_id: 0,
              slave_name: '',
              slave_note: '',
              slave_typeId: 0,
              slave_typeName: '',
              slave_folderId: 0,
              slave_folderName: '',
              slave_fixedState: '',
              slave_customStateId: 0,
              slave_customStateName: ColumnType.Fourth_Column,
              slave_respUserId: 0,
              slave_externalContactId: null,
              slave_externalContactName: null,
            };
            // Add the new record to the first array
            slaveData.push(newRecord);
          }
        });

        // Add First Custom Column for Main Kanban View

        let firstColumnArray: any[] = [];
        (slaveData as any[]).forEach(item => {
          let newItem = { ...item };
          newItem.slave_id = 1;
          newItem.slave_customStateName = ColumnType.First_Column;
          newItem.slave_note = '';
          if (!hasObjectWithId(firstColumnArray, newItem.id, newItem.slave_customStateName)) {
            firstColumnArray.push(newItem);
          }
        });

        // Add First Custom Column in First place
        slaveData.splice(0, 0, ...firstColumnArray);

        // Add Second Custom Column for Main Kanban View

        let secondColumnArray: any[] = [];
        (slaveData as any[]).forEach(item => {
          let newItem = { ...item };
          newItem.slave_id = 2;
          newItem.slave_customStateName = ColumnType.Second_Column;
          newItem.slave_note = '';
          if (!hasObjectWithId(secondColumnArray, newItem.id, newItem.slave_customStateName)) {
            secondColumnArray.push(newItem);
          }
        });

        // Add Second Custom Column in Second place

        slaveData.splice(1, 0, ...secondColumnArray);

        // Add Third Custom Column for Main Kanban View

        let thirdColumnArray: any[] = [];
        (slaveData as any[]).forEach(item => {
          let newItem = { ...item };
          newItem.slave_id = 3;
          newItem.slave_customStateName = ColumnType.Third_Column;
          newItem.slave_note = '';
          if (!hasObjectWithId(thirdColumnArray, newItem.id, newItem.slave_customStateName)) {
            thirdColumnArray.push(newItem);
          }
        });

        // Add Third Custom Column in Third place

        slaveData.splice(2, 0, ...thirdColumnArray);

        //Swimlane Key and Text filed only accept single value as a string.Create Swimlane header dynamically

        const updatedSlaveData = slaveData.map((slave: any) => ({
          ...slave,
          swimlane_textField: `${slave.folderName} > ${slave.name}`,
          swimlane_keyField: `${slave.folderName} > ${slave.name} ^${slave.id}`,
        }));
        console.log('Slave Data', updatedSlaveData);
        setSlaveData(updatedSlaveData);
        dataRef.current.slaveData = updatedSlaveData;

        // let kanbanLocalStorage = JSON.parse(localStorage.getItem('kanbanRCT_Kanban') as string) ?? {};
        // kanbanLocalStorage.dataSource = updatedSlaveData;
        // //console.log('kanbanLocalStorage', kanbanLocalStorage);
        // localStorage.setItem('kanbanRCT_Kanban', JSON.stringify(kanbanLocalStorage));

        //GET PROJECT STATUS

        //Unique SlaveCustomName to Show in Kanban Header

        let _uniqueStatus: any = Array.from(new Set(slaveData?.map((data: any) => data.slave_customStateName)));

        //console.log('projectStatusPriorty', dataRef.current.projectStatusPriorty);
        let projectStatusPriorty = dataRef.current.projectStatusPriorty;
        // Sort the categories array based on priority values
        _uniqueStatus.sort((a: string, b: string) => {
          const priorityA = parseInt(projectStatusPriorty[a]?.priority || 0);
          const priorityB = parseInt(projectStatusPriorty[b]?.priority || 0);
          return priorityA - priorityB;
        });

        //console.log('projectStatusList', statusArray);
        //console.log('selectedColumn', selected_column_temp);

        let newStatusName = [];
        if (selected_column_temp) {
          //GET COLUMN NAME ON THE BASIS OF SELECTED COLUMN
          const columnName = statusArray?.filter((item: any) => selected_column_temp.includes(item.id));
          //console.log('columnName', columnName);
          //console.log('_uniqueStatus', _uniqueStatus);

          //Filter the column name based on the selected column

          const filteredStatus = columnName?.filter((item: any) => _uniqueStatus.includes(item.name));
          //console.log('filteredStatus', filteredStatus);
          //GET COLUMN NAME
          const statusName = filteredStatus.map((item: any) => item.name);

          const preservedColumn = ['Vacancy', 'Current status', 'Next steps', 'No Applicant'];
          newStatusName = [...preservedColumn, ...statusName];
        } else {
          newStatusName = _uniqueStatus;
        }
        //REMOVE THE DUPLICATE STATUS
        const uniqueStatusArray = [...new Set(newStatusName)];
        //let combinedUniqueStatus = [..._uniqueStatus, ...(previousUniqueStatus ?? [])];
        setUniqueStatus(uniqueStatusArray);
        //console.log('uniqueArray', uniqueStatusArray);
        //setPreviousUniqueStatus(combinedUniqueStatus);
        dataRef.current.uniqueStatus = uniqueStatusArray;
        setNoRecord(false);
        console.log('_uniqueStatus', newStatusName);
      } else {
        // setSlaveData([]);
        // setNoRecord(true);
        let noRecordMsg = pageOffset > 0 ? 'No more records found' : 'No record found';
        setPageOffset(prevPageOffset => prevPageOffset - PAGE_OFFSET);
        dataRef.current.pageOffset = PAGE_OFFSET;
        let requestParams = queryParams; //localStorage.getItem('rct_reqParams');
        if (requestParams && requestParams !== '') {
          let reqParams;
          reqParams = requestParams;
          reqParams.listMasterFilter.offset = pageOffset;
          //localStorage.setItem('rct_reqParams', JSON.stringify(reqParams));
          setQueryParams(reqParams);
          dataRef.current.queryParams = JSON.stringify(reqParams);
        }
        //setNoRecord(true);
        ToastQueue.info(noRecordMsg, { timeout: 3000 });
      }
    } finally {
      setShowLoader(false);
    }
  });

  const loadData = async () => {
    //console.log('loadData');
    // for (let i = 0; i < localStorage.length; i++) {
    //   const key = localStorage.key(i);
    //   if (key && key.startsWith('rec_filter_')) {
    //     localStorage.removeItem(key);
    //   }
    // }

    const statusArray = await getStatusArray();
    //GET SELECTED COLUMN FROM SETTING
    const selected_column_temp = await getSelectedColumns(filtersSetting, statusArray);
    setSelectedColumn(selected_column_temp);
    dataRef.current.selectedColumn = selected_column_temp;
    //console.log('selected_column_temp', selected_column_temp);
    let reqParams: any = {};
    if (dataRef.current.filtersSetting) {
      //if (1 > 2) {
      let ObjFilterOptions = JSON.parse(dataRef.current.filtersSetting);

      let column_key = `${ListRegisterUtils.Modules.RctDashboard}_${ListRegisterUtils.Keys.RctDashboard.FilterSettings}`;
      //console.log('column_temp', column_key);
      const filterOptions = ObjFilterOptions[column_key].value ? JSON.parse(ObjFilterOptions[column_key].value) : null;

      //console.log('filterOptions', filterOptions);

      let objParam: any = {
        listMasterFilter: {
          limit: 20,
          offset: 0,
          masterProjectCategory: 'com.atollon.project.fpatypecategory.recruitment.vacancy',
          masterFolderCategory: 'com.atollon.project.fpatypecategory.recruitment.client',
          masterFolderRespUserId: 'Responsible User',
          masterFolderCustomStateIdList: 'Client Status',
          onlyPrimaryMasterFolders: 1,
          FILTER_MASTER_PROJECT: {
            COL: [
              {
                name: 'typeId',
                type: 'equals',
                value: 'Vacancy Type',
              },
              {
                name: 'fixedState',
                type: 'in',
                value: 'Vacancy Status Checkbox',
              },
              {
                name: 'customStateId',
                type: 'in',
                value: ['Vacancy Status Dropdown'],
              },
              {
                name: 'respUserId',
                type: 'equals',
                value: 'Responsible User',
              },
              {
                name: 'externalContactId',
                type: 'equals',
                value: 'Contact Person',
              },
            ],
          },
          FILTER_SLAVE_PROJECT: {
            COL: {
              name: 'fixedState',
              type: 'in',
              value: 'show applicaion with selection',
            },
          },
        },
      };

      if (filterOptions.vacancyTypeReef && filterOptions.vacancyTypeReef !== '') {
        objParam.listMasterFilter.FILTER_MASTER_PROJECT.COL.forEach((item: any) => {
          if (item.name === 'typeId') {
            item.value = filterOptions.vacancyTypeReef;
          }
        });
      } else {
        objParam.listMasterFilter.FILTER_MASTER_PROJECT.COL = objParam.listMasterFilter.FILTER_MASTER_PROJECT.COL.filter((item: any) => item.name !== 'typeId');
      }

      let vacancyStausValues = '';
      if (filterOptions.vacancyInitialReef) {
        vacancyStausValues += '1';
      }
      if (filterOptions.vacancyActiveReef) {
        vacancyStausValues += ',2';
      }
      if (filterOptions.vacancyClosedReef) {
        vacancyStausValues += ',3';
      }
      //Remove , from Start
      vacancyStausValues = vacancyStausValues.replace(/^,/, '');

      if (vacancyStausValues.length > 0) {
        objParam.listMasterFilter.FILTER_MASTER_PROJECT.COL.forEach((item: any) => {
          if (item.name === 'fixedState') {
            item.value = vacancyStausValues;
          }
        });
      } else {
        objParam.listMasterFilter.FILTER_MASTER_PROJECT.COL = objParam.listMasterFilter.FILTER_MASTER_PROJECT.COL.filter((item: any) => item.name !== 'fixedState');
      }
      //console.log('filterOptions?.vacancyStatusReef?.length', filterOptions?.vacancyStatusReef);
      if (filterOptions?.vacancyStatusReef?.length) {
        objParam.listMasterFilter.FILTER_MASTER_PROJECT.COL.forEach((item: any) => {
          if (item.name === 'customStateId') {
            item.value = filterOptions.vacancyStatusReef.join(', ');
          }
        });
      } else {
        objParam.listMasterFilter.FILTER_MASTER_PROJECT.COL = objParam.listMasterFilter.FILTER_MASTER_PROJECT.COL.filter((item: any) => item.name !== 'customStateId');
      }

      if (filterOptions.responsibleUserReef && filterOptions.responsibleUserReef !== '') {
        objParam.listMasterFilter.masterFolderRespUserId = filterOptions.responsibleUserReef;
        objParam.listMasterFilter.FILTER_MASTER_PROJECT.COL.forEach((item: any) => {
          if (item.name === 'respUserId') {
            item.value = +filterOptions.responsibleUserReef;
          }
        });
      } else {
        delete objParam.listMasterFilter.masterFolderRespUserId;
        objParam.listMasterFilter.FILTER_MASTER_PROJECT.COL = objParam.listMasterFilter.FILTER_MASTER_PROJECT.COL.filter((item: any) => item.name !== 'respUserId');

        // objParam.listMasterFilter.masterFolderRespUserId = store.UserId;
        // objParam.listMasterFilter.FILTER_MASTER_PROJECT.COL.forEach((item: any) => {
        //   if (item.name === 'respUserId') {
        //     item.value = store.UserId;
        //   }
        // });
      }

      if (filterOptions.contactPersonReef && filterOptions.contactPersonReef !== '') {
        objParam.listMasterFilter.FILTER_MASTER_PROJECT.COL.forEach((item: any) => {
          if (item.name === 'externalContactId') {
            item.value = filterOptions.contactPersonIDReef;
          }
        });
      } else {
        objParam.listMasterFilter.FILTER_MASTER_PROJECT.COL = objParam.listMasterFilter.FILTER_MASTER_PROJECT.COL.filter((item: any) => item.name !== 'externalContactId');
      }

      let stateStausValues = '';
      if (filterOptions.stateInitialReef) {
        stateStausValues += '1';
      }

      if (filterOptions.stateActiveReef) {
        stateStausValues += ',2';
      }

      if (filterOptions.stateClosedReef) {
        stateStausValues += ',3';
      }
      //Remove , from Start
      stateStausValues = stateStausValues.replace(/^,/, '');

      if (stateStausValues.length > 0) {
        objParam.listMasterFilter.FILTER_SLAVE_PROJECT.COL.value = stateStausValues;
      } else {
        delete objParam.listMasterFilter.FILTER_SLAVE_PROJECT;
      }

      if (filterOptions?.clientStatusReef?.length) {
        objParam.listMasterFilter.masterFolderCustomStateIdList = filterOptions.clientStatusReef.join(', ');
      } else {
        delete objParam.listMasterFilter.masterFolderCustomStateIdList;
      }

      if (objParam.listMasterFilter.FILTER_MASTER_PROJECT.COL.length === 0) {
        // Remove FILTER_MASTER_PROJECT if COL is empty
        delete objParam.listMasterFilter.FILTER_MASTER_PROJECT;
      }
      //console.log('objParam', objParam);
      reqParams = objParam;
    } else {
      reqParams = {
        listMasterFilter: {
          limit: PAGE_LIMIT,
          offset: 0,
          masterFolderRespUserId: store.UserId,
          onlyPrimaryMasterFolders: 1,
          masterProjectCategory: 'com.atollon.project.fpatypecategory.recruitment.vacancy',
          masterFolderCategory: 'com.atollon.project.fpatypecategory.recruitment.client',
          FILTER_MASTER_PROJECT: [
            {
              COL: [
                {
                  name: 'respUserId',
                  type: 'equals',
                  value: store.UserId,
                },
              ],
            },
          ],
        },
      };
    }

    //console.log('queryParams', queryParams);
    if (queryParams && queryParams !== '') {
      reqParams = queryParams;
    } else {
      setQueryParams(reqParams);
      dataRef.current.queryParams = JSON.stringify(reqParams);
    }

    memoizedLoadKanbanData(reqParams, selected_column_temp, statusArray);
  };

  //#endregion

  const filterDialogOpen = () => {
    setShowFilterDialog(true);
  };

  const searchDialogOpen = () => {
    setShowSearchDialog(true);
  };

  const updateRecruitementState = useCallback((reloadKanban: boolean = false) => {
    //console.log('reloadKanban', reloadKanban);
    setShowLoader(true);
    if (reloadKanban) {
      let reqParams = {
        listMasterFilter: {
          limit: PAGE_LIMIT,
          offset: pageOffset,
          onlyPrimaryMasterFolders: 1,
          masterProjectCategory: 'com.atollon.project.fpatypecategory.recruitment.vacancy',
          masterFolderCategory: 'com.atollon.project.fpatypecategory.recruitment.client',
        },
      };
      //console.log('updateRecruitementState queryParams', dataRef.current.queryParams);
      let requestParams = dataRef.current.queryParams && JSON.parse(dataRef.current.queryParams); //localStorage.getItem('rct_reqParams');
      //console.log('requestParams', requestParams, 'scrollTopValue', scrollTopValue);
      if (requestParams && requestParams !== '') {
        reqParams = requestParams;
      }
      if (scrollRef.current) {
        //console.log('scrollRef.current.scrollTop On State UPdate', scrollRef.current.scrollTop);
        localStorage.setItem('kanban_scrollPosition_saved', String(scrollRef.current.scrollTop));
      }
      //console.log('dataRef.current.projectStatusList', dataRef.current.projectStatusList);
      memoizedLoadKanbanData(reqParams, dataRef.current.selectedColumn, dataRef.current.projectStatusList);
    }
  }, []);

  const updateMasterState: any = useCallback((updatedMasterData: any) => {
    console.log('updatedMasterData', updatedMasterData);
    setMasterData(updatedMasterData);
    dataRef.current.masterData = updatedMasterData;
  }, []);

  const updateFilterDialogState = useCallback((flag: boolean, reloadKanban: boolean = false, reqParams: object = {}, filtersSetting: any = null): void => {
    //console.log(' updateFilterDialogState filtersSetting', filtersSetting);
    setShowFilterDialog(flag);
    dataRef.current.filtersSetting = JSON.stringify(filtersSetting);
    setFiltersSetting(filtersSetting);

    let column_key = `${ListRegisterUtils.Modules.RctDashboard}_${ListRegisterUtils.Keys.RctDashboard.FilterSettings}`;
    //console.log('column_temp', column_temp[column_key]);
    const filterOptions = JSON.parse(filtersSetting[column_key].value);
    //console.log('updateFilterDialogState filterOptions', filterOptions);
    setSelectedColumn(filterOptions.selectedColumnsReef);
    dataRef.current.selectedColumn = filterOptions.selectedColumnsReef;
    //console.log('fupdateFilterDialogState selectedColumnsReef', filterOptions.selectedColumnsReef);
    if (reloadKanban) {
      setShowLoader(true);
      setScrollTopValue(0);
      setPageOffset(0);
      dataRef.current.pageOffset = 0;
      resetScrollPosition(0);
      //console.log('updateFilterDialogState params', reqParams);
      //localStorage.setItem('rct_reqParams', JSON.stringify(reqParams));
      setQueryParams(reqParams);
      dataRef.current.queryParams = JSON.stringify(reqParams);
      memoizedLoadKanbanData(reqParams, filterOptions.selectedColumnsReef, dataRef.current.projectStatusList);
    }
  }, []);

  const updateSearchDialogState = (flag: boolean, reloadKanban: boolean = false, reqParams: any = {}): void => {
    //console.log('updateSearchDialogState param', reqParams);
    setShowSearchDialog(flag);
    if (reloadKanban) {
      setShowLoader(true);
      setScrollTopValue(0);
      setPageOffset(0);
      dataRef.current.pageOffset = 0;
      resetScrollPosition(0);
      if (reqParams.hasOwnProperty('masterFolderCategory')) {
        delete reqParams.masterFolderCategory;
      }
      //localStorage.setItem('rct_reqParams', JSON.stringify(reqParams));
      setQueryParams(reqParams);
      dataRef.current.queryParams = JSON.stringify(reqParams);
      memoizedLoadKanbanData(reqParams, dataRef.current.selectedColumn, dataRef.current.projectStatusList);
    }
  };

  async function updateRegisters(filter_calls: any[], store: any, filtersSetting: any, filterOptions: any) {
    //console.log('filterOptions', filterOptions);

    let filter_key = `${ListRegisterUtils.Modules.RctDashboard}_${ListRegisterUtils.Keys.RctDashboard.FilterSettings}`;
    //console.log('filtersSetting', filtersSetting[filter_key]);
    if (filtersSetting[filter_key] === undefined) return;
    //console.log('filtersSetting', filtersSetting[filter_key]);
    const filter_settings = filtersSetting[filter_key] ? filtersSetting[filter_key] : {};
    filtersSetting[filter_key].value = JSON.stringify(filterOptions);
    for (let call of filter_calls) {
      await featureService.updateRegister(
        new UpdateRegisterRequest(store.Server, store.SessionId, filter_settings.id, undefined, call.moduleId, undefined, call.key, call.level, JSON.stringify(filterOptions), filter_settings.assignTo)
      );
      //console.log('response', response);
    }
  }

  const loadMyVacancies_Click = async () => {
    setShowLoader(true);
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      key?.startsWith('kanban_data_') && localStorage.removeItem(key);
    }

    localStorage.removeItem('rec_search_value');

    setScrollTopValue(0);
    setPageOffset(0);
    dataRef.current.pageOffset = 0;
    resetScrollPosition(0);

    let reqParams: any = {
      listMasterFilter: {
        limit: PAGE_LIMIT,
        offset: 0,
        masterFolderRespUserId: store.UserId,
        onlyPrimaryMasterFolders: 1,
        masterProjectCategory: 'com.atollon.project.fpatypecategory.recruitment.vacancy',
        masterFolderCategory: 'com.atollon.project.fpatypecategory.recruitment.client',
        FILTER_MASTER_PROJECT: [
          {
            COL: [
              {
                name: 'respUserId',
                type: 'equals',
                value: store.UserId,
              },
            ],
          },
        ],
      },
    };

    let requestParams = queryParams; //localStorage.getItem('rct_reqParams');
    //console.log('requestParams', requestParams);
    if (requestParams && requestParams !== '') {
      reqParams = requestParams;

      reqParams.listMasterFilter.masterFolderRespUserId = store.UserId;
      if (!reqParams.hasOwnProperty('masterFolderCategory')) {
        reqParams.listMasterFilter.masterFolderCategory = 'com.atollon.project.fpatypecategory.recruitment.client';
      }

      if (Array.isArray(reqParams.listMasterFilter.FILTER_MASTER_PROJECT)) {
        reqParams.listMasterFilter.FILTER_MASTER_PROJECT.forEach((filter: any) => {
          if (Array.isArray(filter.COL)) {
            filter.COL = filter.COL.filter((item: any) => item.name !== 'folderId');
          }
        });
      }

      const respUserFilter = {
        name: 'respUserId',
        type: 'equals',
        value: store.UserId,
      };

      if (reqParams.listMasterFilter.FILTER_MASTER_PROJECT && reqParams.listMasterFilter.FILTER_MASTER_PROJECT.COL) {
        if (!reqParams.listMasterFilter.FILTER_MASTER_PROJECT.COL.some((item: any) => item.name === 'respUserId')) {
          reqParams.listMasterFilter.FILTER_MASTER_PROJECT.COL.push(respUserFilter);
        }
      } else {
        reqParams.listMasterFilter.FILTER_MASTER_PROJECT = {
          COL: [respUserFilter],
        };
      }
    }

    reqParams.listMasterFilter.offset = 0;
    dataRef.current.pageOffset = 0;
    setPageOffset(0);

    //localStorage.setItem('rct_reqParams', JSON.stringify(reqParams));
    setQueryParams(reqParams);
    dataRef.current.queryParams = JSON.stringify(reqParams);
    //console.log('filtersSetting', filtersSetting);
    //UPDATE FILTERS SETTING
    let filterOptions = filtersSetting[`${ListRegisterUtils.Modules.RctDashboard}_${ListRegisterUtils.Keys.RctDashboard.FilterSettings}`].value
      ? JSON.parse(filtersSetting[`${ListRegisterUtils.Modules.RctDashboard}_${ListRegisterUtils.Keys.RctDashboard.FilterSettings}`].value)
      : null;
    filterOptions.responsibleUserReef = `${store.UserId}`;
    //console.log('filterOptions', filterOptions);
    filtersSetting[`${ListRegisterUtils.Modules.RctDashboard}_${ListRegisterUtils.Keys.RctDashboard.FilterSettings}`].value = JSON.stringify(filterOptions);
    dataRef.current.filtersSetting = JSON.stringify(filtersSetting);
    setFiltersSetting(filtersSetting);
    await updateRegisters(filter_calls, store, filtersSetting, filterOptions);

    memoizedLoadKanbanData(reqParams, selectedColumn, projectStatusList);
  };

  const loadAllVacancies_Click = async () => {
    setShowLoader(true);
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      key?.startsWith('kanban_data_') && localStorage.removeItem(key);
    }

    localStorage.removeItem('rec_search_value');
    //localStorage.setItem('rct_reqParams', '');

    setScrollTopValue(0);
    resetScrollPosition(0);

    let reqParams: any = {
      listMasterFilter: {
        limit: PAGE_LIMIT,
        offset: 0,
        onlyPrimaryMasterFolders: 1,
        masterProjectCategory: 'com.atollon.project.fpatypecategory.recruitment.vacancy',
        masterFolderCategory: 'com.atollon.project.fpatypecategory.recruitment.client',
      },
    };

    let requestParams = queryParams; //localStorage.getItem('rct_reqParams');
    //console.log('loadAllVacancies_Click requestParams', requestParams);
    if (requestParams && requestParams !== '') {
      reqParams = requestParams;

      if (!reqParams.hasOwnProperty('masterFolderCategory')) {
        reqParams.listMasterFilter.masterFolderCategory = 'com.atollon.project.fpatypecategory.recruitment.client';
      }

      if (Array.isArray(reqParams.listMasterFilter.FILTER_MASTER_PROJECT)) {
        reqParams.listMasterFilter.FILTER_MASTER_PROJECT.forEach((filter: any) => {
          if (Array.isArray(filter.COL)) {
            filter.COL = filter.COL.filter((item: any) => item.name !== 'respUserId');
          }
        });
      } else {
        if (reqParams.listMasterFilter.FILTER_MASTER_PROJECT && reqParams.listMasterFilter.FILTER_MASTER_PROJECT.COL) {
          reqParams.listMasterFilter.FILTER_MASTER_PROJECT.COL = reqParams.listMasterFilter.FILTER_MASTER_PROJECT.COL.filter((item: any) => item.name !== 'respUserId');
        }
      }

      if (Array.isArray(reqParams.listMasterFilter.FILTER_MASTER_PROJECT)) {
        reqParams.listMasterFilter.FILTER_MASTER_PROJECT.forEach((filter: any, index: any) => {
          if (!Array.isArray(filter.COL)) {
            delete reqParams.listMasterFilter.FILTER_MASTER_PROJECT[index];
          }
        });
      }

      if (reqParams.listMasterFilter.hasOwnProperty('masterFolderRespUserId')) {
        delete reqParams.listMasterFilter.masterFolderRespUserId;
      }

      if (reqParams.listMasterFilter.FILTER_MASTER_PROJECT?.COL) {
        reqParams.listMasterFilter.FILTER_MASTER_PROJECT.COL = reqParams.listMasterFilter.FILTER_MASTER_PROJECT.COL.filter((item: any) => item.name !== 'folderId');
      }
    }

    if (Array.isArray(reqParams.listMasterFilter.FILTER_MASTER_PROJECT)) {
      reqParams.listMasterFilter.FILTER_MASTER_PROJECT = reqParams.listMasterFilter.FILTER_MASTER_PROJECT.filter((filter: any) => !(Array.isArray(filter.COL) && filter.COL.length === 0));

      if (reqParams.listMasterFilter.FILTER_MASTER_PROJECT.length === 0) {
        delete reqParams.listMasterFilter.FILTER_MASTER_PROJECT;
      }
    } else if (reqParams.listMasterFilter.FILTER_MASTER_PROJECT?.COL) {
      if (reqParams.listMasterFilter.FILTER_MASTER_PROJECT.COL.length === 0) {
        delete reqParams.listMasterFilter.FILTER_MASTER_PROJECT;
      }
    }

    reqParams.listMasterFilter.offset = 0;
    dataRef.current.pageOffset = 0;
    setPageOffset(0);

    //localStorage.setItem('rct_reqParams', JSON.stringify(reqParams));
    setQueryParams(reqParams);
    dataRef.current.queryParams = JSON.stringify(reqParams);

    //UPDATE FILTERS SETTING
    let filterOptions = filtersSetting[`${ListRegisterUtils.Modules.RctDashboard}_${ListRegisterUtils.Keys.RctDashboard.FilterSettings}`].value
      ? JSON.parse(filtersSetting[`${ListRegisterUtils.Modules.RctDashboard}_${ListRegisterUtils.Keys.RctDashboard.FilterSettings}`].value)
      : null;
    filterOptions.responsibleUserReef = ``;
    //console.log('filterOptions', filterOptions);
    filtersSetting[`${ListRegisterUtils.Modules.RctDashboard}_${ListRegisterUtils.Keys.RctDashboard.FilterSettings}`].value = JSON.stringify(filterOptions);
    dataRef.current.filtersSetting = JSON.stringify(filtersSetting);
    setFiltersSetting(filtersSetting);
    await updateRegisters(filter_calls, store, filtersSetting, filterOptions);
    memoizedLoadKanbanData(reqParams, selectedColumn, projectStatusList);
  };

  const nextPage_Click = () => {
    setScrollTopValue(0);
    setShowLoader(true);
    resetScrollPosition(0);
    let reqParams = {
      listMasterFilter: {
        limit: PAGE_LIMIT,
        offset: 0,
        onlyPrimaryMasterFolders: 1,
        masterProjectCategory: 'com.atollon.project.fpatypecategory.recruitment.vacancy',
        masterFolderCategory: 'com.atollon.project.fpatypecategory.recruitment.client',
      },
    };

    let requestParams = queryParams; //localStorage.getItem('rct_reqParams');
    //console.log('requestParams', requestParams);
    if (requestParams && requestParams !== '') {
      reqParams = requestParams;
    }
    //console.log('pageOffset + PAGE_OFFSET', pageOffset);
    reqParams.listMasterFilter.offset = pageOffset + PAGE_OFFSET;
    dataRef.current.pageOffset = pageOffset + PAGE_OFFSET;
    setPageOffset(pageOffset + PAGE_OFFSET);

    //localStorage.setItem('rct_reqParams', JSON.stringify(reqParams));
    setQueryParams(reqParams);
    dataRef.current.queryParams = JSON.stringify(reqParams);

    memoizedLoadKanbanData(reqParams, selectedColumn, projectStatusList);
  };

  const previousPage_Click = () => {
    setScrollTopValue(0);
    setShowLoader(true);
    resetScrollPosition(0);
    let reqParams = {
      listMasterFilter: {
        limit: PAGE_LIMIT,
        offset: 0,
        onlyPrimaryMasterFolders: 1,
        masterProjectCategory: 'com.atollon.project.fpatypecategory.recruitment.vacancy',
        masterFolderCategory: 'com.atollon.project.fpatypecategory.recruitment.client',
      },
    };

    let requestParams = queryParams; //localStorage.getItem('rct_reqParams');
    //console.log('requestParams', requestParams);
    if (requestParams && requestParams !== '') {
      reqParams = requestParams;
    }

    setPageOffset(prevPageOffset => prevPageOffset - PAGE_OFFSET);
    dataRef.current.pageOffset = pageOffset - PAGE_OFFSET;

    reqParams.listMasterFilter.offset = pageOffset - PAGE_OFFSET;
    //localStorage.setItem('rct_reqParams', JSON.stringify(reqParams));
    setQueryParams(reqParams);
    dataRef.current.queryParams = JSON.stringify(reqParams);

    memoizedLoadKanbanData(reqParams, selectedColumn, projectStatusList);
  };

  const handleScroll = (event: any) => {
    if (scrollRef.current) {
      //console.info('handleScroll position:', scrollRef.current.scrollTop);
      dataRef.current.scrollTopCurrentValue = scrollRef.current.scrollTop;
      setScrollTopCurrentValue(scrollRef.current.scrollTop);
    }
  };

  const recruitmentComponent = (
    <div id="divScroll" style={{ overflowY: 'scroll', scrollBehavior: 'smooth' }} ref={scrollRef} onScroll={handleScroll}>
      <RecruitmentComponent
        slaveData={slaveData}
        masterData={masterData}
        uniqueStatus={uniqueStatus}
        updateRecruitementState={updateRecruitementState}
        updateMasterState={updateMasterState}
        columnType={ColumnType}
        vacancyFormLabels={vacancyFormLabels}
        selectedColumn={selectedColumn}
      />
    </div>
  );

  return (
    <>
      <Flex height="size-600" gap="size-50" UNSAFE_style={{ position: 'fixed', width: '100%', zIndex: '10', backgroundColor: 'white', paddingTop: '10px' }}>
        <View marginStart="size-100">
          <ActionButton onPress={() => loadMyVacancies_Click()} UNSAFE_style={{ color: '#1976D2', padding: '10px', fontWeight: '700', borderColor: '#1976D2', fontSize: '14px' }}>
            <MyVacanciesIcon size={20} />
            <Text UNSAFE_style={{ paddingLeft: '5px' }}> {isSmallDevice ? '' : t('my_vacancies', { ns: 'recruitment' })}</Text>
          </ActionButton>
        </View>
        <View>
          <ActionButton onPress={() => loadAllVacancies_Click()} UNSAFE_style={{ color: '#1976D2', padding: '10px', fontWeight: '700', borderColor: '#1976D2', fontSize: '14px' }}>
            <AllVacanciesIcon size={20} />
            <Text UNSAFE_style={{ paddingLeft: '5px' }}> {isSmallDevice ? '' : t('all_vacancies', { ns: 'recruitment' })}</Text>
          </ActionButton>
        </View>
        <Flex UNSAFE_style={{ marginLeft: 'auto' }} marginEnd="size-100" gap="size-50">
          <View>
            <ActionButton UNSAFE_style={{ border: 'none' }} onPress={() => searchDialogOpen()}>
              <span className="filterIcon">
                <Search size="M" />
              </span>
            </ActionButton>
          </View>
          <View>
            <ActionButton UNSAFE_style={{ border: 'none' }} onPress={() => filterDialogOpen()}>
              <span className="filterIcon">
                <Filter size={'L'} />
              </span>
            </ActionButton>
          </View>
        </Flex>
      </Flex>
      <Flex UNSAFE_style={{ paddingTop: '50px', backgroundColor: 'white' }}>
        <View>
          {showLoader && <Loader />}
          {showSearchDialog && <SearchDialogTemplate updateSearchDialogState={updateSearchDialogState} dialogVisibility={showSearchDialog} />}
          {showFilterDialog && (
            <FilterDialogTemplate
              updateFilterDialogState={updateFilterDialogState}
              dialogVisibility={showFilterDialog}
              _filtersSetting={dataRef.current.filtersSetting}
              projectStatusList={dataRef.current.projectStatusList}
            />
          )}
          {useMemo(() => {
            return noRecord ? (
              <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                <EmptyIcon size={100} />
                <b>No Record Found</b>
              </div>
            ) : (
              recruitmentComponent
            );
          }, [uniqueStatus, noRecord, width])}
        </View>
      </Flex>
      <Flex
        UNSAFE_className="paginationContainer"
        position={'absolute'}
        gap="size-50"
        UNSAFE_style={{ width: '100%', zIndex: '10', backgroundColor: 'white', paddingTop: '11px', paddingLeft: '10px', paddingBottom: '10px' }}
      >
        {!noRecord ? (
          <>
            <View>
              {pageOffset > 0 && !showLoader ? (
                <ActionButton onPress={() => previousPage_Click()} UNSAFE_style={{ color: '#1976D2', padding: '10px', fontWeight: '700', borderColor: '#1976D2', width: '175px', fontSize: '14px' }}>
                  <Text UNSAFE_style={{ paddingLeft: '5px' }}> {t('Previous', { ns: 'common' })}</Text>
                </ActionButton>
              ) : (
                <ActionButton isDisabled={true} UNSAFE_style={{ color: 'darkgray', padding: '10px', fontWeight: '700', borderColor: 'darkgray', width: '175px', fontSize: '14px' }}>
                  <Text UNSAFE_style={{ paddingLeft: '5px' }}> {t('Previous', { ns: 'common' })}</Text>
                </ActionButton>
              )}
            </View>
            <View>
              {showLoader ? (
                ''
              ) : (
                <ActionButton onPress={() => nextPage_Click()} UNSAFE_style={{ color: '#1976D2', padding: '10px', fontWeight: '700', borderColor: '#1976D2', width: '175px', fontSize: '14px' }}>
                  <Text UNSAFE_style={{ paddingLeft: '5px' }}> {t('Next', { ns: 'common' })}</Text>
                </ActionButton>
              )}
            </View>
          </>
        ) : (
          ''
        )}
      </Flex>
    </>
  );
}
