import React, { useEffect, useState } from "react";
import { Item, ListBox, Text, ComboBox, Grid, useAsyncList } from "@adobe/react-spectrum";
import { useDependency } from "../../../../../contexts/DependencyProvider";
import { GetContactListRequest } from "../../../../../services/soap/main/requests/GetContactListRequest";
import { ListPositionRequest } from "../../../../../services/soap/main/requests/ListPositionRequest";


export interface Character {
    id:          string;
    name:        string;
    description: string;
}

interface IPositionDropDownProps{
   selectedPosition:string;
   onChange:(data:Character)=> void;
}

const PositionDropDown:React.FC<IPositionDropDownProps> = ({onChange,selectedPosition}) => {
  const { store, mainService } = useDependency();
  const [selectedKey, setSelectedKey] = useState<string | null>(null);

  useEffect(()=>{
     list.reload();
  },[])

  const list = useAsyncList<Character>({
    async load({ cursor }) {
      const offset = cursor ? Number(cursor) : 0;
      const limit = 30;
      try {
        const request = new ListPositionRequest(store.Server, store.SessionId);
        const response = await mainService.listPosition(request);

        const positions = response?.POSITION || [];
        if (!Array.isArray(positions)) {
          console.error("Invalid API response: Expected an array", response);
          return { items: [] };
        }

        return {
          items: positions,
          cursor: positions.length === limit ? String(offset + limit) : undefined,
        };
      } catch (error) {
        console.error("Error fetching positions:", error);
        return { items: [] };
      }
    },
  });

  useEffect(() => {
      if (selectedPosition) {
        setSelectedKey(selectedPosition);
        list.setFilterText(selectedPosition)
        list.reload();
      } else {
        setSelectedKey(null);
      }
    }, [selectedPosition]);
  
  const handleSelectionChange = (key:any) => {
    setSelectedKey(key);
    if (key) {
      const selectedCompany = list.items.find((x) => x.id === key);
      if (selectedCompany) {
        onChange(selectedCompany);
      }
    }
  };

  return (
    <ComboBox
      label="Position"
      items={list.items}
      loadingState={list.loadingState}
      selectedKey={selectedKey}
      onSelectionChange={handleSelectionChange}
      width="50%"
    > 
  {(item) => (
    <Item key={item.id} textValue={item.name}>
        {item.name}
    </Item>
  )}
</ComboBox>
  );
};

export default PositionDropDown;
