import React, { useEffect, useState } from 'react';
import { ActionButton, ComboBox, Content, DatePicker, Dialog, DialogTrigger, Flex, Item, Link, NumberField, Picker, Section, TextField, View } from '@adobe/react-spectrum';
import { useAsyncList } from 'react-stately';
import { useDependency } from '../../../../contexts/DependencyProvider';
import { ListCurrencyRequest } from '../../../../services/soap/global/requests/ListCurrencyRequest';
import { useTranslation } from 'react-i18next';
import { ListItemRequest } from '../../../../services/soap/item/requests/ListItemRequest';
import { TrashIcon, AlertIconFill } from '../../Icons/IconsLib';
import { useViewInfo } from '../../../../hooks/UseViewInfo';
import { GetFinanceSettingsRequest } from '../../../../services/soap/finance/requests/GetFinanceSettingsRequest';
import { RowData } from './ProjectProductNewActivityComponent';
import { parseDate } from '@internationalized/date';
import moment from 'moment';
import { Provider } from '@adobe/react-spectrum';
import { ListFPARequest2 } from '../../../../services/soap/project/requests/ListFPARequest2';

interface ProjectProductNewActivityAddEditProps {
  id?: string;
  selectedItemType: string | undefined;
  defaultRowData: {};
  selectedRowData: RowData;
  handleRowClose: () => void;
  addRecord: (record: any) => void;
  updateRecord: (record: any) => void;
  deleteRecord: (record: any) => void;
  closeDialog: (isOpen: boolean) => void;
  attrib0ColumnName: string;
  attrib1ColumnName: string;
  attrib2ColumnName: string;
  attrib0ColumnVisible: boolean;
  attrib1ColumnVisible: boolean;
  attrib2ColumnVisible: boolean;
  billingDateColumnName: string;
  billingDateColumnVisible: boolean;
  componentTitle: string;
  quantityColumnName: string;
  quantityColumnVisible: boolean;
  serialNumberColumnName: string;
  serialNumberColumnVisible: boolean;
  unitPriceColumnName: string;
  unitPriceColumnVisible: boolean;
  supplierColumnVisible?: boolean;
  styles: any;
}

interface Character {
  name: string;
}

interface CharacterProject {
  name: string;
}

const ProjectProductNewActivityAddEdit: React.FC<ProjectProductNewActivityAddEditProps> = ({
  id,
  selectedItemType,
  defaultRowData,
  selectedRowData,
  handleRowClose,
  addRecord,
  updateRecord,
  deleteRecord,
  closeDialog,
  attrib0ColumnName,
  attrib1ColumnName,
  attrib2ColumnName,
  attrib0ColumnVisible,
  attrib1ColumnVisible,
  attrib2ColumnVisible,
  billingDateColumnName,
  billingDateColumnVisible,
  componentTitle,
  quantityColumnName,
  quantityColumnVisible,
  serialNumberColumnName,
  serialNumberColumnVisible,
  unitPriceColumnName,
  unitPriceColumnVisible,
  supplierColumnVisible,
  styles,
}) => {
  const { isMobile } = useViewInfo();
  const { itemService, globalService, financeService, projectService, store } = useDependency();
  const [selectedRow, setSelectedRow] = useState<RowData>(selectedRowData || defaultRowData);
  const [currencyList, setCurrencyList] = useState<any>([]);
  const [currencySetting, setCurrencySetting] = useState<string>('');
  const [partNumberData, setPartNumberData] = useState<any>([]);
  const [projectList, setProjectList] = useState<any>([]);
  const [totalProjectRec, setTotalProjectRec] = useState<number | undefined>(undefined);
  const [validationMessages, setValidationMessages] = useState<any>({ partNumber: '', quantity: '', unitPrice: '', currency: '' });
  const { t } = useTranslation();
  const PAGE_SIZE: number = 50;

  useEffect(() => {
    //console.log('selectedRowData', selectedRowData);
    //console.log('defaultRowData', defaultRowData);
    //console.log('selectedItemType', selectedItemType);
    setValidationMessages({ partNumber: selectedRowData.id === '0' ? 'required' : '', quantity: '', unitPrice: '', currency: '' });
    setSelectedRow(selectedRowData || defaultRowData);
    list.setFilterText(selectedRowData?.partNumber || '');
    //loadProjectList();
    getCurrencyList();
  }, [id, selectedRowData]);

  const getCurrencyList = async () => {
    let currencyList = await globalService.listCurrency(new ListCurrencyRequest(store.Server, store.SessionId, 50, 0));
    if (currencyList?.result === 'OK') {
      //console.log('currencyList', currencyList);
      const formattedArray = currencyList.ROWS.map(({ ITEM }: { ITEM: { id: string; code: string } }) => ({
        id: ITEM.id,
        code: ITEM.code,
      }));
      setCurrencyList(formattedArray);
      //console.log('selectedRow.id', selectedRowData.id);
      selectedRowData.id === '0' && (await getCurrencyDetails(formattedArray));
    }
  };

  const getCurrencyDetails = async (arrCurrencyList: any) => {
    let currencySetting = await financeService.getFinanceSettings(new GetFinanceSettingsRequest(store.Server, store.SessionId));
    if (currencySetting.result === 'OK' && currencySetting.count > 0) {
      //console.log('currencySetting', currencySetting.SETTINGS.currency);
      setCurrencySetting(currencySetting.SETTINGS.currency);
      //console.log('arrCurrencyList', arrCurrencyList);
      setSelectedRow(prev => ({ ...prev, currency: arrCurrencyList.find((item: any) => item.id === currencySetting.SETTINGS.currency)?.code }));
    }
  };

  const calculateTotalPrice = () => {
    setSelectedRow(prev => ({ ...prev, totalPrice: (prev.quantity * prev.unitPrice).toFixed(2), totalPriceWithVat: (prev.quantity * prev.amountWithVat).toFixed(2) }));
  };

  const validateFields = () => {
    const fields = [
      { key: 'partNumber', condition: selectedRow.partNumber === '' || selectedRow.partNumber === ' ' },
      { key: 'quantity', condition: false },
      { key: 'unitPrice', condition: false },
      { key: 'currency', condition: selectedRow.currency === '' },
    ];

    const isValid = fields.every(({ key, condition }) => {
      const isFieldValid = validationMessages[key] === '' && !condition;
      if (!isFieldValid) {
        validationMessages[key] = 'required';
        setValidationMessages((prev: any) => ({ ...prev, validationMessages }));
      }
      return isFieldValid;
    });

    return isValid;
  };

  const validateAndSubmit = () => {
    if (!validateFields()) return;
    const action = selectedRowData && Number(selectedRowData.id) !== 0 ? updateRecord : addRecord;
    //console.log('selectedRow', selectedRow);
    action(selectedRow);
    closeDialog(false);
  };

  const findObjectById = (id: string) => {
    let newId = id.endsWith('_ref') ? id.replace('_ref', '_name') : id.replace('_name', '_ref');
    //console.log('id', id);

    for (const group of partNumberData) {
      const found = group.children.find((child: any) => child._refId === id || child._refId === newId);
      if (found) return found;
    }
    return null;
  };

  const findObjectByProjectId = (id: string) => {
    let newId = id.endsWith('_ref') ? id.replace('_ref', '_name') : id.replace('_name', '_ref');
    //console.log('id', id);

    let found = projectList.find((child: any) => child.id === id || child.id === newId);
    return found;
  };

  // Define a custom hook called "list" using the useAsyncList hook
  const list = useAsyncList<Character>({
    async load({ filterText }) {
      // Set filterText to selectedRow's partNumber if filterText is empty
      filterText = filterText || (selectedRow.partNumber ? selectedRow.partNumber : ' ');
      // Update the selectedRow state with the new filterText
      setSelectedRow(prev => ({ ...prev, partNumber: filterText }));
      // Concurrently fetch item data by searchName and refId using filterText
      const [itemResp, itemRespByRefID]: any = await Promise.all([
        itemService.listItem(new ListItemRequest(store.Server, store.SessionId, 10, 0, { searchName: filterText.trim() })),
        itemService.listItem(new ListItemRequest(store.Server, store.SessionId, 10, 0, { refId: filterText.trim() })),
      ]);

      const formatItemRefID = ({ ITEM }: { ITEM: any }) => ({
        id: ITEM.id,
        refId: ITEM.refId,
        _refId: `${ITEM.refId}_${ITEM.name}_ref`, // Custom reference ID combining refId and name
        refIdName: `${ITEM.refId}(${ITEM.name})`, // Display name combining refId and name
        productName: ITEM.name,
        unitPrice: ITEM.amount,
        amountWithVat: ITEM.amountWithVat, // Amount with VAT
        vatValue: ITEM.vatValue, // VAT value
        purchasePrice: ITEM.purchasePrice,
        totalAmountWithVat: ITEM.totalAmountWithVat,
      });

      const formatItemName = ({ ITEM }: { ITEM: any }) => ({
        id: ITEM.id,
        refId: ITEM.refId,
        _refId: ITEM.refId + '_' + ITEM.name + '_name', // Custom reference ID combining refId and name
        refIdName: `${ITEM.name}${ITEM.refId ? `(${ITEM.refId})` : ''}`, // Display name combining refId and name
        productName: ITEM.name,
        unitPrice: ITEM.amount,
        amountWithVat: ITEM.amountWithVat, // Amount with VAT
        vatValue: ITEM.vatValue, // VAT value
        purchasePrice: ITEM.purchasePrice,
        totalAmountWithVat: ITEM.totalAmountWithVat,
      });

      const options = [
        ...(itemRespByRefID.ROWS?.length ? [{ name: `${itemRespByRefID.ROWS.length} ref id match`, children: itemRespByRefID.ROWS.map(formatItemRefID) }] : []),
        ...(itemResp.ROWS?.length ? [{ name: `${itemResp.ROWS.length} name match`, children: itemResp.ROWS.map(formatItemName) }] : []),
      ];
      //console.log('options', options);
      setPartNumberData(options);
      return { items: options, cursor: undefined };
    },
  });

  let getProjectCount = async () => {
    let projectList = await projectService.listFPA2(
      new ListFPARequest2(store.Server, store.SessionId, {
        countOnly: true,
        folderFilter: {
          COL: [
            {
              name: 'fpaTypeCategories',
              type: 'any',
              value: 'com.atollon.technology.fpatype.manufacturer',
            },
          ],
        },
      })
    );

    if (projectList.result === 'OK') {
      return projectList.count;
    }
  };

  let listProject = useAsyncList<CharacterProject>({
    async load({ cursor }) {
      //console.log('cursor', cursor);

      let totalRecords: any = totalProjectRec ?? (await getProjectCount());
      //console.log('totalRecords', totalRecords);
      let currentPage = cursor ? parseInt(cursor) : 0;
      //console.log('currentPage', currentPage);
      let offset = PAGE_SIZE * currentPage;
      //console.log('offset', offset);
      if (isNaN(offset) || offset >= totalRecords) {
        //setShowLoader(false);
        return { items: [], cursor: undefined };
      }

      let projectResp = await projectService.listFPA2(
        new ListFPARequest2(store.Server, store.SessionId, {
          limit: PAGE_SIZE,
          offset: offset,
          flatView: true,
          folderFilter: {
            COL: [
              {
                name: 'fpaTypeCategories',
                type: 'any',
                value: 'com.atollon.technology.fpatype.manufacturer',
              },
            ],
          },
        })
      );

      //console.log('projectResp', projectResp);

      if (projectResp.result === 'OK') {
        let projects: any = [];

        projectResp.ITEMS.map((item: any) =>
          projects.push({
            id: item.ITEM?.ACTIVITY.id,
            name: item.ITEM?.ACTIVITY.name,
            customStateName: item.ITEM?.ACTIVITY.customStateName,
          })
        );
        //console.log('projects', projects);
        setProjectList(projects);

        return {
          items: projects,
          cursor: String(offset >= totalRecords ? undefined : currentPage + 1),
        };
      }
      //setShowLoader(false);
      return { items: [], cursor: undefined };
    },
  });

  let onInputChange = (value: any) => {
    list.setSelectedKeys(value === '' ? new Set<any>() : list.selectedKeys);
    validationMessages.partNumber = value === '' ? 'Part number is required' : '';
    setValidationMessages((prev: any) => ({ ...prev, validationMessages }));
    list.setFilterText(value === '' ? ' ' : value);
  };

  const selectedIndexChanged = (value: any) => {
    //if (!value) return;
    //console.log('partNumberData', partNumberData);'
    let selectedItem: any = findObjectById(value);
    //console.log('selectedItem', selectedItem);
    if (selectedItem) {
      setValidationMessages((prev: any) => ({ ...prev, partNumber: '' }));
      list.setFilterText(selectedItem.refId);
      list.setSelectedKeys(new Set([selectedItem._refId]));
      setSelectedRow(prev => ({
        ...prev,
        _refId: selectedItem._refId,
        partNumber: selectedItem.refId,
        productName: selectedItem.productName,
        partID: selectedItem.id,
        unitPrice: selectedItem.unitPrice,
        amountWithVat: selectedItem.amountWithVat,
        vatValue: selectedItem.vatValue,
        purchasePrice: selectedItem.purchasePrice,
        totalAmountWithVat: selectedItem.totalAmountWithVat,
        supplier: selectedItem.supplier,
      }));
      calculateTotalPrice();
    } else {
      list.setFilterText('');
      list.setSelectedKeys(new Set());
      setSelectedRow(prev => ({ ...prev, _refId: '', partNumber: '' }));
      setValidationMessages((prev: any) => ({ ...prev, partNumber: 'required' }));
    }
  };

  const projectSelectedIndexChanged = (value: any) => {
    //if (!value) return;
    //console.log('partNumberData', partNumberData);'
    let selectedItem: any = findObjectByProjectId(value);
    //console.log('selectedItem', selectedItem);
    if (selectedItem) {
      listProject.setFilterText(selectedItem.name);
      listProject.setSelectedKeys(new Set([selectedItem.id]));
      setSelectedRow(prev => ({
        ...prev,
        supplier: selectedItem.id,
        supplierName: selectedItem.name,
      }));
    } else {
      listProject.setFilterText('');
      listProject.setSelectedKeys(new Set());
      setSelectedRow(prev => ({ ...prev, supplier: '', supplierName: '' }));
    }
  };

  const ProjectProductDialog = ({ close }: { close: () => void }) => (
    <Dialog size="S">
      <Flex UNSAFE_className={styles.delete_pop_up_parent}>
        <Content UNSAFE_className={styles.delete_pop_up_header}>{t('remove_product', { ns: 'layout_components' })}</Content>
        <View UNSAFE_className={styles.delete_pop_up_line}></View>
        <View UNSAFE_className={styles.delete_pop_up_warning_text}>{t('remove_product_message', { ns: 'layout_components' })}</View>
        <View UNSAFE_className={styles.buttons_parent}>
          <View UNSAFE_className={styles.button_cancel}>
            <Link UNSAFE_className={styles.delete_pop_up_cancel_text} isQuiet onPress={close}>
              {t('no_keep_it', { ns: 'layout_components' })}
            </Link>
          </View>
          <View UNSAFE_className={styles.button_confirm}>
            <div className={styles.trash_icon}>
              <TrashIcon size={32} />
            </div>
            <Link UNSAFE_className={styles.delete_pop_up_confirm_text} isQuiet onPress={() => deleteRecord(selectedRow)}>
              {t('yes_remove', { ns: 'layout_components' })}
            </Link>
          </View>
        </View>
        <View UNSAFE_className={styles.ellipse_div}>
          <View UNSAFE_className={styles.delete_alert_icon}>
            <AlertIconFill size={72} />
          </View>
        </View>
      </Flex>
    </Dialog>
  );

  const RemoveProjectProductButton = () =>
    Number(selectedRowData?.id) !== 0 && (
      <Flex marginTop={isMobile ? '10px' : '0'}>
        <DialogTrigger isDismissable type="modal">
          <ActionButton UNSAFE_className={styles.btn_delete}> {t('remove_product', { ns: 'layout_components' })}</ActionButton>
          {close => <ProjectProductDialog close={close} />}
        </DialogTrigger>
      </Flex>
    );

  const ProjectProductActions = () => (
    <Flex direction={{ base: 'row', L: 'row' }} gap={'size-200'}>
      <Flex>
        <Link UNSAFE_className={styles.btn_cancel} isQuiet onPress={handleRowClose}>
          {t('cancel', { ns: 'layout_components' })}
        </Link>
      </Flex>
      <Flex>
        <Link width={'180px'} UNSAFE_className={styles.btn_confirm} isQuiet onPress={() => validateAndSubmit()}>
          {selectedRowData?.id === '0' ? t('add', { ns: 'layout_components' }) : t('edit', { ns: 'layout_components' })}
        </Link>
      </Flex>
    </Flex>
  );

  const ProjectProductListButtons = () => (
    <Flex direction={isMobile ? 'column' : { base: 'column', L: 'row' }} justifyContent={'end'} gap={'size-100'} marginTop={isMobile ? '0' : 'size-100'}>
      <Flex>
        <RemoveProjectProductButton />
      </Flex>
      <ProjectProductActions />
    </Flex>
  );

  return (
    <Flex direction="column" width={'100%'} justifyContent={'center'} alignContent={'center'} UNSAFE_style={{ overflowY: 'auto' }}>
      {isMobile ? (
        <Flex direction="column" width={'100%'} justifyContent={'center'} alignContent={'center'}>
          <View>
            <ComboBox
              label={t('ref_id', { ns: 'layout_components' })}
              aria-label="none"
              isRequired
              marginTop={'18px'}
              width={'100%'}
              items={list.items}
              selectedKey={selectedRow._refId && selectedRow._refId}
              defaultInputValue={selectedRow.partNumber}
              inputValue={list.filterText || selectedRow.partNumber}
              onInputChange={onInputChange}
              onSelectionChange={selectedIndexChanged}
              loadingState={list.loadingState}
              onLoadMore={list.loadMore}
              validationState={validationMessages.partNumber !== '' || validationMessages.partNumber === ' ' ? 'invalid' : 'valid'}
            >
              {(item: any) => (
                <Section key={item.name} items={item.children} title={item.name}>
                  {(item: any) => <Item key={item._refId}>{item.refIdName}</Item>}
                </Section>
              )}
            </ComboBox>
          </View>
          <View>
            <TextField
              aria-label="none"
              width={'100%'}
              value={selectedRow.productName}
              label={t('product', { ns: 'layout_components' })}
              onChange={value => setSelectedRow(prev => ({ ...prev, productName: value }))}
            />
          </View>
          {supplierColumnVisible && (
            <View>
              <ComboBox
                label={t('supplier', { ns: 'layout_components' })}
                width={'100%'}
                items={listProject.items}
                selectedKey={selectedRow.supplier}
                defaultInputValue={selectedRow.supplier}
                inputValue={listProject.filterText || selectedRow.supplierName}
                loadingState={listProject.loadingState}
                onLoadMore={listProject.loadMore}
                onSelectionChange={value => {
                  if (value !== null) {
                    setSelectedRow(prev => ({
                      ...prev,
                      supplier: value.toString(),
                    }));
                  }
                }}
              >
                {(item: any) => <Item key={item.id}>{item.name}</Item>}
              </ComboBox>
            </View>
          )}
          {attrib0ColumnVisible && (
            <View>
              <TextField width={'100%'} aria-label="none" value={selectedRow.attrib0} label={attrib0ColumnName} onChange={value => setSelectedRow(prev => ({ ...prev, attrib0: value }))} />
            </View>
          )}

          {attrib1ColumnVisible && (
            <View>
              <TextField width={'100%'} aria-label="none" value={selectedRow.attrib1} label={attrib1ColumnName} onChange={value => setSelectedRow(prev => ({ ...prev, attrib1: value }))} />
            </View>
          )}

          {attrib2ColumnVisible && (
            <View>
              <TextField width={'100%'} aria-label="none" value={selectedRow.attrib2} label={attrib2ColumnName} onChange={value => setSelectedRow(prev => ({ ...prev, attrib2: value }))} />
            </View>
          )}

          {serialNumberColumnVisible && (
            <View>
              <TextField width={'100%'} aria-label="none" value={selectedRow.refId} label={serialNumberColumnName} onChange={value => setSelectedRow(prev => ({ ...prev, refId: value }))} />
            </View>
          )}

          {quantityColumnVisible && (
            <View>
              <NumberField
                width={'100%'}
                isRequired
                hideStepper
                value={selectedRow.quantity}
                label={quantityColumnName}
                onChange={value => {
                  validationMessages.quantity = isNaN(value) || value <= 0 ? t('quantity_must_be_greater_than_zero', { ns: 'layout_components' }) : '';
                  setValidationMessages((prev: any) => ({ ...prev, validationMessages }));
                  setSelectedRow(prev => ({ ...prev, quantity: value }));
                  calculateTotalPrice();
                }}
                validationState={validationMessages.quantity !== '' ? 'invalid' : 'valid'}
              />
            </View>
          )}

          {unitPriceColumnVisible && (
            <>
              <View>
                <NumberField
                  width={'100%'}
                  aria-label="none"
                  hideStepper
                  isRequired
                  minValue={0}
                  value={selectedRow.unitPrice}
                  label={unitPriceColumnName}
                  onChange={value => {
                    validationMessages.unitPrice = isNaN(value) || value <= 0 ? t('unit_price_must_be_zero_or_greater_than_zero', { ns: 'layout_components' }) : '';
                    setValidationMessages((prev: any) => ({ ...prev, validationMessages }));
                    setSelectedRow(prev => ({ ...prev, unitPrice: value }));
                    calculateTotalPrice();
                  }}
                  validationState={validationMessages.unitPrice !== '' ? 'invalid' : 'valid'}
                />
              </View>
              <View>
                <Picker
                  label={t('currency', { ns: 'layout_components' })}
                  aria-label="none"
                  width={'100%'}
                  items={currencyList}
                  onSelectionChange={value => {
                    setValidationMessages((prev: any) => ({ ...prev, currency: '' }));
                    const currencyCode = currencyList.find((item: any) => item.id === value)?.code;
                    setSelectedRow(prev => ({ ...prev, currency: currencyCode }));
                  }}
                  selectedKey={currencyList.find((item: any) => item.code === selectedRow.currency)?.id || currencySetting}
                  validationState={validationMessages.currency !== '' ? 'invalid' : 'valid'}
                >
                  {(item: any) => <Item key={item.id}>{item.code}</Item>}
                </Picker>
              </View>
            </>
          )}

          {billingDateColumnVisible && (
            <View>
              <Provider locale="en-US">
                <DatePicker
                  label={billingDateColumnName}
                  aria-label="none"
                  width={'100%'}
                  isRequired
                  value={parseDate(moment(selectedRow.billingDate, 'YYYY-MM-DD').format('YYYY-MM-DD'))}
                  onChange={(value: any) => {
                    //console.log('value', parseDate(moment(selectedRow.billingDate, 'YYYY-MM-DD').format('YYYY-MM-DD')));
                    setSelectedRow(prev => ({ ...prev, billingDate: value }));
                  }}
                />
              </Provider>
            </View>
          )}
        </Flex>
      ) : (
        <table style={!unitPriceColumnName ? { marginTop: '20px', overflowX: 'auto', width: '800px' } : {}} cellSpacing={0} cellPadding={0}>
          <thead>
            <tr>
              <th style={{ width: '10%' }}>
                <ComboBox
                  isRequired
                  aria-label="none"
                  width={'98%'}
                  items={list.items}
                  selectedKey={selectedRow._refId && selectedRow._refId}
                  defaultInputValue={selectedRow.partNumber}
                  inputValue={list.filterText || selectedRow.partNumber}
                  onInputChange={onInputChange}
                  onSelectionChange={selectedIndexChanged}
                  loadingState={list.loadingState}
                  onLoadMore={list.loadMore}
                  validationState={validationMessages.partNumber !== '' || validationMessages.partNumber === ' ' ? 'invalid' : 'valid'}
                >
                  {(item: any) => (
                    <Section key={item.name} items={item.children} title={item.name}>
                      {(item: any) => <Item key={item._refId}>{item.refIdName}</Item>}
                    </Section>
                  )}
                </ComboBox>
              </th>
              <th style={{ width: '12%' }}>
                <TextField width={'98%'} value={selectedRow.productName} aria-label="none" label="" onChange={value => setSelectedRow(prev => ({ ...prev, productName: value }))} />
              </th>
              {supplierColumnVisible && (
                <th style={{ width: '12%' }}>
                  <ComboBox
                    id="projectProductSupplier"
                    width={'98%'}
                    aria-label="none"
                    items={listProject.items}
                    selectedKey={selectedRow.supplier}
                    defaultInputValue={selectedRow.supplier}
                    inputValue={listProject.filterText || selectedRow.supplierName}
                    loadingState={listProject.loadingState}
                    onLoadMore={listProject.loadMore}
                    onSelectionChange={projectSelectedIndexChanged}
                  >
                    {(item: any) => <Item key={item.id}> {item.name}</Item>}
                  </ComboBox>
                </th>
              )}
              {attrib0ColumnVisible && (
                <th style={{ width: '8%' }}>
                  <TextField width={'98%'} aria-label="none" value={selectedRow.attrib0} label="" onChange={value => setSelectedRow(prev => ({ ...prev, attrib0: value }))} />
                </th>
              )}

              {attrib1ColumnVisible && (
                <th style={{ width: '8%' }}>
                  <TextField width={'98%'} aria-label="none" value={selectedRow.attrib1} label="" onChange={value => setSelectedRow(prev => ({ ...prev, attrib1: value }))} />
                </th>
              )}

              {attrib2ColumnVisible && (
                <th style={{ width: '10%' }}>
                  <TextField width={'98%'} aria-label="none" value={selectedRow.attrib2} label="" onChange={value => setSelectedRow(prev => ({ ...prev, attrib2: value }))} />
                </th>
              )}

              {serialNumberColumnVisible && (
                <th style={{ width: '10%' }}>
                  <TextField width={'98%'} aria-label="none" value={selectedRow.refId} label="" onChange={value => setSelectedRow(prev => ({ ...prev, refId: value }))} />
                </th>
              )}

              {quantityColumnVisible && (
                <th style={{ width: '10%' }}>
                  <NumberField
                    width={'98%'}
                    aria-label="none"
                    isRequired
                    hideStepper
                    value={selectedRow.quantity}
                    label=""
                    onChange={value => {
                      validationMessages.quantity = isNaN(value) || value <= 0 ? t('quantity_must_be_greater_than_zero', { ns: 'layout_components' }) : '';
                      setValidationMessages((prev: any) => ({ ...prev, validationMessages }));
                      setSelectedRow(prev => ({ ...prev, quantity: value }));
                      calculateTotalPrice();
                    }}
                    validationState={validationMessages.quantity !== '' ? 'invalid' : 'valid'}
                  />
                </th>
              )}

              {unitPriceColumnVisible && (
                <th style={{ width: '10%' }}>
                  <table width={'100%'} style={{ marginTop: '-2px' }}>
                    <thead>
                      <tr>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <NumberField
                            width={'100%'}
                            hideStepper
                            isRequired
                            minValue={0}
                            value={selectedRow.unitPrice}
                            label=""
                            aria-label="none"
                            onChange={value => {
                              validationMessages.unitPrice = isNaN(value) || value <= 0 ? t('unit_price_must_be_zero_or_greater_than_zero', { ns: 'layout_components' }) : '';
                              setValidationMessages((prev: any) => ({ ...prev, validationMessages }));
                              setSelectedRow(prev => ({ ...prev, unitPrice: value }));
                              calculateTotalPrice();
                            }}
                            validationState={validationMessages.unitPrice !== '' ? 'invalid' : 'valid'}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Picker
                            label=""
                            aria-label="none"
                            width={'100%'}
                            items={currencyList}
                            onSelectionChange={value => {
                              setValidationMessages((prev: any) => ({ ...prev, currency: '' }));
                              const currencyCode = currencyList.find((item: any) => item.id === value)?.code;
                              setSelectedRow(prev => ({ ...prev, currency: currencyCode }));
                            }}
                            selectedKey={currencyList.find((item: any) => item.code === selectedRow.currency)?.id || currencySetting}
                            validationState={validationMessages.currency !== '' ? 'invalid' : 'valid'}
                          >
                            {(item: any) => <Item key={item.id}>{item.code}</Item>}
                          </Picker>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </th>
              )}

              {billingDateColumnVisible && (
                <th style={{ width: '1%' }}>
                  <Provider locale="en-US">
                    <DatePicker
                      UNSAFE_style={{ float: 'right', width: '100px' }}
                      aria-label="none"
                      isRequired
                      value={parseDate(moment(selectedRow.billingDate, 'YYYY-MM-DD').format('YYYY-MM-DD'))}
                      onChange={(value: any) => {
                        //console.log('value', parseDate(moment(selectedRow.billingDate, 'YYYY-MM-DD').format('YYYY-MM-DD')));
                        setSelectedRow(prev => ({ ...prev, billingDate: value }));
                      }}
                    />
                  </Provider>
                </th>
              )}
            </tr>
          </thead>
        </table>
      )}
      <ProjectProductListButtons />
    </Flex>
  );
};

export default ProjectProductNewActivityAddEdit;
