import { BaseRequest, IBaseRequest } from '../../BaseRequest';

export interface IDeleteMessageRequest extends IBaseRequest {
    realDelete?: string;
    emptyTrash?: string;
    MESSAGE?:    Message;
}

export interface Message {
    id:     string;
    parent: string;
}

export class DeleteMessageRequest extends BaseRequest implements IDeleteMessageRequest {
    realDelete?: string;
    emptyTrash?: string;
    MESSAGE?:    Message;

  constructor(server: string, session: string, otherOptions: any) {
    super(server, session);
    this.realDelete = otherOptions.realDelete;
    this.emptyTrash = otherOptions.emptyTrash;
    this.MESSAGE = otherOptions.MESSAGE;
  }



  public toXml(): string {
    var parameters: string = super.toXml();

    if (this.realDelete) parameters += `<realDelete>${this.realDelete}</realDelete>`;   
    if (this.emptyTrash) parameters += `<emptyTrash>${this.emptyTrash}</emptyTrash>`;
    if (this.MESSAGE) parameters += `<MESSAGE><id>${this.MESSAGE.id}</id><parent>${this.MESSAGE.parent}</parent></MESSAGE>`;

    return super.wrapperXml('DeleteMessage', parameters);
  }
}
