import { useEffect, useState } from 'react';
import { LayoutComponent } from '../LayoutComponent';
import { FPAData, FPADataTypes } from '../../../../infra/protected/FPA/FPAData';
import { Content, Dialog, DialogContainer, Flex, ProgressCircle } from '@adobe/react-spectrum';
import { ToastQueue } from '@react-spectrum/toast';
import { useTranslation } from 'react-i18next';
import { useDependency } from '../../../../contexts/DependencyProvider';
import { GetFolderRequest } from '../../../../services/soap/project/requests/GetFolderRequest';
import { GetProjectRequest } from '../../../../services/soap/project/requests/GetProjectRequest';
import { useViewInfo } from '../../../../hooks/UseViewInfo';
import FolderComponent from './FolderComponent';
import ProjectComponent from './ProjectComponent';
import { GetActivityRequest } from '../../../../services/soap/project/requests/GetActivityRequest';
import ActivityComponent from './ActivityComponent';
import FolderComponentMobile from './FolderComponentMobile';
import ProjectComponentMobile from './ProjectComponentMobile';
import ActivityComponentMobile from './ActivityComponentMobile';
import { MenuItemModel } from '@syncfusion/ej2-react-navigations';
import PrintDialog from './component/PrintDialog/PrintDialog';
import { invokeCreateContextApp } from '../../../../utils/NavigationUtils';

export interface IHeaderToolbarProps {
  readonly onPrint?: (item: FPAData) => void;
  readonly onEdit?: (item: FPAData) => void;
  readonly onDelete?: (item: FPAData) => void;
  readonly onAI?: (item: FPAData) => void;
  readonly selectedItem?: FPAData;
}

function HeaderToolbar({ onPrint, onEdit, onDelete, onAI, selectedItem }: IHeaderToolbarProps) {
  const { store, projectService } = useDependency();
  const { isMobile } = useViewInfo();
  const [headerData, setHeaderData] = useState<any>([]);
  const [showLoader, setShowLoader] = useState<boolean>(true);
  const [isPrintOpen, setIsPrintOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  const copy_item = {
    iconCss: 'bi bi-copy',
    text: t('copy', { ns: 'layout_components' }),
  };

  const move_item = {
    iconCss: 'bi bi-forward-fill',
    text: t('move', { ns: 'layout_components' }),
  };

  let menuItems: MenuItemModel[] = [
    {
      iconCss: 'bi bi-printer-fill',
      text: t('print', { ns: 'layout_components' }),
    },
    {
      iconCss: 'bi bi-pencil-square',
      text: t('edit', { ns: 'layout_components' }),
    },
    {
      iconCss: 'bi bi-trash',
      text: t('delete', { ns: 'layout_components' }),
    },
  ];

  if (selectedItem) {
    switch (selectedItem.type) {
      case FPADataTypes.PROJECT:
      case FPADataTypes.ACTIVITY:
        menuItems.push(move_item, copy_item);
        break;
      default:
        break;
    }
  }
  useEffect(() => {
    (async () => {
      //console.log('selectedItem', selectedItem);
      setShowLoader(true);
      if (selectedItem?.type === FPADataTypes.FOLDER) {
        const response = await projectService.getFolder(new GetFolderRequest(store.Server, store.SessionId, selectedItem?.id || 0));
        setHeaderData(response.FOLDER);
        //console.log('response.FOLDER', response.FOLDER);
        setShowLoader(false);
      } else if (selectedItem?.type === FPADataTypes.PROJECT) {
        const response = await projectService.getProject(new GetProjectRequest(store.Server, store.SessionId, selectedItem?.id));
        setHeaderData(response.PROJECT);
        //console.log('response.PROJECT', response.PROJECT);
        setShowLoader(false);
      } else if (selectedItem?.type === FPADataTypes.ACTIVITY) {
        const response = await projectService.getActivity(new GetActivityRequest(store.Server, store.SessionId, selectedItem?.id));
        setHeaderData(response.ACTIVITY);
        //console.log('response.Activity', response);
        if (response.result === 'FAIL') {
          ToastQueue.negative(response.EXCEPTION.message, { timeout: 1000 });
        }
        setShowLoader(false);
      }
    })();
    //console.log('selectedItem in header', selectedItem);
  }, [selectedItem]);

  function statusColor(color: string) {
    //console.log('customStateID', customStateID);
    if (!color || color === '0') return '';
    try {
      let finalColor = (+color).toString(16).split('').reverse();
      let padding = Array(6 - finalColor?.length).fill('0');
      let finalColorString = '#' + [...finalColor, ...padding].join('');
      //console.log('finalColorString', finalColorString);
      return finalColorString;
    } catch (error) {
      return '';
    }
  }

  const handleMenuItemClick = (args: any) => {
    console.log('--------- handleMenuItemClick mobile: ', args?.item);
    switch (args?.item?.text) {
      case 'Print':
        console.log('Print clicked');
        onPrint && selectedItem && onPrint(selectedItem);
        setIsPrintOpen(true);
        break;
      case 'Edit':
        console.log('Edit clicked');
        onEdit && selectedItem && onEdit(selectedItem);
        break;
      case 'Delete':
        console.log('Delete clicked');
        onDelete && selectedItem && onDelete(selectedItem);
        break;
      case 'Copy':
        console.log('Copy clicked');
        handleCopyClicked();
        break;
      case 'Move':
        console.log('Move clicked');
        handleMoveClicked();
        break;
    }
  };

  const handleCopyClicked = () => {
    invokeCreateContextApp(selectedItem?.id?.toString() || '', selectedItem?.type, true);
  };

  const handleMoveClicked = () => {
    invokeCreateContextApp(selectedItem?.id?.toString() || '', selectedItem?.type, false);
  };

  if (isMobile) {
    //console.log('selectedItem?.type', selectedItem?.type);

    if (showLoader) {
      return (
        <Flex width="100%" justifyContent={'center'} marginTop={10}>
          <ProgressCircle aria-label="Loading…" isIndeterminate />
        </Flex>
      );
    } else if (selectedItem?.type === FPADataTypes.FOLDER) {
      return (
        <>
          <FolderComponentMobile headerData={headerData} statusColor={statusColor} menuItems={menuItems} handleMenuItemClick={handleMenuItemClick} onAIClicked={onAI} selectedItem={selectedItem} />
          <DialogContainer
            isDismissable
            onDismiss={() => {
              setIsPrintOpen(false);
            }}
          >
            {isPrintOpen && (
              <Dialog size="L">
                <Content>
                  <PrintDialog selectedItem={selectedItem} print_type="folder" />
                </Content>
              </Dialog>
            )}
          </DialogContainer>
        </>
      );
    } else if (selectedItem?.type === FPADataTypes.PROJECT) {
      return (
        <>
          <ProjectComponentMobile
            headerData={headerData}
            statusColor={statusColor}
            menuItems={menuItems}
            handleMenuItemClick={handleMenuItemClick}
            onAI={onAI}
            selectedItem={selectedItem}
            onCopy={handleCopyClicked}
            onMove={handleMoveClicked}
          />

          <DialogContainer
            isDismissable
            onDismiss={() => {
              setIsPrintOpen(false);
            }}
          >
            {isPrintOpen && (
              <Dialog size="L">
                <Content>
                  <PrintDialog selectedItem={selectedItem} print_type="project" />
                </Content>
              </Dialog>
            )}
          </DialogContainer>
        </>
      );
    } else if (selectedItem?.type === FPADataTypes.ACTIVITY) {
      return (
        <>
          <ActivityComponentMobile
            headerData={headerData}
            statusColor={statusColor}
            menuItems={menuItems}
            handleMenuItemClick={handleMenuItemClick}
            onAI={onAI}
            selectedItem={selectedItem}
            onCopy={handleCopyClicked}
            onMove={handleMoveClicked}
          />
          <DialogContainer
            isDismissable
            onDismiss={() => {
              setIsPrintOpen(false);
            }}
          >
            {isPrintOpen && (
              <Dialog size="L">
                <Content>
                  <PrintDialog selectedItem={selectedItem} print_type="activity" />
                </Content>
              </Dialog>
            )}
          </DialogContainer>
        </>
      );
    } else {
      return '';
    }
  } else {
    if (showLoader) {
      return (
        <Flex width="100%" justifyContent={'center'} marginTop={10}>
          <ProgressCircle aria-label="Loading…" isIndeterminate />
        </Flex>
      );
    } else if (selectedItem?.type === FPADataTypes.FOLDER) {
      return <FolderComponent headerData={headerData} statusColor={statusColor} onPrint={onPrint} onEdit={onEdit} onDelete={onDelete} selectedItem={selectedItem} />;
    } else if (selectedItem?.type === FPADataTypes.PROJECT) {
      return (
        <ProjectComponent
          headerData={headerData}
          statusColor={statusColor}
          onPrint={onPrint}
          onEdit={onEdit}
          onDelete={onDelete}
          onAI={onAI}
          onCopy={handleCopyClicked}
          onMove={handleMoveClicked}
          selectedItem={selectedItem}
        />
      );
    } else if (selectedItem?.type === FPADataTypes.ACTIVITY) {
      return (
        <ActivityComponent
          headerData={headerData}
          statusColor={statusColor}
          onPrint={onPrint}
          onEdit={onEdit}
          onDelete={onDelete}
          onCopy={handleCopyClicked}
          onMove={handleMoveClicked}
          selectedItem={selectedItem}
        />
      );
    } else {
      return '';
    }
  }
}

export const HeaderToolbarComponent = LayoutComponent(HeaderToolbar);
