import React, { useCallback, useEffect, useState } from 'react';
import { Flex, ListBox, Item, View, ProgressCircle, Link } from '@adobe/react-spectrum';
import { CircleFill, ChevronDown, ChevronUp } from 'react-bootstrap-icons';
import { statusColor, truncateText } from '../../../../../utils/helperUtil';
import { useViewInfo } from '../../../../../hooks/UseViewInfo';
import { useDependency } from '../../../../../contexts/DependencyProvider';
import { ListStatusRequest, ListStatusType } from '../../../../../services/soap/project/requests/ListStatusRequest';
import { ListRegisterRequest } from '../../../../../services/soap/features/requests/ListRegisterRequest';
import { useTranslation } from 'react-i18next';
import { RootState } from '../../../../../state/store';
import { useSelector } from 'react-redux';
import styles from './folder_type_filder_component.module.css';
import { usePreloadAssets } from '../../../../../hooks/UsePreloadAssets';

interface FolderTypeFilterComponentProps {
  createParams: (filterType: any, filterValue: any) => void;
  filterParams?: any;
  clearFilters?:boolean
}

const FolderTypeFilterComponent: React.FC<FolderTypeFilterComponentProps> = ({ createParams, filterParams,clearFilters }) => {
  const selectedApp = useSelector((state: RootState) => state.userWebLayout.selectedAppId);
  const { isMobile } = useViewInfo();
  const { t } = useTranslation();
  const { store, projectService, featureService } = useDependency();
  const {folderTypes} = usePreloadAssets();
  const [toggleListVisibility, setToggleListVisibility] = useState<any>({ stateList: true });
  const [selectedFolderKey, setSelectedFolderKey] = useState<Set<string>>(new Set(['All folders']));
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const handleSelectionChange = (value: any) => {
    const arrValue = Array.from(value);
    const filteredValue = arrValue.filter(item => item !== 'All folders');
    const updateValue = filteredValue.length > 0 ? new Set(filteredValue) : new Set(['All folders']);
    setSelectedFolderKey(new Set(Array.from(updateValue).map(String)));
    createParams('folderType', updateValue);
  };

  useEffect(() => {
    if(clearFilters){
      setSelectedFolderKey(new Set(['All folders']));
    }
  }, [clearFilters]);
  const folders = Object.entries(folderTypes).map(([id, folder]) => ({
    id,
    name: folder?.name
  }));
  if (showLoader) {
    return (
      <Flex width="100%" height="100%" UNSAFE_style={{ backgroundColor: 'white' }} justifyContent={'center'} alignContent={'center'} alignItems={'center'}>
        <ProgressCircle aria-label="Loading…" isIndeterminate />
      </Flex>
    );
  } else {
    return (
      <Flex direction={'column'} minWidth={'200px'} alignSelf={'stretch'} alignItems={'start'} justifyContent={'start'}>
        <Flex direction={'column'} gap={'4px'} alignSelf={'stretch'} alignItems={'start'} justifyContent={'start'} UNSAFE_className={styles.folder_type_container}>
          <Flex direction={'row'} height={'36px'} alignSelf={'stretch'} alignItems={'center'} justifyContent={'space-between'} UNSAFE_className={styles.folder_type_heading}>
            <Flex direction={'row'} flex={1} alignItems={'center'} justifyContent={'start'}>
              <View position={'relative'} UNSAFE_className={styles.folder_type_heading_text}>
                {/* {t('folder_type', { ns: 'layout_components' })} */}
                Contact folder
              </View>
            </Flex>

            {toggleListVisibility.stateList ? (
              <ChevronDown color="#B0BEC5" onClick={() => setToggleListVisibility((preValue: any) => ({ ...preValue, stateList: false }))} style={{ cursor: 'pointer' }} />
            ) : (
              <ChevronUp color="#B0BEC5" onClick={() => setToggleListVisibility((preValue: any) => ({ ...preValue, stateList: true }))} style={{ cursor: 'pointer' }} />
            )}
          </Flex>
          <Flex
            direction={'column'}
            alignSelf={'stretch'}
            alignItems={'start'}
            justifyContent={'start'}
            UNSAFE_className={isMobile ? styles.folder_type_component_mobile : styles.folder_type_component}
            UNSAFE_style={{ display: toggleListVisibility.stateList ? '' : 'none' }}
          >
            <ListBox
              id="listActivityStatus"
              width="100%"
              selectionMode="single"
              defaultSelectedKeys={selectedFolderKey}
              selectedKeys={selectedFolderKey}
              items={folders}
              onSelectionChange={(value: any) => {
                handleSelectionChange(value);
              }}
              UNSAFE_style={{ color: '#455a64 !important' }}
              aria-label="none"
            >
              {(item: any) => (
                <Item key={item.id} textValue={truncateText(item.name, 18)}>
                  <Flex direction={'row'} height={'30px'} UNSAFE_style={{ paddingBottom: '30px',paddingLeft:'10px' }}>
                    <View UNSAFE_style={{ whiteSpace: 'nowrap', color: '#455a64 !important', lineHeight: '30px' }}>
                      <span title={item.name}>{truncateText(item.name, 18)}</span>
                    </View>
                  </Flex>
                </Item>
              )}
            </ListBox>
            {/* <View position={'relative'} width={'100%'} height={'20px'} UNSAFE_className={styles.folder_type_clear_all_container}>
              <View position={'relative'} UNSAFE_className={styles.folder_type_clear_all_text}>
                <Link onPress={() => handleSelectionChange(new Set(['All folders']))}>{t('clear_all', { ns: 'layout_components' })}</Link>
              </View>
            </View> */}
          </Flex>
        </Flex>
      </Flex>
    );
  }
};

export default FolderTypeFilterComponent;
