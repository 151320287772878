import React, { useEffect, useState } from "react";
import { Picker, Item, ListBox, Text, ComboBox, Grid, useAsyncList } from "@adobe/react-spectrum";
import { useDependency } from "../../../../../contexts/DependencyProvider";
import { ListRegionRequest } from "../../../../../services/soap/translate/reqeusts/ListRegionRequest";


export interface Character {
    country: string
    id: string
    name: string
    region: string
    regionId: string
    regionName:string 
}

interface IRegionDropDownProps{
   selectedRegion:string;
   onChange:(data:Character)=> void;
}

const RegionDropDown:React.FC<IRegionDropDownProps> = ({selectedRegion,onChange}) => {
  const { store, translateService } = useDependency();
  const [selectedKey, setSelectedKey] = useState<string | null>(null);

  useEffect(()=>{
     list.reload();
  },[]);

  useEffect(() => {
      if (selectedRegion) {
        setSelectedKey(selectedRegion);
        list.reload();
      } else {
        setSelectedKey(null);
      }
    }, [selectedRegion]);
  
  
    const handleSelectionChange = (key:any) => {
      setSelectedKey(key);
      if (key) {
        const selectedCity = list.items.find((x) => x.region === key);
        if (selectedCity) {
          onChange(selectedCity);
        }
      }
    };

  const list = useAsyncList<Character>({
    async load({ cursor,filterText }) {
      const offset = cursor ? Number(cursor) : 0;
      const limit = 50;
      try {
        const request = new ListRegionRequest(store.Server, store.SessionId, {
          limit,
          offset,
          ...(filterText ? { regionName: filterText } : { regionId: selectedRegion })
      });
      
        const response = await translateService.listRegion(request);

        const regions = response?.ROWS?.ROW || [];
        if (!Array.isArray(regions)) {
          console.error("Invalid API response: Expected an array", response);
          return { items: [] };
        }

        return {
          items: regions,
          cursor: regions.length === limit ? String(offset + limit) : undefined,
        };
      } catch (error) {
        console.error("Error fetching regions:", error);
        return { items: [] };
      }
    },
  });



  return (
    <ComboBox
  label="Region"
  aria-label="Region"
  items={list.items}
  onInputChange={list.setFilterText}
  loadingState={list.loadingState}
  onLoadMore={list.loadMore}
  onSelectionChange={handleSelectionChange}
  width="100%"
  selectedKey={selectedKey}
>
  {(item) => (
    <Item key={item.region} textValue={item.regionName}>
        {item.regionName}
    </Item>
  )}
</ComboBox>
  );
};

export default RegionDropDown;
