import { Button, ButtonGroup, Content, Dialog, Divider, Flex, Footer, Header, Heading, Key, ProgressBar, Selection, TextField, View } from '@adobe/react-spectrum';
import { FPAData, FPADataTypes, FPASystemTypes } from '../../../infra/protected/FPA/FPAData';
import { useContextNewDialog } from './ContextNewDialog.hooks';
import { FinderContextTree } from '../../../pages/protected/finder/components/FinderContextTree';
import styles from './ContextNewDialog.module.css';
import { FolderContextFields } from './FolderContextFields';
import { ProjectContextFields } from './ProjectContextFields';
import { ActivityContextFields } from './ActivityContextField';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export type ContextNewDialogMode = 'new' | 'copy' | 'move';

export interface IContextNewDialogProps {
    parentItem: FPAData | null;
    onClose: (reload_flag:boolean) => void;
    additionalMode: boolean;
    additionalData?: { fpaTypeCategory: string, fpaType: 'FOLDER'|'PROJECT'|'ACTIVITY', haveMasterActivity: boolean };
    mode?: ContextNewDialogMode;
}


export function ContextNewDialog ({
    onClose,
    parentItem,
    additionalMode,
    additionalData,
    mode
}: IContextNewDialogProps) {
    const [selectedKeys, setSelectedKeys] = useState<Selection>();
    const [expandedKeys, setExpandedKeys] = useState<Set<Key>>();


    const { 
        treeData, 
        onSelection, 
        loading, 
        currentStep,
        nextStep ,
        prevStep,
        selectedItem,
        settings,
        setFormData,
        getFormData,
        contextTypeFormId,
        activityType,
        contextFilter,
        handleContextFilterChange

    } = useContextNewDialog({ parentItem, onClose, additionalMode, additionalData, mode });
    const { t } = useTranslation();
    const _t = (key: string) => t(`newContextDialog.${key}`, { ns: 'finder' });
    const showFields = () => {
        if(selectedItem){
            switch (selectedItem.type) {
                case FPADataTypes.FOLDER:
                    return <FolderContextFields settings={settings} parentItem={parentItem} selectedItem={selectedItem} contextTypeFormId={contextTypeFormId} setFormData={setFormData} activityType={activityType} />;
                case FPADataTypes.PROJECT:
                    return <ProjectContextFields settings={settings} parentItem={parentItem} selectedItem={selectedItem} contextTypeFormId={contextTypeFormId} setFormData={setFormData} activityType={activityType} getFormData={getFormData} mode={mode} />;
                case FPADataTypes.ACTIVITY:
                    return <ActivityContextFields settings={settings} parentItem={parentItem} selectedItem={selectedItem} contextTypeFormId={contextTypeFormId} setFormData={setFormData} activityType={activityType} getFormData={getFormData} mode={mode} 
                    haveMasterActivity={+selectedItem.systemType! === FPASystemTypes.ACTIVITY_SLAVE} />;
                default:
                    console.log('unknownselectedItem: ', selectedItem);
                    return (<>{_t('unknown_item_type_selected')}</>)
            }
        }
    }
    useEffect(() => {
        if(selectedItem){
            setSelectedKeys(new Set([selectedItem.id]));
        }
    }, [selectedItem]);
    return (
        <Dialog>
            <Heading>
                 {_t('add') + ' ' + (selectedItem ? selectedItem.title : _t('new_context'))}
            </Heading>
            <Header>
                {currentStep === 1 ? _t('step_1') : _t('step_2')}
            </Header>
            <Divider />
            <Content>
                {currentStep === 1 && (
                    <Flex direction="column">
                        <View>
                            <TextField label={_t('choose_context')} autoFocus width={'100%'} value={contextFilter} onChange={handleContextFilterChange} />
                        </View>
                        <View>
                            <FinderContextTree 
                                items={treeData} 
                                onSelection={onSelection} 
                                expandedKeys={expandedKeys}
                                setExpandedKeys={setExpandedKeys}
                                selectedKeys={selectedKeys}
                                setSelectedKeys={setSelectedKeys}
                                />
                        </View>
                    </Flex>
                )}
                {currentStep === 2 && (
                    <Flex direction="column">
                        <View>
                            {_t('fill_in_the_details')}
                        </View>
                        {showFields()}
                    </Flex>
                )}
            </Content>
            <Footer>
                <Flex direction={'column'}>
                    {currentStep == 2 && selectedItem && (
                        <Button variant='secondary' onPress={() => prevStep()}>{_t('back')}</Button>
                    )}
                    <View>
                        {loading && (<ProgressBar label={_t('loading')+'...'} isIndeterminate />)}
                    </View>
                </Flex>
            </Footer>
            <ButtonGroup>
                <Button isDisabled={loading} variant="secondary" onPress={() => onClose(false)}>{_t('cancel')}</Button>
                <Button isDisabled={loading} variant="accent" onPress={nextStep}>{currentStep == 1 ? _t('next') : _t('save') }</Button>
            </ButtonGroup>
        </Dialog>
    );
}
