import { BaseRequest, IBaseRequest } from '../../BaseRequest';

export interface INewMailRequest extends IBaseRequest {
  mail: Mail;
}

export interface Mail {
  identity?: string;
  trackingCode?: string;
  priority?: string;
  group?: string;
  treeHandle?: string;
  parent?: string;
  html?: string;
  encoding?: string;
  messageType?: string;
  mailboxId?: string;
  from?: string;
  to?: string[];
  cc?: string[];
  bcc?: string[];
  notify?: Notify[];
  recipient?: Recipient[];
  subject?: string;
  body?: string;
  attachments?: Attachments;
  forwardEmails?: ForwardEmails;
  embedds?: Embedds;
  contextId?: string;
  comment?: string;
}

function toMailXml(mail: Mail): string {
  var xml = '';
  if (mail.identity) xml += `<identity>${mail.identity}</identity>`;
  if (mail.trackingCode) xml += `<trackingCode>${mail.trackingCode}</trackingCode>`;
  if (mail.priority) xml += `<priority>${mail.priority}</priority>`;
  if (mail.group) xml += `<group>${mail.group}</group>`;
  if (mail.treeHandle) xml += `<treeHandle>${mail.treeHandle}</treeHandle>`;
  if (mail.parent) xml += `<parent>${mail.parent}</parent>`;
  if (mail.html) xml += `<html>${mail.html}</html>`;
  if (mail.encoding) xml += `<encoding>${mail.encoding}</encoding>`;
  if (mail.messageType) xml += `<type>${mail.messageType}</type>`;
  if (mail.mailboxId) xml += `<mailboxId>${mail.mailboxId}</mailboxId>`;
  if (mail.from) xml += `<from>${mail.from}</from>`;
  if (mail.to) xml += mail.to.map(to => `<to>${to}</to>`).join('');
  if (mail.cc) xml += mail.cc.map(cc => `<cc>${cc}</cc>`).join('');
  if (mail.bcc) xml += mail.bcc.map(bcc => `<bcc>${bcc}</bcc>`).join('');
  if (mail.notify) xml += mail.notify.map(notify => toNotifyXml(notify)).join('');
  if (mail.recipient) xml += mail.recipient.map(recipient => toRecipientXml(recipient)).join('');
  if (mail.subject) xml += `<subject>${mail.subject}</subject>`;
  if (mail.body) xml += `<body>${mail.body}</body>`;
  if (mail.attachments) xml += toAttachmentsXml(mail.attachments);
  if (mail.forwardEmails) xml += toForwardEmailsXml(mail.forwardEmails);
  if (mail.embedds) xml += toEmbeddsXml(mail.embedds);
  if (mail.contextId) xml += `<contextId>${mail.contextId}</contextId>`;
  if (mail.comment) xml += `<comment>${mail.comment}</comment>`;
  return `<mail>${xml}</mail>`;
}

export interface Attachments {
  attachment: Attachment[];
}

function toAttachmentsXml(attachments: Attachments): string {
  console.log('attachments', attachments);
  var xml = '';
  attachments.attachment.forEach(attachment => {
    xml += toAttachmentXml(attachment);
  });
  return `<attachments>${xml}</attachments>`;
}

export interface Attachment {
  id: string;
}

function toAttachmentXml(attachment: Attachment): string {
  return `<attachment><documentId>${attachment.id}</documentId></attachment>`;
}

export interface Embedds {
  EMBEDD_DOCLIB: Attachment[];
}

function toEmbeddsXml(embedds: Embedds): string {
  var xml = '';
  embedds.EMBEDD_DOCLIB.forEach(attachment => {
    xml += toAttachmentXml(attachment);
  });
  return `<embedds>${xml}</embedds>`;
}

export interface ForwardEmails {
  forwadEmail: ForwadEmail[];
}

function toForwardEmailsXml(forwardEmails: ForwardEmails): string {
  var xml = '';
  forwardEmails.forwadEmail.forEach(forwadEmail => {
    xml += toForwadEmailXml(forwadEmail);
  });
  return `<forwardEmails>${xml}</forwardEmails>`;
}

export interface ForwadEmail {
  messageId: string;
}

function toForwadEmailXml(forwadEmail: ForwadEmail): string {
  return `<messageId>${forwadEmail.messageId}</messageId>`;
}

export interface Notify {
  contact?: string;
  id?: string;
}

function toNotifyXml(notify: Notify): string {
  var xml = '';
  if (notify.contact) xml += `<userName>${notify.contact}</userName>`;
  if (notify.id) xml += `<userId>${notify.id}</userId>`;
  return `<notify>${xml}</notify>`;
}

export interface Recipient {
  content?: string;
  contact?: string;
  id?: string;
}

function toRecipientXml(recipient: Recipient): string {
  var xml = '';
  if (recipient.content) xml += `<content>${recipient.content}</content>`;
  if (recipient.contact) xml += `<contact>${recipient.contact}</contact>`;
  if (recipient.id) xml += `<id>${recipient.id}</id>`;
  return `<user>${xml}</user>`;
}

export class NewMailRequest extends BaseRequest implements INewMailRequest {
  mail: Mail;

  constructor(server: string, session: string, mail: Mail) {
    super(server, session);
    this.mail = mail;
  }

  public toXml(): string {
    var parameters: string = super.toXml();
    parameters += toMailXml(this.mail);
    return super.wrapperXml('NewMail', parameters);
  }
}
