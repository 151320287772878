import { BaseRequest } from '../../../soap/BaseRequest';


export class PrintDataRequest extends BaseRequest {
    public print_type?: string;
    public object_id?: string;
    public template_id?: string;
    public language?: string;
  constructor(server: string, session: string, otherOptions: any) {
    super(server, session);

    this.print_type = otherOptions.print_type;
    this.object_id = otherOptions.object_id;
    this.template_id = otherOptions.template_id;
    this.language = otherOptions.language;
  }

  public toQueryString(): string {
    let items: string[] = [`server=${this.server}&session=${this.session}`];
    if (this.print_type) {
      items.push(`print_type=${this.print_type}`);
    }
    if (this.object_id) {
      items.push(`object_id=${this.object_id}`);
    }
    if (this.template_id) {
      items.push(`template_id=${this.template_id}`);
    }
    if (this.language) {
      items.push(`language=${this.language}`);
    }
    return items.join('&');
  }
}
