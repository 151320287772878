import { useCallback, useEffect, useState } from "react";
import { useViewInfo } from "../../../../hooks/UseViewInfo";
import { Offcanvas } from "react-bootstrap";
import {
  Flex,
  View,
  Link,
  DialogContainer,
  Dialog,
  Content,
  Header,
  StatusLight,
  Heading,
} from "@adobe/react-spectrum";
import { Plus, Pen, ChevronDown, ChevronUp } from "react-bootstrap-icons";
import { FilterIcon } from "../../../../components/protected/Icons/IconsLib";
import { useDependency } from "../../../../contexts/DependencyProvider";
import { useSelector } from "react-redux";
import { RootState } from "../../../../state/store";
import { ListRegisterRequest } from "../../../../services/soap/features/requests/ListRegisterRequest";
import ActivityStatusFilterComponent from "../../../../components/protected/layout-components/FilterComponent/ActivityStatusFilter/ActivityStatusFilterComponent";
import ActivityTypeFilterComponent from "../../../../components/protected/layout-components/FilterComponent/ActivityTypeFilter/ActivityTypeFilterComponent";
import UsersFilterComponent from "../../../../components/protected/layout-components/FilterComponent/UsersFilter/UsersFilterComponent";
import { useTranslation } from "react-i18next";
import styles from "./filter_component.module.css";
import FolderTypeFilterComponent from "../../../../components/protected/layout-components/FilterComponent/FolderTypeFilter/FolderTypeFilterComponent";
import IndustryFilterComponent from "../../../../components/protected/layout-components/FilterComponent/IndustryFilter/IndustryFilterComponent";
import DistributionGroupFilterComponent from "../../../../components/protected/layout-components/FilterComponent/DistributionGroupFilter/DistributionGroupFilterComponent";

interface IFilterComponentProps {
  onFilterChange: (filterParams: {}) => void;
  filterParams: any;
  filterStyle?: any;
}

const FilterComponent: React.FC<IFilterComponentProps> = ({
  onFilterChange,
  filterParams,
  filterStyle,
}) => {
  const { store, featureService } = useDependency();
  const selectedApp = useSelector(
    (state: RootState) => state.userWebLayout.selectedAppId
  );
  const { t } = useTranslation();
  const { isMobile } = useViewInfo();
  const [show, setShow] = useState(false);
  const [selectedView, setSelectedView] = useState<string>("");
  const [contactType, setContacttype] = useState<string>("");
  const [selectedFolder, setSelectedFolder] = useState<string>("");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [clearFilters, setClearFilters] = useState<boolean>(false);
  const [toggleListVisibility, setToggleListVisibility] = useState<any>({
    stateList: true,
  });
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const createParams = useCallback(
    async (filterTypeName: any, filterValue: any) => {
      //initializeFilterParams();
      const filterValueStr =
        filterTypeName === "contactType"
          ? filterValue
          : Array.from(filterValue).join(",");
      if (
        (filterTypeName == "contactType" && filterValueStr == "All Contacts") ||
        (filterTypeName == "folderType" && filterValueStr == "All folders") ||
        (filterTypeName == "industry" && filterValueStr == "All industries") ||
        (filterTypeName == "groups" && filterValueStr == "All groups")
      ) {
        filterParams.FILTER.COL = [];
        onFilterChange(filterParams);
        return;
      }
      handleFilterType(filterTypeName, filterValueStr, filterValue);
    },
    [selectedApp]
  );

  const updateFilterParams = (name: string, value: string) => {
    let updatedArray = filterParams.FILTER.COL.map((obj: any) =>
      obj.name === name ? { ...obj, value } : obj
    );
    const hasNameSearch = filterParams.FILTER.COL.some(
      (obj: any) => obj.name === name
    );

    if (!hasNameSearch) {
      updatedArray.push({ name, value });
    }
    filterParams.FILTER.COL = updatedArray;
  };

  const handleFilterType = (
    filterTypeName: any,
    filterValueStr: any,
    filterValue: any
  ) => {
    switch (filterTypeName) {
      case "contactType":
        updateFilterParams("contactType", filterValueStr);
        break;
      case "folderType":
        updateFilterParams("folderType", filterValueStr);
        break;
      case "industry":
        updateFilterParams("industry", filterValueStr);
        break;
      case "groups":
        updateFilterParams("groups", filterValueStr);
        break;
      default:
        break;
    }
    //console.log('filterParams', filterParams);
    filterParams.FILTER.COL = filterParams.FILTER.COL.filter(
      (col: any) => col.value !== ""
    );
    onFilterChange(filterParams);
  };

  const handleClearFilters = () => {
    setClearFilters(true);
    setTimeout(() => setClearFilters(false), 0);
  };

  return isMobile ? (
    <View
      position={"relative"}
      width={"100%"}
      height={"64px"}
      UNSAFE_className={styles.mobile_header_parent}
    >
      <Flex
        direction={"column"}
        position={"absolute"}
        top={"calc(50% - 16px)"}
        left={"20px"}
        align-items={"start"}
        justifyContent={"start"}
      >
        <View
          position={"relative"}
          UNSAFE_className={styles.mobile_header_text_1}
        >
          {/* {selectedView === 'All views'
            ? t('all_service_activities', { ns: 'layout_components' })
            : selectedView === '1,2'
            ? t('my_service_activities', { ns: 'layout_components' })
            : t('today_service_activities', { ns: 'layout_components' })} */}
          <Flex alignItems="start">
            <Heading level={3}>Contacts</Heading>
            <StatusLight variant="yellow"></StatusLight>
            <Heading level={4}>In development.</Heading>
          </Flex>
        </View>
        <View
          position={"relative"}
          UNSAFE_className={styles.mobile_header_text_2}
        >
          {/* {t('service_activities', { ns: 'layout_components' })} */}
        </View>
      </Flex>
      {/* <View position={'absolute'} top={'calc(50% - 12px)'} left={'16px'} width={'24px'} height={'24px'} overflow={'hidden'}>
        <span onClick={handleShow} className="me-2">
          <i className="bi bi-list"></i>
        </span>
        <Offcanvas show={show} onHide={handleClose} placement="start">
          <Offcanvas.Header closeButton>
            <Offcanvas.Title></Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Flex
              direction={'column'}
              position={'absolute'}
              top={'50px'}
              left={'15px'}
              width={'240px'}
              alignItems={'start'}
              justifyContent={'start'}
              gap={'5px'}
              UNSAFE_className={styles.views_menu_container}
            >
              <Flex direction={'row'} UNSAFE_className={styles.views_menu_heading} alignSelf={'stretch'} alignItems={'center'} justifyContent={'start'}>
                <View position={'relative'} UNSAFE_className={styles.menu_heading_text}>
                  {t('views', { ns: 'layout_components' })}
                </View>
              </Flex>
              <Flex direction={'column'} alignSelf={'stretch'} alignItems={'start'} justifyContent={'start'} gap={'4px'} UNSAFE_className={styles.filter_views_parent}>
                <a
                  onClick={() => {
                    setSelectedView('All views');
                    createParams('contactType', 'All Contacts');
                    handleClose();
                  }}
                >
                  <Flex
                    direction={'row'}
                    height={'36px'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    UNSAFE_className={selectedView === 'All views' ? styles.filter_views_container_selected : styles.filter_views_container}
                  >
                    <Flex direction={'row'} flex={1} alignItems={'center'} justifyContent={'start'}>
                      <Flex position={'relative'} flex={1} UNSAFE_className={styles.filter_views_text}>
                        {t('all_service_activities', { ns: 'layout_components' })}
                      </Flex>
                    </Flex>
                  </Flex>
                </a>
                <a
                  onClick={() => {
                    setSelectedView('1,2');
                    createParams('contactType', '1,2');
                    handleClose();
                  }}
                >
                  <Flex alignSelf={'stretch'} UNSAFE_className={selectedView === '1,2' ? styles.filter_views_container_selected : styles.filter_views_container}>
                    <Flex direction={'row'} flex={1} alignItems={'center'} justifyContent={'start'} alignSelf={'stretch'}>
                      <Flex position={'relative'} flex={1} UNSAFE_className={styles.filter_views_text}>
                        {t('my_service_activities', { ns: 'layout_components' })}
                      </Flex>
                    </Flex>
                  </Flex>
                </a>
                <a
                  onClick={() => {
                    setSelectedView('3');
                    createParams('view', '3');
                    handleClose();
                  }}
                >
                  <Flex alignSelf={'stretch'} UNSAFE_className={selectedView === '3' ? styles.filter_views_container_selected : styles.filter_views_container}>
                    <Flex direction={'row'} flex={1} alignItems={'center'} justifyContent={'start'} alignSelf={'stretch'}>
                      <Flex position={'relative'} flex={1} alignSelf={'stretch'} UNSAFE_className={styles.filter_views_text}>
                        {t('today_service_activities', { ns: 'layout_components' })}
                      </Flex>
                    </Flex>
                  </Flex>
                </a>
              </Flex>
            </Flex>
            <View position={'relative'} top={'85%'} width={'100%'} height={'1px'} UNSAFE_className={styles.separator}>
              <Flex direction={'column'} position={'absolute'} width={'240px'} alignItems={'start'} justifyContent={'start'} gap={'5px'} UNSAFE_className={styles.views_menu_container}>
                <Flex direction={'column'} alignItems={'start'} justifyContent={'start'} UNSAFE_className={styles.button_group_parent}>
                  <Flex position={'relative'} width={'266px'} height={'56px'} flexShrink={0} UNSAFE_className={styles.button_group_wrapper}>
                    <Flex direction={'row'} position={'absolute'} top={'calc(50% - 10px)'} left={'14px'} alignItems={'center'} justifyContent={'center'} UNSAFE_className={styles.btn_style}>
                      <Link
                        isQuiet
                        onPress={() => {
                          console.log('Clicked on Add View');
                        }}
                      >
                        <>
                          <Plus fontSize={'25px'} />
                          {t('add_view', { ns: 'layout_components' })}
                        </>
                      </Link>
                    </Flex>
                  </Flex>
                  <Flex position={'relative'} width={'266px'} height={'56px'} flexShrink={0} UNSAFE_className={styles.button_group_wrapper}>
                    <Flex
                      direction={'row'}
                      position={'absolute'}
                      gap={'8px'}
                      top={'calc(50% - 10px)'}
                      left={'14px'}
                      alignItems={'center'}
                      justifyContent={'center'}
                      UNSAFE_className={styles.btn_style}
                    >
                      <Link
                        isQuiet
                        onPress={() => {
                          console.log('Clicked on Edit View');
                        }}
                      >
                        <>
                          <Pen />
                          {t('edit_view', { ns: 'layout_components' })}
                        </>
                      </Link>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            </View>
          </Offcanvas.Body>
        </Offcanvas>
      </View> */}

      <View
        position={"absolute"}
        top={"calc(50% - 12px)"}
        left={"calc(100% - 40px)"}
        width={"24px"}
        height={"24px"}
        overflow={"hidden"}
      >
        <div onClick={() => setIsOpen(true)}>
          <FilterIcon size={20} />
        </div>

        <DialogContainer
          isDismissable
          onDismiss={() => {
            setIsOpen(false);
          }}
        >
          {isOpen && (
            <Dialog size="L">
              <Content>
                <Header>
                  <Flex
                    position={"relative"}
                    direction={"row"}
                    justifyContent={"center"}
                    alignContent={"center"}
                    width={"100%"}
                  >
                    <View
                      position={"relative"}
                      UNSAFE_className={styles.popup_header_text}
                    >
                      {t("filters", { ns: "layout_components" })}
                    </View>
                  </Flex>
                </Header>
                <View width={"100%"} position={"relative"} height={"100vh"}>
                  <Flex
                    direction={"column"}
                    position={"absolute"}
                    width={"95%"}
                    top={"30px"}
                    left={"16px"}
                    alignItems={"start"}
                    justifyContent={"start"}
                    gap={"24px"}
                    UNSAFE_className={styles.popup_header_body_parent}
                  >
                    <Flex
                      direction={"column"}
                      width={"100%"}
                      alignItems={"start"}
                      justifyContent={"start"}
                      gap={"12px"}
                      UNSAFE_className={styles.popup_header_body}
                    >
                      <Flex
                        direction={"row"}
                        height={"36px"}
                        alignSelf={"stretch"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        UNSAFE_className={styles.contact_type_heading}
                      >
                        <Flex
                          direction={"row"}
                          flex={1}
                          alignItems={"center"}
                          justifyContent={"start"}
                        >
                          <View
                            position={"relative"}
                            UNSAFE_className={styles.contact_type_heading_text}
                          >
                            {/* {t('folder_type', { ns: 'layout_components' })} */}
                            Type
                          </View>
                        </Flex>

                        {toggleListVisibility.stateList ? (
                          <ChevronDown
                            color="#B0BEC5"
                            size={16}
                            onClick={() =>
                              setToggleListVisibility((preValue: any) => ({
                                ...preValue,
                                stateList: false,
                              }))
                            }
                            style={{ cursor: "pointer" }}
                          />
                        ) : (
                          <ChevronUp
                            color="#B0BEC5"
                            size={16}
                            onClick={() =>
                              setToggleListVisibility((preValue: any) => ({
                                ...preValue,
                                stateList: true,
                              }))
                            }
                            style={{ cursor: "pointer" }}
                          />
                        )}
                      </Flex>
                      <Flex
                        direction={"column"}
                        alignSelf={"stretch"}
                        alignItems={"start"}
                        justifyContent={"start"}
                        gap={"4px"}
                        UNSAFE_className={styles.filter_views_parent}
                        UNSAFE_style={{
                          display: toggleListVisibility.stateList ? "" : "none",
                        }}
                      >
                        <a
                          onClick={() => {
                            setContacttype("All Contacts");
                            handleClearFilters();
                            createParams("contactType", "All Contacts");
                          }}
                        >
                          <Flex
                            direction={"row"}
                            height={"36px"}
                            alignItems={"center"}
                            justifyContent={"center"}
                            UNSAFE_className={
                              contactType === "All Contacts"
                                ? styles.filter_views_container_selected
                                : styles.filter_views_container
                            }
                          >
                            <Flex
                              direction={"row"}
                              flex={1}
                              alignItems={"center"}
                              justifyContent={"start"}
                            >
                              <Flex
                                position={"relative"}
                                flex={1}
                                UNSAFE_className={styles.filter_views_text}
                              >
                                {/* {t('all_service_activities', { ns: 'layout_components' })} */}
                                All Contacts
                              </Flex>
                            </Flex>
                          </Flex>
                        </a>
                        <a
                          onClick={() => {
                            setContacttype("1");
                            createParams("contactType", "1");
                          }}
                        >
                          <Flex
                            UNSAFE_className={
                              contactType === "1"
                                ? styles.filter_views_container_selected
                                : styles.filter_views_container
                            }
                          >
                            <Flex
                              direction={"row"}
                              flex={1}
                              alignItems={"center"}
                              justifyContent={"start"}
                            >
                              <Flex
                                position={"relative"}
                                flex={1}
                                UNSAFE_className={styles.filter_views_text}
                              >
                                {/* {t('my_service_activities', { ns: 'layout_components' })} */}
                                Persons
                              </Flex>
                            </Flex>
                          </Flex>
                        </a>

                        <a
                          onClick={() => {
                            setContacttype("2");
                            createParams("contactType", "2");
                          }}
                        >
                          <Flex
                            UNSAFE_className={
                              contactType === "2"
                                ? styles.filter_views_container_selected
                                : styles.filter_views_container
                            }
                          >
                            <Flex
                              direction={"row"}
                              flex={1}
                              alignItems={"center"}
                              justifyContent={"start"}
                            >
                              <Flex
                                position={"relative"}
                                flex={1}
                                UNSAFE_className={styles.filter_views_text}
                              >
                                {/* {t('today_service_activities', { ns: 'layout_components' })} */}
                                Companies
                              </Flex>
                            </Flex>
                          </Flex>
                        </a>
                        <a
                          onClick={() => {
                            setContacttype("3");
                            createParams("contactType", "3");
                          }}
                        >
                          <Flex
                            UNSAFE_className={
                              contactType === "3"
                                ? styles.filter_views_container_selected
                                : styles.filter_views_container
                            }
                          >
                            <Flex
                              direction={"row"}
                              flex={1}
                              alignItems={"center"}
                              justifyContent={"start"}
                            >
                              <Flex
                                position={"relative"}
                                flex={1}
                                UNSAFE_className={styles.filter_views_text}
                              >
                                {/* {t('today_service_activities', { ns: 'layout_components' })} */}
                                System Users
                              </Flex>
                            </Flex>
                          </Flex>
                        </a>
                      </Flex>
                      <FolderTypeFilterComponent
                        createParams={createParams}
                        clearFilters={clearFilters}
                      />
                      <DistributionGroupFilterComponent
                        createParams={createParams}
                        clearFilters={clearFilters}
                      />
                      <IndustryFilterComponent
                        createParams={createParams}
                        clearFilters={clearFilters}
                      />
                    </Flex>
                  </Flex>
                </View>
              </Content>
            </Dialog>
          )}
        </DialogContainer>
      </View>
    </View>
  ) : (
    <View
      width={"100%"}
      position={"relative"}
      backgroundColor={"static-white"}
      UNSAFE_className={styles.filter_menu_parent}
      UNSAFE_style={filterStyle}
    >
      <Flex
        direction={"column"}
        top={"24px"}
        left={"0px"}
        width={"240px"}
        alignItems={"start"}
        justifyContent={"start"}
        gap={"5px"}
        UNSAFE_className={styles.views_menu_container}
      >
        <Flex
          direction={"row"}
          UNSAFE_className={styles.views_menu_heading}
          alignSelf={"stretch"}
          alignItems={"center"}
          justifyContent={"start"}
        >
          <View
            position={"relative"}
            UNSAFE_className={styles.menu_heading_text}
          >
            {/* {t('views', { ns: 'layout_components' })}  */}
            Filters
          </View>
        </Flex>
        <Flex
          direction={"row"}
          height={"36px"}
          alignSelf={"stretch"}
          alignItems={"center"}
          justifyContent={"space-between"}
          UNSAFE_className={styles.contact_type_heading}
        >
          <Flex
            direction={"row"}
            flex={1}
            alignItems={"center"}
            justifyContent={"start"}
          >
            <View
              position={"relative"}
              UNSAFE_className={styles.contact_type_heading_text}
            >
              {/* {t('folder_type', { ns: 'layout_components' })} */}
              Type
            </View>
          </Flex>

          {toggleListVisibility.stateList ? (
            <ChevronDown
              color="#B0BEC5"
              size={16}
              onClick={() =>
                setToggleListVisibility((preValue: any) => ({
                  ...preValue,
                  stateList: false,
                }))
              }
              style={{ cursor: "pointer" }}
            />
          ) : (
            <ChevronUp
              color="#B0BEC5"
              size={16}
              onClick={() =>
                setToggleListVisibility((preValue: any) => ({
                  ...preValue,
                  stateList: true,
                }))
              }
              style={{ cursor: "pointer" }}
            />
          )}
        </Flex>
        <Flex
          direction={"column"}
          alignSelf={"stretch"}
          alignItems={"start"}
          justifyContent={"start"}
          gap={"4px"}
          UNSAFE_className={styles.filter_views_parent}
          UNSAFE_style={{
            display: toggleListVisibility.stateList ? "" : "none",
          }}
        >
          <a
            onClick={() => {
              setContacttype("All Contacts");
              handleClearFilters();
              createParams("contactType", "All Contacts");
            }}
          >
            <Flex
              direction={"row"}
              height={"36px"}
              alignItems={"center"}
              justifyContent={"center"}
              UNSAFE_className={
                contactType === "All Contacts"
                  ? styles.filter_views_container_selected
                  : styles.filter_views_container
              }
            >
              <Flex
                direction={"row"}
                flex={1}
                alignItems={"center"}
                justifyContent={"start"}
              >
                <Flex
                  position={"relative"}
                  flex={1}
                  UNSAFE_className={styles.filter_views_text}
                >
                  {/* {t('all_service_activities', { ns: 'layout_components' })} */}
                  All Contacts
                </Flex>
              </Flex>
            </Flex>
          </a>
          <a
            onClick={() => {
              setContacttype("1");
              createParams("contactType", "1");
            }}
          >
            <Flex
              UNSAFE_className={
                contactType === "1"
                  ? styles.filter_views_container_selected
                  : styles.filter_views_container
              }
            >
              <Flex
                direction={"row"}
                flex={1}
                alignItems={"center"}
                justifyContent={"start"}
              >
                <Flex
                  position={"relative"}
                  flex={1}
                  UNSAFE_className={styles.filter_views_text}
                >
                  {/* {t('my_service_activities', { ns: 'layout_components' })} */}
                  Persons
                </Flex>
              </Flex>
            </Flex>
          </a>

          <a
            onClick={() => {
              setContacttype("2");
              createParams("contactType", "2");
            }}
          >
            <Flex
              UNSAFE_className={
                contactType === "2"
                  ? styles.filter_views_container_selected
                  : styles.filter_views_container
              }
            >
              <Flex
                direction={"row"}
                flex={1}
                alignItems={"center"}
                justifyContent={"start"}
              >
                <Flex
                  position={"relative"}
                  flex={1}
                  UNSAFE_className={styles.filter_views_text}
                >
                  {/* {t('today_service_activities', { ns: 'layout_components' })} */}
                  Companies
                </Flex>
              </Flex>
            </Flex>
          </a>
          <a
            onClick={() => {
              setContacttype("3");
              createParams("contactType", "3");
            }}
          >
            <Flex
              UNSAFE_className={
                contactType === "3"
                  ? styles.filter_views_container_selected
                  : styles.filter_views_container
              }
            >
              <Flex
                direction={"row"}
                flex={1}
                alignItems={"center"}
                justifyContent={"start"}
              >
                <Flex
                  position={"relative"}
                  flex={1}
                  UNSAFE_className={styles.filter_views_text}
                >
                  {/* {t('today_service_activities', { ns: 'layout_components' })} */}
                  System Users
                </Flex>
              </Flex>
            </Flex>
          </a>
        </Flex>
      </Flex>
      <Flex
        direction={"column"}
        position={"relative"}
        // top={toggleListVisibility.stateList ? '250px':'95px'}
        left={"0px"}
        width={"240px"}
        alignItems={"start"}
        justifyContent={"start"}
        gap={"5px"}
        UNSAFE_className={styles.filters_menu_container}
      >
        <FolderTypeFilterComponent
          createParams={createParams}
          clearFilters={clearFilters}
        />
      </Flex>
      <Flex
        direction={"column"}
        position={"relative"}
        // top={toggleListVisibility.stateList ? '350px':'95px'}
        left={"0px"}
        width={"240px"}
        alignItems={"start"}
        justifyContent={"start"}
        gap={"5px"}
        UNSAFE_className={styles.filters_menu_container}
      >
        <DistributionGroupFilterComponent
          createParams={createParams}
          clearFilters={clearFilters}
        />
      </Flex>
      <Flex
        direction={"column"}
        position={"relative"}
        // top={toggleListVisibility.stateList ? '350px':'95px'}
        left={"0px"}
        width={"240px"}
        alignItems={"start"}
        justifyContent={"start"}
        gap={"5px"}
        UNSAFE_className={styles.filters_menu_container}
      >
        <IndustryFilterComponent
          createParams={createParams}
          clearFilters={clearFilters}
        />
      </Flex>
    </View>
  );
};

export default FilterComponent;
