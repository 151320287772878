import React, { useEffect, useState } from "react";
import { Item, ListBox, Text, ComboBox, Grid, useAsyncList } from "@adobe/react-spectrum";
import { useDependency } from "../../../../../contexts/DependencyProvider";
import { ListCityRequest } from "../../../../../services/soap/translate/reqeusts/ListCityRequest";


export interface Character {
    id:string;
    name: string;
    city:string;
    cityName:string;
    cityId:string;
    region:string;
}

interface ICityDropDownProps{
   selectedCityId:string;
   onChange:(data:Character)=> void;
}

const CityDropDown:React.FC<ICityDropDownProps> = ({selectedCityId,onChange}) => {
  const { store, translateService } = useDependency();
  const [selectedKey, setSelectedKey] = useState<string | null>(null);

  useEffect(()=>{
     list.reload();
  },[])

  const list = useAsyncList<Character>({
    async load({ cursor,filterText }) {
      const offset = cursor ? Number(cursor) : 0;
      const limit = 50;
      try {
        const request = new ListCityRequest(store.Server, store.SessionId, {
          limit,
          offset,
          ...(filterText ? { cityName: filterText } : { cityId: selectedCityId })
      });
        const response = await translateService.listCity(request);

        const cities = response?.ROWS?.ROW || [];
        if (!Array.isArray(cities)) {
          console.error("Invalid API response: Expected an array", response);
          return { items: [] };
        }
        return {
          items: cities,
          cursor: cities.length === limit ? String(offset + limit) : undefined,
        };
      } catch (error) {
        console.error("Error fetching cities:", error);
        return { items: [] };
      }
    },
  });

  
  useEffect(() => {
    if (selectedCityId) {
      setSelectedKey(selectedCityId);
      list.reload();
    } else {
      setSelectedKey(null);
    }
  }, [selectedCityId]);


  const handleSelectionChange = (key:any) => {
    setSelectedKey(key);
    if (key) {
      const selectedCity = list.items.find((x) => x.city === key);
      if (selectedCity) {
        onChange(selectedCity);
      }
    }
  };

  return (
    <ComboBox
      label="City"
      aria-label="City"
      items={list.items}
      onInputChange={list.setFilterText}
      loadingState={list.loadingState}
      onLoadMore={list.loadMore}
      selectedKey={selectedKey}
      onSelectionChange={handleSelectionChange}
      width="100%"
    > 
  {(item) => (
    <Item key={item.city} textValue={item.cityName}>
        {item.cityName}
    </Item>
  )}
</ComboBox>
  );
};

export default CityDropDown;
