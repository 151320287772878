import { BaseRequest, IBaseRequest } from '../../BaseRequest';
import { Person, toPersonXml } from '../../dataObjects/Person';

export interface IUpdateContactRequest extends IBaseRequest {
    PERSON?:       Person;
    COMPANY?:      Person;
    contactId?:    string;
}

export class UpdateContactRequest extends BaseRequest implements IUpdateContactRequest {
    public contactId?: string;
    public PERSON?: Person;
    public COMPANY?: Person;

  constructor(server: string, session: string, person?: Person, company?: Person, contactId?: string) {
    super(server, session);
    this.contactId = contactId;
    this.PERSON = person;
    this.COMPANY = company;
  }


  public toXml(): string {
    var parameters: string = super.toXml();

    if(this.contactId) parameters += '<contactId>' + this.contactId + '</contactId>';
    if(this.PERSON) parameters += toPersonXml(this.PERSON);
    if(this.COMPANY) parameters += toPersonXml(this.COMPANY, 'COMPANY');

    return super.wrapperXml('UpdateContact', parameters);
  }
}

