import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface AppInfo {
    key: string;
    data: any;
}

export interface FormSignature {
    ident?: string;
    name: string;
    picture: string;
}

interface UserWebLayoutState {
    appInfos: any;
    selectedAppId: string;
    formSignatures: { [key: string]: FormSignature | undefined };
}

const initialState: UserWebLayoutState = {
    appInfos: {},
    selectedAppId: '',
    formSignatures: {},
};

const userWebLayoutSlice = createSlice({
    name: 'userWebLayout',
    initialState,
    reducers: {
        addAppInfo(state, action: PayloadAction<string>) {
            state.appInfos[action.payload] = { };
        },
        removeAppInfo(state, action: PayloadAction<string>) {
            state.appInfos[action.payload] = undefined;
        },
        updateAppInfo(state, action: PayloadAction<AppInfo>) {
            state.appInfos[action.payload.key] = action.payload.data;
        },
        selectApp(state, action: PayloadAction<string>) {
            state.selectedAppId = action.payload;
        },
        addFormSignature(state, action: PayloadAction<FormSignature>) {
            if (action.payload.ident) {
                state.formSignatures[action.payload.ident] = {
                    name: action.payload.name,
                    picture: action.payload.picture
                };
            }
        },
        removeFormSignature(state, action: PayloadAction<string>) {
            state.formSignatures[action.payload] = undefined;
        },
        clearFormSignatures(state) {
            state.formSignatures = {};
        },
    },
});

export const { 
    addAppInfo, 
    removeAppInfo,
    updateAppInfo,
    selectApp,
    addFormSignature,
    removeFormSignature,
    clearFormSignatures
} = userWebLayoutSlice.actions;

export default userWebLayoutSlice.reducer;
