import React, { useEffect, useState } from "react";
import { Picker, Item, ListBox, Text, ComboBox, Grid, useAsyncList } from "@adobe/react-spectrum";
import { useDependency } from "../../../../../contexts/DependencyProvider";
import { ListCountryRequest } from "../../../../../services/soap/translate/reqeusts/ListCountryRequest";


export interface Character {
    id:          number;
    country:     number;
    countryId:   number;
    name:        string;
    countryName: string;
    countryNut:  string;
}

interface ICountryDropDownProps{
   selectedCountryId:string;
   onChange:(data:Character)=> void;
}

const CountriesDropDown:React.FC<ICountryDropDownProps> = ({selectedCountryId,onChange}) => {
  const { store, translateService } = useDependency();
  const [selectedKey, setSelectedKey] = useState<string | null>(null);
  useEffect(()=>{
     list.reload();
  },[])

  useEffect(() => {
        if (selectedCountryId) {
          setSelectedKey(selectedCountryId);
          list.reload();
        } else {
          setSelectedKey(null);
        }
      }, [selectedCountryId]);
    
    
      const handleSelectionChange = (key:any) => {
        setSelectedKey(key);
        if (key) {
          const selectedCity = list.items.find((x) => x.country === key);
          if (selectedCity) {
            onChange(selectedCity);
          }
        }
      };

  const list = useAsyncList<Character>({
    async load({ cursor,filterText }) {
      const offset = cursor ? Number(cursor) : 0;
      const limit = 50;
      try {
        const request = new ListCountryRequest(store.Server, store.SessionId, {
          limit,
          offset,
          ...(filterText ? { countryName: filterText } : { countryId: selectedCountryId })
      });      
        const response = await translateService.listCountry(request);

        const countries = response?.ROWS?.ROW || [];
        if (!Array.isArray(countries)) {
          console.error("Invalid API response: Expected an array", response);
          return { items: [] };
        }

        return {
          items: countries,
          cursor: countries.length === limit ? String(offset + limit) : undefined,
        };
      } catch (error) {
        console.error("Error fetching countries:", error);
        return { items: [] };
      }
    },
  });

  return (
    <ComboBox
  label="Country"
  aria-label="Country"
  items={list.items}
  onInputChange={list.setFilterText}
  loadingState={list.loadingState}
  onLoadMore={list.loadMore}
  onSelectionChange={handleSelectionChange}
  width="100%"
  selectedKey={selectedKey}
>
  {(item) => (
    <Item key={item.country} textValue={item.name}>
        {item.name}
    </Item>
  )}
</ComboBox>
  );
};

export default CountriesDropDown;
