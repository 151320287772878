import * as React from 'react';
import { ILayoutComponentProps } from './LayoutComponent';
import { FallbackComponent } from './Fallback/FallbackComponent';
import { LayoutDefinition } from '../../../infra/protected/Layout/LayoutDefinitions';
import { TabsLayoutComponent } from './TabsLayout/TabsLayoutComponent';
import { HorizontalLayoutComponent } from './HorizontalLayout/HorizontalLayoutComponent';
import { VerticalLayoutComponent } from './VerticalLayout/VerticalLayoutComponent';
import { BasicLayoutComponent } from './BasicLayout/BasicLayoutComponent';
import { SystemAttributes } from '../../../infra/protected/Layout/SystemAttributes';
import { FPAData } from '../../../infra/protected/FPA/FPAData';

export interface IComponentFactoryProps extends ILayoutComponentProps {
  definition: LayoutDefinition | null;
  systemAttributes: SystemAttributes<FPAData>;
  selectedItem: FPAData;
}

export function ComponentFactory({ definition, systemAttributes, selectedItem }: IComponentFactoryProps) {
  if (!definition) return <>No definition</>;

  switch (definition.type) {
    case 'tabsLayout':
      return (
        <TabsLayoutComponent selectedItem={selectedItem} systemAttributes={systemAttributes} {...definition.layoutAttributes}>
          {definition.children}
        </TabsLayoutComponent>
      );
    case 'horizontalLayout':
      return (
        <HorizontalLayoutComponent selectedItem={selectedItem} systemAttributes={systemAttributes} {...definition.layoutAttributes}>
          {definition.children}
        </HorizontalLayoutComponent>
      );
    case 'verticalLayout':
      return (
        <VerticalLayoutComponent selectedItem={selectedItem} systemAttributes={systemAttributes} {...definition.layoutAttributes}>
          {definition.children}
        </VerticalLayoutComponent>
      );
    case 'basicLayout':
      var new_props = {
        ...definition.layoutAttributes,
        viewComponent: definition.viewComponent,
        componentAttributes: definition.componentAttributes,
        systemAttributes: systemAttributes,
        selectedItem: selectedItem,
      };
      return <BasicLayoutComponent {...new_props} />;
    default:
      return <FallbackComponent {...definition.layoutAttributes} />;
  }
}
