import { BaseRequest, IBaseRequest } from '../../BaseRequest';

export interface Filter {
    name: string;
    code?: string;
}

export interface IListCurrencyRateRequest extends IBaseRequest {
    id?:          string;
    linkTable?:   string;
    linkName?:    string;
    treeHandle?:  string;
    orderBy?:     string;
    orderByType?: string;
    countOnly?:   string;
    offset?:      string;
    limit?:       string;
    FILTER?:      Filter;
    currency?:    string;
    rateDate?:    string;
}

export class ListCurrencyRateRequest extends BaseRequest implements IListCurrencyRateRequest {
    public id?:          string;
    public linkTable?:   string;
    public linkName?:    string;
    public treeHandle?:  string;
    public orderBy?:     string;
    public orderByType?: string;
    public countOnly?:   string;
    public offset?:      string;
    public limit?:       string;
    public FILTER?:      Filter;
    public currency?:    string;
    public rateDate?:    string;

  constructor(server: string, session: string, otherOptions: any) {
    super(server, session);
    this.id = otherOptions.id;
    this.linkTable = otherOptions.linkTable;
    this.linkName = otherOptions.linkName;
    this.treeHandle = otherOptions.treeHandle;
    this.orderBy = otherOptions.orderBy;
    this.orderByType = otherOptions.orderByType;
    this.countOnly = otherOptions.countOnly;
    this.offset = otherOptions.offset;
    this.limit = otherOptions.limit;
    this.FILTER = otherOptions.FILTER;
    this.currency = otherOptions.currency;
    this.rateDate = otherOptions.rateDate;
  }

  public toXml(): string {
    var parameters: string = super.toXml();

    if (this.id) {
      parameters += '<id>' + this.id + '</id>';
    }
    if (this.linkTable) {
      parameters += '<linkTable>' + this.linkTable + '</linkTable>';
    }
    if (this.linkName) {
      parameters += '<linkName>' + this.linkName + '</linkName>';
    }
    if (this.treeHandle) {
      parameters += '<treeHandle>' + this.treeHandle + '</treeHandle>';
    }
    if (this.orderBy) {
      parameters += '<orderBy>' + this.orderBy + '</orderBy>';
    }
    if (this.orderByType) {
      parameters += '<orderByType>' + this.orderByType + '</orderByType>';
    }
    if (this.countOnly) {
      parameters += '<countOnly>' + this.countOnly + '</countOnly>';
    }
    if (this.offset) {
      parameters += '<offset>' + this.offset + '</offset>';
    }
    if (this.limit) {
      parameters += '<limit>' + this.limit + '</limit>';
    }
    if (this.FILTER) {
      parameters += '<FILTER>';
      parameters += '<name>' + this.FILTER.name + '</name>';
      if (this.FILTER.code) {
        parameters += '<code>' + this.FILTER.code + '</code>';
      }
      parameters += '</FILTER>';
    }
    if (this.currency) {
      parameters += '<currency>' + this.currency + '</currency>';
    }

    if (this.rateDate) {
      parameters += '<rateDate>' + this.rateDate + '</rateDate>';
    }
    return super.wrapperXml('ListCurrencyRate', parameters);
  }
}
