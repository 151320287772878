import { BaseRequest, IBaseRequest } from '../../BaseRequest';

export interface IGetProjectTemplateRequest extends IBaseRequest {
    id?: number;
    templateName?: string;
}

export class GetProjectTemplateRequest extends BaseRequest implements IGetProjectTemplateRequest {

    public id?: number;
    public templateName?: string;
    constructor(server: string, session: string, id?: number, templateName?: string) {
        super(server, session);
        this.id = id;
        this.templateName = templateName;
    }



    public toXml(): string {
        var parameters: string = super.toXml();
         if(this.id) {
            parameters += '<id>' + this.id + '</id>';
         }
         if(this.templateName) {
            parameters += '<templateName>' + this.templateName + '</templateName>';
         }
        return super.wrapperXml('GetProjectTemplate', parameters);

    }
}
