import { Flex, View } from '@adobe/react-spectrum';
import moment from 'moment';
import { truncateText } from '../../../../utils/helperUtil';
import styles from './card_component.module.css';
import { ChevronRight } from 'react-bootstrap-icons';
import { ContactIcon } from '../../../../components/protected/Icons/IconsLib';
interface ICardComponentProps {
  item: any;
  activityStatusList: any;
}

const CardComponent: React.FC<ICardComponentProps> = ({ item, activityStatusList }) => {
  const getStatusColorName = (statusName: string) => {
    //console.log('status', statusName);
    const statusObj = activityStatusList?.find((obj: any) => obj.name === statusName);
    return statusObj?.color;
  };

  return (
    <Flex 
    direction="column" 
    position={'absolute'} 
    width={'100%'} 
    alignItems={'start'} 
    justifyContent={'start'} 
    UNSAFE_className={styles.card_list}>
      <Flex 
      direction={'row'} 
      position={'relative'} 
      width={'100%'} 
      alignItems={'center'} 
      justifyContent={'start'} 
      UNSAFE_className={styles.card_child_wrapper}>
        <View
          position={'absolute'}
          margin={'0px'}
          height={'calc(100% - 36px)'}
          width={'4px'}
          top={'18px'}
          bottom={'18px'}
          left={'-1px'}
          maxHeight={'100%'}
          zIndex={1}
          UNSAFE_style={{ backgroundColor: +item.folderCount > 0 ? '#ff9800' : '#B0BEC5', borderRadius: '5px' }}
        />
        <Flex 
        direction={'column'} 
        width={'100%'}
        alignSelf={'stretch'}
        alignItems={'start'} 
        justifyContent={'start'} 
        gap={'10px'} zIndex={1}>
          <Flex 
          direction={'column'} 
          alignSelf={'stretch'} 
          alignItems={'start'} 
          justifyContent={'start'} gap={'12px'}>
            {/* <Flex direction={'row'} alignSelf={'stretch'} alignItems={'center'} justifyContent={'start'} gap={'8px'}>
              <View flex={1} position={'relative'} UNSAFE_className={styles.heading_text}>
                {item.name}
              </View>
              <View position={'relative'} UNSAFE_className={styles.heading_date}>
                {moment(item.created).format('MM.DD.YYYY')}
              </View>
            </Flex> */}
            <Flex direction={'row'} alignItems={'center'} justifyContent={'start'} gap={'6px'} UNSAFE_className={styles.header_path_wrapper}>
              <View position={'relative'} UNSAFE_className={styles.header_path_text}>
                <View UNSAFE_className={styles.contact_info}>
                <span className={
                   +item.folderCount > 0 ?styles.contact_icon_orange:styles.contact_icon_grey
                  }><ContactIcon size={20} color={'#ff9800'} /></span>
                <span>
                {truncateText(item.name3,25)} {truncateText(item.name1, 15)} 
                </span>
                <p>{item.phone}</p> 
                <p>{item.email}</p>
                </View>
              </View>
            </Flex>
          </Flex>
          {/* <View position={'relative'} alignSelf={'stretch'} height={'1px'} UNSAFE_className={styles.header_divider}></View>
          <Flex direction={'row'} alignItems={'center'} justifyContent={'center'} UNSAFE_className={styles.footer_wrapper}>
            <Flex direction={'row'} alignItems={'center'} justifyContent={'center'} gap={'6px'}>
              <View position={'relative'} UNSAFE_className={styles.footer_text_1}>
                <span title={item.typeName}>{truncateText(item.typeName, 40)}</span>
              </View>
              {item.refId ? (
                <>
                  <View position={'relative'} width={'2px'} height={'20px'} UNSAFE_className={styles.pipe_sign}></View>
                  <div className={styles.footer_text_2}>{item.refId} </div>
                </>
              ) : (
                <View position={'relative'} width={'2px'} height={'20px'} UNSAFE_className={styles.pipe_sign_transparent}></View>
              )}
            </Flex>
          </Flex> */}
        </Flex>
      </Flex>
      <View UNSAFE_style={{ height: '10px' }} />
    </Flex>
  );
};

export default CardComponent;
