import { BaseRequest, IBaseRequest } from '../../BaseRequest';

export interface IListZipRequest extends IBaseRequest {
    limit?:       string;
    offset?:      string;
    countOnly?:   string;
    orderByType?: string;
    orderBy?:     string;
    zipName?:     string;
    zipId?:       string;
    cityName?:    string;
    cityId?:      string;
    regionName?:  string;
    regionId?:    string;
    countryName?: string;
    countryId?:   string;
}

export class ListZipRequest extends BaseRequest implements IListZipRequest {
    public limit?: string;
    public offset?: string;
    public countOnly?: string;
    public orderByType?: string;
    public orderBy?: string;
    public zipName?: string;
    public zipId?: string;
    public cityName?: string;
    public cityId?: string;
    public regionName?: string;
    public regionId?: string;
    public countryName?: string;
    public countryId?: string;

  constructor(server: string, session: string, otherOptions: any) {
    super(server, session);
    this.limit = otherOptions.limit;
    this.offset = otherOptions.offset;
    this.countOnly = otherOptions.countOnly;
    this.orderByType = otherOptions.orderByType;
    this.orderBy = otherOptions.orderBy;
    this.zipName = otherOptions.zipName;
    this.zipId = otherOptions.zipId;
    this.cityName = otherOptions.cityName;
    this.cityId = otherOptions.cityId;
    this.regionName = otherOptions.regionName;
    this.regionId = otherOptions.regionId;
    this.countryName = otherOptions.countryName;
    this.countryId = otherOptions.countryId;
  }


  public toXml(): string {
    var parameters: string = super.toXml();

    if (this.limit) parameters += `<limit>${this.limit}</limit>`;   
    if (this.offset) parameters += `<offset>${this.offset}</offset>`;
    if (this.countOnly) parameters += `<countOnly>${this.countOnly}</countOnly>`;
    if (this.orderByType) parameters += `<orderByType>${this.orderByType}</orderByType>`;
    if (this.orderBy) parameters += `<orderBy>${this.orderBy}</orderBy>`;
    if (this.zipName) parameters += `<zipName>${this.zipName}</zipName>`;
    if (this.zipId) parameters += `<zipId>${this.zipId}</zipId>`;
    if (this.cityName) parameters += `<cityName>${this.cityName}</cityName>`;
    if (this.cityId) parameters += `<cityId>${this.cityId}</cityId>`;
    if (this.regionName) parameters += `<regionName>${this.regionName}</regionName>`;
    if (this.regionId) parameters += `<regionId>${this.regionId}</regionId>`;
    if (this.countryName) parameters += `<countryName>${this.countryName}</countryName>`;
    if (this.countryId) parameters += `<countryId>${this.countryId}</countryId>`;

    return super.wrapperXml('ListZip', parameters);
  }
}
