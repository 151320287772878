import { BaseRequest, IBaseRequest } from '../../BaseRequest';

export interface IListMailboxRequest extends IBaseRequest {
    treeHandle?: string;
    domainId?: number;
    userId?: number;
    usableByUser?: number;
    offset?: number;
    limit?: number;
}

export class ListMailboxRequest extends BaseRequest implements IListMailboxRequest {
    public treeHandle?: string;
    public domainId?: number;
    public userId?: number;
    public usableByUser?: number;
    public offset?: number;
    public limit?: number;

  constructor(server: string, session: string, otherOptions?: any) {
    super(server, session);
    this.treeHandle = otherOptions.treeHandle;
    this.domainId = otherOptions.domainId;
    this.userId = otherOptions.userId;
    this.usableByUser = otherOptions.usableByUser;
    this.offset = otherOptions.offset;
    this.limit = otherOptions.limit;
  }

  public toXml(): string {
    var parameters: string = super.toXml();

    if(this.treeHandle) parameters += '<treeHandle>' + this.treeHandle + '</treeHandle>';
    if(this.domainId) parameters += '<domainId>' + this.domainId + '</domainId>';
    if(this.userId) parameters += '<userId>' + this.userId + '</userId>';
    if(this.usableByUser) parameters += '<usableByUser>' + this.usableByUser + '</usableByUser>';
    if(this.offset) parameters += '<offset>' + this.offset + '</offset>';
    if(this.limit) parameters += '<limit>' + this.limit + '</limit>';

    return super.wrapperXml('ListMailbox', parameters);
  }
}
