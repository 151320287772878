import { Flex, TextArea, TextField, View } from '@adobe/react-spectrum';
import { SettingItem } from '../../../services/soap/features/responses/GetFormSettingsResponse';
import { ContextFieldsMapping } from './ContextFieldsMapping';
import { FPATemplateItem } from '../../../infra/protected/FPA/FPATemplateItem';
import { FPAData, FPADataTypes, FPASystemTypes } from '../../../infra/protected/FPA/FPAData';
import IndustryDropDown from '../data-components/IndustryDropDown';
import CountryDropDown from '../data-components/CountryDropDown';
import LanguageDropDown from '../data-components/LanguageDropDown';
import PositionDropDown from '../data-components/PositionDropDown';
import DepartmentDropDown from '../data-components/DepartmentDropDown';
import ContactDropDown from '../data-components/ContactDropDown';
import { FormViewComponent } from '../layout-components/FormView/FormViewComponent';
import FolderDropDown from '../data-components/FolderDropDown';
import ProjectDropDown from '../data-components/ProjectDropDown';
import ActivityDropDown from '../data-components/ActivityDropDown';
import { useEffect, useRef, useState } from 'react';
import EmailDropDown from '../data-components/EmailDropDown';
import { ResponsibleUserDropDown } from '../data-components/ResponsibleUserDropDown';
import { useDependency } from '../../../contexts/DependencyProvider';
import { GetContactRequest } from '../../../services/soap/main/requests/GetContactRequest';
import SurnameDropDown from '../data-components/SurnameDropDown';
import { ListFPARequest2 } from '../../../services/soap/project/requests/ListFPARequest2';
import { Col, FilterCol, Order } from '../../../services/soap/project/requests/ListFPARequest';
import { ContextNewDialogMode } from './ContextNewDialog';
import { ProjectProductNewActivityComponent } from '../layout-components/ProjectProductNewActivity/ProjectProductNewActivityComponent';

export interface SectionFieldsProps {
  settings: SettingItem[];
  parentItem: FPAData | null;
  selectedItem?: FPATemplateItem;
  setFormData?: (key: string, value: any, isNew: boolean) => void;
  getFormData?: (key: string) => any;
  mode?: ContextNewDialogMode;
}
function generateMap(keys: string[], values: SettingItem[]) {
  let map = new Map<string, SettingItem>();
  values
    .filter(setting => setting.visibility == 1 && setting.coltype != 'label' && keys.includes(setting.itemname))
    .forEach(setting => {
      map.set(setting.itemname, setting);
    });
  return map;
}

function CreateTextField(
  label: string,
  key: string,
  width: string,
  isRequired: boolean,
  dataKey: string,
  setValue?: (key: string, value: any, isNew: boolean) => void,
  flex_value: number = 1,
  value?: string
) {
  const message = `${label} is required`;
  const [errorMessage, setErrorMessage] = useState(isRequired ? message : '');
  const [textValue, setTextValue] = useState('');

  const onChange = (value: string) => {
    if (setValue) setValue(dataKey, value, true);
    setTextValue(value);
    if (isRequired && value == '') setErrorMessage(message);
    else setErrorMessage('');
  };

  useEffect(() => {
    if (value != undefined) setTextValue(value || '');
  }, [value]);
  return (
    <View flex={flex_value}>
      <TextField
        label={label}
        key={key}
        value={textValue}
        width={width}
        isRequired={isRequired}
        onChange={onChange}
        validationState={errorMessage != '' ? 'invalid' : undefined}
        errorMessage={errorMessage}
      />
    </View>
  );
}

function CreateMemoField(label: string, key: string, width: string, isRequired: boolean, dataKey: string, setValue?: (key: string, value: any, isNew: boolean) => void, value?: string) {
  const message = `${label} is required`;
  const [errorMessage, setErrorMessage] = useState(isRequired ? message : '');
  const [textValue, setTextValue] = useState(value || '');

  const onChange = (value: string) => {
    if (setValue) setValue(dataKey, value, true);
    setTextValue(value);
    if (isRequired && value == '') setErrorMessage(message);
    else setErrorMessage('');
  };

  return (
    <View flex>
      <TextArea
        label={label}
        key={key}
        width={width}
        isRequired={isRequired}
        onChange={onChange}
        value={textValue}
        validationState={errorMessage != '' ? 'invalid' : undefined}
        errorMessage={errorMessage}
      />
    </View>
  );
}

function CreateIndustryComponent(label: string, key: string, width: string, isRequired: boolean, dataKey: string, setValue?: (key: string, value: any, isNew: boolean) => void, value?: string) {
  const onChange = (value: string, new_value: string) => {
    if (setValue) setValue(dataKey, value === '' ? new_value : value, value === '');
  };
  return (
    <View flex>
      <IndustryDropDown compKey={key} label={label} width={width} isRequired={isRequired} onValueChange={onChange} value={value} />
    </View>
  );
}

function CreateMasterProjectComponent(label: string, key: string, width: string, isRequired: boolean, dataKey: string, setValue?: (key: string, value: any, isNew: boolean) => void, value?: string) {
  const onChange = (value: string, new_value: string) => {
    if (setValue) setValue(dataKey, value === '' ? new_value : value, value === '');
  };
  return (
    <View flex>
      <ProjectDropDown
        compKey={key}
        label={label}
        width={width}
        isRequired={isRequired}
        onValueChange={onChange}
        value={value}
        fpa_type_category={'com.atollon.campaigns.fpatypecategory.campaign'}
        flatView={true}
        showParent={true}
      />
    </View>
  );
}


function CreateCountryComponent(label: string, key: string, width: string, isRequired: boolean, dataKey: string, setValue?: (key: string, value: any, isNew: boolean) => void, value?: string) {
  const onChange = (value: string, new_value: string) => {
    if (setValue) setValue(dataKey, value === '' ? new_value : value, value === '');
  };
  return (
    <View flex>
      <CountryDropDown compKey={key} label={label} width={width} isRequired={isRequired} onValueChange={onChange} value={value} />
    </View>
  );
}

function CreateLanguageComponent(label: string, key: string, width: string, isRequired: boolean, dataKey: string, setValue?: (key: string, value: any, isNew: boolean) => void, value?: string) {
  const onChange = (value: string, new_value: string) => {
    if (setValue) setValue(dataKey, value === '' ? new_value : value, value === '');
  };
  return (
    <View flex>
      <LanguageDropDown compKey={key} label={label} width={width} isRequired={isRequired} onValueChange={onChange} value={value} />
    </View>
  );
}

function CreatePositionComponent(label: string, key: string, width: string, isRequired: boolean, dataKey: string, setValue?: (key: string, value: any, isNew: boolean) => void) {
  const onChange = (value: string, new_value: string) => {
    if (setValue) setValue(dataKey, value === '' ? new_value : value, value === '');
  };
  return (
    <View flex>
      <PositionDropDown compKey={key} label={label} width={width} isRequired={isRequired} onValueChange={onChange} />
    </View>
  );
}

function CreateDepartmentComponent(label: string, key: string, width: string, isRequired: boolean, dataKey: string, setValue?: (key: string, value: any, isNew: boolean) => void) {
  const onChange = (value: string, new_value: string) => {
    if (setValue) setValue(dataKey, value === '' ? new_value : value, value === '');
  };
  return (
    <View flex>
      <DepartmentDropDown compKey={key} label={label} width={width} isRequired={isRequired} onValueChange={onChange} />
    </View>
  );
}

function CreateCompanyComponent(
  label: string,
  key: string,
  width: string,
  isRequired: boolean,
  dataKey: string,
  organization_id: number,
  setValue?: (key: string, value: any, isNew: boolean) => void
) {
  const onChange = (value: string, new_value: string) => {
    if (setValue) setValue(dataKey, value === '' ? new_value : value, value === '');
  };
  return (
    <View flex>
      <ContactDropDown
        compKey={key}
        label={label}
        width={width}
        isRequired={isRequired}
        onValueChange={onChange}
        apiOptions={{
          type: 2,
          onlyIds: false,
          organizationId: organization_id,
          hasFolder: 'all',
          showOnlyUc: false,
          conjuctionDistrGroups: 0,
        }}
      />
    </View>
  );
}

function CreateSurnameComponent(
  label: string,
  key: string,
  width: string,
  isRequired: boolean,
  dataKey: string,
  organization_id: number,
  setValue?: (key: string, value: any, isNew: boolean) => void,
  value?: string,
  firstName?: string
) {
  const onChange = (value: string, new_value: string) => {
    if (setValue) setValue(dataKey, value === '' ? new_value : value, value === '');
  };
  return (
    <View flex={2}>
      <SurnameDropDown
        compKey={key}
        label={label}
        width={width}
        isRequired={isRequired}
        onValueChange={onChange}
        apiOptions={{
          type: 1,
          onlyIds: false,
          organizationId: organization_id,
          hasFolder: 'all',
          showOnlyUc: false,
          conjuctionDistrGroups: 0,
        }}
        value={value}
        firstName={firstName}
      />
    </View>
  );
}

function CreateEmailComponent(
  label: string,
  key: string,
  width: string,
  isRequired: boolean,
  dataKey: string,
  organization_id: number,
  setValue?: (key: string, value: any, isNew: boolean) => void,
  value?: string
) {
  const onChange = (value: string, new_value: string) => {
    if (setValue) setValue(dataKey, value === '' ? new_value : value, value === '');
  };
  return (
    <View flex>
      <EmailDropDown
        compKey={key}
        label={label}
        width={width}
        isRequired={isRequired}
        onValueChange={onChange}
        apiOptions={{
          type: 1,
          onlyIds: false,
          organizationId: organization_id,
          hasFolder: 'all',
          showOnlyUc: false,
          conjuctionDistrGroups: 0,
        }}
        value={value}
      />
    </View>
  );
}

export function CreateContextFields({
  setting,
  setValue,
  flex_value = 1,
  organization_id,
  value,
}: {
  setting?: SettingItem;
  setValue?: (key: string, value: any, isNew: boolean, full_data?: any) => void;
  flex_value?: number;
  organization_id?: number;
  value?: string;
}) {
  if (!setting) return null;
  switch (setting.itemname) {
    case ContextFieldsMapping.COMPANY.INDUSTRY:
      return CreateIndustryComponent(setting.caption ? setting.caption : setting.name, '' + setting.id, '100%', setting.mandatory == 1 ? true : false, setting.itemname, setValue);

    case ContextFieldsMapping.PROJECT.MASTER_PROJECT:
      return CreateMasterProjectComponent(setting.caption ? setting.caption : setting.name, '' + setting.id, '100%', setting.mandatory == 1 ? true : false, setting.itemname, setValue, value);

    case ContextFieldsMapping.COMPANY.COUNTRY:
    case ContextFieldsMapping.PERSON.COUNTRY:
      return CreateCountryComponent(setting.caption ? setting.caption : setting.name, '' + setting.id, '100%', setting.mandatory == 1 ? true : false, setting.itemname, setValue, value);

    case ContextFieldsMapping.PERSON.SURNAME:
      return CreateSurnameComponent(
        setting.caption ? setting.caption : setting.name,
        '' + setting.id,
        '100%',
        setting.mandatory == 1 ? true : false,
        setting.itemname,
        organization_id!,
        setValue,
        value
      );

    case ContextFieldsMapping.PERSON.PREFERRED_LANGUAGE:
      return CreateLanguageComponent(setting.caption ? setting.caption : setting.name, '' + setting.id, '100%', setting.mandatory == 1 ? true : false, setting.itemname, setValue, value);

    case ContextFieldsMapping.EMPLOYEE.POSITION:
      return CreatePositionComponent(setting.caption ? setting.caption : setting.name, '' + setting.id, '100%', setting.mandatory == 1 ? true : false, setting.itemname, setValue);

    case ContextFieldsMapping.EMPLOYEE.DEPARTMENT:
      return CreateDepartmentComponent(setting.caption ? setting.caption : setting.name, '' + setting.id, '100%', setting.mandatory == 1 ? true : false, setting.itemname, setValue);

    case ContextFieldsMapping.COMPANY.NAME:
      return CreateCompanyComponent(setting.caption ? setting.caption : setting.name, '' + setting.id, '100%', setting.mandatory == 1 ? true : false, setting.itemname, organization_id!, setValue);
    case ContextFieldsMapping.PERSON.EMAIL:
      return CreateEmailComponent(
        setting.caption ? setting.caption : setting.name,
        '' + setting.id,
        '100%',
        setting.mandatory == 1 ? true : false,
        setting.itemname,
        organization_id!,
        setValue,
        value
      );
    case ContextFieldsMapping.SIMPLE_FOLDER.DESCRIPTION:
    case ContextFieldsMapping.PROJECT.DESCRIPTION:
    case ContextFieldsMapping.ACTIVITY.DESCRIPTION:
      return CreateMemoField(setting.caption ? setting.caption : setting.name, '' + setting.id, '100%', setting.mandatory == 1 ? true : false, setting.itemname, setValue, value);

    default:
      return CreateTextField(setting.caption ? setting.caption : setting.name, '' + setting.id, '100%', setting.mandatory == 1 ? true : false, setting.itemname, setValue, flex_value, value);
  }
}

export function CreateCompanyFields({ settings, selectedItem, setFormData }: SectionFieldsProps) {
  let keys = [
    ContextFieldsMapping.COMPANY.NAME,
    ContextFieldsMapping.COMPANY.VAT_NO,
    ContextFieldsMapping.COMPANY.COUNTRY,
    ContextFieldsMapping.COMPANY.INDUSTRY,
    ContextFieldsMapping.COMPANY.TRACKING_CODE,
    ContextFieldsMapping.COMPANY.REF_NO,
  ];

  let map = generateMap(keys, settings);

  if (!selectedItem || selectedItem.systemType != FPASystemTypes.FOLDER_CONTACT) {
    return null;
  }

  return (
    <Flex direction="column" gap="size-100" width={'100%'}>
      <Flex direction={{ base: 'column', M: 'row' }} gap="size-100">
        <CreateContextFields setting={map.get(ContextFieldsMapping.COMPANY.NAME)} setValue={setFormData} />
      </Flex>
      <Flex direction={{ base: 'column', M: 'row' }} gap="size-100">
        <CreateContextFields setting={map.get(ContextFieldsMapping.COMPANY.INDUSTRY)} setValue={setFormData} />
      </Flex>
      <Flex direction={{ base: 'column', M: 'row' }} gap="size-100">
        <CreateContextFields setting={map.get(ContextFieldsMapping.COMPANY.VAT_NO)} setValue={setFormData} />
        <CreateContextFields setting={map.get(ContextFieldsMapping.COMPANY.COUNTRY)} setValue={setFormData} />
      </Flex>
      <Flex direction={{ base: 'column', M: 'row' }} gap="size-100">
        <CreateContextFields setting={map.get(ContextFieldsMapping.COMPANY.REF_NO)} setValue={setFormData} />
        <CreateContextFields setting={map.get(ContextFieldsMapping.COMPANY.TRACKING_CODE)} setValue={setFormData} />
      </Flex>
    </Flex>
  );
}

export function CreatePersonFields({ settings, selectedItem, setFormData }: SectionFieldsProps) {
  const [prefix, setPrefix] = useState('');
  const [firstName, setFirstName] = useState('');
  const [surname, setSurname] = useState('');
  const [suffix, setSuffix] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [phone, setPhone] = useState('');
  const [salutation, setSalutation] = useState('');
  const [country, setCountry] = useState('');
  const [preferredLanguage, setPreferredLanguage] = useState('');
  const { mainService, store } = useDependency();

  let keys = [
    ContextFieldsMapping.PERSON.PREFIX,
    ContextFieldsMapping.PERSON.FIRST_NAME,
    ContextFieldsMapping.PERSON.SURNAME,
    ContextFieldsMapping.PERSON.SUFFIX,
    ContextFieldsMapping.PERSON.EMAIL,
    ContextFieldsMapping.PERSON.MOBILE,
    ContextFieldsMapping.PERSON.PHONE,
    ContextFieldsMapping.PERSON.SALUTATION,
    ContextFieldsMapping.PERSON.COUNTRY,
    ContextFieldsMapping.PERSON.PREFERRED_LANGUAGE,
  ];

  let map = generateMap(keys, settings);

  if (!selectedItem) return null;

  if (selectedItem.type == FPADataTypes.FOLDER && selectedItem.systemType != FPASystemTypes.FOLDER_CONTACT && selectedItem.systemType != FPASystemTypes.FOLDER_USER) return null;

  const loadContact = async (contactId: string) => {
    const full_data = await mainService.getContact(new GetContactRequest(store.Server, store.SessionId, undefined, undefined, +contactId));

    setPrefix(full_data.PERSON.PERSONINFO?.prefixTitle || '');
    setFirstName(full_data.PERSON.name3 || '');
    setSurname(full_data.PERSON.name1 || '');
    setSuffix(full_data.PERSON.PERSONINFO?.postfixTitle || '');
    setMobile(full_data.PERSON.mobile || '');
    setEmail(full_data.PERSON.email || '');
    setPhone(full_data.PERSON.phone || '');
    setSalutation(full_data.PERSON.PERSONINFO?.salutation || '');
    setCountry(full_data.PERSON.country || '');
    setPreferredLanguage(full_data.PERSON.PERSONINFO?.preferedLang || '');
  };

  const handleEmailChange = (key: string, value: any, isNew: boolean) => {
    if (setFormData) {
      setFormData(key, value, isNew);
    }

    setEmail(value);

    if (!isNew && value) {
      loadContact(value);
    }
  };

  const handleSurnameChange = (key: string, value: any, isNew: boolean) => {
    if (setFormData) {
      setFormData(key, value, isNew);
    }

    setSurname(value);

    if (!isNew && value) {
      loadContact(value);
    }
  };

  const handleSetFormData = (key: string, value: any, isNew: boolean) => {
    if (setFormData) {
      setFormData(key, value, isNew);
    }
    switch (key) {
      case ContextFieldsMapping.PERSON.PREFIX:
        setPrefix(value);
        break;
      case ContextFieldsMapping.PERSON.FIRST_NAME:
        setFirstName(value);
        break;
      case ContextFieldsMapping.PERSON.SURNAME:
        setSurname(value);
        break;
      case ContextFieldsMapping.PERSON.SUFFIX:
        setSuffix(value);
        break;
      case ContextFieldsMapping.PERSON.EMAIL:
        setEmail(value);
        break;
      case ContextFieldsMapping.PERSON.MOBILE:
        setMobile(value);
        break;
      case ContextFieldsMapping.PERSON.PHONE:
        setPhone(value);
        break;
      case ContextFieldsMapping.PERSON.SALUTATION:
        setSalutation(value);
        break;
      case ContextFieldsMapping.PERSON.COUNTRY:
        setCountry(value);
        break;
      case ContextFieldsMapping.PERSON.PREFERRED_LANGUAGE:
        setPreferredLanguage(value);
        break;
    }
  };
  return (
    <Flex direction="column" gap="size-100" width={'100%'}>
      <Flex direction={{ base: 'column', M: 'row' }} gap="size-100">
        <CreateContextFields setting={map.get(ContextFieldsMapping.PERSON.PREFIX)} setValue={handleSetFormData} flex_value={1} value={prefix} />
        <CreateContextFields setting={map.get(ContextFieldsMapping.PERSON.FIRST_NAME)} setValue={handleSetFormData} flex_value={2} value={firstName} />
        <CreateContextFields setting={map.get(ContextFieldsMapping.PERSON.SURNAME)} setValue={handleSurnameChange} flex_value={2} value={surname} />
        <CreateContextFields setting={map.get(ContextFieldsMapping.PERSON.SUFFIX)} setValue={handleSetFormData} flex_value={1} value={suffix} />
      </Flex>
      <Flex direction={{ base: 'column', M: 'row' }} gap="size-100">
        <CreateContextFields setting={map.get(ContextFieldsMapping.PERSON.EMAIL)} setValue={handleEmailChange} value={email} />
        <CreateContextFields setting={map.get(ContextFieldsMapping.PERSON.MOBILE)} setValue={handleSetFormData} value={mobile} />
      </Flex>
      <Flex direction={{ base: 'column', M: 'row' }} gap="size-100">
        <CreateContextFields setting={map.get(ContextFieldsMapping.PERSON.PHONE)} setValue={handleSetFormData} value={phone} />
        <CreateContextFields setting={map.get(ContextFieldsMapping.PERSON.SALUTATION)} setValue={handleSetFormData} value={salutation} />
      </Flex>
      <Flex direction={{ base: 'column', M: 'row' }} gap="size-100">
        <CreateContextFields setting={map.get(ContextFieldsMapping.PERSON.COUNTRY)} setValue={handleSetFormData} value={country} />
        <CreateContextFields setting={map.get(ContextFieldsMapping.PERSON.PREFERRED_LANGUAGE)} setValue={handleSetFormData} value={preferredLanguage} />
      </Flex>
    </Flex>
  );
}

export function CreateEmployeeFields({ settings, setFormData }: SectionFieldsProps) {
  let keys = [
    // ContextFieldsMapping.EMPLOYEE.PANEL,
    ContextFieldsMapping.EMPLOYEE.POSITION,
    ContextFieldsMapping.EMPLOYEE.POSITION_NAME,
    ContextFieldsMapping.EMPLOYEE.DEPARTMENT,
  ];

  let map = generateMap(keys, settings);

  return (
    <Flex direction="column" gap="size-100" width={'100%'}>
      <Flex direction={{ base: 'column', M: 'row' }} gap="size-100">
        {/* <CreateContextFields setting={map.get(ContextFieldsMapping.EMPLOYEE.PANEL)} setValue={setFormData} /> */}
        <CreateContextFields setting={map.get(ContextFieldsMapping.EMPLOYEE.POSITION)} setValue={setFormData} />
      </Flex>
      <Flex direction={{ base: 'column', M: 'row' }} gap="size-100">
        <CreateContextFields setting={map.get(ContextFieldsMapping.EMPLOYEE.POSITION_NAME)} setValue={setFormData} />
        <CreateContextFields setting={map.get(ContextFieldsMapping.EMPLOYEE.DEPARTMENT)} setValue={setFormData} />
      </Flex>
    </Flex>
  );
}

export function CreateSimpleFolderFields({ settings, setFormData }: SectionFieldsProps) {
  let keys = [
    ContextFieldsMapping.SIMPLE_FOLDER.REF_ID,
    ContextFieldsMapping.SIMPLE_FOLDER.TRACKING_CATEGORY_ONE,
    ContextFieldsMapping.SIMPLE_FOLDER.TRACKING_CATEGORY_TWO,
    ContextFieldsMapping.SIMPLE_FOLDER.TRACKING_CATEGORY_THREE,
    ContextFieldsMapping.SIMPLE_FOLDER.DESCRIPTION,
  ];

  let map = generateMap(keys, settings);

  return (
    <Flex direction="column" gap="size-100" width={'100%'}>
      <Flex direction={{ base: 'column', M: 'row' }} gap="size-100">
        <CreateContextFields setting={map.get(ContextFieldsMapping.SIMPLE_FOLDER.REF_ID)} setValue={setFormData} />
        <CreateContextFields setting={map.get(ContextFieldsMapping.SIMPLE_FOLDER.TRACKING_CATEGORY_ONE)} setValue={setFormData} />
      </Flex>
      <Flex direction={{ base: 'column', M: 'row' }} gap="size-100">
        <CreateContextFields setting={map.get(ContextFieldsMapping.SIMPLE_FOLDER.TRACKING_CATEGORY_TWO)} setValue={setFormData} />
        <CreateContextFields setting={map.get(ContextFieldsMapping.SIMPLE_FOLDER.TRACKING_CATEGORY_THREE)} setValue={setFormData} />
      </Flex>
      <Flex direction={{ base: 'column', M: 'row' }} gap="size-100">
        <CreateContextFields setting={map.get(ContextFieldsMapping.SIMPLE_FOLDER.DESCRIPTION)} setValue={setFormData} />
      </Flex>
    </Flex>
  );
}

export function CreateProjectFields({ settings, setFormData, mode, getFormData, parentItem, selectedItem }: SectionFieldsProps) {
  let keys = [
    ContextFieldsMapping.PROJECT.DESCRIPTION,
    ContextFieldsMapping.PROJECT.MASTER_PROJECT,
    ContextFieldsMapping.PROJECT.SUB_FOLDER_TYPE_NAME,
    ContextFieldsMapping.PROJECT.TRACKING_CATEGORY_ONE,
    ContextFieldsMapping.PROJECT.TRACKING_CATEGORY_TWO,
    ContextFieldsMapping.PROJECT.TRACKING_CATEGORY_THREE,
    ContextFieldsMapping.PROJECT.NAME,
    ContextFieldsMapping.PROJECT.REF_ID,
    ContextFieldsMapping.PROJECT.CONTACT,
  ];

  var master_project = {
    id: 0,
    colname: 'masterProjectId',
    visibility: selectedItem?.systemType == FPASystemTypes.PROJECT_SLAVE ? 1 : 0,
    settingsId: 4,
    mandatory: 0,
    defaultval: '',
    coltype: '',
    otherparams: '',
    caption: 'Master Project',
    itemid: 0,
    name: 'project.masterProjectId',
    itemname: 'project.masterProjectId',
  };

  settings.push(master_project);

  let map = generateMap(keys, settings);

  var project_name = {
    id: 0,
    colname: 'name',
    visibility: 1,
    settingsId: 4,
    mandatory: 1,
    defaultval: '',
    coltype: '',
    otherparams: '',
    caption: 'Name',
    itemid: 0,
    name: 'project.name',
    itemname: 'project.name',
  };

  map.set(ContextFieldsMapping.PROJECT.NAME, project_name);

  return (
    <Flex direction="column" gap="size-100" width={'100%'}>
      <Flex direction={{ base: 'column', M: 'row' }} gap="size-100">
        <CreateContextFields setting={map.get(ContextFieldsMapping.PROJECT.NAME)} setValue={setFormData} value={mode === 'copy' && getFormData ? getFormData(ContextFieldsMapping.PROJECT.NAME) : ''} />
      </Flex>

      <Flex direction={{ base: 'column', M: 'row' }} gap="size-100">
        <CreateContextFields
          setting={map.get(ContextFieldsMapping.PROJECT.DESCRIPTION)}
          setValue={setFormData}
          value={mode === 'copy' && getFormData ? getFormData(ContextFieldsMapping.PROJECT.DESCRIPTION) : ''}
        />
      </Flex>

      <Flex direction={{ base: 'column', M: 'row' }} gap="size-100">
        <CreateContextFields setting={map.get(ContextFieldsMapping.PROJECT.MASTER_PROJECT)} 
          setValue={setFormData} 
          value={mode === 'copy' && getFormData ? getFormData(ContextFieldsMapping.PROJECT.MASTER_PROJECT) : ''} 
          />
      </Flex>

      <Flex direction={{ base: 'column', M: 'row' }} gap="size-100">
        <CreateContextFields
          setting={map.get(ContextFieldsMapping.PROJECT.SUB_FOLDER_TYPE_NAME)}
          setValue={setFormData}
          value={mode === 'copy' && getFormData ? getFormData(ContextFieldsMapping.PROJECT.SUB_FOLDER_TYPE_NAME) : ''}
        />
      </Flex>

      <Flex direction={{ base: 'column', M: 'row' }} gap="size-100">
        <CreateContextFields
          setting={map.get(ContextFieldsMapping.PROJECT.TRACKING_CATEGORY_ONE)}
          setValue={setFormData}
          value={mode === 'copy' && getFormData ? getFormData(ContextFieldsMapping.PROJECT.TRACKING_CATEGORY_ONE) : ''}
        />
        <CreateContextFields
          setting={map.get(ContextFieldsMapping.PROJECT.TRACKING_CATEGORY_TWO)}
          setValue={setFormData}
          value={mode === 'copy' && getFormData ? getFormData(ContextFieldsMapping.PROJECT.TRACKING_CATEGORY_TWO) : ''}
        />
      </Flex>

      <Flex direction={{ base: 'column', M: 'row' }} gap="size-100">
        <CreateContextFields
          setting={map.get(ContextFieldsMapping.PROJECT.TRACKING_CATEGORY_THREE)}
          setValue={setFormData}
          value={mode === 'copy' && getFormData ? getFormData(ContextFieldsMapping.PROJECT.TRACKING_CATEGORY_THREE) : ''}
        />
        <CreateContextFields
          setting={map.get(ContextFieldsMapping.PROJECT.REF_ID)}
          setValue={setFormData}
          value={mode === 'copy' && getFormData ? getFormData(ContextFieldsMapping.PROJECT.REF_ID) : ''}
        />
      </Flex>
    </Flex>
  );
}

export function CreateCustomFormFields({ settings, selectedItem, setFormData, getFormData }: SectionFieldsProps) {
  const data = useRef<{ [key: string]: any }>({});
  let keys = [ContextFieldsMapping.CUSTOM_FORM];

  let map = generateMap(keys, settings);
  if (map.size == 0 || !selectedItem?.formId) return null;

  const setFormViewData = (key: string, value: any) => {
    if (setFormData) {
      setFormData(`CF_${key}`, value, true);
    }
    data.current = { ...data.current, [key]: value };
  };

  const getFormViewData = (key: string, default_value: any) => {
    var external_value = getFormData ? getFormData(`CF_${key}`) : default_value;
    return data.current[key] || external_value;
  };
  return (
    <View>
      <FormViewComponent formIdType={'self'} formId={`${selectedItem.formId}`} isNew={true} setFormViewData={setFormViewData} getFormViewData={getFormViewData} />
    </View>
  );
}

export function CreateCustomFormFieldForContextType({
  formId,
  setFormData,
  mode,
  getFormData,
}: {
  formId?: string;
  setFormData?: (key: string, value: any, isNew: boolean) => void;
  mode?: ContextNewDialogMode;
  getFormData?: (key: string) => any;
}) {
  if (!formId) return null;

  const setFormViewData = (key: string, value: any) => {
    if (setFormData) {
      setFormData(`CF_${key}`, value, true);
    }
  };

  const getFormViewData = (key: string, default_value: any) => {
    if (!getFormData) return default_value;
    var external_value = getFormData(`CF_${key}`);
    return external_value;
  };

  return (
    <View>
      <FormViewComponent formIdType={'self'} formId={formId} isNew={true} setFormViewData={setFormViewData} getFormViewData={getFormViewData} />
    </View>
  );
}
export function CreateTrackingCodeFields({ settings }: SectionFieldsProps) {
  let keys = [ContextFieldsMapping.TRACKING_CODE];

  let map = generateMap(keys, settings);
  if (map.size == 0) return null;

  return null;
  // return (
  // <View UNSAFE_style={{color:'red'}}>Tracking Code Goes here</View>);
}

interface ContextInfoFieldsProps extends SectionFieldsProps {
  folderType?: number;
  showFolders: boolean;
  showProjects: boolean;
  showActivities: boolean;
  activityType?: number;
  onChangeProject?: (projectId: number) => void;
}

export function CreateContextInfoFields({
  settings,
  selectedItem,
  folderType,
  showFolders,
  showProjects,
  showActivities,
  activityType,
  setFormData,
  parentItem,
  onChangeProject,
}: ContextInfoFieldsProps) {
  const [folderTypeId, setFolderTypeId] = useState(folderType);
  const [folderId, setFolderId] = useState<number | undefined>();
  const [subFolderTypeId, setSubFolderTypeId] = useState(folderType);
  const [subFolderId, setSubFolderId] = useState<number | undefined>();
  const [projectFolderId, setProjectFolderId] = useState<number | undefined>();
  const [projectId, setProjectId] = useState<number | undefined>();
  const [activityId, setActivityId] = useState<number | undefined>();
  const [hasSubFolder, setHasSubFolder] = useState<boolean>(false);

  const { store, projectService } = useDependency();

  const hasFolderInChildren = async (id: string): Promise<boolean> => {
    const folder_filter: FilterCol[] = [];

    folder_filter.push(new FilterCol('parent', 'equals', id));

    const response = await projectService.listFPA2(
      new ListFPARequest2(store.Server, store.SessionId, {
        offset: 0,
        limit: 1,
        flatView: true,
        folderFilter: {
          COL: folder_filter,
        },
        ORDER: new Order([new Col('name'), new Col('created', true)]),
      })
    );
    if (response.EXCEPTION) {
      console.error(response.EXCEPTION.message);
      return false;
    } else if (response.ITEMS) {
      if (response.ITEMS[0].ITEM.FOLDER) return true;
    }
    return false;
  };

  const handleFolderChange = (value: string) => {
    // console.log('folder: ',value);
    setFolderId(+value);
    hasFolderInChildren(value).then(isSubFolder => {
      setHasSubFolder(isSubFolder);
    });
    setProjectFolderId(+value);
    if (setFormData) {
      setFormData('folder', value, value === '');
    }
  };

  const handleSubFolderChange = (value: string) => {
    // console.log('Sub folder: ',value);
    setSubFolderId(+value);
    setProjectFolderId(+value);
    if (setFormData) {
      setFormData('subFolder', value, value === '');
    }
  };

  const handleProjectChange = (value: string) => {
    // console.log('project: ',value);
    setProjectId(+value);
    if (setFormData) {
      setFormData('project', value, value === '');
    }
    if (onChangeProject) {
      onChangeProject(+value);
    }
  };

  const handleActivityChange = (value: string) => {
    // console.log('activity: ',value);
    setActivityId(+value);
    if (setFormData) {
      setFormData('activity', value, value === '');
    }
  };

  const getFoldersInContext = (): FPAData[] => {
    var items = [];
    var ref: FPAData | undefined | null = parentItem;
    while (ref) {
      if (ref.type == FPADataTypes.FOLDER) {
        items.push({ ...ref });
      }
      ref = ref.parent;
    }
    return items.reverse();
  };
  useEffect(() => {
    if (parentItem) {
      const folders = getFoldersInContext();
      const have_sub_folder = showFolders && folders.length > 1;

      if (folders.length == 0) return;

      handleFolderChange(`${folders[0].id}`);
      setFolderTypeId(folders[0].item_type_id);
      if (have_sub_folder) {
        handleSubFolderChange(`${folders[1].id}`);
        setSubFolderTypeId(folders[1].item_type_id);
      }

      switch (parentItem.type) {
        case FPADataTypes.PROJECT:
          handleProjectChange(`${parentItem.id}`);
          break;
        case FPADataTypes.ACTIVITY:
          setActivityId(parentItem.id);
          break;
        default:
          break;
      }

      setHasSubFolder(have_sub_folder);
    }
  }, [parentItem, showFolders]);

  return (
    <Flex direction={{ base: 'column', M: 'row' }} gap="size-100" width={'100%'}>
      {showFolders && (
        <View flex={1}>
          <FolderDropDown
            compKey={'folder'}
            label={'Folder'}
            width={'100%'}
            isRequired={true}
            onValueChange={handleFolderChange}
            folder_type_id={folderTypeId}
            activity_type_id={activityType}
            value={`${folderId}`}
            flatView={false}
          />
        </View>
      )}

      {hasSubFolder && (
        <View flex={1}>
          <FolderDropDown
            compKey={'subfolder'}
            label={'Sub Folder'}
            width={'100%'}
            isRequired={false}
            onValueChange={handleSubFolderChange}
            folder_type_id={subFolderTypeId}
            activity_type_id={activityType}
            parent_folder_id={folderId}
            value={`${subFolderId}`}
            flatView={true}
          />
        </View>
      )}

      {showProjects && (
        <View flex={1}>
          <ProjectDropDown
            compKey={'project'}
            label={'Project'}
            width={'100%'}
            isRequired={true}
            onValueChange={handleProjectChange}
            parent_folder_id={projectFolderId}
            activity_type_id={activityType}
            value={`${projectId}`}
          />
        </View>
      )}
      {showActivities && (
        <View flex={1}>
          <ActivityDropDown compKey={'activity'} label={'Activity'} width={'100%'} isRequired={true} onValueChange={handleActivityChange} parent_project_id={projectId} value={`${activityId}`} />
        </View>
      )}
    </Flex>
  );
}

function CreateResponsibleUserComponent(label: string, key: string, width: string, isRequired: boolean, dataKey: string, setValue?: (key: string, value: any, isNew: boolean) => void) {
  const onChange = (value: string, new_value: string) => {
    if (setValue) setValue(dataKey, value === '' ? new_value : value, value === '');
  };
  return (
    <View flex>
      <ResponsibleUserDropDown compKey={key} label={label} width={width} isRequired={isRequired} onValueChange={onChange} />
    </View>
  );
}

export function CreateResponsibleUserFields({ settings, setFormData }: SectionFieldsProps) {
  let keys = [ContextFieldsMapping.RESPONSIBLE_USER];

  let map = generateMap(keys, settings);
  if (map.size == 0) return null;

  var obj = map.get(ContextFieldsMapping.RESPONSIBLE_USER);
  if (!obj) return null;

  return CreateResponsibleUserComponent(obj.caption ? obj.caption : obj.name, '' + obj.id, '100%', obj.mandatory == 1 ? true : false, obj.itemname, setFormData);
}

export function CreateSalesItemsFields({ settings, setFormData }: SectionFieldsProps) {
  let keys = [ContextFieldsMapping.SALES_ITEMS];

  let map = generateMap(keys, settings);
  if (map.size == 0) return null;

  const setting = map.get(ContextFieldsMapping.SALES_ITEMS);

  function setData(dataItems: any[]) {
    console.log('sales items: ', dataItems);
    if (setFormData) setFormData('salesItems', dataItems, false);
  }

  return (
    <View>
      <ProjectProductNewActivityComponent
        isNew={true}
        setData={setData}
        componentTitle="Sales Items"
        productType={0}
        quantityColumnVisible={true}
        unitPriceColumnVisible={true}
        isRequired={setting && setting.mandatory == 1 ? true : false}
      />
    </View>
  );
}

export function CreateBillingItemsFields({ settings, setFormData }: SectionFieldsProps) {
  let keys = [ContextFieldsMapping.BILLING_ITEMS];

  let map = generateMap(keys, settings);
  if (map.size == 0) return null;

  const setting = map.get(ContextFieldsMapping.BILLING_ITEMS);

  function setData(dataItems: any[]) {
    console.log('billing items: ', dataItems);
    if (setFormData) setFormData('billingItems', dataItems, false);
  }

  return (
    <View>
      <ProjectProductNewActivityComponent
        isNew={true}
        setData={setData}
        componentTitle="Billing Items"
        productType={1}
        quantityColumnVisible={true}
        unitPriceColumnVisible={true}
        isRequired={setting && setting.mandatory == 1 ? true : false}
      />
    </View>
  );
}
interface ServiceItemsFieldsProps extends SectionFieldsProps {
  projectId?: number;
}

export function CreateServiceItemsFields({ settings, setFormData, selectedItem, parentItem, projectId }: ServiceItemsFieldsProps) {
  let keys = [ContextFieldsMapping.SERVICED_ITEMS];

  let map = generateMap(keys, settings);
  if (map.size == 0) return null;

  const setting = map.get(ContextFieldsMapping.SERVICED_ITEMS);

  function setData(dataItems: any[]) {
    console.log('service items: ', dataItems);
    if (setFormData) setFormData('servicedItems', dataItems, false);
  }

  return (
    <View>
      <ProjectProductNewActivityComponent
        isNew={true}
        setData={setData}
        componentTitle="Serviced Items"
        productType={4}
        serialNumberColumnVisible={true}
        isRequired={setting && setting.mandatory == 1 ? true : false}
        selectedItem={parentItem ? parentItem : undefined}
        parentItem={selectedItem}
        projectId={projectId}
      />
    </View>
  );
}

export function CreateTechnologyItemsFields({ settings, setFormData }: SectionFieldsProps) {
  let keys = [ContextFieldsMapping.TECHNOLOGY_ITEMS];

  let map = generateMap(keys, settings);
  if (map.size == 0) return null;

  const setting = map.get(ContextFieldsMapping.TECHNOLOGY_ITEMS);

  function setData(dataItems: any[]) {
    if (setFormData) setFormData('technologyItems', dataItems, false);
  }

  return (
    <View>
      <ProjectProductNewActivityComponent isNew={true} setData={setData} componentTitle="Technology Items" productType={3} isRequired={setting && setting.mandatory == 1 ? true : false} />
    </View>
  );
}
interface MasterActivityFieldsProps extends SectionFieldsProps {
  activityType?: number;
  default_value?: string;
}
export function CreateMasterActivityFields({ setFormData, activityType, parentItem }: MasterActivityFieldsProps) {
  return (
    <Flex direction="column" gap="size-100" width={'100%'}>
      <ActivityDropDown
        compKey={'activity'}
        label={'Master Activity'}
        width={'100%'}
        isRequired={true}
        onValueChange={(value: string) => {
          if (setFormData) setFormData('activity.masterActivity', value, value === '');
        }}
        value={parentItem?.id ? `${parentItem.id}` : undefined}
        related_to_activity_type={activityType}
      />
    </Flex>
  );
}
export function CreateActivityFields({ settings, setFormData }: SectionFieldsProps) {
  let keys = [
    ContextFieldsMapping.ACTIVITY.DESCRIPTION,
    ContextFieldsMapping.ACTIVITY.FOLDER_TYPE_NAME,
    ContextFieldsMapping.ACTIVITY.SUB_FOLDER_TYPE_NAME,
    ContextFieldsMapping.ACTIVITY.TRACKING_CATEGORY_ONE,
    ContextFieldsMapping.ACTIVITY.TRACKING_CATEGORY_TWO,
    ContextFieldsMapping.ACTIVITY.TRACKING_CATEGORY_THREE,
    ContextFieldsMapping.ACTIVITY.NAME,
    ContextFieldsMapping.ACTIVITY.REF_ID,
    ContextFieldsMapping.ACTIVITY.CONTACT,
  ];

  let map = generateMap(keys, settings);

  if (map.size == 0) return null;

  let panel_info = map.get(ContextFieldsMapping.ACTIVITY.CONTACT);

  if (!panel_info || !panel_info.visibility) {
    console.warn('activity person panel is hidden.');
    return null;
  }

  return (
    <Flex direction="column" gap="size-100" width={'100%'}>
      <Flex direction={{ base: 'column', M: 'row' }} gap="size-100">
        <CreateContextFields setting={map.get(ContextFieldsMapping.ACTIVITY.DESCRIPTION)} setValue={setFormData} />
      </Flex>
      <Flex direction={{ base: 'column', M: 'row' }} gap="size-100">
        <CreateContextFields setting={map.get(ContextFieldsMapping.ACTIVITY.FOLDER_TYPE_NAME)} setValue={setFormData} />
        <CreateContextFields setting={map.get(ContextFieldsMapping.ACTIVITY.SUB_FOLDER_TYPE_NAME)} setValue={setFormData} />
      </Flex>
      <Flex direction={{ base: 'column', M: 'row' }} gap="size-100">
        <CreateContextFields setting={map.get(ContextFieldsMapping.ACTIVITY.TRACKING_CATEGORY_ONE)} setValue={setFormData} />
        <CreateContextFields setting={map.get(ContextFieldsMapping.ACTIVITY.TRACKING_CATEGORY_TWO)} setValue={setFormData} />
      </Flex>
      <Flex direction={{ base: 'column', M: 'row' }} gap="size-100">
        <CreateContextFields setting={map.get(ContextFieldsMapping.ACTIVITY.TRACKING_CATEGORY_THREE)} setValue={setFormData} />
        <CreateContextFields setting={map.get(ContextFieldsMapping.ACTIVITY.REF_ID)} setValue={setFormData} />
      </Flex>
    </Flex>
  );
}

export function CreateActivityNameField({ settings, setFormData, getFormData }: SectionFieldsProps) {
  var activity_name = {
    id: 0,
    colname: 'name1',
    visibility: 1,
    settingsId: 4,
    mandatory: 1,
    defaultval: '',
    coltype: '',
    otherparams: '',
    caption: 'Name',
    itemid: 0,
    name: ContextFieldsMapping.ACTIVITY.NAME,
    itemname: ContextFieldsMapping.ACTIVITY.NAME,
  };

  return (
    <Flex direction="column" gap="size-100" width={'100%'}>
      <CreateContextFields setting={activity_name} setValue={setFormData} value={getFormData ? getFormData(ContextFieldsMapping.ACTIVITY.NAME) : ''} />
    </Flex>
  );
}
