import { View, Accordion, Disclosure, DisclosureTitle, DisclosurePanel, Flex, RadioGroup, Radio, DateField, DatePicker, Heading, Divider } from '@adobe/react-spectrum';
import React, { useState } from 'react';
import { ContactFormData } from '../AddNewPerson';
import { parseDate } from '@internationalized/date';

interface IMailSubscriptionSection{
  data: ContactFormData['massMailSubscription'];
  onChange: (data: ContactFormData['massMailSubscription']) => void;
}

export const MailSubscriptionSection: React.FC<IMailSubscriptionSection> = ({ data, onChange }) => {
  
  const [selectedType,setSelectedType] = useState<any>("")

  return (
    <View padding="size-200" paddingBottom='size-0' paddingTop='size-300'>
      <Heading level={5}>Mass Mail Subscription</Heading>
      <Divider />
            <Flex direction="column" gap="size-100" width='100%' marginTop={10}>
              <RadioGroup
                aria-label='Type'
                value={data.type}
                onChange={(type) => {
                  onChange({ ...data, type: type as 'Allowed' | 'Forbidden' | 'Unknown' })
                  setSelectedType(type as 'Allowed' | 'Forbidden' | 'Unknown')
                }
              }
              >
                <Flex gap='size-100' direction='row'>
                  <Radio aria-label='Allowed' value="Allowed">Allowed</Radio>
                  <Radio aria-label='Forbidden' value="Forbidden">Forbidden</Radio>
                  <Radio aria-label='Unknown' value="Unknown">Unknown</Radio>
                </Flex>
              </RadioGroup>
              <Flex gap="size-100" direction='row' width='100%'>
                  <DatePicker aria-label='From' isDisabled = {selectedType === "Forbidden"}
                      value={!data.enabledFrom || selectedType === "Forbidden"
                      ? null : parseDate(data.enabledFrom)}
                      onChange={(value)=>{ 
                      if(!value) return;
                      const jsDate = new Date(value.year, value.month - 1, value.day);
                      const newData = {...data,enabledFrom:jsDate.toISOString().split("T")[0]};
                      onChange(newData);
                    }
                  } label="Enabled From" width="50%" />
                  <DatePicker aria-label='To' isDisabled = {selectedType === "Forbidden"}
                      value={!data.enabledTo || selectedType === "Forbidden" ? null : parseDate(data.enabledTo)}
                      onChange={(value)=>{ 
                      if(!value) return;
                      const jsDate = new Date(value.year, value.month - 1, value.day);
                      const newData = {...data,enabledTo:jsDate.toISOString().split("T")[0]};
                      onChange(newData);
                    }
                  } label="Enabled To" width="50%" />
              </Flex>
            </Flex>
    </View>
  );
}
  
