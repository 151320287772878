import { View, Accordion, Disclosure, DisclosureTitle, DisclosurePanel, Flex, ComboBox, ListView, Heading, Divider } from '@adobe/react-spectrum';
import { Item } from '@react-stately/collections';
import React, { useEffect, useState } from 'react';
import { ContactFormData } from '../AddNewPerson';
import { usePreloadAssets } from '../../../../../hooks/UsePreloadAssets';
import { useDependency } from '../../../../../contexts/DependencyProvider';
import { ListFolderTemplateRequest } from '../../../../../services/soap/project/requests/ListFolderTemplateRequest';

interface IContactFolderSectionProps{
  data: ContactFormData['FOLDERS'];
  onChange: (data: ContactFormData['FOLDERS']) => void;
  contactType?:string //1 for person, 2 for company
}

export const ContactFolderSection: React.FC<IContactFolderSectionProps> = ({
  data,
  onChange,
  contactType,
}) => {
  const { folderTypes } = usePreloadAssets();
  const { projectService, store } = useDependency();
  const [contactFolders, setContactFolders] = useState<{ id: string; name: string }[]>([]);

  const handleSelectionChange = (selectedKeys: Set<string>) => {
    const updatedFolders = Array.from(selectedKeys).map((id) => {
      const folder = contactFolders.find((f) => f.id === id);
      return folder ? { id: folder.id, name: folder.name } : null;
    }).filter(Boolean) as { id: string; name: string }[];

    onChange(updatedFolders);
  };

  const getListFolderTemplates = async (folderTypesArray: any) => {
    const response = await projectService.listFolderTemplate(
      new ListFolderTemplateRequest(store.Server, store.SessionId, {})
    );

    if (response.result === 'OK' && response.count > 0) {
      const filteredTemplates = response.FOLDERTEMPLATE.filter(
        (x) => x.contactType.toString() === contactType
      );

      if (filteredTemplates.length > 0) {
        const templateFolderTypes = new Set(
          filteredTemplates.map((folder) => folder.folderType)
        );

        const filteredFolders = folderTypesArray
          .filter((item: any) => templateFolderTypes.has(item.id))
          .map((item: any) => ({ id: item.id, name: item.name }));

        setContactFolders(filteredFolders);
      }
    }
  };

  useEffect(() => {
    const folderTypesArray = Object.entries(folderTypes).map(([key, value]) => ({
      id: key,
      ...value,
    }));
    getListFolderTemplates(folderTypesArray);
  }, [folderTypes]);

  return (
    <View padding="size-200" paddingBottom="size-0" paddingTop="size-300">
      <Heading level={5}>Contact Folders</Heading>
      <Divider aria-label='' />
      <Flex direction="column" gap="size-100" marginTop={10}>
        <ListView
          aria-label='Contact Folder'
          selectionMode="multiple"
          selectedKeys={new Set(data.map((folder) => folder.id))}
          onSelectionChange={(keys) => handleSelectionChange(keys as Set<string>)}
          width="100%"
        >
          {contactFolders.map((folder) => (
            <Item aria-label={folder.name} key={folder.id}>{folder.name}</Item>
          ))}
        </ListView>
      </Flex>
    </View>
  );
};

