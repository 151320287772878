import { LayoutComponent } from '../LayoutComponent';
import { Flex, View } from '@adobe/react-spectrum';
import { useTranslation } from 'react-i18next';
import { FPAData } from '../../../../infra/protected/FPA/FPAData';
import SignatureControls from './component/SignatureControls';
import styles from './SignComponent.module.css';
export interface ISignComponentProps {
  readonly isSignatureRequired?: boolean;
  readonly minHeight?: number;
  readonly numSignatures?: number;
  readonly percentWidth?: number;
  readonly signatureColor?: number;
  readonly signatureIdent?: string;
  readonly signatureSize?: number;
  readonly signatureTitle?: string;
  readonly signature2Title?: string;
  readonly signedStatusId?: string;
  readonly selectedItem?: FPAData;
}

function Signature({
  isSignatureRequired,
  minHeight,
  percentWidth,
  signatureColor,
  signatureSize,
  signedStatusId,
  selectedItem,
  numSignatures,
  signatureIdent,
  signatureTitle,
  signature2Title,
}: ISignComponentProps) {
  //console.log('signedStatusId', signedStatusId);
  const { t } = useTranslation();
  return (
    <Flex width={'100%'} direction={'column'} alignItems={'start'} justifyContent={'start'} gap={'16px'} position={'relative'} marginBottom={'20px'} UNSAFE_className={styles.signature_parent}>
      <Flex direction={'column'} alignItems={'start'} justifyContent={'start'} gap={'12px'}>
        <View alignSelf={'stretch'} position={'relative'} UNSAFE_className={styles.label_heading}>
          {numSignatures === 2 ? t('signatures', { ns: 'layout_components' }) : signatureTitle ?? t('signature', { ns: 'layout_components' })}
        </View>
        <View alignSelf={'stretch'} position={'relative'} UNSAFE_className={styles.label_text}>
          {t('before_signing_the_document_fill_in_your_name_and_surname_below', { ns: 'layout_components' })}
        </View>
      </Flex>
      <SignatureControls
        signatureColor={signatureColor}
        signatureSize={signatureSize}
        signedStatusId={signedStatusId}
        selectedItem={selectedItem}
        numSignatures={numSignatures}
        signatureIdent={signatureIdent}
        signatureTitle={signatureTitle}
        signature2Title={signature2Title}
      />
    </Flex>
  );
}

export const SignComponent = LayoutComponent(Signature);
