import { BaseRequest, IBaseRequest } from '../../BaseRequest';

export interface IGetListMessageRequest extends IBaseRequest {
    listId: string;
}

export class GetListMessageRequest extends BaseRequest implements IGetListMessageRequest {

    listId: string;

  constructor(server: string, session: string, listId: string) {
    super(server, session);
    this.listId = listId;
  }

  public toXml(): string {
    var parameters: string = super.toXml();

    parameters += `<listId>${this.listId}</listId>`;

    return super.wrapperXml('GetListMessage', parameters);
  }
}
