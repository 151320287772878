import React, { useEffect, useState } from "react";
import { Picker, Item, ListBox, Text, ComboBox, Grid } from "@adobe/react-spectrum";
import { useAsyncList } from "@react-stately/data";
import { useDependency } from "../../../../../contexts/DependencyProvider";
import { ListCityRequest } from "../../../../../services/soap/translate/reqeusts/ListCityRequest";
import { ListZipRequest } from "../../../../../services/soap/translate/reqeusts/ListZipRequest";


export interface Character {
    id:string;
    name: string;
    zip:string;
    zipId:string;
    zipName:string;
    city:string;
    cityName:string;
}

interface IZipCodeDropDownProps{
  selectedZip:string;
  onChange:(data:Character)=>void;
}

const ZipCodeDropDown:React.FC<IZipCodeDropDownProps> = ({selectedZip,onChange})=> {
  const { store, translateService } = useDependency();
  const [selectedKey, setSelectedKey] = useState<string | null>(null);

  useEffect(()=>{
     list.reload();
  },[])

  useEffect(() => {
        if (selectedZip) {
          setSelectedKey(selectedZip);
          list.reload();
        } else {
          setSelectedKey(null);
        }
      }, [selectedZip]);

  const list = useAsyncList<Character>({
    async load({ cursor,filterText }) {
      const offset = cursor ? Number(cursor) : 0;
      const limit = 50;
      try {
        const request = new ListZipRequest(store.Server, store.SessionId, { limit, offset,
          ...(filterText ? { zipName: filterText } : { zipId: selectedZip })
        });
        const response = await translateService.listZip(request);

        const cities = response?.ROWS?.ROW || [];
        if (!Array.isArray(cities)) {
          console.error("Invalid API response: Expected an array", response);
          return { items: [] };
        }

        return {
          items: cities,
          cursor: cities.length === limit ? String(offset + limit) : undefined,
        };
      } catch (error) {
        console.error("Error fetching cities:", error);
        return { items: [] };
      }
    },
  });

  const handleChange = (data:any)=>{
    setSelectedKey(data);
    const selectedzip = list.items.find(x=>x.zip === data);
    if(selectedzip){
      onChange(selectedzip)
    }
  }

  return (
    <ComboBox
    label="Zip"
    aria-label="Zip"
    items={list.items}
    selectedKey={selectedKey}
    onInputChange={list.setFilterText}
    loadingState={list.loadingState}
    onLoadMore={list.loadMore}
    onSelectionChange={handleChange}
    width="100%"
>
  {(item) => (
    <Item key={item.zip} textValue={item.name}>
      {`${item.name ? item.name : ''} ${item.cityName ? item.cityName : ''}`}
    </Item>
  )}
</ComboBox>
  );
};

export default ZipCodeDropDown;
